import {gridCustomizationConstants} from '../constants/gridCustomizationConstants';
import {deepCopy} from '../../app/utils/helpers';
let initialState = {};

export const gridCustomizationReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case gridCustomizationConstants.SORT_GRID: {
            const filters = state.customization && state.customization.filters ? [...state.customization.filters] : null;
            return {
                ...state,
                customization: {
                    ...state.customization,
                    sort: [{
                        field: payload.field,
                        direction: payload.direction,
                    }],
                    filters
                }
            };
        }

        case gridCustomizationConstants.FILTER_GRID: {
            const sort = setSort(state);
            let filtersArr = [];
            if (state.customization && state.customization.filters) {
                filtersArr = [...state.customization.filters];
            }

            const foundFilterField = filtersArr.find(item => item.field === payload.field && item.type === payload.type);
            const filter = {
                field: payload.field,
                type: payload.type,
                value: payload.value
            }

            // Add new filter
            if (!foundFilterField) {
                filtersArr = [...filtersArr, filter];
            }

            // Remove filter
            if (foundFilterField && filter.value == '') {
                const index = filtersArr.findIndex(el => el.field === filter.field);
                filtersArr.splice(index, 1);
            }
            // Update filter
            if (foundFilterField && ((!Array.isArray(filter.value) && filter.value !== '') || filter.value.length > 0)) {
                filtersArr[filtersArr.findIndex(el => el.field === filter.field)] = filter;
            }

            return {
                ...state,
                customization: {
                    ...state.customization,
                    sort,
                    filters: filtersArr
                }
            };
        }
        case gridCustomizationConstants.FILTER_OPTIONS_GRID: {
            const sort = setSort(state);
            let filtersArr = [];
            if (state.customization && state.customization.filters) {
                filtersArr = deepCopy(state.customization.filters);
            }
            const foundFilterField = filtersArr.find(item => item.field === payload.field && item.type === payload.type);
            const filterFieldIndex = filtersArr.indexOf(foundFilterField);
            const filter = {
                field: payload.field,
                type: payload.type,
                value: [payload.value],
                filterOptions: payload.filterOptions
            }
            // Adding new field
            if (!foundFilterField) {
                filtersArr = [...filtersArr, filter];
            }
            // Existing field, adding or removing value
            if (foundFilterField) {
                let filterItemArr = [...foundFilterField.value];
                const index = foundFilterField.value.findIndex(x => x == payload.value);
                // Add new filter value
                if (index == -1) {
                    filtersArr[filterFieldIndex].value = [...filterItemArr, payload.value];
                }
                else {
                    filterItemArr.splice(index, 1);
                    filtersArr[filterFieldIndex].value = filterItemArr;
                }

                // Remove the found column filter if no values in the filter
                if (foundFilterField.value.length == 0){
                    filtersArr.splice(filterFieldIndex, 1);
                }
            }
            return {
                ...state,
                customization: {
                    ...state.customization,
                    sort,
                    filters: filtersArr
                }
            }
        }
        case gridCustomizationConstants.TOGGLE_FILTER_ALL: {
            const sort = setSort(state);
            let filtersArr = [];
            if (state.customization && state.customization.filters) {
                filtersArr = deepCopy(state.customization.filters);
            }
            const foundFilterField = filtersArr.find(item => item.field === payload.field && item.type === payload.type);
            const filter = {
                field: payload.field,
                type: payload.type,
                value: payload.value,
                filterOptions: payload.filterOptions
            }
            // Adding new field
            if (!foundFilterField) {
                filtersArr = [...filtersArr, filter];
            } else {
                const filterFieldIndex = filtersArr.indexOf(foundFilterField);
                filtersArr.splice(filterFieldIndex, 1);
            }
            return {
                ...state,
                customization: {
                    ...state.customization,
                    sort,
                    filters: filtersArr
                }
            };
        }

        case gridCustomizationConstants.CLEAR_ALL_GRID_FILTERS: {
            return {
                ...state,
                customization: {
                    ...state.customization,
                    sort: null,
                    filters: []
                }
            };
        }

        case gridCustomizationConstants.SET_FILTER_OPTIONS: {
            return {
                ...state,
                customization: {
                    ...state.customization,
                    allFilterOptions: payload
                }
            };
        }

        default:
            return state
    }
}
function setSort(state) {
    return state.customization && state.customization.sort ? [...state.customization.sort] : null;
}
