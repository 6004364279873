import {valueKind} from './formatUtils';

export const trendType = {
    positive: 'positive',
    negative: 'negative'
}

export const trendDirection = {
    up: 'up',
    down: 'down',
    none: 'none'
}

export const counterTypes = {
    'revenue' : {
        kind: valueKind.money,
        title: 'Revenue',
        icon: 'stackoverflow.svg',
        trend: trendType.positive
    },
    'ecpm' : {
        kind: valueKind.moneyWihDecimal,
        title: 'eCPM',
        // icon: 'ecpm.svg',
        trend: trendType.positive
    },
    'payable_units' : {
        kind: valueKind.thousands,
        title: 'Impressions',
        icon: 'asterisk.svg',
        trend: trendType.positive
    },
    'fill-rate' : {
        kind: valueKind.percent,
        title: 'Fill Rate',
        icon: 'list.svg',
        trend: trendType.positive,
        deliveryType:'* Direct Only'
    },
    'win-rate' : {
        kind: valueKind.percent,
        title: 'HB Win Rate',
        trend: trendType.positive,
        icon: 'hammer-kpis.svg'
    },
    'bid-rate' : {
        kind: valueKind.percent,
        title: 'Bid Rate',
        trend: trendType.positive,
        icon: 'stack.svg'
    }
}
