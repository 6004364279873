export const defaultWidgetsConfig= [{
    type: 'main-kpis',
    kind: 'stripe',
    component: 'MainKpisStripeWidget',
    static: true
},
{
    type: 'revenue-chart',
    kind: 'chart',
    component: 'RevenueChartWidget'
},
{
    type: 'platform-chart',
    kind: 'chart',
    component: 'PlatformChartWidget'
},
{
    type: 'top-domains-chart',
    kind: 'chart',
    component: 'TopDomainsChartWidget'
},
{
    type: 'top-adunits-grid',
    kind: 'grid',
    component: 'TopAdunitsGridWidget'
}
];