import React from 'react';
import PropTypes from 'prop-types';
import {formatToEstDate} from '../imports';

const AdslotsDateFormatter = (props) => {
    const {value} = props;
    return (
        <div>
            <div data-automation={`ad-slot-string-value-${formatToEstDate(value)}`}>{formatToEstDate(value)}</div>
        </div>
    )
}

AdslotsDateFormatter.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
};

export default AdslotsDateFormatter;