import _ from 'lodash';
const DEFAULT_ID = 'code';
class MetadataProvider {
    constructor() {
        this.metadataList = null;
        this.metadataMap = null;
    }


    init = (metadata, requestedMetadata) => {
        if (metadata) {
            this.metadataList = Object.freeze(metadata);
            this.metadataMap = Object.freeze(Object.keys(metadata).reduce((res, key) => {
                if (!_.isEmpty(_.get(metadata, key))) {
                    res[key] = metadata[key].reduce((md, curr) => {
                        const requested = _.find(requestedMetadata, item => item === key || item.name === key);
                        const identifier = requested && requested.valueKey ? curr[requested.valueKey] : curr[DEFAULT_ID];
                        md[identifier] = {...curr};
                        return md;
                    }, {});
                }
                return res;
            }, {}));
        }
    };

    getAsList = (key) => {
        return _.get(this.metadataList, key, []);
    };

    getAsMap = (key) => {
        return this.metadataMap[key];
    };
}

const metadataProvider = new MetadataProvider();

export default metadataProvider;
