import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const CLASS_NAMES = {
    INACTIVE: 'grid-row-inactive',
};

export default class GridRow extends Component {
    render() {
        const {renderBaseRow, ...rest} = this.props;
        return (<div>{renderBaseRow({
            ...rest,
            extraClasses: cx({[CLASS_NAMES.INACTIVE]: this.props.row.status === 'inactive'})
        })}</div>);
    }
}

GridRow.propTypes = {
    renderBaseRow: PropTypes.func,
    row: PropTypes.object,
};
