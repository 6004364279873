import widgetTypes from './widgetType';
import {defaultWidgetsConfig} from './defaultWidgetsConfig';

import {lazy} from "react";

export const registerWidgets = (data) => {
    const widgetData = data ? data : defaultWidgetsConfig;
    for (const widget of widgetData) {
       const component = lazy(() => import(`../components/${widget.component}.js`));
       widgetTypes.registerType({
                type: widget.type,
                kind: widget.kind,
                component
      })
    }
}
