import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {selectFeatureFlag} from '@flopflip/react-redux';
import {Button} from '@perion-undertone/ut-react-common';

import Image from './../Image';
import {isPublisherUserOrPublisherAdmin} from '../../auth';
import SurveyPopup from './SurveyPopup';
import {CN, TXT, getTriggerTimeInSeconds, getSurvey, saveSurvey} from './surveyUtils';

const SurveyContainer = (props) => {
    const [displayPopup, setDisplayPopup] = useState(false);
    const [triggerPopup, setTriggerPopup] = useState(false);
    const {isSurveyFeatureEnabled, user} = props;
    let survey = getSurvey();

    useEffect(() => {
        if (!triggerPopup) {
            triggerSurveyPopup();
        }
    });

    const triggerSurveyPopup = () => {
        survey = getSurvey();
        if (survey) {
            setTimeout(() => {
                if (survey.shouldDisplay && !displayPopup) {
                    setDisplayPopup(true);
                    setTriggerPopup(true);
                }
            }, getTriggerTimeInSeconds(survey));
        }
    }

    const onClickHelpUsImprove = () => {
        setDisplayPopup(true);
        survey = getSurvey();
        if (survey) {
            survey.shouldDisplay = false;
        }
        saveSurvey(survey);
    }

    const closeSurveyPopup = () => {
        setDisplayPopup(false);
        survey = getSurvey();
        if (survey) {
            survey.shouldDisplay = false;
        }
        saveSurvey(survey);
    }

    if (!isPublisherUserOrPublisherAdmin(user) || !isSurveyFeatureEnabled) {
        return null;
    }

    if (!survey || survey.isSubmitted) {
        return null;
    }

    return (
        <div>
            {!displayPopup &&
                <Button className={CN.SURVEY_TRIGGER_BUTTON}
                        onClick={onClickHelpUsImprove}
                >
                    {TXT.HELP_US_IMPROVE}
                    <Image name='hearts.png' />
                </Button>
            }
            {displayPopup &&
                <SurveyPopup closeSurveyPopup={closeSurveyPopup}
                             link={survey.link}
                />
            }
        </div>
    );
}

SurveyContainer.propTypes = {
    user: PropTypes.object,
    isSurveyFeatureEnabled: PropTypes.bool,
    closeSurveyPopup: PropTypes.func
};

const mapStateToProps = (state) => ({
    user: state.app.user.data,
    isSurveyFeatureEnabled: selectFeatureFlag('surveyPopup')(state)
});

export default connect(mapStateToProps)(SurveyContainer);