import {appStates} from '../constants/appStates';
import {advertisersConstants} from '../constants/advertisersConstants';
import {reducerHelper} from './reducerHelper';

const initialState = {};

export const advertisersReducer = (state = initialState, action) => {
    const {type, payload} = action;
    const advertisersList = 'advertisersList';

    switch (type) {
        case advertisersConstants.GET_ADVERTISERS_REQUEST:
            return reducerHelper(state, advertisersList, undefined, appStates.LOADING);
        case advertisersConstants.GET_ADVERTISERS_SUCCESS:
            return reducerHelper(state, advertisersList, payload.advertisersList, appStates.LOADED);
        case advertisersConstants.GET_ADVERTISERS_FAILURE:
            return reducerHelper(state, advertisersList, undefined, appStates.ERROR);
        default:
            return state;
    }
}