import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import AdUnitGroup from './AdUnitGroup';
import {appStates, textProvider}  from '../imports';

const CLASS_NAMES = {
    AD_UNITS_LIST_CONTAINER: 'ad-units-list-container',
    AD_UNITS_LIST: 'ad-units-list'
};

const CONSTANT_TEXTS = {
    LOADING: textProvider.getText('common', 'loading')
};

const AdUnitsList = (props) => {
    const {adUnits, tagKey} = props;
    return (
        <div className={CLASS_NAMES.AD_UNITS_LIST_CONTAINER}>
            {adUnits.state === appStates.LOADED && tagKey ?
                <AdUnitGroup key={tagKey} adUnitKey={tagKey}></AdUnitGroup>
                : <div>{CONSTANT_TEXTS.LOADING}</div>}
        </div>
    );
}

AdUnitsList.propTypes = {
    adUnits: PropTypes.object,
    tagKey: PropTypes.string
};

const mapStateToProps = ({placements}) => ({
    adUnits: placements.adUnits
})

export default connect(mapStateToProps)(AdUnitsList);