import React, {Component} from 'react';
import ReactDataGrid from 'react-data-grid';
import PropTypes from 'prop-types';
import {AutoSizer} from 'react-virtualized';
import getBlockedAdvertisersGridSchema from './blockedAdvertisersGridSchema';
import GridNoRowsTemplate from '../../../GridNoRowsTemplate';
import {Animation} from '@perion-undertone/ut-react-common';
import {appStates} from '../imports';

const CLASS_NAMES = {
    CONTAINER: 'block-advertisers-grid-container',
    INNER_CONTAINER: 'block-advertisers-grid-inner-container',
};

class BlockedAdvertisersGrid extends Component {
    filterBlockedAdvertisers = (blockedAdvertisers, searchTerm) => {
        if (!blockedAdvertisers.data) {
            return [];
        }
        if (searchTerm) {
            const lcaseTerm = searchTerm.toString().toLowerCase();
            return blockedAdvertisers.data.filter(blockedAdv =>
                blockedAdv.advertiserId.toString().includes(lcaseTerm) ||
                blockedAdv.Advertiser.name.toLowerCase().includes(lcaseTerm))
        }
        return blockedAdvertisers.data;
    }

    render() {
        const {blockedAdvertisers, isPublisherLevel, isBulkBlock, publishersSupplySourcesIds} = this.props;
        const searchTerm = blockedAdvertisers.searchTerm;
        const rowSource = this.filterBlockedAdvertisers(blockedAdvertisers, searchTerm);
        const blockedAdvertisersGridSchema = getBlockedAdvertisersGridSchema(isPublisherLevel, isBulkBlock, publishersSupplySourcesIds);
        return (<div className={CLASS_NAMES.CONTAINER}>
            {appStates.LOADED === blockedAdvertisers.state
                ? (<div className={CLASS_NAMES.INNER_CONTAINER}>
                    <AutoSizer>
                        {({height, width}) => {
                            return (<ReactDataGrid columns={blockedAdvertisersGridSchema}
                                                   enableCellAutoFocus={false}
                                                   rowGetter={i => rowSource[i]}
                                                   rowsCount={rowSource.length}
                                                   rowHeight={56}
                                                   headerRowHeight={41}
                                                   minWidth={width}
                                                   minHeight={height}
                                                   emptyRowsView={GridNoRowsTemplate}
                            />)
                        }}
                    </AutoSizer>
                </div>)
                : <Animation type="chasing"
                             height={100}
                             width={100}
                             rendererSettings={{preserveAspectRatio: 'xMaxYMax'}}
                             hookId='blocked-advertisers-loading'
                />}
        </div>)
    }
}

BlockedAdvertisersGrid.propTypes = {
    blockedAdvertisers: PropTypes.object,
    publishersSupplySourcesId: PropTypes.number,
    publisherId: PropTypes.number,
    isPublisherLevel: PropTypes.bool,
    isBulkBlock: PropTypes.bool,
    publishersSupplySourcesIds: PropTypes.array
};

export default BlockedAdvertisersGrid;
