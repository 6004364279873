import React from 'react';
import PropTypes from 'prop-types';
import {TextEllipsis} from '@perion-undertone/ut-react-common';

const NameFormatter = ({value}) => {
    return (
        <TextEllipsis placement="top">
            {value}
        </TextEllipsis>
    );
};

NameFormatter.propTypes = {
    value: PropTypes.string,
};

export default NameFormatter;