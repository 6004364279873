import React, {useState, useCallback} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Select, Icon} from '@perion-undertone/ut-react-common';

import textProvider from '../../texts';
import {
    redirectTo,
    fetchPublishers,
    SearchPublisherOption,
    MIN_SEARCH_QUERY_LENGTH
} from './imports';

const CLASS_NAMES = {
    SEARCH_PUBLISHER: 'search-publisher',
    SELECT_LIST_CONTAINER: 'select-publisher-container',
    SELECT_PUBLISHER_ACCOUNTS: 'select-publisher-accounts'
};

const CONSTANT_TEXTS = {
    START_TYPING: textProvider.getText('selectPublisher', 'startTyping')
};

const IconSearch = () => <Icon icon='icon-search' />;

const SearchPublisher = (props) => {
    const {publishers, fetchPublishers, autoFocus, defaultSelectedPublisher} = props;
    const [selectedPublisher, setSelectedPublisher] = useState(defaultSelectedPublisher);
    const [inputValue, setInputValue] = useState('');

    const getOptionLabel = (opt) => (opt.name);

    const getOptionValue = (opt) => (opt.publisherId);

    const handlePublisherChanges = useCallback((selectedPublisher) => {
        setSelectedPublisher(selectedPublisher);
        redirectTo(`/${selectedPublisher.publisherId}/dashboard`);
        location.reload();
    }, [selectedPublisher]);

    const handleInputChange = useCallback((inputValue) => {
        setInputValue(inputValue);
        if (inputValue.length >= MIN_SEARCH_QUERY_LENGTH) {
            fetchPublishers({searchTerm: inputValue});
        }
    }, [fetchPublishers, inputValue]);

    const options = !!publishers && Array.isArray(publishers.data) && publishers.data.length > 0
        && inputValue && inputValue.length >= MIN_SEARCH_QUERY_LENGTH ? publishers.data : [];

    return (
        <div className={CLASS_NAMES.SELECT_LIST_CONTAINER}>
            <div className={CLASS_NAMES.SELECT_PUBLISHER_ACCOUNTS}>
                <Select className={CLASS_NAMES.SEARCH_PUBLISHER}
                        isMulti={false}
                        value={selectedPublisher}
                        cacheOptions
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        isOptionDisabled={false}
                        options={options}
                        onChange={handlePublisherChanges}
                        onInputChange={handleInputChange}
                        noOptionsMessage={() => (CONSTANT_TEXTS.START_TYPING)}
                        components={
                            {
                                Option: SearchPublisherOption,
                                DropdownIndicator: IconSearch
                            }
                        }
                        hookId="publisher-select"
                        transparent
                        autoFocus={autoFocus}
                        placeholder=''
                />
            </div>
        </div>
    );
}

SearchPublisher.propTypes = {
    fetchPublishers: PropTypes.func,
    defaultSelectedPublisher: PropTypes.object,
    publishers: PropTypes.object,
    autoFocus: PropTypes.bool
};

const mapStateToProps = ({app}) => ({
    publishers: app.publishers
});

const mapDispatchToProps = {
    fetchPublishers
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPublisher);