import {appStates} from '../constants/appStates';
import {emailConstants} from '../constants/emailConstants';

const initialState = {
    send: {
        state: appStates.DEFAULT
    }
};

export const emailReducer = (state = initialState, action) => {
    const {type} = action;
    switch (type) {
        case emailConstants.SUBMIT_EMAIL: {
            return {
                ...state,
                send: {
                    state: appStates.LOADING
                }
            };
        }
        case emailConstants.SUBMIT_EMAIL_SUCCESS: {
            return {
                ...state,
                send: {
                    state: appStates.LOADED
                }
            };
        }
        case emailConstants.SUBMIT_EMAIL_FAILURE: {
            return {
                ...state,
                send: {
                    state: appStates.ERROR
                }
            };
        }

        case emailConstants.RESET_EMAIL_STATE: {
            return initialState;
        }

        default:
            return state;
    }
};
