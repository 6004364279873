import {supplyConstants} from '../constants/supplyConstants';
import {appConstants} from "../constants/appConstants";
import {appStates} from "../constants/appStates";
import {publisherStates} from "../constants/publisherStates";
import {reducerHelper} from './reducerHelper';
import {deepCopy} from '../utils/helpers';
import {gridCustomizationConstants} from "../constants/gridCustomizationConstants";
import {publisherConstants} from '../constants/publisherConstants';
import {ToastProvider} from '@perion-undertone/ut-react-common';
import _ from 'lodash';
const initialState = {
    page: 1,
    itemsPerPage: 20,
    publisher: {
        loadingState: publisherStates.DEFAULT,
    },
};

export const supplyReducer = (state = initialState, action) => {
    const {type, payload} = action;
    const supplySources = 'supplySources';
    const blockedAdvertisers = 'blockedAdvertisers';
    const blockedCategories = 'blockedCategories';
    const bulkBlockedCategories = 'bulkBlockedCategories';
    const videoLimitations = 'videoLimitations';

    switch (type) {
        case supplyConstants.UPDATE_SYSTEM_PIXEL_SUCCESS: {
            const updatedpubSupplySource = _.first(payload.publisherSupplySources);
            const pubSources = _.map(state.supplySources.data, pubSupplySource =>{
                    if(updatedpubSupplySource.publishersSupplySourcesId === pubSupplySource.publishersSupplySourcesId){
                        return {
                            ...pubSupplySource,
                            useSystemAppendPixel: updatedpubSupplySource.useSystemAppendPixel
                        }
                    }
                    return pubSupplySource;
            })
            
            return {
                ...state,
                supplySources: {
                    ...state.supplySources,
                    data: pubSources
                }
            };
          
        }

        // Get Supply sources
        case supplyConstants.SUPPLY_SOURCES_REQUEST: {
            return {
                ...state,
                supplySources: {
                    ...state.supplySources,
                    state: appStates.LOADING
                }
            }
        }
        case supplyConstants.SUPPLY_SOURCES_SUCCESS: {
            return {
                ...state,
                supplySources: {
                    state: appStates.LOADED,
                    data: payload.supplySources,
                    count: payload.count
                }
            }
        }
        case supplyConstants.SUBMIT_SITES_REQUEST: {
            return {
                ...state,
                itemsPerPage: initialState.itemsPerPage,
                page: initialState.page
            }
        }
        case supplyConstants.SUPPLY_SOURCES_FAILURE:
            return reducerHelper(state, supplySources, undefined, appStates.ERROR);

        case gridCustomizationConstants.FILTER_GRID:
            return {
                ...state,
                itemsPerPage: initialState.itemsPerPage,
                page: initialState.page
            }

        case gridCustomizationConstants.FILTER_OPTIONS_GRID:
            return {
                ...state,
                itemsPerPage: initialState.itemsPerPage,
                page: initialState.page
            }

        case supplyConstants.CHANGE_ITEMS_PER_PAGE:
            return {
                ...state,
                page: initialState.page,
                itemsPerPage: payload.itemsPerPage
            }

        case supplyConstants.CHANGE_PAGE:
            return {
                ...state,
                page: payload.page
            }

        case supplyConstants.OPEN_ADD_SITES_MODAL:
            return {
                ...state,
                openSitesModal: true,
                selectedSites: []
            };

        case supplyConstants.CLOSE_ADD_SITES_MODAL:
            return {
                ...state,
                openSitesModal: false,
                selectedSites: []
            };

        case supplyConstants.ADD_SELECTED_SITE: {
            return {
                ...state,
                selectedSites: [
                    ...state.selectedSites,
                    {
                        data: payload.site,
                        state: appStates.DEFAULT
                    }
                ]
            }
        }
        case supplyConstants.DELETE_SELECTED_SITE:
            return {
                ...state,
                selectedSites: state.selectedSites.filter(item => item.data.url !== payload.site.url)
            };

        case supplyConstants.SUBMIT_SITES_SUCCESS:
            return {
                ...state,
                openSitesModal: false,
                selectedSites: [],
                supplySources: {
                    data: payload.supplySources,
                    count: payload.count,
                    state: appStates.LOADED
                }
            };
        case supplyConstants.SEARCH_SUPPLY_SITES:
        return {
            ...state,
            supplySources: {
                ...state.supplySources,
                searchTerm: payload.searchTerm
            }
        };

        case supplyConstants.VALIDATE_DOMAIN_REQUEST: {
            const {url} = payload;
            if (state.selectedSites) {
                const selectedSites = deepCopy(state.selectedSites);
                const currentSite = selectedSites.find(site => site.data.url === url);
                currentSite.state = appStates.LOADING;

                return {
                    ...state,
                    selectedSites
                };
            }

            return {
                ...state
            };
        }

        case supplyConstants.VALIDATE_DOMAIN_SUCCESS: {
            const {domainData} = payload;
            if (state.selectedSites) {
                const selectedSites = deepCopy(state.selectedSites);
                const currentSite = selectedSites.find(site => site.data.url === domainData.domain);
                currentSite.state = appStates.LOADED;
                currentSite.data.hasValidAdsTxt = domainData.exists;
                return {
                    ...state,
                    selectedSites
                };
            }
            return {
                ...state,
                domainData
            };
        }

        case supplyConstants.VALIDATE_DOMAIN_FAILURE: {
            const {url, error} = payload;
            if (state.selectedSites) {
                const selectedSites = deepCopy(state.selectedSites);
                const currentSite = selectedSites.find(site => site.data.url === url);
                currentSite.state = appStates.ERROR;
                currentSite.error = error;

                return {
                    ...state,
                    selectedSites
                };
            }

            return {
                ...state
            };
        }

        case supplyConstants.SET_ADSTXT_DOMAIN_REQUEST:
            return {
                ...state,
                validatingAdsTxt: true
            };

        case supplyConstants.SET_ADSTXT_DOMAIN_SUCCESS:
            return {
                ...state,
                supplySources: {
                    ...state.supplySources,
                    data: payload.supplySources
                },
                validatingAdsTxt: false,
                validatedDomain: payload.supplySources.find(site => site.publishersSupplySourcesId === state.validatedDomain.publishersSupplySourcesId)
            };

        case supplyConstants.SET_ADSTXT_DOMAIN_FAILURE:
            return {
                ...state,
                validatingAdsTxt: false
            };

        case supplyConstants.OPEN_ADS_TXT_MODAL:
            return {
                ...state,
                isOpenAdsTxtModal: true,
                validatedDomain: payload.validatedDomain
            };
        case supplyConstants.CLOSE_ADS_TXT_MODAL:
            return {
                ...state,
                isOpenAdsTxtModal: false,
                validatedDomain: undefined
            };
        // Get Blocked Advertisers
        case supplyConstants.GET_BLOCKED_ADVERTISERS_REQUEST:
            return reducerHelper(state, blockedAdvertisers, undefined, appStates.LOADING);
        case supplyConstants.GET_BLOCKED_ADVERTISERS_SUCCESS:
            return reducerHelper(state, blockedAdvertisers, payload.blockedAdvertisers, appStates.LOADED);
        case supplyConstants.GET_BLOCKED_ADVERTISERS_FAILURE:
            return reducerHelper(state, blockedAdvertisers, undefined, appStates.ERROR);

        // Block Advertisers
        case supplyConstants.BLOCK_ADVERTISERS_REQUEST:
            return reducerHelper(state, blockedAdvertisers, undefined, appStates.LOADING);
        case supplyConstants.BLOCK_ADVERTISERS_SUCCESS:
            return reducerHelper(state, blockedAdvertisers, payload.blockedAdvertisers, appStates.LOADED);
        case supplyConstants.BLOCK_ADVERTISERS_FAILURE:
            return reducerHelper(state, blockedAdvertisers, undefined, appStates.ERROR);

        // Search Blocked Advertisers
        case supplyConstants.SEARCH_BLOCKED_ADVERTISERS:
            return {
                ...state,
                blockedAdvertisers: {
                    ...state.blockedAdvertisers,
                    searchTerm: payload.searchTerm
                }
            };

        // Get Blocked Categories
        case supplyConstants.GET_BLOCKED_CATEGORIES_REQUEST:
            return reducerHelper(state, blockedCategories, undefined, appStates.LOADING);
        case supplyConstants.GET_BLOCKED_CATEGORIES_SUCCESS:
            return reducerHelper(state, blockedCategories, payload.blockedCategories, appStates.LOADED);
        case supplyConstants.GET_BLOCKED_CATEGORIES_FAILURE:
            return reducerHelper(state, blockedCategories, undefined, appStates.ERROR);

        // Block Categories
        case supplyConstants.BLOCK_CATEGORIES_REQUEST:
            return reducerHelper(state, blockedCategories, undefined, appStates.LOADING);
        case supplyConstants.BLOCK_CATEGORIES_SUCCESS:
            return reducerHelper(state, blockedCategories, payload.blockedCategories, appStates.LOADED);
        case supplyConstants.BLOCK_CATEGORIES_FAILURE:
            return reducerHelper(state, blockedCategories, undefined, appStates.ERROR);

        // Get bulk Blocked Categories by Supply Sources Ids
        case supplyConstants.GET_BULK_BLOCKED_CATEGORIES_REQUEST:
            return reducerHelper(state, bulkBlockedCategories, undefined, appStates.LOADING);
        case supplyConstants.GET_BULK_BLOCKED_CATEGORIES_SUCCESS:
            return reducerHelper(state, bulkBlockedCategories, payload.bulkBlockedCategories, appStates.LOADED);
        case supplyConstants.GET_BULK_BLOCKED_CATEGORIES_FAILURE:
            return reducerHelper(state, bulkBlockedCategories, undefined, appStates.ERROR);

        // Bulk Block/Unblock Categories
        case supplyConstants.BULK_BLOCK_CATEGORIES_REQUEST:
            return reducerHelper(state, bulkBlockedCategories, undefined, appStates.LOADING);
        case supplyConstants.BULK_BLOCK_CATEGORIES_SUCCESS:
            return reducerHelper(state, bulkBlockedCategories, payload.bulkBlockedCategories, appStates.LOADED);
        case supplyConstants.BULK_BLOCK_CATEGORIES_FAILURE:
            return reducerHelper(state, bulkBlockedCategories, undefined, appStates.ERROR);

        // Get Video Limitations by Supply Sources Ids
        case supplyConstants.GET_VIDEO_LIMITATIONS_REQUEST:
            return reducerHelper(state, videoLimitations, undefined, appStates.LOADING);
        case supplyConstants.GET_VIDEO_LIMITATIONS_SUCCESS:
            return reducerHelper(state, videoLimitations, payload.videoLimitations, appStates.LOADED);
        case supplyConstants.GET_VIDEO_LIMITATIONS_FAILURE:
            return reducerHelper(state, videoLimitations, undefined, appStates.ERROR);

        // Manage Video Limitations by Supply Sources Ids
        case supplyConstants.MANAGE_VIDEO_LIMITATIONS_REQUEST:
            return reducerHelper(state, videoLimitations, undefined, appStates.LOADING);
        case supplyConstants.MANAGE_VIDEO_LIMITATIONS_SUCCESS:
            return reducerHelper(state, videoLimitations, payload.videoLimitations, appStates.LOADED);
        case supplyConstants.MANAGE_VIDEO_LIMITATIONS_FAILURE:
            ToastProvider.error("Could not update limitations.");
            return reducerHelper(state, videoLimitations, undefined, appStates.ERROR);

        case appConstants.CURRENT_PUBLISHER_REQUEST:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    loadingState: publisherStates.LOADING,
                }
            };
        case appConstants.CURRENT_PUBLISHER_SUCCESS:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    ...payload.publisher,
                    loadingState: publisherStates.LOADED,
                }
            }
        case appConstants.CURRENT_PUBLISHER_FAILURE:
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    loadingState: publisherStates.ERROR,
                    error: payload.error
                }
            }
        case publisherConstants.UPDATE_PUBLISHER_REQUEST: {
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    loadingState: publisherStates.LOADING
                }
            }
        }
        case publisherConstants.UPDATE_PUBLISHER_SUCCESS: {
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    ...payload,
                    loadingState: publisherStates.LOADED
                }
            }
        }
        case publisherConstants.UPDATE_PUBLISHER_FAILURE: {
            return {
                ...state,
                publisher: {
                    ...state.publisher,
                    ...payload,
                    loadingState: publisherStates.ERROR,
                    error: payload.error
                }
            }
        }
        default:
            return state;
    }
};
