import React from 'react';

import Pagination from './Pagination';
import ZoomTools from './ZoomTools';
import ExportOptions from './ExportOptions';

const CLASS_NAMES = {
    WEB_PREVIEW_SETTINGS_CONTAINER: 'web-preview-settings-container',
    WEB_PREVIEW_SETTINGS_DIVIDER: 'web-preview-settings-divider'
};

const WebPreviewSettings = (props) => {
    return (
        <div className={CLASS_NAMES.WEB_PREVIEW_SETTINGS_CONTAINER}>
            <Pagination />
            <div className={CLASS_NAMES.WEB_PREVIEW_SETTINGS_DIVIDER} />
            {<ZoomTools {...props} />}
            <div className={CLASS_NAMES.WEB_PREVIEW_SETTINGS_DIVIDER} />
            <ExportOptions {...props} />
        </div>
    );
};

export default WebPreviewSettings;
