import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import textProvider from "../../../../texts";
import OnboardingSideBar from './OnboardingSideBar';

const CLASS_NAMES = {
    STEP_COMPONENT: 'step-comp',
    STEP_LAYOUT_LEFT: 'layout-left'
};

const CONSTANT_TEXTS = {
    TITLE: textProvider.getText('onboardingModal', 'step _3_3.title'),
    DOMAIN_INFO_LABEL1: textProvider.getText('onboardingModal', 'step_3_3.domainInfoLabel1'),
    DOMAIN_INFO_LABEL2: textProvider.getText('onboardingModal', 'step_3_3.domainInfoLabel2'),
    MORE_STEPS: textProvider.getText('onboardingModal', 'step_3_3.moreSteps'),
    BUTTON: textProvider.getText('onboardingModal', 'step_3_3.button')
};

const OnboardingStep_3_3 = (props) => {
    const {onboarding} = props;
    const address = onboarding.mainSite.SupplySource.address;

    return (
        <div id="step_3_3" className={CLASS_NAMES.STEP_COMPONENT}>
            <div className={CLASS_NAMES.STEP_LAYOUT_LEFT}>
                <h2>Amazing! We found it!</h2>
                <p>
                    {CONSTANT_TEXTS.DOMAIN_INFO_LABEL1}
                    <b>{` ${address} `}</b>
                    {CONSTANT_TEXTS.DOMAIN_INFO_LABEL2}
                </p>
                <hr/>
                <br/>
                <p>{CONSTANT_TEXTS.MORE_STEPS}</p>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <button>{CONSTANT_TEXTS.BUTTON}</button>
            </div>
            <OnboardingSideBar img='step_3_3.png' />
        </div>
    );
}

OnboardingStep_3_3.propTypes = {
    onboarding: PropTypes.object,
};

const mapStateToProps = ({app}) => ({
    onboarding: app.onboarding
});

export default connect(mapStateToProps, null)(OnboardingStep_3_3);