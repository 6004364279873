import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Icon, confirm} from '@perion-undertone/ut-react-common';
import cx from 'classnames';
import {connect} from 'react-redux';
import {toggleAdSlotsEditMode, savePlacementGroupAdSlots, deletePlacementGroupAdSlot} from '../imports';

const IconButton = ({disabled, hookId, onClick, icon, children, disableMessage, className, tooltipMessage}) => {
    const iconButton = (
        <Icon className={cx('icon-button', className)}
            disabled={disabled}
            onClick={children ? _.noop : onClick}
            icon={icon}
            hookId={hookId}
            disableMessage={disableMessage}
            tooltip={!disableMessage ? tooltipMessage : undefined}
        />
    );
    if (!children) {
        return iconButton;
    }
    return (
        <button className={cx('icon-button-with-text', {['icon-button-with-text--disabled']: disabled})}
                onClick={disabled ? _.noop : onClick}
        >
            <div className="icon-button-icon">{iconButton}</div>
            <div className="icon-button-text">{children}</div>
        </button>
    );
};

IconButton.propTypes = {
    disableMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    hookId: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    tooltipMessage: PropTypes.string
};

class AdSlotsActionButtonsFormatter extends Component {

    enterEditMode = () => {
        const {toggleAdSlotsEditMode, row} = this.props;
        return toggleAdSlotsEditMode(row.id);
    }

    cancelEditMode = () => {
        const {toggleAdSlotsEditMode} = this.props;
        return toggleAdSlotsEditMode(null);
    }

    onSave = () => {
        const {savePlacementGroupAdSlots, row} = this.props;
        return savePlacementGroupAdSlots([{...row, name: row.name.trim()}]);
    }

    onDelete = async () => {
        const {deletePlacementGroupAdSlot, row} = this.props;
        const isConfirmed = await confirm(this.getConfirmMessage());
        if(isConfirmed) {
            return deletePlacementGroupAdSlot(row);
        }
    }

    getConfirmMessage = () => {
        return {
            confirmationMessage: 'Are you sure you want to delete this ad slot?',
            headerText: 'Delete ad slot',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }
    }

    isNameUniqe = () => {
        const {adSlots, row} = this.props;
        const match = _.find(adSlots, adSlot => {
            return adSlot.id !== row.id && adSlot.name.trim() === row.name.trim();
        })
        return match;
    }

    isSaveDisabled = () => {
        const {row} = this.props;
        const incorectName = this.isNameUniqe();
        if(incorectName || row.name === '') {
            return true;
        }
        return false;
    }

    getDisabledMsg = () => {
        const {row} = this.props;
        const incorectName = this.isNameUniqe();
        if(incorectName) { 
            return 'AdSlot name must be unique, please choose another';
        }
        if(row.name === '') {
            return 'Please enter AdSlot name';
        }
    }

    render() {
        const {adSlotToEdit, row, newAdSlot} = this.props;
        const isEditMode = !_.isNil(row.id) && adSlotToEdit === row.id ? true : false;
        const disabled = this.isSaveDisabled();
        const disabledMsg = this.getDisabledMsg();
        return (
            <div className='ad-slots-action-btns-container'>
                {isEditMode ?
                    <div className="ad-slots-action-btns-row">
                        <IconButton icon="icon-checkmark_checkbox"
                                    className='adslots-icon-save'
                                    onClick={this.onSave}
                                    disabled={disabled}
                                    disableMessage={disabledMsg}
                                    hookId={`adslots-save-button-${row.rowIndex}`}
                        />
                        <IconButton icon="icon-close"
                                    className='adslots-icon-cancel'
                                    onClick={this.cancelEditMode}
                                    hookId={`adslots-close-button-${row.rowIndex}`}
                        />
                        <IconButton icon="icon-trash_03"
                                    className='adslots-icon-delete'
                                    onClick={_.isEmpty(newAdSlot) ? this.onDelete : this.cancelEditMode}
                                    hookId={`adslots-delete-button-${row.rowIndex}`}
                        />
                    </div> :
                    <div className="ad-slots-action-btns-row ad-slots-action-btns--hovered">
                        <IconButton icon="icon-menu_edit"
                                    onClick={this.enterEditMode}
                                    disabled={!_.isNil(adSlotToEdit)}
                                    hookId={`adslots-edit-button-${row.rowIndex}`}
                        />
                        <IconButton icon="icon-trash_03"
                                    className='adslots-icon-delete'
                                    onClick={this.onDelete}
                                    disabled={!_.isNil(adSlotToEdit)}
                                    hookId={`adslots-delete-button-${row.rowIndex}`}
                        />
                    </div>
                }
            </div>);
    }

}

AdSlotsActionButtonsFormatter.propTypes = {
    adSlots: PropTypes.array,
    adSlotToEdit: PropTypes.number,
    toggleAdSlotsEditMode: PropTypes.func,
    row: PropTypes.object,
    newAdSlot: PropTypes.array,
    savePlacementGroupAdSlots: PropTypes.func,
    deletePlacementGroupAdSlot: PropTypes.func,
};

const mapStateToProps = ({placements}) => ({
    adSlots: placements.currentGroup.adSlots,
    adSlotToEdit: placements.currentGroup.adSlotToEdit,
    newAdSlot: placements.currentGroup.newAdSlot
});

const mapDispatchToProps = ({
    toggleAdSlotsEditMode,
    savePlacementGroupAdSlots,
    deletePlacementGroupAdSlot
})

export default connect(mapStateToProps, mapDispatchToProps)(AdSlotsActionButtonsFormatter)