import {Observable} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {ofType} from 'redux-observable';

import {getFeaturesSuccess, getFeaturesFailed} from '../actions/featureActions';
import {featuresConstants} from '../constants/featuresConstants';

export const featuresEpic = actions$ => {
    return actions$.pipe(
        ofType(featuresConstants.FEATURES_REQUEST),
        mergeMap(() =>
            ajax.get(`/api/features`, {'Content-Type': 'application/json'}).pipe(
                map(res => {
                    return getFeaturesSuccess(res.response);
                })
            )
        ),
        catchError(error => Observable.of(getFeaturesFailed(error)))
    )
};