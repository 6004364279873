import {dashboardConstants} from '../constants/dashboardConstants';
// import { logData } from '../utils/helpers';

export const getWidgetData = (type, publisherId, period) => ({
    type: dashboardConstants.WIDGET_DATA_REQUEST,
    payload: {type, publisherId, period}
});

export const getWidgetDataSuccess = (type, data) => ({
    type: dashboardConstants.WIDGET_DATA_SUCCESS,
    payload: {type, data}
});
export const getWidgetDataFailed = (type, error) => ({
    type: dashboardConstants.WIDGET_DATA_FAILURE,
    payload: {type, error}
});

export const getWidgetList = () => ({
    type: dashboardConstants.WIDGET_LIST_REQUEST
});
export const getWidgetListSuccess = (widgetPreferences) => ({
    type: dashboardConstants.WIDGET_LIST_SUCCESS,
    payload: {widgetPreferences}
});
export const getWidgetListFailed = (error) => ({
    type: dashboardConstants.WIDGET_LIST_FAILURE,
    payload: {error}
});

export const updateWidgetPreferences = (widgetSchema) => ({
    type: dashboardConstants.UPDATE_WIDGET_PREFERENCES_REQUEST,
    payload: {widgetSchema}
});
export const updateWidgetPreferencesSuccess = (widgetPreferences) => ({
    type: dashboardConstants.UPDATE_WIDGET_PREFERENCES_SUCCESS,
    payload: {widgetPreferences}
});
export const updateWidgetPreferencesFailed = (error) => ({
    type: dashboardConstants.UPDATE_WIDGET_PREFERENCES_FAILURE,
    payload: {error}
});

export const getWidgetLibrary = () => ({
    type: dashboardConstants.WIDGET_LIBRARY_REQUEST
});
export const getWidgetLibrarySuccess = (widgetLibrary) => ({
    type: dashboardConstants.WIDGET_LIBRARY_SUCCESS,
    payload: {widgetLibrary}
});
export const getWidgetLibraryFailed = (error) => ({
    type: dashboardConstants.WIDGET_LIBRARY_FAILURE,
    payload: {error}
});

export const setPeriod = period => ({
    type: dashboardConstants.SET_PERIOD,
    payload: period
});

export const setSelectedView = (type, value) => ({
    type: dashboardConstants.SELECTED_VIEW,
    payload: {type, value}
});