export const logData = (message) => {
    return (value) => {
        // eslint-disable-next-line no-console
        console.log(message, value);
        return value;
    }
};

export const deepCopy = (value) => JSON.parse(JSON.stringify(value));

export const getDatesBetween = (startDate, endDate) => {
    const result = [];

    // Strip hours minutes seconds etc.
    let currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

    while (currentDate <= endDate) {
        result.push(currentDate);

        currentDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() + 1, // Will increase month if over range
        );
    }

    return result;
};

export const getReplacedValue = (value, replaceFrom = '@', replaceTo = '*') => !value || value.replace(replaceFrom, replaceTo);

export const getImagePath = icon => {
    try {
        return require(`../../assets/${icon}`);
    } catch (err) {
        return null;
    }
};

export const getCategoryName = (category) => {
    return !category.isIAB ? `UN-${category.name}` : `${category.iabValue}-${category.name}`;
}