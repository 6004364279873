export const onboardingConstants = {
    STEPS: [
        '1_1',
        '2_1',
        '3_1',
        '3_2',
        '3_3',
        '3_4',
        '4_1',
    ],
    ERROR_STEPS: [
        '3_1'
    ],
    isErrorStep: step => {
        return onboardingConstants.ERROR_STEPS.indexOf(step) !== -1;
    },

    GET_ONBOARDING_DATA_REQUEST: 'GET_ONBOARDING_DATA_REQUEST',
    GET_ONBOARDING_DATA_SUCCESS: 'GET_ONBOARDING_DATA_SUCCESS',
    GET_ONBOARDING_DATA_FAILURE: 'GET_ONBOARDING_DATA_FAILURE',

    ADD_MAIN_SITE_REQUEST: 'ADD_MAIN_SITE_REQUEST',
    ADD_MAIN_SITE_SUCCESS: 'ADD_MAIN_SITE_SUCCESS',
    ADD_MAIN_SITE_FAILURE: 'ADD_MAIN_SITE_FAILURE',

    VALIDATE_MAIN_SITE_REQUEST: 'VALIDATE_MAIN_SITE_REQUEST',
    VALIDATE_MAIN_SITE_SUCCESS: 'VALIDATE_MAIN_SITE_SUCCESS',
    VALIDATE_MAIN_SITE_FAILURE: 'VALIDATE_MAIN_SITE_FAILURE',

    DISPLAY_ENTER_SITE: 'DISPLAY_ENTER_SITE',
    SET_PREFILLED_ACCOUNT_DATA: 'SET_PREFILLED_ACCOUNT_DATA'
};
