import {supplyConstants} from '../constants/supplyConstants';

export const getAdUnitConfigurations = (publisherId, publishersSupplySourcesId) => ({
    type: supplyConstants.GET_ADUNIT_CONFIGURATIONS_REQUEST,
    payload: {publisherId, publishersSupplySourcesId}
});

export const getAdUnitConfigurationsSuccess = adUnitConfigurations => ({
    type: supplyConstants.GET_ADUNIT_CONFIGURATIONS_SUCCESS,
    payload: {adUnitConfigurations}
});

export const getAdUnitConfigurationsFailed = error => ({
    type: supplyConstants.GET_ADUNIT_CONFIGURATIONS_FAILURE,
    payload: {error}
});

export const saveAdUnitConfigurations = (publisherId, adUnitConfigurations) => ({
    type: supplyConstants.SAVE_ADUNIT_CONFIGURATIONS_REQUEST,
    payload: {publisherId, adUnitConfigurations}
});

export const saveAdUnitConfigurationsSuccess = adUnitConfigurations => ({
    type: supplyConstants.SAVE_ADUNIT_CONFIGURATIONS_SUCCESS,
    payload: {adUnitConfigurations}
});

export const saveAdUnitConfigurationsFailed = error => ({
    type: supplyConstants.SAVE_ADUNIT_CONFIGURATIONS_FAILURE,
    payload: {error}
});
