import React, {Component} from 'react';

import {Image, textProvider} from './imports';

const CLASS_NAMES = {
    STEP_COMPONENT: 'step-comp',
    STEP_LAYOUT_LEFT: 'layout-left',
    STEP_LAYOUT_RIGHT: 'layout-right',
};

const CONSTANT_TEXTS = {
    TITLE: textProvider.getText('onboardingModal', 'step_4_1.title'),
    TAG_INFO_LABEL: textProvider.getText('onboardingModal', 'step_4_1.tagInfoLabel'),
    BUTTON: textProvider.getText('onboardingModal', 'step_4_1.button'),
    TAKE_TIME: textProvider.getText('onboardingModal', 'step_4_1.takeTime'),
    SEE: textProvider.getText('onboardingModal', 'step_4_1.see'),
    GUIDE: textProvider.getText('onboardingModal', 'step_4_1.guide')
};

class OnboardingStep_4_1 extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="step_4_1" className={CLASS_NAMES.STEP_COMPONENT}>
                <div className={CLASS_NAMES.STEP_LAYOUT_LEFT}>
                    <h2>{CONSTANT_TEXTS.TITLE}</h2>
                    <p>{CONSTANT_TEXTS.TAG_INFO_LABEL}</p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <p>{CONSTANT_TEXTS.TAKE_TIME} <span className="action">{CONSTANT_TEXTS.SEE}</span> {CONSTANT_TEXTS.GUIDE}</p>
                    <button>{CONSTANT_TEXTS.BUTTON}</button>
                </div>
                <div className={CLASS_NAMES.STEP_LAYOUT_RIGHT}>
                    <Image name='iphone.png' />
                </div>
            </div>
        )
    }
}

export default OnboardingStep_4_1;