import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';
import {Icon, Popover} from '@perion-undertone/ut-react-common';

import {setFilterOptions} from './../actions/gridCustomizationActions';

const CLASS_NAMES = {
    CONTAINER: 'filter-icon',
    ICON: 'icon-filter'
};
class FilterIcon extends Component {

    renderIcon() {
        return <div className={cx(CLASS_NAMES.CONTAINER)}><Icon icon={CLASS_NAMES.ICON} hookId="popover-filter-icon" /></div>;
    }
    render() {
        const {column, filterOptions, allFilterOptions} = this.props;
        this.props.setFilterOptions(allFilterOptions);
        const {filterComponent: FilterComponent, ...rest} = column;
        return (
            <Popover trigger={this.renderIcon()}
                placement='bottom-start'
                appendToBody
                basic
                className='filter-popover'
                hookId="popover-filter"
            >
                <div>
                    {FilterComponent && <FilterComponent filterOptions={filterOptions} {...rest} />}
                </div>
            </Popover>
        )
    }
}

FilterIcon.propTypes = {
    filterComponent: PropTypes.func,
    column: PropTypes.object,
    filterOptions: PropTypes.array,
    allFilterOptions: PropTypes.object,
    setFilterOptions: PropTypes.func
};

const mapDispatchToProps = ({
    setFilterOptions
});

export default connect(null, mapDispatchToProps)(FilterIcon);