import {placementsConstants} from '../constants/placementsConstants';
import {statusConstants} from '../constants/statusConstants';
// import {logData} from '../utils/helpers';

export const getPlacementGroups = (publisherId, limit, page, searchTerm, shouldShowLoadingAnimation = true) => {
    return {
        type: placementsConstants.PLACEMENT_GROUPS_REQUEST,
        payload: {publisherId, limit, page, searchTerm, shouldShowLoadingAnimation}
    }
};
export const getPlacementGroupsSuccess = placementGroupsResponse => {
    return {
        type: placementsConstants.PLACEMENT_GROUPS_SUCCESS,
        payload: {placementGroups: placementGroupsResponse.placementGroups, placementGroupsCount: placementGroupsResponse.placementGroupsCount}
    }
};
export const getPlacementGroupsFailed = error => ({
    type: placementsConstants.PLACEMENT_GROUPS_FAILURE,
    payload: {error}
});

export const getPlacementFile = publisherId => ({
    type: placementsConstants.PLACEMENT_FILE_REQUEST,
    payload: {publisherId}
});
export const getPlacementFileSuccess = file => ({
    type: placementsConstants.PLACEMENT_FILE_SUCCESS,
    payload: {file}
});
export const getPlacementFileFailed = error => ({
    type: placementsConstants.PLACEMENT_FILE_FAILURE,
    payload: {error}
});

export const uploadFile = (file, publisherId) => ({
    type: placementsConstants.UPLOAD_FILE_REQUEST,
    payload: {file, publisherId}
});
export const uploadFileProgress = progressInfo => ({
    type: placementsConstants.UPLOAD_FILE_PROGRESS,
    payload: {progressInfo}
});
export const uploadFileSuccess = file => ({
    type: placementsConstants.UPLOAD_FILE_SUCCESS,
    payload: {file}
});
export const uploadFileFailed = error => ({
    type: placementsConstants.UPLOAD_FILE_FAILURE,
    payload: {error}
});

export const importFile = (file) => ({
    type: placementsConstants.IMPORT_FILE_REQUEST,
    payload: {file}
});
export const importFileProgress = progressInfo => ({
    type: placementsConstants.IMPORT_FILE_PROGRESS,
    payload: {progressInfo}
});
export const importFileSuccess = payload => ({
    type: placementsConstants.IMPORT_FILE_SUCCESS,
    payload
});
export const importFileFailed = error => ({
    type: placementsConstants.IMPORT_FILE_FAILURE,
    payload: {error}
});

export const removeUploadErrorMessage = () => ({
    type: placementsConstants.REMOVE_UPLOAD_ERROR_MESSAGE,
    payload: {}
})

export const downloadFileRequest = publisherId => ({
    type: placementsConstants.DOWNLOAD_FILE_REQUEST,
    payload: {publisherId}
});
export const downloadFileSuccess = data => ({
    type: placementsConstants.DOWNLOAD_FILE_SUCCESS,
    payload: {data}
});
export const markAsDownloaded = name => ({
    type: placementsConstants.MARK_AS_DOWNLOADED,
    payload: {name}
});
export const downloadFileFailed = error => ({
    type: placementsConstants.DOWNLOAD_FILE_FAILURE,
    payload: {error}
});

export const deleteFile = (publisherId) => ({
    type: placementsConstants.DELETE_FILE_REQUEST,
    payload: {publisherId}
});
export const deleteFileSuccess = () => ({
    type: placementsConstants.DELETE_FILE_SUCCESS,
    payload: {}
});
export const deleteFileFailed = error => ({
    type: placementsConstants.DELETE_FILE_FAILURE,
    payload: {error}
});

export const createPlacementGroup = (placementGroup, publisherId, limit, page, searchTerm, sort) => ({
    type: placementsConstants.CREATE_PLACEMENT_GROUP_REQUEST,
    payload: {placementGroup, publisherId, limit, page, searchTerm, sort}
});
export const createPlacementGroupSuccess = placementGroupsResponse => ({
    type: placementsConstants.CREATE_PLACEMENT_GROUP_SUCCESS,
    payload: {placementGroups: placementGroupsResponse.placementGroups, placementGroupsCount: placementGroupsResponse.placementGroupsCount}
});
export const createPlacementGroupFailed = (error) => ({
    type: placementsConstants.CREATE_PLACEMENT_GROUP_FAILURE,
    payload: {error}
});

export const updatePlacementGroup = (publisherId, placementGroupId, placementGroup, limit, page, searchTerm, sort) => ({
    type: placementsConstants.UPDATE_PLACEMENT_GROUP_REQUEST,
    payload: {publisherId, placementGroupId, placementGroup, limit, page, searchTerm, sort}
});
export const updatePlacementGroupSuccess = placementGroupsResponse => ({
    type: placementsConstants.UPDATE_PLACEMENT_GROUP_SUCCESS,
    payload: {placementGroups: placementGroupsResponse.placementGroups, placementGroupsCount: placementGroupsResponse.placementGroupsCount}

});
export const updatePlacementGroupFailed = error => ({
    type: placementsConstants.UPDATE_PLACEMENT_GROUP_FAILURE,
    payload: {error}
});

export const deletePlacementGroups = (publisherId, placementGroupsIds,  limit, page, searchTerm, sort) => ({
    type: placementsConstants.DELETE_PLACEMENT_GROUPS_REQUEST,
    payload: {publisherId, placementGroupsIds,  limit, page, searchTerm, sort}
});

export const deletePlacementGroupsSuccess = placementGroupsResponse => ({
    type: placementsConstants.DELETE_PLACEMENT_GROUPS_SUCCESS,
    payload: {placementGroups: placementGroupsResponse.placementGroups, placementGroupsCount: placementGroupsResponse.placementGroupsCount}
});

export const deletePlacementGroupsFailed = error => ({
    type: placementsConstants.DELETE_PLACEMENT_GROUPS_FAILURE,
    payload: {error}
});

export const setPlacementGroupStatus = (publisherId, placementGroupId, isSelected, limit, page, searchTerm, 
    sort) => ({
    type: placementsConstants.SET_PLACEMENT_GROUP_STATUS_REQUEST,
    payload: {
        publisherId,
        placementGroupId,
        status: isSelected ? statusConstants.ACTIVE : statusConstants.INACTIVE,
        limit, 
        page,
        searchTerm, 
        sort
    }
});
export const setPlacementGroupStatusSuccess = (placementGroupsResponse) => ({
    type: placementsConstants.SET_PLACEMENT_GROUP_STATUS_SUCCESS,
    payload: {placementGroups: placementGroupsResponse.placementGroups, placementGroupsCount: placementGroupsResponse.placementGroupsCount}
});
export const setPlacementGroupStatusFailed = (error) => ({
    type: placementsConstants.SET_PLACEMENT_GROUP_STATUS_FAILURE,
    payload: {error}
});

export const searchPlacementGroups = (searchTerm, publisherId, limit, page, sort) => ({
    type: placementsConstants.SEARCH_PLACEMENT_GROUPS,
    payload: {searchTerm, publisherId, limit, page, sort}
});

export const openPlacementGroupModal = (placementGroup) => ({
    type: placementsConstants.OPEN_PLACEMENT_GROUP_MODAL,
    payload: {currentGroup: placementGroup}
});
export const closePlacementGroupModal = () => ({
    type: placementsConstants.CLOSE_PLACEMENT_GROUP_MODAL
});

export const closeConfirmationModal = () => ({
    type: placementsConstants.CLOSE_CONFIRMATION_MODAL
});

export const toggleSelectAdUnit = (adUnit) => ({
    type: placementsConstants.TOGGLE_SELECT_AD_UNIT,
    payload: {adUnit}
});

export const toggleSelectAllAdUnits = (adUnits, isAllSelected, adUnitKey) => ({
    type: placementsConstants.TOGGLE_SELECT_ALL_AD_UNITS,
    payload: {adUnits, isAllSelected, adUnitKey}
});

export const clearSelectedAdUnits = () => ({
    type: placementsConstants.CLEAR_SELECTED_AD_UNITS
});

export const previewAdUnitInfo = (previewAdUnit) => ({
    type: placementsConstants.PREVIEW_AD_UNIT_INFO,
    payload: {previewAdUnit}
});

export const setPlacementGroupTitle = (title) => ({
    type: placementsConstants.SET_PLACEMENT_GROUP_TITLE,
    payload: {title}
});

export const createNewPlacementGroupAdSlot = (adSlots) => ({
    type: placementsConstants.CREATE_NEW_PLACEMENT_GROUP_AD_SLOTS,
    payload: {adSlots}
});

export const savePlacementGroupAdSlots = (adSlots) => ({
    type: placementsConstants.SAVE_PLACEMENT_GROUP_AD_SLOTS,
    payload: {adSlots}
});

export const deletePlacementGroupAdSlot = (adSlot) => ({
    type: placementsConstants.DELETE_PLACEMENT_GROUP_AD_SLOTS,
    payload: {adSlot}
});

export const bulkDeletePlacementGroupAdSlot = (adSlotIds) => ({
    type: placementsConstants.BULK_DELETE_PLACEMENT_GROUP_AD_SLOTS,
    payload: {adSlotIds}
});

export const toggleAdSlotsEditMode = (adSlotToEdit) => ({
    type: placementsConstants.TOGGLE_AD_SLOTS_EDIT_MODE,
    payload: adSlotToEdit
});

export const changeAdSlotsValue = (adSlotToEdit, changes) => ({
    type: placementsConstants.AD_SLOTS_CHANGES,
    payload: {adSlotToEdit, changes}
});

export const searchAdSlots = (searchTerm) => ({
    type: placementsConstants.SEARCH_AD_SLOTS,
    payload: {searchTerm}
});

export const setPlacementGroupOneOffPublisher = (isOneOff) => ({
    type: placementsConstants.SET_ONE_OFF_PUBLISHER,
    payload: {isOneOff}
})

export const setPlacementGroupPassbackTag = (passbackTag) => ({
    type: placementsConstants.SET_PASSBACK_TAG,
    payload: {passbackTag}
})

export const setIsThrotteling = (isOverrideThrottling) => ({
    type: placementsConstants.SET_IS_THROTTELING,
    payload: {isOverrideThrottling}
})

export const setUseSystemAppendPixel = (ignoreSystemAppendPixel) => ({
    type: placementsConstants.SET_USE_SYSTEM_APPEND_PIXEL,
    payload: {ignoreSystemAppendPixel}
})

export const setIsAuctionPriceMacro = (isAuctionPriceMacro) => ({
    type: placementsConstants.SET_IS_AUCTION_PRICE_MACRO,
    payload: {isAuctionPriceMacro}
})

export const setIsPreferVidzaooPlayer = (isPreferVidazoo) => ({
    type: placementsConstants.SET_IS_PREFER_VIDAZOO_PLAYER,
    payload: {isPreferVidazoo}
})

export const setAuctionPriceMacro = (auctionPriceMacro) => ({
    type: placementsConstants.SET_AUCTION_PRICE_MACRO,
    payload: {auctionPriceMacro}
})

export const setWinEventType = (winEventType) => ({
    type: placementsConstants.SET_WIN_TYPE_EVENT,
    payload: {winEventType}
})

export const seAuctionPriceMacroType = (actionPriceMacroType) => ({
    type: placementsConstants.SET_AUCTION_PRICE_MACRO_TYPE,
    payload: {actionPriceMacroType}
})

export const setThrotteling = (throttling) => ({
    type: placementsConstants.SET_THROTTELING,
    payload: {throttling}
})



export const setSelectedPlacementGroupsIds = (placementGroupId) => ({
    type: placementsConstants.SET_SELECTED_PLACEMENT_GROUPS_IDS,
    payload: {placementGroupId}
})