import UnblockAdvertiserFormatter from './UnblockAdvertiserFormatter';
import BlockedAdvertiserNameFormatter from './BlockedAdvertiserNameFormatter';
import BlockedAdvertiserIdFormatter from './BlockedAdvertiserIdFormatter';

const CLASS_NAMES = {
    BLOCKED_ADVERTISER_NAME: 'blocked-advertiser-name',
    BLOCKED_ADVERTISER_ID: 'blocked-advertiser-id',
}

const getBlockedAdvertisersGridSchema = (isPublisherLevel, isBulkBlock, publishersSupplySourcesIds) => {
    return [
        {
            name: 'Advertiser',
            key: 'Advertiser.name',
            cellClass: CLASS_NAMES.BLOCKED_ADVERTISER_NAME,
            formatter: BlockedAdvertiserNameFormatter,
            isPublisherLevel: isPublisherLevel,
            getRowMetaData: (rowData, columnInfo) => {
                return {rowData, columnInfo};
            }
        },
        {
            name: 'ID',
            key: 'advertiserId',
            cellClass: CLASS_NAMES.BLOCKED_ADVERTISER_ID,
            formatter: BlockedAdvertiserIdFormatter,
            isPublisherLevel: isPublisherLevel,
            getRowMetaData: (rowData, columnInfo) => {
                return {rowData, columnInfo};
            }
        },
        {
            name: '',
            key: 'actions',
            width: 100,
            formatter: UnblockAdvertiserFormatter,
            isPublisherLevel: isPublisherLevel,
            isBulkBlock: isBulkBlock,
            publishersSupplySourcesIds: publishersSupplySourcesIds,
            getRowMetaData: (rowData, columnInfo) => {
                return {rowData, columnInfo};
            }
        }
    ];
};

export default getBlockedAdvertisersGridSchema;