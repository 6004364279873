import React from 'react';
import PropTypes from 'prop-types';

const AccountHeader = (props) => {
    const {header, subheader, Subheader} = props;

    return (
        <div className='account-header'>
            <div className='account-header-text'>
                {header}
            </div>
            <div className='account-subheader-text'>
                {subheader ? subheader : (Subheader ? <Subheader /> : null)}
            </div>
        </div>
    );
};

AccountHeader.propTypes = {
    header: PropTypes.string,
    Subheader: PropTypes.func,
    subheader: PropTypes.string
};

export default AccountHeader;
