import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Input} from '@perion-undertone/ut-react-common';
import _ from 'lodash';
import {FormField, textProvider} from './imports';
import {connect} from 'react-redux';

const PLACEHOLDER = textProvider.getText('inputFields', 'codePlaceholder');
const QR_CODE_HEADING = textProvider.getText('inputFields', 'qrCodeHeadingPlaceholder');
const QR_CODE_TEXT = textProvider.getText('inputFields', 'qrCodeTextPlaceholder');
const QR_VERIFY_HEADING = textProvider.getText('inputFields', 'qrVerifyHeadingPlaceholder');
const QR_VERIFY_TEXT = textProvider.getText('inputFields', 'qrVerifyTextPlaceholder');

const CLASS_NAMES = {
    CONTAINER: 'qr-code-container',
    HEADING: 'qr-code-heading',
    TEXT: 'qr-code-text',
    ERROR: 'error',
    QR_CODE_INPUT: 'qr-code-input',
    QR_IMAGE: 'qr-image'
};

const CONSTANT_TEXTS = {
    ERROR_EMPTY_CODE_FIELD: textProvider.getText('updatePasswordComponent', 'emptyCodeErrorMessage'),
    INCORRECT_CODE_ERROR_MESSAGE: textProvider.getText('updatePasswordComponent', 'incorrectCodeErrorMessage')
};

const QRCodeField = (props) => {
    const [placeholder, setPlaceholder] = useState(PLACEHOLDER);
    const {autoFocus, code, qrCode, onChange, onBlur, onFocus, hasError, emptyCodeError, loginError} = props;
    const handleBlur = () => {
        setPlaceholder(PLACEHOLDER);
        onBlur && onBlur();
    }

    const handleFocus = () => {
        setPlaceholder('');
        onFocus && onFocus();
    }

    return (
        <FormField>
            {qrCode &&
                <div className={CLASS_NAMES.CONTAINER}>
                    <h4 className={CLASS_NAMES.HEADING}>{QR_CODE_HEADING}</h4>
                    <p className={CLASS_NAMES.TEXT}>{QR_CODE_TEXT}</p>
                    <img className={CLASS_NAMES.QR_IMAGE} src={qrCode}></img>
                </div>
            }
            {!qrCode && 
                <div className={CLASS_NAMES.CONTAINER}>
                    <h4 className={CLASS_NAMES.HEADING}>{QR_VERIFY_HEADING}</h4>
                    <p className={CLASS_NAMES.TEXT}>{QR_VERIFY_TEXT}</p>
                </div>
            }
            <Input autoFocus={autoFocus}
                type='number'
                name='code'
                placeholder={placeholder}
                value={code}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                hookId="code-field"
                className={cx(CLASS_NAMES.QR_CODE_INPUT, {[CLASS_NAMES.ERROR]: hasError || loginError || emptyCodeError})}
            />
            {emptyCodeError && 
                <div className={CLASS_NAMES.ERROR}>
                    {CONSTANT_TEXTS.ERROR_EMPTY_CODE_FIELD}
                </div>
            }
            {loginError && !_.isEmpty(loginError) && 
                <div className={CLASS_NAMES.ERROR}>
                    {loginError}
                </div>
            }
        </FormField>
    );
};

QRCodeField.defaultProps = {
    autoFocus: false
};

QRCodeField.propTypes = {
    autoFocus: PropTypes.bool,
    hasError: PropTypes.bool,
    code: PropTypes.string,
    qrCode: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    emptyCodeError: PropTypes.string,
    loginError: PropTypes.string
};

const mapStateToProps = (state) => ({
    loginError: state.app.user.loginError
});

export default connect(mapStateToProps)(QRCodeField);