import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Input} from '@perion-undertone/ut-react-common';

import {FormField, textProvider} from './imports';

const PLACEHOLDER = textProvider.getText('inputFields', 'emailPlaceholder');

const EmailField = (props) => {
    const [placeholder, setPlaceholder] = useState(PLACEHOLDER);
    const {autoFocus, email, onChange, onBlur, onFocus, hasError} = props;

    const handleBlur = () => {
        setPlaceholder(PLACEHOLDER);
        onBlur && onBlur();
    }

    const handleFocus = () => {
        setPlaceholder('');
        onFocus && onFocus();
    }

    return (
        <FormField className='input-container'>
            <Input autoFocus={autoFocus}
                   type='email'
                   name='email'
                   icon='icon-new-email'
                   iconPosition="left"
                   placeholder={placeholder}
                   value={email}
                   onChange={onChange}
                   onBlur={handleBlur}
                   onFocus={handleFocus}
                   hookId="email-field"
                   className={cx({'error': hasError})}
            />
        </FormField>
    );
};

EmailField.defaultProps = {
    autoFocus: false
};

EmailField.propTypes = {
    autoFocus: PropTypes.bool,
    hasError: PropTypes.bool,
    email: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func
};

export default EmailField;