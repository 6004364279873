import {featuresConstants} from '../constants/featuresConstants';

const initialState = {};

export const featuresReducer = (state = initialState, action) => {
    switch (action.type) {
        case featuresConstants.FEATURES_REQUEST:
            return [];
        case featuresConstants.FEATURES_SUCCESS:
            return {features: action.payload};
        case featuresConstants.FEATURES_FAILURE:
            return {  };
        default:
            return state;
    }
};