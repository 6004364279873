import {of} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {getPaymentDataSuccess, getPaymentDataFailed} from '../actions/paymentActions';
import {paymentConstants} from '../constants/paymentConstants';

export const requestPaymentEpic = actions => (
    actions.pipe(
        ofType(paymentConstants.PAYMENT_DATA_REQUEST),
        mergeMap((action) => {
            const {publisherId} = action.payload;
            return ajax.getJSON(`api/payment/publisher/${publisherId}`, {'Content-Type': 'application/json'}).pipe(
                map(data => getPaymentDataSuccess(data)),
                catchError(error => of(getPaymentDataFailed(error)))
            )}
        )
    )
);