import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import cx from 'classnames';
import PlacementsStatusToggle from '../PlacementsStatusToggle';
import {statusConstants} from '../../../../constants/statusConstants';
import {setPlacementGroupStatus, isAuthorized, permissionConstants} from '../imports';
import get from "lodash/get";

const {EDIT_PLACEMENT_GROUP} = permissionConstants;

const CLASS_NAMES = {
    PLACEMENTS_STATUS_TOGGLE_WRAPPER: 'placements-status-toggle-wrapper',
    PLACEMENTS_STATUS: 'placements-status',
    PLACEMENTS_STATUS_ACTIVE: 'placements-status-active'
};

class StatusFormatter extends Component {
    constructor(props) {
        super(props);
        this.onPlacementGroupStatusChange = this.onPlacementGroupStatusChange.bind(this);
    }

    onPlacementGroupStatusChange(event, placementGroupId, isChecked) {
        const {setPlacementGroupStatus, publisherId, placementsPagination: {limit, page}, searchTerm, customization} = this.props;
        const sort = get(customization, 'sort') || [];
        setPlacementGroupStatus(publisherId, placementGroupId, isChecked, limit, page, searchTerm, sort);
    }

    render() {
        const {row, value} = this.props;
        const toggleValue = value === statusConstants.ACTIVE;
        const editPlacement = isAuthorized({permission: EDIT_PLACEMENT_GROUP});
        return (<div className={CLASS_NAMES.PLACEMENTS_STATUS_TOGGLE_WRAPPER}>
            {editPlacement ?
                <PlacementsStatusToggle placementGroupId={row.placementGroupId}
                                        isOn={toggleValue}
                                        onStatusChange={this.onPlacementGroupStatusChange}
                                        disabled={row.isAuto || false}
                /> :
                <div className={cx(CLASS_NAMES.PLACEMENTS_STATUS, {[CLASS_NAMES.PLACEMENTS_STATUS_ACTIVE]: toggleValue})}>{value}</div>
            }
        </div>);
    }
}

StatusFormatter.propTypes = {
    setPlacementGroupStatus: PropTypes.func,
    value: PropTypes.string,
    row: PropTypes.object,
    publisherId: PropTypes.number,
    placementsPagination: PropTypes.object,
    searchTerm: PropTypes.string,
    customization: PropTypes.object
};

const mapStateToProps = ({grid, placements, app}) => ({
    publisherId: app.publisher.data.id,
    placementsPagination: placements.placementsPagination,
    searchTerm: placements.searchTerm,
    customization: grid.customization,
});

const mapDispatchToProps = ({
    setPlacementGroupStatus
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatusFormatter));