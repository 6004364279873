import {getSurveyData} from '../../auth';
import {textProvider} from './../imports';
import {saveToLocalStorage} from '../../utils';

const TRIGGER_ON = {
    THIRTY_SECONDS: 30000,
    FIVE_MINUTES: 300000
};

export const getTriggerTimeInSeconds = (survey) => {
    let seconds = TRIGGER_ON.THIRTY_SECONDS;
    if (survey && survey.triggerOn === 'firstLogin') {
        seconds = TRIGGER_ON.FIVE_MINUTES;
    }
    return seconds;
};

export const CN = {
    SURVEY_POPUP: 'answer-survey-popup',
    SURVEY_TITLE: 'answer-survey-title',
    SURVEY_DESCRIPTION: 'answer-survey-description',
    TAKES_TWO_MIN_LABEL: 'takes-two-minutes-label',
    BOLD_TEXT: 'bold-text',
    REMIND_ME_LATER_LABEL: 'remind-me-later-label',
    ANSWER_SURVEY_ACTION: 'answer-survey-action-button',
    SURVEY_TRIGGER_BUTTON: 'help-us-improve-btn',
};

export const TXT = {
    IT_WILL_TAKE_2_MIN: textProvider.getText('survey', 'itWillTakeTwoMin'),
    BE_PART_OF_UDRIVE_DESIGN: textProvider.getText('survey', 'bePartOfUdriveDesign'),
    HELP_US_IMPROVE: textProvider.getText('survey', 'helpUsImprove'),
    BY_ANSWERING: textProvider.getText('survey', 'byAnswering'),
    FIVE_SHORT: textProvider.getText('survey', 'fiveShort'),
    QUESTIONS: textProvider.getText('survey', 'questions'),
    ANSWER_NOW: textProvider.getText('survey', 'answerNow'),
    REMIND_ME_LATER: textProvider.getText('survey', 'remindMeLater')
};

export const getFeedbackSurvey = (surveys) => {
    return Array.isArray(surveys) && surveys.find(survey => survey.name === 'dashboardAndAnalyticsFeedback');
};

export const getSurvey = () => {
    const surveys = getSurveyData();
    return getFeedbackSurvey(surveys);
};

export const saveSurvey = (survey) => {
    let surveys = getSurveyData();
    surveys = surveys.filter(s => s.name !== survey.name);
    surveys.push(survey);
    saveToLocalStorage('surveys', surveys);
}