import textProvider from '../../../../texts/textProvider';
import headerCellRenderer from '../../../headerCellRenderer';
import AdslotsNameFormatter from './AdslotsNameFormatter';
import AdslotsDateFormatter from './AdslotsDateFormatter';
import AdSlotsActionButtonsFormatter from './AdSlotsActionButtonsFormatter';

const CLASS_NAMES = {
    NAME_CELL: 'name-cell',
    ADUNITS_CELL: 'ad-units-cell'
}

const CONSTANT_TEXTS = {
    NAME: textProvider.getText('adSlots', 'adslotString'),
    DATE: textProvider.getText('adSlots', 'creationDate'),
};

export const adslotsGridSchema = [
    {
        name: CONSTANT_TEXTS.NAME,
        key: "name",
        cellClass: CLASS_NAMES.NAME_CELL,
        formatter: AdslotsNameFormatter,
        headerRenderer: headerCellRenderer,
        isSortable: true
    },
    {
        name: CONSTANT_TEXTS.DATE,
        key: "created",
        cellClass: CLASS_NAMES.ADUNITS_CELL,
        formatter: AdslotsDateFormatter,
        headerRenderer: headerCellRenderer,
        isSortable: true
    },
    {
        name: '',
        key: "actions",
        formatter: AdSlotsActionButtonsFormatter,
    }
];