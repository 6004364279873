export const dashboardConstants = {
    WIDGET_DATA_REQUEST: 'DASHBOARD_WIDGET_DATA_REQUEST',
    WIDGET_DATA_SUCCESS: 'DASHBOARD_WIDGET_DATA_SUCCESS',
    WIDGET_DATA_FAILURE: 'DASHBOARD_WIDGET_DATA_FAILURE',

    WIDGET_LIST_REQUEST: 'DASHBOARD_WIDGET_LIST_REQUEST',
    WIDGET_LIST_SUCCESS: 'DASHBOARD_WIDGET_LIST_SUCCESS',
    WIDGET_LIST_FAILURE: 'DASHBOARD_WIDGET_LIST_FAILURE',

    UPDATE_WIDGET_PREFERENCES_REQUEST: 'UPDATE_DASHBOARD_WIDGET_PREFERENCES_REQUEST',
    UPDATE_WIDGET_PREFERENCES_SUCCESS: 'UPDATE_DASHBOARD_WIDGET_PREFERENCES_SUCCESS',
    UPDATE_WIDGET_PREFERENCES_FAILURE: 'UPDATE_DASHBOARD_WIDGET_PREFERENCES_FAILURE',

    WIDGET_LIBRARY_REQUEST: 'DASHBOARD_WIDGET_LIBRARY_REQUEST',
    WIDGET_LIBRARY_SUCCESS: 'DASHBOARD_WIDGET_LIBRARY_SUCCESS',
    WIDGET_LIBRARY_FAILURE: 'DASHBOARD_WIDGET_LIBRARY_FAILURE',

    SET_PERIOD: 'SET_PERIOD',
    SELECTED_VIEW: 'SELECTED_VIEW'
};

export const MONTH_NAMES = {
    SHORT: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
    LONG: ['January','February','March','April','May','June','July','August','September','October','November','December']
};

export const activeItems = {
    'last-month': 'Last Month',
    'current-month': 'Current Month',
    'last-week': 'Last Week',
    'current-week': 'Current Week',
    'yesterday': 'Yesterday',
    'custom': 'Custom'
};