import {categoriesConstants} from '../constants/categoriesConstants';

export const getCategories = () => ({
    type: categoriesConstants.GET_CATEGORIES_REQUEST
});
export const getCategoriesSuccess = categoriesList => ({
    type: categoriesConstants.GET_CATEGORIES_SUCCESS,
    payload: {categoriesList}
});
export const getCategoriesFailed = error => ({
    type: categoriesConstants.GET_CATEGORIES_FAILURE,
    payload: {error}
});

export const setOpenCategorySections = (type, id, isOpen) => ({
    type: categoriesConstants.SET_OPEN_CATEGORIES_SECTIONS,
    payload: {type, id, isOpen}
});