import {of} from "rxjs";
import ajax from '../utils/ajaxProxy';
import {ofType} from "redux-observable";
import {catchError, map, mergeMap} from "rxjs/operators";
import {onboardingConstants} from "../constants/onboardingConstants";
import {
    getOnboardingDataSuccess, getOnboardingDataFailed,
    addMainSiteSuccess, addMainSiteFailed,
    validateMainSiteSuccess, validateMainSiteFailed
} from "../actions/onboardingActions";

export const getOnboardingDataEpic = actions => (
    actions.pipe(
        ofType(onboardingConstants.GET_ONBOARDING_DATA_REQUEST),
        mergeMap(() => {
            return ajax.getJSON(`api/account/onboarding`,
                {'Content-Type': 'application/json'}).pipe(
                map(data => getOnboardingDataSuccess(data)),
                catchError(error => of(getOnboardingDataFailed(error)))
            )}
        )
    )
);

export const addMainSiteEpic = actions => (
    actions.pipe(
        ofType(onboardingConstants.ADD_MAIN_SITE_REQUEST),
        mergeMap(action => {
            return ajax.post(`api/account/onboarding/main-site`,
                action.payload,
                {'Content-Type': 'application/json'}).pipe(
                    map(data => addMainSiteSuccess(data.response)),
                    catchError(error => of(addMainSiteFailed(error)))
            )
        })
    )
);

export const validateMainSiteEpic = actions$ => {
    return actions$.pipe(
        ofType(onboardingConstants.VALIDATE_MAIN_SITE_REQUEST),
        mergeMap(action => {
            return ajax.post('/api/account/onboarding/validate-site',
            action.payload,
            {'Content-Type': 'application/json'}).pipe(
                map(data => validateMainSiteSuccess(data.response)),
                catchError(error => of(validateMainSiteFailed(error)))
            )
        })
    )
};