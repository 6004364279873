import {of} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {mergeMap, map, catchError, switchMap, delay} from 'rxjs/operators';
import _ from 'lodash';
import {ofType} from 'redux-observable';
import {supplyConstants} from '../constants/supplyConstants';
import {
    getSupplySourcesSuccess, getSupplySourcesFailure,
    submitSitesSuccess, submitSitesFailed,
    validateDomainInAdsTxtSuccess, validateDomainInAdsTxtFailed,
    setAdsTxtDomainSuccess, setAdsTxtDomainFailed,
    getBlockedAdvertisersSuccess, getBlockedAdvertisersFailed,
    blockAdvertisersSuccess, blockAdvertisersFailed,
    getBlockedCategoriesSuccess, getBlockedCategoriesFailed,
    blockCategoriesSuccess, blockCategoriesFailed,
    getBulkBlockedCategoriesSuccess, getBulkBlockedCategoriesFailed,
    bulkBlockCategoriesSuccess, bulkBlockCategoriesFailed,
    getVideoLimitationsSuccess, getVideoLimitationsFailed,
    manageVideoLimitationsSuccess, manageVideoLimitationsFailed
} from '../actions/supplyActions';
import {ToastProvider} from '@perion-undertone/ut-react-common';

const getFilterValue = (value, type, filterOptions) => {
    if(type === 'options') {
        const allAliasValues = _.map(filterOptions, 'alias');
        //get the checked items
        return _.reject(allAliasValues, opt => _.includes(value, opt));
    }
    return value;
}

const transformFilters = (filters) => {
    let query = '';
    _.forEach(filters, (filter) => {
        const {field, type, filterOptions} = filter;
        const filterValue = getFilterValue(filter.value, type, filterOptions);
        const queryValue = _.isArray(filterValue) ? _.join(filterValue, ',') : filterValue;
        query = `${query}&${_.toLower(field)}=${queryValue}`;
    });
    return query;
}

const transformOrder = (sort) => {
    const {field, direction} = _.first(sort) || {};
    if(_.isEmpty(sort) || direction === 'none') {
        return '';
    }
    return `&order=${field},${direction}`
}


export const requestSupplySourcesEpic = (actions, state) => (
    actions.pipe(
        ofType(supplyConstants.SUPPLY_SOURCES_REQUEST),
        delay(100),
        switchMap((action) => {
            const {page, itemsPerPage} = state.value.supply;
            const {publisherId} = action.payload;
            const filters = _.get(state.value, 'grid.customization.filters');
            const sort = _.get(state.value, 'grid.customization.sort')
            const queryFilters = transformFilters(filters);
            const orderBy = transformOrder(sort)
            const query = `count=1&offset=${(page - 1) * itemsPerPage}&limit=${itemsPerPage}${queryFilters}${orderBy}`;
            return ajax.getJSON(`/api/supply-sources/${publisherId}?${query}`).pipe(
                map(data => getSupplySourcesSuccess(data)),
                catchError(error => of(getSupplySourcesFailure(error)))
            )}
        )
    )
);

export const submitSitesEpic = (actions, state) => (
    actions.pipe(
        ofType(supplyConstants.SUBMIT_SITES_REQUEST),
        mergeMap(action => {
            const {publisherId, sites} = action.payload;
            const {page, itemsPerPage} = state.value.supply;
            const query = `count=1&offset=${(page - 1) * itemsPerPage}&limit=${itemsPerPage}`;
            const requestPayload = {sites};
            return ajax.post(`api/supply-sources/${publisherId}?${query}`, requestPayload, {'Content-Type': 'application/json'}).pipe(
                map(data => submitSitesSuccess(data.response)),
                catchError(error => of(submitSitesFailed(error)))
            )
        })
    )
);

export const validateDomainEpic = actions$ => {
    return actions$.pipe(
        ofType(supplyConstants.VALIDATE_DOMAIN_REQUEST),
        mergeMap(action => {
            const {url, publisherId} = action.payload;
            return ajax.getJSON(`/api/supply-sources/${publisherId}/validate-domain?domain=${url}`).pipe(
                map(data => validateDomainInAdsTxtSuccess(data)),
                catchError(error => of(validateDomainInAdsTxtFailed(url, error)))
            )
        })
    )
};

export const setAdsTxtForDomainEpic = actions => (
    actions.pipe(
        ofType(supplyConstants.SET_ADSTXT_DOMAIN_REQUEST),
        mergeMap(action => {
            const {publisherId, publishersSupplySourcesId, url, hasValidAdsTxt} = action.payload;
            const requestPayload = {url, hasValidAdsTxt};
            return ajax.post(`api/supply-sources/${publisherId}/ads-txt-domain/${publishersSupplySourcesId}`,
                requestPayload,
                {'Content-Type': 'application/json'}).pipe(
                map(data => setAdsTxtDomainSuccess(data.response)),
                catchError(error => of(setAdsTxtDomainFailed(error)))
            )
        })
    )
);
export const requestBlockedAdvertisersEpic = actions => (
    actions.pipe(
        ofType(supplyConstants.GET_BLOCKED_ADVERTISERS_REQUEST),
        mergeMap(action => {
            const {publisherId, publishersSupplySourcesIds, isPublisherLevel} = action.payload;
            return ajax.post(`/api/supply-sources/${publisherId}/advertisers/blocklist?isPublisherLevel=${isPublisherLevel}`,
            publishersSupplySourcesIds,
            {'Content-Type': 'application/json'}).pipe(
                map(data => {
                   return getBlockedAdvertisersSuccess(data.response);
                }),
                catchError(error => of(getBlockedAdvertisersFailed(error)))
            )}
        )
    )
);
export const blockAdvertisersEpic = actions => (
    actions.pipe(
        ofType(supplyConstants.BLOCK_ADVERTISERS_REQUEST),
        mergeMap(action => {
            const {publisherId, advertisers} = action.payload;
            const advertisersToManage = advertisers;
            return ajax.post(`/api/supply-sources/${publisherId}/advertisers/block`,
            advertisersToManage,
                {'Content-Type': 'application/json'}).pipe(
                map(data => blockAdvertisersSuccess(data.response)),
                catchError(error => of(blockAdvertisersFailed(error)))
            )}
        )
    )
);

export const requestBlockedCategoriesEpic = actions => (
    actions.pipe(
        ofType(supplyConstants.GET_BLOCKED_CATEGORIES_REQUEST),
        mergeMap(action => {
            const {publisherId, publishersSupplySourcesId, isPublisherLevel} = action.payload;
            return ajax.getJSON(`/api/publisher/${publisherId}/supply-sources/${publishersSupplySourcesId}/category-blocklist?isPublisherLevel=${isPublisherLevel}`).pipe(
                map(data => getBlockedCategoriesSuccess(data)),
                catchError(error => of(getBlockedCategoriesFailed(error)))
            )}
        )
    )
);

export const blockCategoriesEpic = actions => (
    actions.pipe(
        ofType(supplyConstants.BLOCK_CATEGORIES_REQUEST),
        mergeMap(action => {
            const {publisherId, publishersSupplySourcesId, categoryData, isPublisherLevel} = action.payload;
            return ajax.post(`/api/publisher/${publisherId}/supply-sources/${publishersSupplySourcesId}/category-blocklist?isPublisherLevel=${isPublisherLevel}`, categoryData, {'Content-Type': 'application/json'}).pipe(
                map(data => blockCategoriesSuccess(data.response)),
                catchError(error => of(blockCategoriesFailed(error)))
            )}
        )
    )
);

export const requestBulkBlockedCategoriesEpic = actions => (
    actions.pipe(
        ofType(supplyConstants.GET_BULK_BLOCKED_CATEGORIES_REQUEST),
        mergeMap(action => {
            const {publisherId, supplySourcesIds} = action.payload;
            return ajax.post(`/api/supply-sources/${publisherId}/categories-bulk-blocklist`,
                supplySourcesIds,
                {'Content-Type': 'application/json'}).pipe(
                map(data => getBulkBlockedCategoriesSuccess(data.response)),
                catchError(error => of(getBulkBlockedCategoriesFailed(error)))
            )}
        )
    )
);

export const bulkBlockCategoriesEpic = actions => (
    actions.pipe(
        ofType(supplyConstants.BULK_BLOCK_CATEGORIES_REQUEST),
        mergeMap(action => {
            const {publisherId, categories} = action.payload;
            return ajax.post(`/api/supply-sources/${publisherId}/bulk-block-categories`,
                categories,
                {'Content-Type': 'application/json'}).pipe(
                map(data => bulkBlockCategoriesSuccess(data.response)),
                catchError(error => of(bulkBlockCategoriesFailed(error)))
            )}
        )
    )
);

export const requestVideoLimitationsEpic = actions => (
    actions.pipe(
        ofType(supplyConstants.GET_VIDEO_LIMITATIONS_REQUEST),
        mergeMap(action => {
            const {publisherId, supplySourcesIds} = action.payload;
            return ajax.post(`/api/supply-sources/${publisherId}/video-limitations`,
                {supplySourcesIds},
                {'Content-Type': 'application/json'}).pipe(
                map(data => getVideoLimitationsSuccess(data.response)),
                catchError(error => of(getVideoLimitationsFailed(error)))
            )}
        )
    )
);

export const manageVideoLimitationsEpic = actions => (
    actions.pipe(
        ofType(supplyConstants.MANAGE_VIDEO_LIMITATIONS_REQUEST),
        mergeMap(action => {
            const {publisherId, videoLimitations} = action.payload;
            return ajax.post(`/api/supply-sources/${publisherId}/manage-video-limitations`,
                {videoLimitations},
                {'Content-Type': 'application/json'}).pipe(
                map(data => {
                    ToastProvider.success("Updated successfully.");
                    return manageVideoLimitationsSuccess(data.response);
                }),
                catchError(error => of(manageVideoLimitationsFailed(error)))
            )}
        )
    )
);
