export const appConstants = {
    CURRENT_PUBLISHER_REQUEST: 'CURRENT_PUBLISHER_REQUEST',
    CURRENT_PUBLISHER_SUCCESS: 'CURRENT_PUBLISHER_SUCCESS',
    CURRENT_PUBLISHER_FAILURE: 'CURRENT_PUBLISHER_FAILURE',

    FETCH_PUBLISHERS_REQUEST: 'FETCH_PUBLISHERS_REQUEST',
    FETCH_PUBLISHERS_SUCCESS: 'FETCH_PUBLISHERS_SUCCESS',
    FETCH_PUBLISHERS_FAILURE: 'FETCH_PUBLISHERS_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    MFA_CHALLENGE: 'MFA_CHALLENGE',
    AUTHORIZATION_DATA_REQUEST: 'AUTHORIZATION_DATA_REQUEST',
    AUTHORIZATION_DATA_SUCCESS: 'AUTHORIZATION_DATA_SUCCESS',
    AUTHORIZATION_DATA_FAILURE: 'AUTHORIZATION_DATA_FAILURE',

    LOGOUT_USER: 'LOGOUT_USER',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',
    PROFILE_REQUEST: 'USERS_PROFILE_REQUEST',
    PROFILE_SUCCESS: 'USERS_PROFILE_SUCCESS',
    PROFILE_FAILURE: 'USERS_PROFILE_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    SET_PASSWORD_REQUEST: 'SET_PASSWORD_REQUEST',
    SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
    SET_PASSWORD_ERROR: 'SET_PASSWORD_ERROR',

    SHOW_RESET_PASSWORD: 'SHOW_RESET_PASSWORD',

    SET_FLAG_SITE_UNDER_REVIEW_PAGE: 'SET_FLAG_SITE_UNDER_REVIEW_PAGE',
    RESET_APP_STATE: 'RESET_APP_STATE'
}