import React from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';

const CLASS_NAMES = {
    PNF_WRAPPER: 'page-not-found-wrapper',
    PNF_BOX: 'page-not-found-box',
    PNF_404: 'page-not-found-404',
    PNF_TEXT: 'page-not-found-text',
    GO_BACK_LINK: 'go-back-link',
}

const PageNotFound = () => (
    <div className={CLASS_NAMES.PNF_WRAPPER}>
        <div className={CLASS_NAMES.PNF_BOX}>
            <div className={CLASS_NAMES.PNF_404}>404</div>
            <div className={CLASS_NAMES.PNF_TEXT}>Page not found</div>
        </div>
        <div className={CLASS_NAMES.GO_BACK_LINK}><Link to={"/"}>Go Back</Link></div>
    </div>);

PageNotFound.propTypes = {
    history: PropTypes.object,
    profile: PropTypes.object
};

export default withRouter(PageNotFound);