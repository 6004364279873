import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import cx from 'classnames';
import {connect} from 'react-redux';
import {deleteFile} from './imports';
import {fileUploadConstants} from './imports';

const CLASS_NAMES = {
    OVERLAY_WRAPPER: 'overlay-wrapper',
    OVERLAY_ACTION_ICONS: 'overlay-action-icons',
    REFRESH_ICON: 'refresh-icon',
    DELETE_ICON: 'delete-icon',
    DOWNLOAD_ICON: 'download-icon'
}

class FileUploadOverlay extends Component {
    constructor(props) {
        super(props);

        this.onReplaceModalOpen = this.onReplaceModalOpen.bind(this);
        this.onDeleteModalOpen = this.onDeleteModalOpen.bind(this);
        this.onDownloadStart = this.onDownloadStart.bind(this);
    }

    onReplaceModalOpen() {
        const {onOpenModal} = this.props;
        onOpenModal(fileUploadConstants.REPLACE);
    }

    onDeleteModalOpen() {
        const {onOpenModal} = this.props;
        onOpenModal(fileUploadConstants.DELETE);
    }

    onDownloadStart() {
        const {publisherId, startDownload} = this.props;
        startDownload(publisherId);
    }

    render() {
        return (
            <div className={CLASS_NAMES.OVERLAY_WRAPPER}>
                <div className={cx(CLASS_NAMES.OVERLAY_ACTION_ICONS, CLASS_NAMES.REFRESH_ICON)}
                     onClick={this.onReplaceModalOpen}
                >
                    <Icon icon='refresh.svg' />

                </div>
                <div className={cx(CLASS_NAMES.OVERLAY_ACTION_ICONS, CLASS_NAMES.DELETE_ICON)}
                     onClick={this.onDeleteModalOpen}
                >
                    <Icon icon='trash.svg' />

                </div>
                <div className={cx(CLASS_NAMES.OVERLAY_ACTION_ICONS, CLASS_NAMES.DOWNLOAD_ICON)}
                     onClick={this.onDownloadStart}
                >
                    <Icon icon='download.svg' />
                </div>
            </div>
        )
    }
}

FileUploadOverlay.propTypes = {
    deleteFile: PropTypes.func,
    publisherId: PropTypes.number,
    triggerFileRef: PropTypes.func,
    startDownload: PropTypes.func,
    onOpenModal: PropTypes.func
};

const mapDispatchToProps = ({
    deleteFile
})

export default connect(null, mapDispatchToProps)(FileUploadOverlay);