import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {validate} from '@perion-undertone/ramp-helpers';

import AccountLayout from '../AccountLayout';
import ResetLinkSent from './ResetLinkSent';
import EmailField from '../EmailField';
import {appStates, resetPassword, textProvider} from '../imports';

const CLASS_NAMES = {
    RESET_PASSWORD_CONTENT: 'reset-password-content',
    INPUT_CONTAINER: 'input-container',
    ERROR: 'error',
    ERROR_MESSAGE: 'error-message',
};

const CONSTANT_TEXTS = {
    HEADER: textProvider.getText('resetPasswordComponent', 'header'),
    SUBHEADER: textProvider.getText('resetPasswordComponent', 'subheader'),
    ERROR_MESSAGE: textProvider.getText('resetPasswordComponent', 'errorMessage'),
    SUBMIT_TEXT: textProvider.getText('resetPasswordComponent', 'submitText'),
    BACK_TO_LOGIN: textProvider.getText('resetPasswordComponent', 'backToLogin')
};

const ResetPassword = (props) => {
    const {resetRequest, resetPassword, resendRequest} = props;
    const [email, setEmail] = useState('');
    const [emailInputTouched, setEmailInputTouched] = useState(false);

    const hasError = resetRequest && resetRequest.state === appStates.ERROR;
    const markError = !validate.isEmailValid(email) && emailInputTouched || hasError;
    const errorMessage = resetRequest && resetRequest.errorMessage && CONSTANT_TEXTS.ERROR_MESSAGE;

    const onPasswordReset = (e) => {
        e.preventDefault();
        props.resetPassword(email)
    };

    if (resetRequest && resetRequest.isEmailSent) {
        return <ResetLinkSent email={email}
                              resetPassword={resetPassword}
                              resendRequest={resendRequest}
        />;
    }

    return (
        <AccountLayout size='small'
                       backToRoute='/'
                       backToText={CONSTANT_TEXTS.BACK_TO_LOGIN}
                       className='reset-password-layout'
                       headerProps={{
                           header: CONSTANT_TEXTS.HEADER,
                           subheader: CONSTANT_TEXTS.SUBHEADER
                       }}
                       footerProps={{
                           buttonText: CONSTANT_TEXTS.SUBMIT_TEXT,
                           isDisabled: !validate.isEmailValid(email),
                           onSubmit: onPasswordReset
                       }}
        >
            <div className={CLASS_NAMES.RESET_PASSWORD_CONTENT}>
                <EmailField autoFocus
                            value={email}
                            hasError={markError}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => email && setEmailInputTouched(true)}
                />
                <div className={CLASS_NAMES.ERROR_MESSAGE}>
                    {markError && errorMessage}
                </div>
            </div>
        </AccountLayout>
    );
};

ResetPassword.propTypes = {
    resetPassword: PropTypes.func,
    resetRequest: PropTypes.object,
    resendRequest: PropTypes.object
};

const mapStateToProps = ({app}) => ({
    resetRequest: app.resetRequest,
    resendRequest: app.resendRequest
});

const mapDispatchToProps = ({
    resetPassword
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
