import {appConstants} from '../constants/appConstants';

export const getCurrentPublisher = (publisherId, withUserUpdate) => ({
    type: appConstants.CURRENT_PUBLISHER_REQUEST,
    payload: {publisherId, withUserUpdate}
});
export const getCurrentPublisherSuccess = ({publisher, user}) => ({
    type: appConstants.CURRENT_PUBLISHER_SUCCESS,
    payload: {publisher, user}
});
export const getCurrentPublisherFailed = (error) => ({
    type: appConstants.CURRENT_PUBLISHER_FAILURE,
    payload: {error}
});

export const fetchPublishers = ({searchTerm, publisherId}) => ({
    type: appConstants.FETCH_PUBLISHERS_REQUEST,
    payload: {searchTerm, publisherId}
});
export const fetchPublishersSuccess = publishers => ({
    type: appConstants.FETCH_PUBLISHERS_SUCCESS,
    payload: {publishers}
});
export const fetchPublishersFailed = (error) => ({
    type: appConstants.FETCH_PUBLISHERS_FAILURE,
    payload: {error}
});

export const getAuthorizationData = () => ({
    type: appConstants.AUTHORIZATION_DATA_REQUEST
});
export const getAuthorizationDataSuccess = authorizationData => ({
    type: appConstants.AUTHORIZATION_DATA_SUCCESS,
    payload: {authorizationData}
});
export const getAuthorizationDataFailed = (error) => ({
    type: appConstants.AUTHORIZATION_DATA_FAILURE,
    payload: {error}
});

export const setViewSiteUnderReviewPage = (hideSiteUnderReviewPage) => ({
    type: appConstants.SET_FLAG_SITE_UNDER_REVIEW_PAGE,
    payload: {hideSiteUnderReviewPage}
});
export const resetAppState = (data) => ({
    type: appConstants.RESET_APP_STATE,
    payload: {data}
});