import React from 'react';
import PropTypes from 'prop-types';

import {Icon} from '@perion-undertone/ut-react-common';
import {formatString} from '../imports';
import {
    runningAtInfo,
    PERIOD_VALUES, TXT
} from './scheduleReportHelper';


const CLASS_NAMES = {
    SCHEDULE_INFO_WRAPPER: 'schedule-info-wrapper',
    SCHEDULE_INFO_TEXT: 'schedule-info-text',
    RECURRENCE_ICON: 'recurrence-icon',
    END_SCHEDULING_ACTION: 'end-scheduling-action'
};

const ScheduleInfo = (props) => {
    const {item, setReportScheduling} = props;
    const {recurrenceInterval, timePeriod} = item;

    const removeRecurrence = () => {
        setReportScheduling({
            ...item,
            isActive: false
        });
    }

    return (
        <div className={CLASS_NAMES.SCHEDULE_INFO_WRAPPER} >
            <div className={CLASS_NAMES.SCHEDULE_INFO_TEXT}>
                <Icon icon='icon-recurrence' hookId='report-recurrence-icon' className={CLASS_NAMES.RECURRENCE_ICON} />
                {runningAtInfo(recurrenceInterval)}
                {formatString(`${TXT.REPORT_DATE_RANGE}: {0}`, PERIOD_VALUES[timePeriod])}
            </div>
            <div className={CLASS_NAMES.END_SCHEDULING_ACTION}
                 onClick={removeRecurrence}
            >
                {TXT.END_SCHEDULING}
            </div>
        </div>
    );
};

ScheduleInfo.propTypes = {
    item: PropTypes.object,
    setReportScheduling: PropTypes.func
};

export default ScheduleInfo;