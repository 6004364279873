import React from 'react';
import ContentLoader from 'react-content-loader';

const CLASS_NAMES = {
    ANALYTICS_WRAPPER: 'analytics-wrapper',
    ANALYTICS_HEADER_TITLE: 'analytics-header-title',
    ANALYTICS_BODY_LOADER: 'analytics-body-loader',
    ANALYTICS_SIDEBAR: 'analytics-sidebar',
    ANALYTICS_MAIN: 'analytics-main',
    ANALYTICS_MAIN_HEADER: 'analytics-main-header',
    ANALYTICS_MAIN_BODY: 'analytics-main-body',
    ANALYTICS_MAIN_FOOTER: 'analytics-main-footer',
    ANALYTICS_SIDEBAR_HEADER: 'analytics-sidebar-header',
    ANALYTICS_SIDEBAR_MENU: 'analytics-sidebar-menu',
    ANALYTICS_SIDEBAR_MENU_ITEM: 'analytics-sidebar-menu-item'
};

const sidebarItems = [
    {width: 370},
    {width: 260},
    {width: 300},
    {width: 140},
    {width: 370},
    {width: 260}
];

const AnalyticsLoader = () => {
    return  (
        <div className={CLASS_NAMES.ANALYTICS_WRAPPER}>
            <div className={CLASS_NAMES.ANALYTICS_BODY_LOADER}>
                <div className={CLASS_NAMES.ANALYTICS_SIDEBAR}>
                    <div className={CLASS_NAMES.ANALYTICS_SIDEBAR_HEADER}>
                        <ContentLoader speed={3}
                                       primaryColor='#ececec'
                        >
                            <rect x="0" y="20" rx="8" ry="8" width="140" height="15" />
                        </ContentLoader>
                    </div>
                    <ul className={CLASS_NAMES.ANALYTICS_SIDEBAR_MENU}>
                        {sidebarItems.map((item, index) => {
                            return (
                                <li key={index}
                                    className={CLASS_NAMES.ANALYTICS_SIDEBAR_MENU_ITEM}
                                >
                                    <ContentLoader speed={3}
                                                   primaryColor='#ececec'
                                    >
                                        <rect x="0" y="12" rx="8" ry="8" width={item.width} height="15" />
                                    </ContentLoader>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className={CLASS_NAMES.ANALYTICS_MAIN}>
                    <div className={CLASS_NAMES.ANALYTICS_MAIN_HEADER}>
                        <ContentLoader speed={3}
                                       primaryColor='#ececec'
                        >
                            <rect x="0" y="7" rx="3" ry="3" width="100" height="6.6" />
                        </ContentLoader>
                    </div>
                    <div className={CLASS_NAMES.ANALYTICS_MAIN_BODY}>
                        <ContentLoader speed={3}
                                       primaryColor='#ececec'
                        >
                            <rect x="0" y="15" rx="3" ry="3" width="170" height="6.6" />
                            <rect x="0" y="30" rx="3" ry="3" width="130" height="6.6" />
                            <rect x="0" y="45" rx="3" ry="3" width="270" height="6.6" />
                        </ContentLoader>
                    </div>
                    <div className={CLASS_NAMES.ANALYTICS_MAIN_FOOTER} />
                </div>
            </div>
        </div>
    );
};

export default AnalyticsLoader;