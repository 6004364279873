import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';
import {openPlacementGroupModal, deletePlacementGroups} from '../imports';
import Icon from '../../../Icon';
import ConfirmationModal from '../../../ConfirmationModal';
import {Tooltip} from '@perion-undertone/ut-react-common';
import get from "lodash/get";

const CLASS_NAMES = {
    ACTIONS_ICON_WRAPPER: 'actions-icons-wrapper',
    DELETE_ICON_WRAPPER: 'delete-action-wrapper',
    EDIT_ICON_WRAPPER: 'edit-action-wrapper',
    EDIT_ICON_WRAPPER_DISABLED: 'edit-action-wrapper-disabled',
};

class ActionsFormatter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDeleteConfirmationModal: false,
            placementGroupId: []
        }
        this.deletePlacementGroupRow = this.deletePlacementGroupRow.bind(this);
        this.onOpenDeleteConfirmationModal = this.onOpenDeleteConfirmationModal.bind(this);
        this.onCloseDeleteConfirmationModal = this.onCloseDeleteConfirmationModal.bind(this);
    }

    deletePlacementGroupRow() {
        const {publisher, deletePlacementGroups, placementsPagination: {limit, page}, searchTerm, customization} = this.props;
        const sort = get(customization, 'sort') || [];
        deletePlacementGroups(publisher.id, this.state.placementGroupId, limit, page, searchTerm, sort);
    }

    onOpenDeleteConfirmationModal(placementGroupId) {
        this.setState({openDeleteConfirmationModal: true, placementGroupId: [placementGroupId]})
    }

    onCloseDeleteConfirmationModal() {
        this.setState({openDeleteConfirmationModal: false});
    }

    render() {
        const {row} = this.props;
        const isAutoGeneratedGroup = row.isAuto;
        const deleteTrigger=(
            <div className={cx(CLASS_NAMES.DELETE_ICON_WRAPPER, {[CLASS_NAMES.DELETE_ICON_WRAPPER_DISABLED]: isAutoGeneratedGroup})}
                 onClick={() => this.onOpenDeleteConfirmationModal(this.props.row.placementGroupId)}>
                <Icon icon="trash_03" hookId={`${row.name}-delete-button`} />
            </div>
        )
        const editTrigger=(
            <div className={cx(CLASS_NAMES.EDIT_ICON_WRAPPER, {[CLASS_NAMES.EDIT_ICON_WRAPPER_DISABLED]: isAutoGeneratedGroup})}
                 onClick={() => this.props.openPlacementGroupModal(this.props.row)}>
                <Icon icon="menu_edit" hookId={`${row.name}-edit-button`} />
            </div>
        )
        return (<div>
            <div className={CLASS_NAMES.ACTIONS_ICON_WRAPPER}>
                <Tooltip trigger={editTrigger}
                         placement='top'
                         appendToBody={true}
                         className={CLASS_NAMES.AD_UNITS_TOOLTIP}
                         hookId="actions-formatter-edit-tooltip"
                >
                    Edit
                </Tooltip>
                <Tooltip trigger={deleteTrigger}
                         placement='top'
                         appendToBody={true}
                         className={CLASS_NAMES.AD_UNITS_TOOLTIP}
                         hookId="actions-formatter-delete-tooltip"
                >
                    Delete
                </Tooltip>

            </div>
            {this.state.openDeleteConfirmationModal
                ? <ConfirmationModal openModal={this.state.openDeleteConfirmationModal}
                    closeModal={this.onCloseDeleteConfirmationModal}
                    closeOnOutsideClick={false}
                    onConfirm={this.deletePlacementGroupRow}
                />
                : null}
        </div>);
    }
}

const mapStateToProps = ({grid, placements, app}) => ({
    publisher: app.publisher.data,
    placementsPagination: placements.placementsPagination,
    searchTerm: placements.searchTerm,
    customization: grid.customization,
})

const mapDispatchToProps = ({
    openPlacementGroupModal,
    deletePlacementGroups
});

ActionsFormatter.propTypes = {
    openPlacementGroupModal: PropTypes.func,
    deletePlacementGroups: PropTypes.func,
    publisher: PropTypes.object,
    row: PropTypes.object,
    placementsPagination: PropTypes.object,
    searchTerm: PropTypes.string,
    customization: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsFormatter);