import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';
import {Button, Animation, Checkbox, Icon, Tooltip} from '@perion-undertone/ut-react-common';
import {textProvider, updatePublisherRequest, getPlacementGroups, publisherStates} from '../imports';

const CLASS_NAMES = {
    INNER_CONTAINER: 'supply-settings-modal-inner-container',
    INTERSTITIAL_SETTINGS_INNER_CONTAINER: 'supply-settings-modal-interstitial-settings-inner-container',
    TITLE: 'block-list-title',
    SECTION_WRAPPER: 'section-wrapper',
    PAGE_GRABBER_ON_CHROME_TITLE: 'page-grabber-on-chrome-title',
    PG_ACTION_TRIGGER_DISABLED: 'pg-action-trigger-disabled',
    INTERSTITIAL_SETTINGS_CHECKBOX: 'interstitial-settings-checkbox',
    INTERSTITIAL_SETTINGS_ICON: 'interstitial-settings-icon',
    FOOTER: 'supply-settings-modal-footer',
    DONE_BUTTON: 'supply-settings-modal-done-button'
};

const CONSTANT_TEXTS = {
    INTERSTITIAL_SETTINGS: textProvider.getText('supplySourceSettingsModal', 'interstitialSettingsTitle'),
    PAGE_GRABBER_ON_CHROME: textProvider.getText('supplySourceSettingsModal', 'pageGrabberOnChrome'),
    PAGE_GRABBER_ON_CHROME_CHECKBOX: textProvider.getText('supplySourceSettingsModal', 'pageGrabberOnChromeCheckbox'),
    PG_ACTION_TRIGGER: textProvider.getText('supplySourceSettingsModal', 'pgActionTrigger'),
    PG_ACTION_TRIGGER_DISABLED_MSG: textProvider.getText('supplySourceSettingsModal', 'pgActionTriggersDisabledMsg'),
    PG_ACTION_TRIGGER_TOOLTIP: textProvider.getText('supplySourceSettingsModal', 'pgActionTriggerTooltip'),
    APPROVE: textProvider.getText('supplySourceSettingsModal', 'confirmBulkActionButtonText')
};

class InterstitialSettings extends Component {
    state = {
        isPg1Allowed: this.props.publisher.isPg1Allowed && this.props.publisher.isPg1Allowed,
        selectedTriggers: this.props.publisher.selectedTriggers && this.props.publisher.selectedTriggers.length > 0 && [...this.props.publisher.selectedTriggers],
        supportedTriggers: this.props.publisher.supportedTriggers && this.props.publisher.supportedTriggers.length> 0 && [...this.props.publisher.supportedTriggers]
    }

    componentDidMount() {
        const {getPlacementGroups, publisher} = this.props;
        getPlacementGroups(publisher.id);
    }

    getSelectedTrigger = (id) => {
        const {selectedTriggers} = this.state;
        return selectedTriggers && selectedTriggers.includes(id);
    }

    onTriggerChange = (id) => {
        const {selectedTriggers} = this.state;
        let newSelectedTriggers = [];
        if (!selectedTriggers) {
            newSelectedTriggers = [id];
        } else {
            if (selectedTriggers.includes(id)) {
                newSelectedTriggers = selectedTriggers.filter(trigger => trigger !== id);
            } else {
                newSelectedTriggers = [...selectedTriggers, id];
            }
        }
        this.setState({selectedTriggers: newSelectedTriggers});
    }

    onPg1Change = () => {
        const {isPg1Allowed} = this.state;
        const newPg1State = !isPg1Allowed;
        this.setState({isPg1Allowed: newPg1State});
    }

    onSaveInterstitialSettings = () => {
        const {updatePublisherRequest, publisher} = this.props;
        const {selectedTriggers, isPg1Allowed} = this.state;
        updatePublisherRequest(publisher.id, {selectedTriggers, isPg1Allowed});
    }

    areTriggersDisabled = () => {
        const {placements} = this.props;
        const {data} = placements.placementGroups;
        return data.reduce((acc, curr) => {
            const hasPageGrabberAction = curr.adUnits.find(adUnit => adUnit.id === 92);
            if (hasPageGrabberAction) {
                acc = false;
            }
            return acc;
        }, true);
    }

    render () {
        const {publisher, placements} = this.props;
        const {data} = placements.placementGroups;
        const {isPg1Allowed} = this.state;
        const triggersDisabled = data && data.length > 0 && this.areTriggersDisabled();
        return (
            <Fragment>
                <div className={cx(CLASS_NAMES.INNER_CONTAINER, CLASS_NAMES.INTERSTITIAL_SETTINGS_INNER_CONTAINER)}>
                    <div className={CLASS_NAMES.TITLE}>
                        {CONSTANT_TEXTS.INTERSTITIAL_SETTINGS}
                    </div>
                    {(publisher.loadingState === publisherStates.LOADING || publisher.loadingState === publisherStates.DEFAULT) ||
                    !data ?
                        <Animation type="chasing"
                            height={100}
                            width={100}
                            rendererSettings={{preserveAspectRatio: 'xMaxYMax'}}
                            hookId='adUnit-configurations-loading'/> :
                        <div>
                            <div className={CLASS_NAMES.SECTION_WRAPPER}>
                                <div className={CLASS_NAMES.PAGE_GRABBER_ON_CHROME_TITLE}>
                                    {CONSTANT_TEXTS.PAGE_GRABBER_ON_CHROME}
                                </div>
                                <Checkbox squared
                                            label={CONSTANT_TEXTS.PAGE_GRABBER_ON_CHROME_CHECKBOX}
                                            className={CLASS_NAMES.INTERSTITIAL_SETTINGS_CHECKBOX}
                                            checked={isPg1Allowed}
                                            onChange={this.onPg1Change}
                                            hookId='allow-pg-on-chrome-checkbox'
                                />
                            </div>
                            <div className={CLASS_NAMES.SECTION_WRAPPER}>
                                <div className={CLASS_NAMES.PAGE_GRABBER_ON_CHROME_TITLE}>
                                    {CONSTANT_TEXTS.PG_ACTION_TRIGGER}
                                </div>
                                {triggersDisabled && <div className={CLASS_NAMES.PG_ACTION_TRIGGER_DISABLED}>
                                    <Icon icon="icon-additional_details"
                                          hookId="icon-triggers-disabled"
                                    />
                                    {CONSTANT_TEXTS.PG_ACTION_TRIGGER_DISABLED_MSG}
                                </div>}
                                {publisher.supportedTriggers.map(trigger => {
                                    const isTriggerChecked = this.getSelectedTrigger(trigger.id);
                                    const tooltipTrigger = (<span>
                                        <Checkbox squared
                                                  label={trigger.name}
                                                  className={CLASS_NAMES.INTERSTITIAL_SETTINGS_CHECKBOX}
                                                  checked={isTriggerChecked}
                                                  onChange={() => this.onTriggerChange(trigger.id)}
                                                  hookId={`${trigger.name}-trigger-checkbox`}
                                                  disabled={triggersDisabled}
                                        />
                                    </span>)
                                    return (
                                        <div key={trigger.id}>
                                            <a target='_blank'
                                               rel='noopener noreferrer'
                                               href={`https://creative-p.undertone.com/demo/exit_triggers_demo.html?trigger=${trigger.id}`}
                                            >
                                                <Icon icon="icon-preview"
                                                      className={CLASS_NAMES.INTERSTITIAL_SETTINGS_ICON}
                                                      hookId={`${trigger.name}-trigger-icon`}
                                                />
                                            </a>
                                            {triggersDisabled || isTriggerChecked ?
                                                tooltipTrigger :
                                                <Tooltip trigger={tooltipTrigger}
                                                         hookId="pg-action-trigger-tooltip"
                                                         appendToBody={true}
                                                         placement="bottom"
                                                >
                                                    {CONSTANT_TEXTS.PG_ACTION_TRIGGER_TOOLTIP}
                                                </Tooltip>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
                <div className={CLASS_NAMES.FOOTER}>
                    <Button className={CLASS_NAMES.DONE_BUTTON}
                            hookId='interstitial-settings-approve-button'
                            onClick={this.onSaveInterstitialSettings}
                    >
                        {CONSTANT_TEXTS.APPROVE}
                    </Button>
                </div>
            </Fragment>
        )
    }
}

InterstitialSettings.propTypes = {
    updatePublisherRequest: PropTypes.func,
    publisher: PropTypes.object,
    placements: PropTypes.object,
    getPlacementGroups: PropTypes.func
};

const mapStateToProps = (state) => ({
    publisher: state.supply.publisher,
    placements: state.placements
});

const mapDispatchToProps = ({
    updatePublisherRequest,
    getPlacementGroups
});

export default connect(mapStateToProps, mapDispatchToProps)(InterstitialSettings);