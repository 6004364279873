import {supplyConstants} from '../constants/supplyConstants';
import {appStates} from '../constants/appStates';
import {reducerHelper} from './reducerHelper';

const initialState = {};

export const adUnitConfigurationsReducer = (state = initialState, action) => {
    const {type, payload} = action;
    const adUnitConfigurations = 'adUnitConfigurations';

    switch (type) {
        case supplyConstants.GET_ADUNIT_CONFIGURATIONS_REQUEST:
            return reducerHelper(state, adUnitConfigurations, undefined, appStates.LOADING);
        case supplyConstants.GET_ADUNIT_CONFIGURATIONS_SUCCESS:
            return reducerHelper(state, adUnitConfigurations, payload.adUnitConfigurations, appStates.LOADED);
        case supplyConstants.GET_ADUNIT_CONFIGURATIONS_FAILURE:
            return reducerHelper(state, adUnitConfigurations, undefined, appStates.ERROR);
        default:
            return state;
    }
};