export const featureFlags = [
    'paymentTab',
    'publishersOnboarding',
    'reportsScheduling',
    'surveyPopup',
    'videoLimitations',
    'videoLimitationDuration',
    'videoLimitationSkippable',
    'widgetLibrary',
    'hbPerformanceAdditionalReport',
    'invoiceAdditionalReport',
    'revenueAdditionalReport',
    'placementAdditionalReport',
    'domainRevenueAdditionalReport',
    'purchaseOrderAdditionalReport',
    'purchaseOrderDailyTrendsAdditionalReport',
    'placementGroupTagTypes',
    'isVideoOrientationEnabled',
    'placementGroupOneOff',
    'sqlReportEditor',
    'isPlacementGroupThrottelingOn',
    'useMFAInUdrive'
];
