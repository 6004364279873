import React from 'react';
import PropTypes from 'prop-types';
import {Select, Highlighter} from '@perion-undertone/ut-react-common';

const SearchPublisherOption = (props) => {
    return (
        <Select.components.Option {...props} hookId="admin-search-publisher-select">
            <div>
                <Highlighter searchWords={[props.selectProps.inputValue]}
                             textToHighlight={props.children}
                />
            </div>
            <div>
                {props.data.publisherId}
            </div>
        </Select.components.Option>
    );
};

SearchPublisherOption.propTypes = {
    selectProps: PropTypes.object,
    data: PropTypes.object
}

export default SearchPublisherOption;