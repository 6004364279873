import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {SortIcon} from '@perion-undertone/ut-react-common';
import FilterIcon from './FilterIcon';
import {sortGrid} from '../actions/gridCustomizationActions';
import metadataProvider from '../utils/MetadataProvider';
import {supplySourceStoreNames} from '../utils/globals';

const nextDirection = {
    "asc": "desc",
    "desc": "none",
    "none": "asc"
}



class GridHeaderCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adUnits: [],
            status: [],
            supplySourceStatus: [],
            supplySourceType: []
        }
        this.handleGridSort = this.handleGridSort.bind(this);
        this.getAdUnitsOptions = this.getAdUnitsOptions.bind(this);
        this.getStatusOptions = this.getStatusOptions.bind(this);
        this.getSupplyStatusOptions = this.getSupplyStatusOptions.bind(this);
        this.getSupplyTypeOptions = this.getSupplyTypeOptions.bind(this);
    }
    componentDidMount() {
        this.getSupplyStatusOptions();
        this.getSupplyTypeOptions();
    }
    componentDidUpdate(prevProps) {
        if (this.props.adUnits) {
            if (this.props.adUnits.data && (prevProps.adUnits.data !== this.props.adUnits.data)) {
                this.getAdUnitsOptions();
                this.getStatusOptions();
            }
        }
    }

    getAdUnitsOptions() {
        const {adUnits} = this.props;
        let result = [];
        if (adUnits && adUnits.data) {
            result = Object.keys(adUnits.data).reduce((acc, current) => {
                const concated = acc.concat(...adUnits.data[current]);
                return concated;
            }, []);
        }
        return this.setState({adUnits: result});
    }

    getStatusOptions() {
        let result = [
            {id: 0, name: 'Active', alias: 'active', selector: item => item.isActive},
            {id: 1, name: 'Not Active', alias: 'inactive', selector: item => !item.isActive}
        ];
        return this.setState({status: result});
    }

    getSupplyStatusOptions() {
        let result = [
            {id: 0, name: 'Pending Approval', alias: '0'},
            {id: 1, name: 'Approved', alias: '1'},
            {id: 2, name: 'Not Approved', alias: '2'},
            {id: 3, name: 'Missing ads.txt', alias: '3'}
        ];
        return this.setState({supplySourceStatus: result});
    }

    getSupplyTypeOptions() {
        const stores = metadataProvider.getAsList('SupplyStores');
        if(this.state.supplySourceType.length === 0 && stores.length){
            const supplySourceType = [{id: 0, name: 'Website', alias: 'website'}];
            _.forEach(stores, s => {
                    supplySourceStoreNames[s.code] = s.name;
                    supplySourceType.push({id: supplySourceType.length, name: s.name, alias: s.code});
            });
            return this.setState({supplySourceType});
        }else{
            return this.state.supplySourceType;
        }
    }

    handleGridSort() {
        const {sortGrid, column, customization} = this.props;
        let direction = 'none';
        if (customization && customization.sort && customization.sort[0].field === column.key) {
            direction = customization.sort[0].direction;
        }
        const newDirection = nextDirection[direction];
        sortGrid(column.key, newDirection);
    }

    render() {
        const {column, customization} = this.props;
        const sortedColumn = customization && customization.sort && customization.sort.find(col => col.field === column.key);
        const isFilterable = !!column.filterComponent;
        const isSortable = column.isSortable;
        const colKey = column && column.aliasKey ? column.aliasKey : column.key;
        const filterIcon = isFilterable && <FilterIcon column={column} filterOptions={this.state[colKey]} allFilterOptions={this.state} />;
        if (_.isEmpty(this.state.adUnits) && this.props.adUnits && this.props.adUnits.data) {
            this.getAdUnitsOptions();
            this.getStatusOptions();
        }

        return (
            <div>
                <div>
                    <div className='grid-header-cell-name' 
                        data-hook-id={`adslots-header-cell-${column.name}`}
                    >
                        {column.name}</div>
                    {sortedColumn && sortedColumn.direction !== 'none'
                        ? <Fragment>{filterIcon} {isSortable && <SortIcon dir={sortedColumn.direction} onClick={this.handleGridSort} hookId={`header-cell-sort-icon-${column.name}`}/>}</Fragment>
                        : <Fragment><div className='hoverable-filter-icon'>{filterIcon}</div>
                    {isSortable && <div className='hoverable-sort-icon'><SortIcon dir='asc' onClick={this.handleGridSort} hookId="header-cell-sort-icon-asc"/></div>}</Fragment>}
                    <div>
                    </div>
                </div>
            </div>
        );
    }
}

GridHeaderCell.propTypes = {
    column: PropTypes.object,
    sortGrid: PropTypes.func,
    customization: PropTypes.object,
    filterComponent: PropTypes.func,
    adUnits: PropTypes.object
};

const mapStateToProps = ({grid, placements}) => ({
    customization: grid.customization,
    adUnits: placements.adUnits
})

const mapDispatchToProps = ({
    sortGrid
})

export default connect(mapStateToProps, mapDispatchToProps)(GridHeaderCell);
