import {of} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {advertisersConstants} from '../constants/advertisersConstants';
import {
    getAdvertisersSuccess, getAdvertisersFailed
} from '../actions/advertisersActions';

export const requestAdvertisersEpic = actions => (
    actions.pipe(
        ofType(advertisersConstants.GET_ADVERTISERS_REQUEST),
        mergeMap(action => {
            const {inputValue} = action.payload;
            const LIMIT = 50;
            return ajax.getJSON(`/api/advertisers?kind=Advertiser&query=${inputValue}&limit=${LIMIT}`).pipe(
                map(data => getAdvertisersSuccess(data)),
                catchError(error => of(getAdvertisersFailed(error)))
            )}
        )
    )
);