import webFont from 'webfontloader';

class FontPreLoader {
    constructor() {
        this.subscribeToDOMContentLoadedEvent(() => {
            webFont.load(this.webFontConfig);
        });
    }

    get webFontConfig() {
        return {
            custom: {
                families: ['proximaNova:n1,n4,n5,n7,n9', 'ut-icon:n4']
            },
            testStrings: {
                proximaNova: 'QW@HhsXJ'
            },
            timeout: 2500
        };
    }

    getFontWeight(fvd) {
        let result;
        switch (fvd) {
            case 'n1':
                result = 100;
                break;
            case 'n4':
                result = 400;
                break;
            case 'n5':
                result = 500;
                break;
            case 'n7':
                result = 700;
                break;
            case 'n9':
                result = 900;
                break;
            default:
                result = fvd;
                break;
        }
        return result;
    }

    subscribeToDOMContentLoadedEvent(callback) {
        const states = ['complete', 'loaded', 'interactive'];
        document.addEventListener('DOMContentLoaded', () => {
            if (states.indexOf(document.readyState) !== -1) {
                callback();
            }
        }, false);
    }

}

const fontPreLoader = new FontPreLoader();
export default fontPreLoader;
