import React, {Component} from 'react';
import {connect} from 'react-redux';
import {isEmpty, isEqual} from 'lodash';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import cx from 'classnames';
import memoize from 'lodash/memoize';
import {selectFeatureFlag} from '@flopflip/react-redux';
import {Button, GAProvider, getHookId, ToastProvider} from '@perion-undertone/ut-react-common';
import Toaster from '../../Toaster';
import AnalyticsLoader from './AnalyticsLoader';
import ReportsCalendar from './ReportsCalendar';
import ReportsMenu from './ReportsMenu';
import ScheduleInfo from './scheduleReport/ScheduleInfo';
import ScheduleRecurrence from './scheduleReport/ScheduleRecurrence';
import getActiveReportsList from './getActiveReportsList';
import SQLReport from './SQLReport';
import {
    appStates, formatString, getPredefinedReportsList, setPredefinedReportPeriod,
    getPredefinedReportData, getReportPreview, setReportScheduling, PERIOD_TYPES,
    textProvider, calendarPresets, getReplacedValue, WebPreview, LoadingBar, RetryFetchData,
    getPublisherReportsConfig
} from './imports';

const CLASS_NAMES = {
    ANALYTICS_WRAPPER: 'analytics-wrapper',
    ANALYTICS_BODY_CONTAINER: 'analytics-body-container',
    ANALYTICS_OUTPUT_FORMAT_CONTAINER: 'analytics-output-format-container',
    ANALYTICS_OUTPUT_FORMAT_TITLE: 'analytics-output-format-title',
    ANALYTICS_SIDEBAR: 'analytics-sidebar',
    ANALYTICS_MAIN: 'analytics-main',
    ANALYTICS_MAIN_HEADER: 'analytics-main-header',
    RECURRENCE_BTN: 'recurrence-btn',
    ANALYTICS_MAIN_BODY: 'analytics-main-body',
    ANALYTICS_MAIN_FOOTER: 'analytics-main-footer',
    ANALYTICS_SIDEBAR_HEADER: 'analytics-sidebar-header',
    ANALYTICS_DATA_LOADER_DOWNLOADING: 'analytics-data-loader-downloading',
    EXPORT_BTN: 'export-btn',
    HIDDEN: 'hidden',
    ANALYTICS_LEFT_CONTAINER: 'analytics-left-container',
    ANALYTICS_RIGHT_CONTAINER: 'analytics-right-container'
};

const CONSTANT_TEXTS = {
    RECURRENCE: textProvider.getText('analytics', 'recurrence'),
    SIDEBAR_HEADER_TITLE: textProvider.getText('analytics', 'sidebarTitle'),
    PREDEFINED_REPORTS: textProvider.getText('analytics', 'predefinedReports'),
    NEW_REPORTS: textProvider.getText('analytics', 'newReports'),
    EXPORT: textProvider.getText('analytics', 'export'),
    PREVIEW: textProvider.getText('analytics', 'preview'),
    WEB: textProvider.getText('exportOptions', 'web'),
    CSV: textProvider.getText('exportOptions', 'csv'),
    EXCEL: textProvider.getText('exportOptions', 'excel'),
    DOWNLOADING_LABEL: textProvider.getText('fileUpload', 'downloading'),
    PREDEFINE_REPORT_FORMAT: textProvider.getText('ga', 'actions.predefineReportFormat'),
    REPORT_SELECTED_DATE_FORMAT: textProvider.getText('ga', 'actions.reportSelectedDate'),
    SELECT_OUTPUT_FORMAT: textProvider.getText('ga', 'actions.selectOutputFormat'),
    ANALYTICS_MODULE: textProvider.getText('ga', 'category.analyticsModule'),
    ANALYTICS_MODULE_FORMAT: textProvider.getText('ga', 'category.analyticsModuleFormat'),
    DOWNLOAD_LINK_NOTIFICATION: textProvider.getText('analytics', 'downloadLinkNotification'),
};

const getMainBodyHeight = memoize((hasRecurrence) => {
    const fixedHeight = hasRecurrence ? '30px' : '0px';
    // 100% - 11% (main header height) = 89%;
    return {height: `calc(89% - ${fixedHeight})`};
});

class Analytics extends Component {
    constructor(props) {
        super(props);

        const {predefinedReports} = props;
        const exportType = (predefinedReports && predefinedReports.predefinedReportData && predefinedReports.predefinedReportData.exportType) || '';
        this.state = {
            isInDownloadingState: false,
            activeItem: {},
            activeItemName: 'Domain Revenue Reporting',
            activePreset: PERIOD_TYPES.CUSTOM,
            exportType,
            reportId: 'DOMAIN_REVENUE',
            isRecurrenceOpen: false
        }
    }

    componentDidMount() {
        const {getPredefinedReportsList, publisherId, setPredefinedReportPeriod, getPublisherReportsConfig} = this.props;
        const defaultPreset = calendarPresets.find(preset => preset.period === PERIOD_TYPES.LAST_MONTH);
        const defaultPeriod = {
            type: defaultPreset.period,
            from: defaultPreset.startDate,
            to: defaultPreset.endDate
        };
        getPredefinedReportsList(publisherId);
        setPredefinedReportPeriod(defaultPeriod);
        getPublisherReportsConfig(publisherId);

        this.setState({activePreset: defaultPeriod.type});
    }

    componentDidUpdate(prevProps) {
        const prevDownload = prevProps.predefinedReportData && prevProps.predefinedReportData.data;
        if (prevDownload) {
            return;
        }

        const {activeItem, activeItemName} = this.state;
        const {predefinedReportsList} = this.props;

        if (predefinedReportsList && predefinedReportsList.data) {
            const newActiveItem = predefinedReportsList.data.find(item => item.name === activeItemName);
            if (isEmpty(activeItem) || !isEqual(newActiveItem, this.state.activeItem)) {
                this.setState({activeItem: newActiveItem, activeItemName: newActiveItem.name});
            }
        }
    }

    handleItemChange = (item) => {
        const {user} = this.props;

        if (item.name !== this.state.activeItemName) {
            GAProvider.notifyEvent(CONSTANT_TEXTS.ANALYTICS_MODULE, formatString(CONSTANT_TEXTS.PREDEFINE_REPORT_FORMAT, item.name), getReplacedValue(user.data.name))
        }
        this.setState({activeItem: item, activeItemName: item.name, reportId: item.id})
    }
    showEmailDownloadSuccessToaster = () => {
        return ToastProvider.success(
            <Toaster notificationIcon='icon-mail'
                     notificationHookId='toaster-success-icon'
                     notificationMessage={CONSTANT_TEXTS.DOWNLOAD_LINK_NOTIFICATION}
            />,
        true);
    }
    wait = ms => new Promise(resolve => setTimeout(resolve, ms));
    checkFileRequestTime = async() => {
        await this.wait(40000);
        if (this.props.predefinedReportData.state === 'Loading') {
            this.setState({isInDownloadingState: false})
            return this.showEmailDownloadSuccessToaster();
        }
        return false;
    };
    onExportClick = (value, reportQuery) => {
        const {user, getPredefinedReportData, publisherId, period, publisherReportsConfig} = this.props;
        const {activeItemName, reportId, exportType} = this.state;
        const query = reportQuery || null;
        let isDownloading = true;
        this.setState({isInDownloadingState: true})
        if (exportType !== value) {
            GAProvider.notifyEvent(
                formatString(CONSTANT_TEXTS.ANALYTICS_MODULE_FORMAT, activeItemName),
                formatString(CONSTANT_TEXTS.SELECT_OUTPUT_FORMAT, value),
                getReplacedValue(user.data.name)
            );
        }
        this.setState({exportType: value});
        const configData = publisherReportsConfig.data;
        if(configData && configData.publisherId) {
            const reportIdsArr = configData.reportIds.split(',');
            if(reportIdsArr.includes(reportId.toString())) {
                isDownloading = false;
                this.setState({isInDownloadingState: false});
                this.showEmailDownloadSuccessToaster();
            }
        }else {
            this.checkFileRequestTime();
        }
        getPredefinedReportData(publisherId, reportId, value, period, isDownloading, user.data.email, query);

    }

    onWebPreviewClick = () => {
        const {getReportPreview, publisherId, period} = this.props;
        const {reportId} = this.state;
        getReportPreview(publisherId, reportId, period);
    }

    openRecurrenceModal = () => {
        this.setState({isRecurrenceOpen: true});
    };

    closeRecurrenceModal = () => {
        this.setState({isRecurrenceOpen: false});
    };

    onPresetClick = (type, startDate, endDate, updateViewDateForPreset) => {
        const {setPredefinedReportPeriod, user} = this.props;
        const {activeItemName, activePreset} = this.state;
        const period = {
            type,
            from: startDate,
            to: endDate
        };
        if (type !== activePreset) {
            GAProvider.notifyEvent(formatString(CONSTANT_TEXTS.ANALYTICS_MODULE_FORMAT, activeItemName),
                formatString(CONSTANT_TEXTS.REPORT_SELECTED_DATE_FORMAT, type), getReplacedValue(user.data.name))
        }
        this.setState({activePreset: type});
        updateViewDateForPreset([startDate, endDate]);
        setPredefinedReportPeriod(period);
    }

    onCustomPeriodChange = (name, value) => {
        const {setPredefinedReportPeriod} = this.props;
        const period = {
            type: 'custom',
            from: value[0],
            to: value[1]
        };
        this.setState({activePreset: PERIOD_TYPES.CUSTOM});
        setPredefinedReportPeriod(period);
    }

    // downloadFileFromBuffer(content, name) {
    //     const base64data = Buffer.from(content, 'binary');
    //     const url = window.URL.createObjectURL(new Blob([base64data], {type: 'application/json', encoding: 'UTF-8'}));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', name);
    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();
    //     this.setState({isDownloading: false});
    // }

    render() {
        const {predefinedReportsList, period, isReportsSchedulingEnabled, publisherId, predefinedReportData,
            isHbPerformanceReportEnabled, isInvoiceReportEnabled, isRevenueReportEnabled, isPlacementReportEnabled,
            isDomainRevenueReportEnabled, isPurchaseOrderReportEnabled, isPurchaseOrderDailyTrendsReportEnabled, isReportSQLEditorEnabled} = this.props;
        const isDownloading = (predefinedReportData && predefinedReportData.isDownloading && this.state.isInDownloadingState) || false;
        const {activeItem, activeItemName, reportId} = this.state;
        const reportflags = {isHbPerformanceReportEnabled, isInvoiceReportEnabled, isRevenueReportEnabled, isPlacementReportEnabled,
            isDomainRevenueReportEnabled, isPurchaseOrderReportEnabled, isPurchaseOrderDailyTrendsReportEnabled};

        if (!predefinedReportsList || !predefinedReportsList.data ||
            predefinedReportsList.state === appStates.LOADING) {
            return <AnalyticsLoader />
        }

        if (predefinedReportsList.state === appStates.ERROR) {
            return <RetryFetchData classNames='centered' />
        }

        const activeReportsList = getActiveReportsList(predefinedReportsList.data, reportflags);

        return (
            <div className={CLASS_NAMES.ANALYTICS_WRAPPER}
                 {...getHookId('analytics-page')}
            >
                <div className={CLASS_NAMES.ANALYTICS_BODY_CONTAINER}>
                    <div className={CLASS_NAMES.ANALYTICS_SIDEBAR}>
                        <div className={CLASS_NAMES.ANALYTICS_SIDEBAR_HEADER}>
                            {CONSTANT_TEXTS.SIDEBAR_HEADER_TITLE}
                        </div>
                        <ReportsMenu activeItemName={activeItemName}
                                     title={CONSTANT_TEXTS.NEW_REPORTS}
                                     isNewReportsList={true}
                                     reportsList={activeReportsList.new}
                                     handleItemChange={this.handleItemChange}
                                     isReportsSchedulingEnabled={isReportsSchedulingEnabled}
                        />
                        <ReportsMenu activeItemName={activeItemName}
                                     title={CONSTANT_TEXTS.PREDEFINED_REPORTS}
                                     reportsList={activeReportsList.predefined}
                                     handleItemChange={this.handleItemChange}
                                     isReportsSchedulingEnabled={isReportsSchedulingEnabled}
                        />
                    </div>
                    <div className={CLASS_NAMES.ANALYTICS_MAIN}>
                        <div className={CLASS_NAMES.ANALYTICS_MAIN_HEADER}>
                            {activeItemName}
                            {isReportsSchedulingEnabled &&
                                <Button className={CLASS_NAMES.RECURRENCE_BTN}
                                        icon="icon-recurrence"
                                        iconPosition="left"
                                        onClick={this.openRecurrenceModal}
                                        hookId="recurrence-modal-button"
                                >
                                    {CONSTANT_TEXTS.RECURRENCE}
                                </Button>
                            }
                        </div>
                        {isReportsSchedulingEnabled && activeItem.hasRecurrence &&
                            <ScheduleInfo item={activeItem}
                                          setReportScheduling={this.props.setReportScheduling}
                            />
                        }
                        <div className={CLASS_NAMES.ANALYTICS_MAIN_BODY}
                             style={getMainBodyHeight(isReportsSchedulingEnabled && activeItem.hasRecurrence)}
                        >
                            <div className={CLASS_NAMES.ANALYTICS_LEFT_CONTAINER}>
                                {period && <ReportsCalendar onPresetClick={this.onPresetClick}
                                                            onCustomPeriodChange={this.onCustomPeriodChange}
                                                            period={period}
                                                            activePreset={this.state.activePreset}
                                />}
                                <div className={CLASS_NAMES.ANALYTICS_OUTPUT_FORMAT_CONTAINER}>
                                    <div className={CLASS_NAMES.ANALYTICS_OUTPUT_FORMAT_TITLE}>
                                        {CONSTANT_TEXTS.EXPORT}
                                    </div>
                                    <Button onClick={this.onWebPreviewClick}
                                            className={CLASS_NAMES.EXPORT_BTN}
                                            hookId='preview-report-btn'
                                            icon="icon-web"
                                            iconPosition="left"
                                            transparent
                                    >
                                        {CONSTANT_TEXTS.WEB}
                                    </Button>
                                    <Button onClick={() => this.onExportClick('xlsx')}
                                            className={CLASS_NAMES.EXPORT_BTN}
                                            hookId='export-excel-report-btn'
                                            icon="icon-excel"
                                            iconPosition="left"
                                            transparent
                                    >
                                        {CONSTANT_TEXTS.EXCEL}
                                    </Button>
                                    <Button onClick={() => this.onExportClick('csv')}
                                            className={CLASS_NAMES.EXPORT_BTN}
                                            hookId='export-csv-report-btn'
                                            icon="icon-csv"
                                            iconPosition="left"
                                            transparent
                                    >
                                        {CONSTANT_TEXTS.CSV}
                                    </Button>
                                </div>
                            </div>
                            {isReportSQLEditorEnabled && (
                                <div className={CLASS_NAMES.ANALYTICS_RIGHT_CONTAINER}>
                                <SQLReport
                                    predefinedReportsList={predefinedReportsList}
                                    reportId={reportId}
                                    publisherId={publisherId}
                                    startPreviewQuery={this.onExportClick}
                                ></SQLReport>
                            </div>
                            )}
                        </div>
                        <div className={CLASS_NAMES.ANALYTICS_MAIN_FOOTER}>
                            <span className={cx({[CLASS_NAMES.HIDDEN]: !isDownloading},
                                CLASS_NAMES.ANALYTICS_DATA_LOADER_DOWNLOADING)}
                            >
                                {CONSTANT_TEXTS.DOWNLOADING_LABEL}
                            </span>
                            <LoadingBar isLoading={isDownloading} />
                        </div>
                    </div>
                </div>
                <WebPreview onExportClick={this.onExportClick} />
                {isReportsSchedulingEnabled && activeItem &&
                    <ScheduleRecurrence item={activeItem}
                                        publisherId={publisherId}
                                        isOpen={this.state.isRecurrenceOpen}
                                        closeRecurrenceModal={this.closeRecurrenceModal}
                                        setReportScheduling={this.props.setReportScheduling}
                    />
                }
            </div>
        );
    }
}
Analytics.propTypes = {
    getReportPreview: PropTypes.func,
    getPredefinedReportsList: PropTypes.func,
    getPredefinedReportData: PropTypes.func,
    setPredefinedReportPeriod: PropTypes.func,
    setReportScheduling: PropTypes.func,
    predefinedReportsList: PropTypes.object,
    predefinedReportData: PropTypes.object,
    period: PropTypes.object,
    isReportsSchedulingEnabled: PropTypes.bool,
    isHbPerformanceReportEnabled: PropTypes.bool,
    isInvoiceReportEnabled: PropTypes.bool,
    isRevenueReportEnabled: PropTypes.bool,
    isPlacementReportEnabled: PropTypes.bool,
    isDomainRevenueReportEnabled: PropTypes.bool,
    isPurchaseOrderReportEnabled: PropTypes.bool,
    isPurchaseOrderDailyTrendsReportEnabled: PropTypes.bool,
    publisherId: PropTypes.number,
    user: PropTypes.object,
    isDownloading: PropTypes.bool,
    predefinedReports: PropTypes.object,
    getPublisherReportsConfig: PropTypes.func,
    publisherReportsConfig: PropTypes.object,
    isReportSQLEditorEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    publisherId: state.app.publisher.data.id,
    predefinedReportsList: state.predefinedReports.predefinedReportsList,
    period: state.predefinedReports.period,
    predefinedReportData: state.predefinedReports.predefinedReportData,
    user: state.app.user,
    isReportsSchedulingEnabled: selectFeatureFlag('reportsScheduling')(state),
    isHbPerformanceReportEnabled: selectFeatureFlag('hbPerformanceAdditionalReport')(state),
    isInvoiceReportEnabled: selectFeatureFlag('invoiceAdditionalReport')(state),
    isRevenueReportEnabled: selectFeatureFlag('revenueAdditionalReport')(state),
    isPlacementReportEnabled: selectFeatureFlag('placementAdditionalReport')(state),
    isDomainRevenueReportEnabled: selectFeatureFlag('domainRevenueAdditionalReport')(state),
    isPurchaseOrderReportEnabled: selectFeatureFlag('purchaseOrderAdditionalReport')(state),
    isPurchaseOrderDailyTrendsReportEnabled: selectFeatureFlag('purchaseOrderDailyTrendsAdditionalReport')(state),
    isReportSQLEditorEnabled: selectFeatureFlag('sqlReportEditor')(state),
    publisherReportsConfig: state.predefinedReports.publisherReportsConfig
});
const mapDispatchToProps = ({
    getReportPreview,
    getPredefinedReportsList,
    getPredefinedReportData,
    setPredefinedReportPeriod,
    setReportScheduling,
    getPublisherReportsConfig
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Analytics));
