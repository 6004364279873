import React, {Component} from 'react';
import ReactDataGrid from 'react-data-grid';
import {AutoSizer} from 'react-virtualized';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';
import cx from 'classnames';
import {Input, Button} from '@perion-undertone/ut-react-common';
import ImportFile from '../ImportFile';
import BulkActionsOverlay from '../../../grid/BulkActionsOverlay';
import {adslotsGridSchema} from './../grid/adSlotsGridSchema';
import {
    createNewPlacementGroupAdSlot,
    searchAdSlots,
    textProvider
} from '../imports';
import {filterAdSlots} from './adSlotsUtils';

const CLASS_NAMES = {
    REACT_GRID_CHECKBOX_CONTAINER: 'react-grid-checkbox-container',
    REACT_GRID_CHECKBOX_CONTAINER_VISIBLE: 'react-grid-checkbox-container-visible',
    CONTAINER: 'ad-slots-container',
    HEADER_TAB_WRAPPER: 'adslots-header-tab-wrapper',
    COUNTER: 'adslots-counter',
    ACTION_WRAPPER: 'adslots-actions-wrapper',
    ACTION_BTN: 'adslots-action-btn',
    SEARCH_INPUT: 'adslots-search-input',
    LIST: 'ad-slots-list',
    LIST_EDIT_MODE: 'ad-slots-list-edit-mode',
    EDIT_MODE_ROW: 'adslot-edit-mode-row',
    ROW: 'adslot-row',
};

const CONSTANT_TEXTS = {
    SEARCH_ADSLOTS: textProvider.getText('adSlots', 'serchAdslots'),
    ADD_ADSLOTS: textProvider.getText('adSlots', 'addAdslots'),
}

class AdSlotsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedGroupIds: [],
            isSearchFocused: false
        };
    }

    componentDidMount() {
        if(_.isEmpty(this.props.currentGroup.adSlots)) {
            this.addNewAdSlot();
        }
    }

    getNewAdSlotData = () => {
        const {currentGroup: {adSlots, newAdSlot}} = this.props;
        if (newAdSlot && newAdSlot.length > 0) {
            let newData = _.concat(adSlots, newAdSlot);
            return newData;
        }
        return adSlots;
    }

    getAdSlotsChanges = (changes) => {
        const rawData = this.getNewAdSlotData();
        return _.map(rawData, (item) => {
            const foundChange = _.get(changes, item.id);
            if (foundChange) {
                return {
                    ...item,
                    ...foundChange
                }
            }
            return item;
        });
    }

    setSelectAllCheckbox = (rows, shouldSelect) => {
        const {selectedGroupIds} = this.state;
        let rowIds = rows.map(r => r.row.id).filter(id => id !== this.props.isInEditMode);
        const newSelectedGroupIds = shouldSelect
            ? selectedGroupIds.concat(rowIds)
            : selectedGroupIds.filter(i => rowIds.indexOf(i) === -1);

        this.setState(
            {selectedGroupIds: newSelectedGroupIds},
            () => {this.toggleSelectAllCheckbox(this.state.selectedGroupIds)}
        );
    }

    toggleSelectAllCheckbox = (selectedGroupIds) => {
        const {selectAllCheckbox} = this.grid;
        if (_.isArray(selectedGroupIds) && selectedGroupIds.length < 1) {
            selectAllCheckbox.checked = false;
            selectAllCheckbox.parentNode.className = CLASS_NAMES.REACT_GRID_CHECKBOX_CONTAINER;
        } else {
            selectAllCheckbox.checked = true;
            selectAllCheckbox.parentNode.className = CLASS_NAMES.REACT_GRID_CHECKBOX_CONTAINER_VISIBLE;
        }
    }

    onRowsSelected = rows => {
        const shouldSelect = rows.length >= 1;
        this.setSelectAllCheckbox(rows, shouldSelect);
    }

    onRowsDeselected = rows => {
        this.setSelectAllCheckbox(rows, false);
    }

    cancelBulkDelete = () => {
        this.setState({selectedGroupIds: []});
        this.grid.selectAllCheckbox.checked = false;
        this.grid.selectAllCheckbox.parentNode.className = CLASS_NAMES.REACT_GRID_CHECKBOX_CONTAINER;
    }

    RowRenderer = ({renderBaseRow, ...props}, isInEditMode) => {
        return <div className={isInEditMode === props.row.id ?
            CLASS_NAMES.EDIT_MODE_ROW : CLASS_NAMES.ROW}>{renderBaseRow(props)}</div>;
    };

    addNewAdSlot = () => {
        const newAdSlot = {
            id: Date.now(),
            name: '',
            created: Date.now()
        }
        return this.props.createNewPlacementGroupAdSlot([newAdSlot]);
    };

    render () {
        const {isInEditMode, currentGroup, searchAdSlots, isPassBackFocused} = this.props;
        const {adSlotsChanges, searchTerm, customization, adSlots, err, adSlotToEdit} = currentGroup;
        const {selectedGroupIds, isSearchFocused} = this.state;
        const filteredAdslots = filterAdSlots(adSlots, searchTerm, customization, adSlotToEdit);
        const adSlotsData = this.getAdSlotsChanges(adSlotsChanges);
        const rowSource = filterAdSlots(adSlotsData, searchTerm, customization, isInEditMode);
        const checkboxVisible = !!isInEditMode;
        const rowWithProps = _.map(rowSource, (row, index) => {
            return {...row, isPassBackFocused: isPassBackFocused, isSearchFocused: isSearchFocused, rowIndex: index}
        })
        return (
            <div className={CLASS_NAMES.CONTAINER}>
                <div className={CLASS_NAMES.HEADER_TAB_WRAPPER}>
                    <div className={CLASS_NAMES.COUNTER}>
                        {!searchTerm || _.isEmpty(searchTerm) ?
                            `Adslots (${adSlots.length})` :
                            `Adslots (${filteredAdslots.length}/${adSlots.length})`
                        }
                    </div>
                    <div className={CLASS_NAMES.ACTION_WRAPPER}>
                        <Button className={CLASS_NAMES.ACTION_BTN}
                                hookId={!_.isNil(adSlotToEdit) ? 'ad-slots-new-slot-btn-disabled' : 'ad-slots-new-slot-btn'}
                                icon='icon-add'
                                onClick={this.addNewAdSlot}
                                disabled={!_.isNil(adSlotToEdit)}
                                transparent
                        >
                            {CONSTANT_TEXTS.ADD_ADSLOTS}
                        </Button>
                        <ImportFile className={CLASS_NAMES.AD_SLOTS_IMPORT} err={err} />
                        <Input placeholder={CONSTANT_TEXTS.SEARCH_ADSLOTS}
                               icon='icon-search'
                               iconPosition='right'
                               className={CLASS_NAMES.SEARCH_INPUT}
                               onChange={(e) => searchAdSlots(e.target.value)}
                               hookId='search-adslots-input'
                               onFocus={() => this.setState({isSearchFocused: true})}
                               onBlur={() => this.setState({isSearchFocused: false})}
                        />
                    </div>
                </div>
                <div className={cx(CLASS_NAMES.LIST, !!checkboxVisible && CLASS_NAMES.LIST_EDIT_MODE)}>
                    <AutoSizer>
                        {({height, width}) => {
                            return (
                                <ReactDataGrid columns={adslotsGridSchema}
                                               ref={node => this.grid = node}
                                               enableCellAutoFocus={false}
                                               rowGetter={i => rowWithProps[i]}
                                               rowsCount={rowSource.length}
                                               rowHeight={40}
                                               headerRowHeight={40}
                                               minWidth={width}
                                               minHeight={height}
                                               rowRenderer={(rowProps) => this.RowRenderer(rowProps, isInEditMode)}
                                               rowSelection={{
                                                   showCheckbox: true,
                                                   onRowsSelected: this.onRowsSelected,
                                                   onRowsDeselected: this.onRowsDeselected,
                                                   selectBy: {
                                                       keys: {rowKey: 'id', values: selectedGroupIds}
                                                   }
                                               }}
                                />
                            );
                        }}
                    </AutoSizer>
                    {selectedGroupIds.length > 0 &&
                        (<BulkActionsOverlay cancelBulkDelete={this.cancelBulkDelete}
                                             selectedGroupIds={selectedGroupIds}
                                             displayBlockOption={false}
                                             displayDeleteOption={true}
                                             idsType='adSlotsIds'
                                             isFromAdSlots={true}
                        />)
                    }
                </div>
            </div>
        )
    }
}

AdSlotsList.propTypes = {
    currentGroup: PropTypes.object,
    isInEditMode: PropTypes.number,
    grid: PropTypes.object,
    createNewPlacementGroupAdSlot: PropTypes.func,
    searchAdSlots: PropTypes.func,
    isPassBackFocused: PropTypes.bool
};

const mapStateToProps = ({placements, grid}) => ({
    currentGroup: placements.currentGroup,
    isInEditMode: placements.currentGroup.adSlotToEdit,
    grid
});

const mapDispatchToProps = ({
    createNewPlacementGroupAdSlot,
    searchAdSlots
});

export default connect(mapStateToProps, mapDispatchToProps)(AdSlotsList);