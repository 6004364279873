import {of} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {createAccountSuccess, createAccountFailed} from '../actions/userActions';
import {accountConstants} from '../constants/accountConstants';

export const createAccountEpic = actions => (
    actions.pipe(
        ofType(accountConstants.CREATE_ACCOUNT_REQUEST),
        mergeMap(action => {
            return ajax.post(`api/auth/create-account`,
                action.payload.data,
                {'Content-Type': 'application/json'}).pipe(
                map(data => createAccountSuccess(data.response)),
                catchError(error => of(createAccountFailed(error)))
            )
        })
    )
);