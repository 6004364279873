import React from 'react';
import PropTypes from 'prop-types';
import {Route, NavLink as RouterLink, withRouter} from 'react-router-dom';

function NavLink({to, location, children}) {
    /* eslint-disable react/jsx-no-bind */
    return (
        <Route render={() =>
            <RouterLink replace={to === location.pathname} to={to}>
                {children}
            </RouterLink>
        }
        />
    );
}

NavLink.propTypes = {
    to: PropTypes.string
};

export default withRouter(NavLink);
