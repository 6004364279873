import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {
    textProvider,
    setViewSiteUnderReviewPage,
    displayEnterSite
} from './imports';
import ApprovalCounter from './ApprovalCounter';
import OnboardingSideBar from './OnboardingSideBar';
import {VisitHelpCenter, SiteReviewDescription} from './SiteUnderReviewPage';

const CLASS_NAMES = {
    STEP_COMPONENT: 'step-comp',
    STEP_LAYOUT_LEFT: 'layout-left'
};

const CONSTANT_TEXTS = {
    BUTTON: textProvider.getText('onboardingModal', 'step_2_1.button')
};

class OnboardingStep_2_1 extends Component {
    constructor(props) {
        super(props);

        this.closeOnboardingModal = this.closeOnboardingModal.bind(this);
    }

    closeOnboardingModal() {
        this.props.setViewSiteUnderReviewPage(false);
        this.props.closeModal();
    }

    render() {
        const {onboarding, displayEnterSite} = this.props;

        return (
            <div id="step_2_1" className={CLASS_NAMES.STEP_COMPONENT}>
                <div className={CLASS_NAMES.STEP_LAYOUT_LEFT}>
                    <SiteReviewDescription address={onboarding.mainSite.SupplySource.address}
                                           displayEnterSite={displayEnterSite}
                    />
                    <button onClick={this.closeOnboardingModal}>
                        {CONSTANT_TEXTS.BUTTON}
                    </button>
                </div>
                <OnboardingSideBar className='site-under-review-step' >
                    <ApprovalCounter date={onboarding.mainSite.createdAt} />
                    <VisitHelpCenter />
                </OnboardingSideBar>
            </div>
        )
    }
}

OnboardingStep_2_1.propTypes = {
    user: PropTypes.object,
    onboarding: PropTypes.object,
    closeModal: PropTypes.func,
    setViewSiteUnderReviewPage: PropTypes.func,
    displayEnterSite: PropTypes.func
};

const mapStateToProps = ({app}) => ({
    user: app.user,
    onboarding: app.onboarding
});

const mapDispatchToProps = ({
    setViewSiteUnderReviewPage,
    displayEnterSite
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingStep_2_1);