import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';
import _ from 'lodash';

import {redirectTo, resetAppState, UndertoneLogo} from './imports';
import AccountHeader from './AccountHeader';
import AccountFooter from './AccountFooter';

const SIZE = {
    SMALL: 'small',
    NORMAL: 'normal'
};

const AccountLayout = (props) => {
    const {size, backToRoute, backToText, children, resetAppState,
        className, customRedirect, headerProps, footerProps} = props;

    const navigateTo = () => {
        redirectTo(backToRoute);
        resetAppState();
    };

    return (
        <div className={cx('account-layout', {['account-layout--small']: size === SIZE.SMALL})}>
            <UndertoneLogo />
            <div className='account-content-wrapper-container'>
                <div className={cx('account-main-wrapper', className,
                    {['account-wrapper--small']: size === SIZE.SMALL})}
                    style={{marginTop: backToRoute || customRedirect ? '60px' : 0}}
                >
                    {headerProps && !_.isEmpty(headerProps.header) && <AccountHeader {...headerProps} />}
                    {children}
                    {footerProps && <AccountFooter {...footerProps} />}
                </div>
                {backToRoute &&
                   <div className='redirect-bottom-container link-underline'
                        onClick={navigateTo}
                    >
                        <span>{backToText}</span>
                    </div>
                }
                {customRedirect}
            </div>
        </div>
    );
};

AccountLayout.propTypes = {
    className: PropTypes.string,
    headerProps: PropTypes.object,
    footerProps: PropTypes.object,
    backToRoute: PropTypes.string,
    backToText: PropTypes.string,
    customRedirect: PropTypes.element,
    size: PropTypes.string,
    resetAppState: PropTypes.func
};

AccountLayout.defaultProps = {
    size: SIZE.NORMAL
};

const mapDispatchToProps = ({
    resetAppState
});

export default connect(null, mapDispatchToProps)(AccountLayout);