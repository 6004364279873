import React, {Component} from 'react';
import PropTypes from 'prop-types';
import textProvider from '../texts/textProvider';
import {Modal, Button} from '@perion-undertone/ut-react-common';

const CLASS_NAMES = {
    CONFIRMATION_MODAL: 'confirmation-modal',
    CONFIRMATION_MODAL_HEADER: 'confirmation-modal-header',
    CONFIRMATION_MODAL_BODY: 'confirmation-modal-body',
    CONFIRMATION_MODAL_FOOTER: 'confirmation-modal-footer',
    CONFIRMATION_MODAL_BUTTON: 'confirmation-modal-button',
    CANCEL_BUTTON: 'confirmation-modal-cancel-button',
    SAVE_BUTTON: 'confirmation-modal-save-button',
    BUTTON_TEXT: 'confirmation-modal-button-text'
};

const CONSTANT_TEXTS = {
    CONFIRMATION_MESSAGE: textProvider.getText('confirmationModal', 'confirmationMessage'),
    CANCEL: textProvider.getText('confirmationModal', 'cancel'),
    DELETE: textProvider.getText('confirmationModal', 'delete'),
    HEADER_TEXT: textProvider.getText('confirmationModal', 'confirmDeleteAction')
}

class ConfirmationModal extends Component {
    constructor(props) {
        super(props);

        this.cancelModal = this.cancelModal.bind(this);
        this.confirmOperation = this.confirmOperation.bind(this);
    }

    cancelModal() {
        this.props.closeModal();
    }

    confirmOperation() {
        const {closeModal, onConfirm} = this.props;
        onConfirm();
        closeModal();
    }

    render() {
        const {openModal, closeOnOutsideClick, headerText, confirmationMessage, cancelButtonText, confirmButtonText} = this.props;

        if (!openModal) {
            return null;
        }

        return (
            <Modal className={CLASS_NAMES.CONFIRMATION_MODAL}
                   open={openModal}
                   closeOnOutsideClick={closeOnOutsideClick}
                   onModalClose={this.cancelModal}
                   closeButton={false}
                   hookId="confirmation-modal"
            >
                <div className={CLASS_NAMES.CONFIRMATION_MODAL_HEADER}>
                    {headerText}
                </div>
                <div className={CLASS_NAMES.CONFIRMATION_MODAL_BODY}>
                    {confirmationMessage}
                </div>
                <div className={CLASS_NAMES.CONFIRMATION_MODAL_FOOTER}>
                    <Button transparent
                            onClick={this.cancelModal}
                            className={CLASS_NAMES.CANCEL_BUTTON}
                            hookId='confirmation-cancel-button'
                    >
                        {cancelButtonText}
                    </Button>
                    <Button onClick={this.confirmOperation}
                            hookId='confirmation-save-button'
                    >
                        {confirmButtonText}
                    </Button>
                </div>
            </Modal>
        )
    }
}

ConfirmationModal.propTypes = {
    onConfirm: PropTypes.func,
    openModal: PropTypes.bool,
    closeModal: PropTypes.func,
    closeOnOutsideClick: PropTypes.bool,
    confirmationMessage: PropTypes.string,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    headerText: PropTypes.string
};

ConfirmationModal.defaultProps = {
    closeOnOutsideClick: false,
    confirmationMessage: CONSTANT_TEXTS.CONFIRMATION_MESSAGE,
    cancelButtonText: CONSTANT_TEXTS.CANCEL,
    confirmButtonText: CONSTANT_TEXTS.DELETE,
    headerText: CONSTANT_TEXTS.HEADER_TEXT
}


export default ConfirmationModal;
