import {paymentConstants} from '../constants/paymentConstants';

export const getPaymentData = (publisherId) => ({
    type: paymentConstants.PAYMENT_DATA_REQUEST,
    payload: {publisherId}
})
export const getPaymentDataSuccess = (data) => ({
    type: paymentConstants.PAYMENT_DATA_SUCCESS,
    payload: {data}
});
export const getPaymentDataFailed = (error) => ({
    type: paymentConstants.PAYMENT_DATA_FAILURE,
    payload: {error}
});