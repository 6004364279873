import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Checkbox, Input} from '@perion-undertone/ut-react-common';
import {filterOptionsGrid, toggleAllOptions} from '../../../actions/gridCustomizationActions';
import textProvider from '../../../texts/textProvider';

const CLASS_NAMES = {
    FILTER_CONTAINER: 'filter-container',
    SELECT_BOX: 'selectBox',
    SEARCH_FILTER: 'search-filter',
    SELECT_ALL: 'select-all',
    SEARCH_ICON: 'search-icon',
    FILTER_SCROLL: 'filter-scroll',
    CHECKBOX_LIST: 'checkbox-list'
}

const CONSTANT_TEXTS = {
    SEARCH: textProvider.getText('common', 'search')
}

class ColumnCheckboxFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredOptions: props.filterOptions,
            searchIconClass: 'icon-search'
        };
    }
    handleOptionsFilter = ({target: {value}}) => {
        const lcaseTerm = value.toLowerCase();
        const updatedList = this.props.filterOptions.filter(option =>
            option.name.toLowerCase().includes(lcaseTerm)
        )
        return this.setState({
            filteredOptions: updatedList,
            searchIconClass: !value ? 'icon-search' : '',
        });
    }
    handleClearOptionsFilter = () => {
        this.setState({filteredOptions: this.props.filterOptions});
    }
    getCheckedOptions = (optName) => {
        const {customization, id} = this.props;
        if (customization && customization.filters && customization.filters.length > 0) {
            const foundField = customization.filters.find(x => x.field == id);
            const isChecked = foundField ? foundField.value.includes(optName) : false;
            return !isChecked;
        }
        return true;
    }
    handleCheckBoxChange = (id, optName, filterOptions) => {
        this.props.filterOptionsGrid(id, 'options', optName, filterOptions);
    }
    handleAllCheckbox(checked) {
        const {filterOptions, toggleAllOptions, id} = this.props;
        const allFilters = filterOptions.map(item => item.alias || item.name);
        const filters = !checked ? [] : allFilters;
        toggleAllOptions(id, 'options', filters, filterOptions);
    }
    toggleAllChecked = () => {
        const {customization, id, filterOptions} = this.props;
        if (customization && customization.filters && customization.filters.length > 0) {
            const foundField = customization.filters.find(x => x.field == id);
            return !(foundField && foundField.value.length <= filterOptions.length);
        }
        return true;
    }
    render() {
        const {filteredOptions} = this.state;
        const {id, isSearchFilter} = this.props;
        const allChecked = this.toggleAllChecked();
        return (
            <div className={CLASS_NAMES.FILTER_CONTAINER}>
                {isSearchFilter && <div className={CLASS_NAMES.SEARCH_ICON}>
                    <Input type='search'
                        placeholder={CONSTANT_TEXTS.SEARCH}
                        icon={this.state.searchIconClass}
                        iconPosition='right'
                        size='small'
                        className={CLASS_NAMES.SEARCH_FILTER}
                        onChange={this.handleOptionsFilter}
                        onClear={this.handleClearOptionsFilter}
                        hookId="filter-search"
                    />
                </div>}
                <div className={CLASS_NAMES.FILTER_SCROLL}>
                    <Checkbox label='All'
                        onChange={() => this.handleAllCheckbox(allChecked)}
                        squared
                        checked={allChecked}
                        className={CLASS_NAMES.SELECT_ALL}
                        hookId="filter-all"
                    />

                    {this.state.filteredOptions.map(option => {
                        const optName = option.alias || option.name;
                        const isChecked = this.getCheckedOptions(optName);
                        return (
                            <div key={option.id} className={CLASS_NAMES.CHECKBOX_LIST}>
                                <Checkbox label={option.name}
                                    squared
                                    checked={isChecked}
                                    onChange={() => this.handleCheckBoxChange(id, optName, filteredOptions)}
                                    value={Number(option.id)}
                                    className={CLASS_NAMES.SELECT_BOX}
                                    hookId="filter-option"
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
ColumnCheckboxFilter.propTypes = {
    filterOptions: PropTypes.array,
    filterOptionsGrid: PropTypes.func,
    toggleAllOptions: PropTypes.func,
    customization: PropTypes.object,
    id: PropTypes.string,
    isSearchFilter: PropTypes.bool
};

const mapStateToProps = ({grid}) => ({
    customization: grid.customization
})

const mapDispatchToProps = ({
    filterOptionsGrid,
    toggleAllOptions
})

export default connect(mapStateToProps, mapDispatchToProps)(ColumnCheckboxFilter);
