export const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');

    for (const c of cookies) {
      const cookie = c.trim();
      if (cookie.startsWith(name)) {
        return cookie.slice(name.length);
      }
    }

    return "";
}

export const setTokenCookies = (tokens) => {
  document.cookie = `x-access-token=${tokens['x-access-token']}`;
  document.cookie = `cg-refresh-token=${tokens['cg-refresh-token']}`;
  document.cookie = `cg-id-token=${tokens['cg-id-token']}`;
  document.cookie = `cg-id-token-exp=${tokens['cg-id-token-exp']}`;
};

export const clearCookies = () => {
  const d = new Date();
  d.setTime(d.getTime() - (1000*60*60*24));
  const expires = `expires=${d.toGMTString()}`;
  document.cookie = `cg-id-token=; ${expires}`;
  document.cookie = `cg-id-token-exp=; ${expires}`;
  document.cookie = `cg-refresh-token=; ${expires}`;
  document.cookie = `uid=; ${expires}`;
  document.cookie = `x-access-token=; ${expires}`;
  document.cookie = `x-wf-token=; ${expires}`;
};