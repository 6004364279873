import {map} from 'lodash';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {has} from 'lodash';
import {FLOPFLIP_STATE_SLICE} from '@flopflip/react-redux';
import {validate} from '@perion-undertone/ramp-helpers';
import {Input, Checkbox, Select} from '@perion-undertone/ut-react-common';

import AccountLayout from './AccountLayout';
import PasswordField from './PasswordField';

import {
    appStates, createAccount, FormField, FormGroup,
    geoSelectOptions, getGeos, setPrefilledAccountData,
    redirectTo, textProvider
} from './imports';

const CLASS_NAMES = {
    MAIN_CONTENT_WRAPPER: 'create-account-main-content-wrapper',
    AGREEMENT_WRAPPER: 'agreement-wrapper',
    TERMS_OF_SERVICE_LINK: "terms-of-service link-underline",
    PRIVACY_POLICY_LINK: "privacy-policy link-underline"
};

const CONSTANT_TEXTS = {
    I_AGREE: textProvider.getText('account', 'agreement'),
    TERMS_OF_SERVICE: textProvider.getText('account', 'termsOfService'),
    PRIVACY_POLICY: textProvider.getText('account', 'privacyPolicy'),
    SIGN_IN: textProvider.getText('account', 'signIn'),
    CREATE_ACCOUNT: textProvider.getText('account', 'createAccount'),
    HAVE_AN_ACCOUNT: textProvider.getText('account', 'haveAnAccount')
};

const NavigateToLogin = () => {
    return (
        <div className='redirect-bottom-container' style={{textAlign: 'left', color: 'white'}}>
            {`${CONSTANT_TEXTS.HAVE_AN_ACCOUNT} `}
            <a className='link-underline' onClick={() => redirectTo('/')}>
                {CONSTANT_TEXTS.SIGN_IN}
            </a>
        </div>
    )
};

const CreateAccount = (props) => {
    const {account, createAccount, featureFlags, prefillAccount} = props;
    const [password, setPassword] = useState(prefillAccount.password || '');
    const [firstName, setFirstName] = useState(prefillAccount.firstName || '');
    const [lastName, setLastName] = useState(prefillAccount.lastName || '');
    const [companyName, setCompanyName] = useState(prefillAccount.companyName || '');
    const [email, setEmail] = useState('');
    const [geo, setGeo] = useState(getGeos(prefillAccount.geo) || []);
    const [agreement, setAgreement] = useState(false);

    const submitCreateAccount = (e) => {
        e.preventDefault();
        const geosArr = map(geo, 'value');
        const geos = geosArr.toString().toLowerCase();
        props.setPrefilledAccountData({companyName, password, firstName, lastName, geo: geosArr});
        createAccount({
            username: email, password,
            attributes: {companyName, firstName, lastName, geo: geos}
        });
    };

    const renderNextPage = (account) => {
        if (account.data.userCreated) {
            return redirectTo('/activation-email-sent');
        }
        redirectTo('/welcome-back');
    }

    if (account && account.state === appStates.LOADED) {
        renderNextPage(account);
    }

    if (featureFlags) {
        const {status, flags} = featureFlags;
        if (status.isReady && has(flags, 'publishersOnboarding') && !flags.publishersOnboarding) {
            redirectTo('/');
            return (<div></div>);
        }
    }

    const isFormValid = () => {
        if (agreement && companyName && firstName && lastName &&
            (geo && Array.isArray(geo) && geo.length > 0) &&
            validate.isEmailValid(email) &&
            validate.validatePassword(password, {
                minLength: 8,
                minLowerCaseLetters: 1,
                minUpperCaseLetters: 1,
                minSpecialChars: 1
            }).isValid) {
            return true;
        }
        return false;
    };

    return (
        <AccountLayout headerProps={{
                            header: CONSTANT_TEXTS.CREATE_ACCOUNT
                       }}
                        footerProps={{
                            onSubmit: submitCreateAccount,
                            buttonText: CONSTANT_TEXTS.CREATE_ACCOUNT,
                            isDisabled: !isFormValid()
                        }}
                        customRedirect={<NavigateToLogin />}
                        className='create-account-layout'
        >
            <div className={CLASS_NAMES.MAIN_CONTENT_WRAPPER}>
                <form>
                    <FormGroup>
                        <FormField field='firstName' labelName='First name' className='input-container'>
                            <Input value={firstName}
                                   onChange={(e) => setFirstName(e.target.value)}
                                   hookId='create-account-first-name'
                            />
                        </FormField>
                        <FormField field='lastName' labelName='Last name' className='input-container'>
                            <Input value={lastName}
                                   onChange={(e) => setLastName(e.target.value)}
                                   hookId='create-account-last-name'
                            />
                        </FormField>
                    </FormGroup>
                    <FormField field='companyName' labelName='Company name' className='input-container'>
                        <Input value={companyName}
                               onChange={(e) => setCompanyName(e.target.value)}
                               hookId='create-account-company-name'
                        />
                    </FormField>
                    <FormField field='email' labelName='Email' className='input-container'>
                        <Input value={email}
                               onChange={(e) => setEmail(e.target.value)}
                               hookId='create-account-email'
                        />
                    </FormField>
                    <FormField field='geo' labelName='Geo' className='input-container'>
                        <Select options={geoSelectOptions}
                                type='select'
                                value={geo}
                                isMulti
                                isFocused={false}
                                disabled={false}
                                onChange={(geo) => setGeo(geo)}
                                components={{Option: Select.components.Option}}
                                hookId='geo-select'
                        />
                    </FormField>
                    <div className='content-divider'></div>
                    <PasswordField label='Set password'
                                   password={password}
                                   onChange={(e) => setPassword(e.target.value)}
                    />
                    <FormField>
                        <div className={CLASS_NAMES.AGREEMENT_WRAPPER}>
                            <Checkbox squared={true}
                                      transparent={true}
                                      inverted={true}
                                      checked={agreement}
                                      onChange={() => setAgreement(!agreement)}
                                      hookId='create-account-sign-up-checkbox'
                            >
                                {CONSTANT_TEXTS.I_AGREE}
                                <span className={CLASS_NAMES.TERMS_OF_SERVICE_LINK}>
                                    {CONSTANT_TEXTS.TERMS_OF_SERVICE}
                                </span> &amp;
                                <span className={CLASS_NAMES.PRIVACY_POLICY_LINK}>
                                    {CONSTANT_TEXTS.PRIVACY_POLICY}
                                </span>
                            </Checkbox>
                        </div>
                    </FormField>
                </form>
            </div>
        </AccountLayout>
    );
};

CreateAccount.propTypes = {
    account: PropTypes.object,
    createAccount: PropTypes.func,
    prefillAccount: PropTypes.object,
    setPrefilledAccountData: PropTypes.func,
    featureFlags: PropTypes.object
};

CreateAccount.defaultProps = {
    prefillAccount: {}
};

const mapStateToProps = (state) => ({
    account: state.app.account,
    prefillAccount: state.app.prefillAccount,
    featureFlags: state[FLOPFLIP_STATE_SLICE]
});

const mapDispatchToProps = {
    createAccount,
    setPrefilledAccountData
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);