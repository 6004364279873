import React, {Component} from 'react';
import {Route, HashRouter as Router, Switch, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {has, get, toNumber} from 'lodash';
import {selectFeatureFlag} from '@flopflip/react-redux';
import {enums} from '@perion-undertone/ramp-helpers';
import {GAProvider, GoogleAnalyticsPageView} from '@perion-undertone/ut-react-common';
import _ from 'lodash';

import Header from './Header';
import Sidebar from './Sidebar';
import routes from '../routes';
import {isAdmin, isPublisherUserOrPublisherAdmin} from '../auth';
import SurveyContainer from './survey/SurveyContainer';
import {
    routesHelper,
    appStates,
    formatString,
    textProvider,
    getReplacedValue,
    getCurrentPublisher,
    getAuthorizationData,
    SelectPublisher,
    SelectAllowedPublisher,
    getOnboardingData,
    SiteUnderReviewPage,
    OnboardingModal,
    RetryFetchData,
    fetchPublishers,
    redirectToHomePage,
    clearPredefinedReportData
} from './imports';
import {PUBLISHER_TYPES_MAP} from '../utils/globals';

const {PUBLISHER_ONBOARDING_STEPS} = enums;
const {SITE_UNDER_REVIEW} = PUBLISHER_ONBOARDING_STEPS;

const CLASS_NAMES = {
    HOME_LAYOUT: 'home-layout',
    HOME_BACKGROUND: 'home-background',
    MAIN_CONTAINER: 'main-container',
    CONTENT_WRAPPER: 'content-wrapper',
    SELECT_ALLOWED_PUBLISHER_WRAPPER: 'publisher-search-holder centered allowed-publishers'
};

const CONSTANT_TEXTS = {
    LOGIN_CATEGORY: textProvider.getText('ga', 'category.login'),
    CLICKED_LOGIN: textProvider.getText('ga', 'actions.clickedLogin'),
    PUBLISHER_PLATFORM: textProvider.getText('ga', 'category.publisherPlatform'),
    SELECTED_MODULE_FORMAT: textProvider.getText('ga', 'actions.selectedModuleFormat')
};

const HomeLayout = (props) => {
    return (
        <div className={CLASS_NAMES.HOME_LAYOUT}>
            <div className={CLASS_NAMES.HOME_BACKGROUND} />
            <Header />
            {props.children}
        </div>
    );
}

class Home extends Component {
    componentDidMount() {
        const {user} = this.props;
        redirectToHomePage();
        this.loadAuthorizationData();
        if (has(user, 'loginError') && !user.loginError) {
            GAProvider.notifyEvent(CONSTANT_TEXTS.LOGIN_CATEGORY, CONSTANT_TEXTS.CLICKED_LOGIN, getReplacedValue(user.data.name));
        }
    }

    componentDidUpdate() {
        const {user, auth, onboarding, isOnboardingEnabled, publishers, fetchPublishers, predefinedReportData} = this.props;
        if (auth && auth.data) {
            this.loadPublisherDetails();

            if(user && user.data && isPublisherUserOrPublisherAdmin(user.data) && !publishers) {
                fetchPublishers({publisherId: user.data.allowedPublisherId});
            }
        }
        if (isOnboardingEnabled && !onboarding && get(user, 'data.onBoardingCompleted') === '0') {
            this.props.getOnboardingData();
        }
        if (predefinedReportData && predefinedReportData.data && predefinedReportData.exportType !== 'web') {
            const {fileBuf, fileName} = predefinedReportData.data;
            fileBuf && this.downloadFileFromBuffer(fileBuf.data, fileName);
        }
        if (has(user, 'loginError') && !user.loginError) {
            GAProvider.notifyEvent(CONSTANT_TEXTS.LOGIN_CATEGORY, CONSTANT_TEXTS.CLICKED_LOGIN, getReplacedValue(user.data.name));
        }
    }

    loadAuthorizationData() {
        this.props.getAuthorizationData();
    }

    loadPublisherDetails() {
        const user = this.props.user.data;
        const {publisher, getCurrentPublisher, userUpdateState} = this.props;

        if (userUpdateState) return;

        let publisherId;
        if (isAdmin(user)) {
            const hash = window.location.hash;
            const hashParams = hash.split('/');
            publisherId = toNumber(hashParams[1]);
        }
        if (!isAdmin(user)) {
            publisherId = user.currentPublisherId;
        }

        if (!publisherId) return;

        const publisherData = publisher && publisher.data;
        if (!publisher || (publisherData && publisherData.id !== publisherId)) {
            getCurrentPublisher(publisherId);
        }
    }

    locationChanged = (location) => {
        const {user} = this.props;
        const splitRoute = location.pathname.split('/');
        if (splitRoute.length > 2) {
            GAProvider.notifyEvent(CONSTANT_TEXTS.PUBLISHER_PLATFORM, formatString(CONSTANT_TEXTS.SELECTED_MODULE_FORMAT, splitRoute[2]), getReplacedValue(user.data.name));
        }
    };

    downloadFileFromBuffer(content, name) {
        const base64data = Buffer.from(content, 'binary');
        const url = window.URL.createObjectURL(new Blob([base64data], {type: 'application/json', encoding: 'UTF-8'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.props.clearPredefinedReportData();
        // this.setState({isDownloading: false});
    }

    render() {
        const {user, features, publisher, auth, location, isOnboardingEnabled, hideSiteUnderReviewPage,
            shouldDisplayEnterSite, onboarding, userUpdateState, paymentTab, publisherCountry} = this.props;

        const hash = window.location.hash;
        const hashParams = hash.split('/');

        // Admin homepage
        if (!publisher && !toNumber(hashParams[1]) && isAdmin(user.data)) {
            return (
                <HomeLayout>
                    <SelectPublisher />
                </HomeLayout>
            );
        }

        // Direct user homepage, no current publisher
        const currentPublisherId = user.data.currentPublisherId;
        if (!currentPublisherId && isPublisherUserOrPublisherAdmin(user.data)) {
            return (
                <HomeLayout>
                    <div className={CLASS_NAMES.SELECT_ALLOWED_PUBLISHER_WRAPPER}>
                        <SelectAllowedPublisher />
                    </div>
                </HomeLayout>
            );
        }

        if (!publisher || (publisher.state === appStates.LOADING)) {
            return <HomeLayout />;
        }

        if (!userUpdateState && (publisher.state === appStates.ERROR || (auth && auth.state === appStates.ERROR))) {
            return (
                <HomeLayout>
                    <RetryFetchData classNames='centered' />
                </HomeLayout>
            );
        }

        const publisherId = publisher.data.id;
        const publisherTypeCd = PUBLISHER_TYPES_MAP[publisher.data.publisherTypeId];
        const filteredRoutes = routesHelper(routes, features, publisherId, user, {paymentTab}, publisherTypeCd, publisherCountry);

        if (isOnboardingEnabled && !hideSiteUnderReviewPage && !shouldDisplayEnterSite &&
            get(user, 'data.onBoardingStep') === SITE_UNDER_REVIEW && onboarding.state === appStates.LOADED) {
            return (
                <HomeLayout>
                    <div className={CLASS_NAMES.MAIN_CONTAINER}>
                        <Sidebar publisherId={publisherId}
                                 filteredRoutes={filteredRoutes}
                                 disabledMenu
                                 auth={auth}
                                 user={user}
                        />
                        <div className={CLASS_NAMES.CONTENT_WRAPPER}>
                            <SiteUnderReviewPage />
                        </div>
                    </div>
                </HomeLayout>
            );
        }

        return (
            <HomeLayout>
                <Router>
                    <div className={CLASS_NAMES.MAIN_CONTAINER}>
                        <Sidebar publisherId={publisherId}
                                 filteredRoutes={filteredRoutes}
                                 auth={auth}
                                 user={user}
                        />
                        <div className={CLASS_NAMES.CONTENT_WRAPPER}>
                            <Switch>
                                {filteredRoutes.map((route, index) => {
                                        return route.isEnabled && (
                                            <Route key={index}
                                                   path={route.path}
                                                   exact={route.exact}
                                                   component={route.component}
                                            />
                                        )
                                    }
                                )}
                            </Switch>
                        </div>
                    </div>
                </Router>
                <GoogleAnalyticsPageView locationChanged={this.locationChanged} location={location}/>
                {(shouldDisplayEnterSite || isOnboardingEnabled && get(user, 'data.onBoardingCompleted') === '0')
                && <OnboardingModal />}
                <SurveyContainer />
            </HomeLayout>
        );
    }
}

Home.propTypes = {
    features: PropTypes.array,
    history: PropTypes.object,
    user: PropTypes.object,
    publisher: PropTypes.object,
    publishers: PropTypes.object,
    getCurrentPublisher: PropTypes.func,
    fetchPublishers: PropTypes.func,
    userUpdateState: PropTypes.string,
    getAuthorizationData: PropTypes.func,
    getOnboardingData: PropTypes.func,
    onboarding: PropTypes.object,
    paymentTab: PropTypes.bool,
    isOnboardingEnabled: PropTypes.bool,
    hideSiteUnderReviewPage: PropTypes.bool,
    shouldDisplayEnterSite: PropTypes.bool,
    auth: PropTypes.object,
    predefinedReportData: PropTypes.object,
    clearPredefinedReportData: PropTypes.func,
    publisherCountry: PropTypes.string
};

const mapStateToProps = (state) => ({
    user: state.app.user,
    publisher: state.app.publisher,
    publishers: state.app.publishers,
    features: state.features,
    auth: state.app.auth,
    onboarding: state.app.onboarding,
    hideSiteUnderReviewPage: state.app.hideSiteUnderReviewPage,
    shouldDisplayEnterSite: state.app.shouldDisplayEnterSite,
    userUpdateState: state.app.userUpdateState,
    isOnboardingEnabled: selectFeatureFlag('publishersOnboarding')(state),
    predefinedReportData: state.predefinedReports.predefinedReportData,
    paymentTab: selectFeatureFlag('paymentTab')(state),
    publisherCountry: _.get(state, 'app.publisher.data.country'),
});

const mapDispatchToProps = {
    getCurrentPublisher,
    getAuthorizationData,
    getOnboardingData,
    fetchPublishers,
    clearPredefinedReportData
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
