import React, {Suspense, lazy} from 'react';

const Img = lazy(() => import('./Img'));

const Image = (props) => {
    return (
        <Suspense fallback={<div></div>} >
            <Img {...props} />
        </Suspense>
    );
};

export default Image;