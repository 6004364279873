import React, {useState, useCallback, useEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Input, Textarea, Button} from '@perion-undertone/ut-react-common';
import cx from 'classnames';

import textProvider from "../texts";
import {submitEmail, resetEmailState} from "../actions/emailActions";
import {FormField} from "./Form";
import Icon from "./Icon";
import {appStates} from "../constants/appStates";

const getButtonText = {
    Default: 'Send',
    Loading: 'Sending...',
    Loaded: 'Done!'
};

const CLASS_NAMES = {
    SEND_EMAIL_HEADER: 'send-email-header',
    SEND_EMAIL_WRAPPER: 'send-email-wrapper',
    SHARE_HOLDER_INNER: 'send-email-form-wrapper',
    BUTTON_WRAPPER: 'send-email-button-wrapper',
    SEND_BUTTON: 'send-email-button',
    SENDING: 'email-sending',
    SENT: 'email-sent'
};

const CONSTANT_TEXTS = {
    RECIPIENT: textProvider.getText('sendEmail', 'recipient'),
    SUBJECT: textProvider.getText('sendEmail', 'subject'),
    MESSAGE: textProvider.getText('sendEmail', 'message'),
    SEND: textProvider.getText('sendEmail', 'send'),
    TITLE: textProvider.getText('sendEmail', 'shareSnippet')
};

const SendEmail = (props) => {
    const {sender, placeholder, submitEmail, resetEmailState, send} = props;
    const [subject, setSubject] = useState('');
    const [recipients, setRecipients] = useState('');
    const [message, setMessage] = useState(placeholder);
    const [isFormVisible, setIsFormVisible] = useState(false);

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        submitEmail(sender, [recipients], subject, message);
    }, [sender, recipients, subject, message]);

    useEffect(() => {
        if (send.state === appStates.LOADED) {
            setTimeout(() => {
                resetEmailState();
            }, 3000);
        }
        if (props.shouldClose && isFormVisible) {
            setIsFormVisible(false);
        }
    });

    return (
        <div className={CLASS_NAMES.SEND_EMAIL_WRAPPER}>
            <div onClick={() => setIsFormVisible(!isFormVisible)}
                    className={CLASS_NAMES.SEND_EMAIL_HEADER}
            >
                <span className='title'>{CONSTANT_TEXTS.TITLE}</span>
                <Icon className='arrow-down' icon='arrow_down.png' />
            </div>
            {isFormVisible &&
                <div className={CLASS_NAMES.SHARE_HOLDER_INNER}>
                    <form>
                        <FormField labelName={CONSTANT_TEXTS.RECIPIENT}>
                            <Input type='email'
                                   name='recipients'
                                   value={recipients}
                                   onChange={(e) => setRecipients(e.target.value)}
                                   hookId='send-email-recipients'
                            />
                        </FormField>
                        <FormField labelName={CONSTANT_TEXTS.SUBJECT}>
                            <Input type='subject'
                                   name='subject'
                                   value={subject}
                                   onChange={(e) => setSubject(e.target.value)}
                                   hookId='send-email-subject'
                            />
                        </FormField>
                        <FormField labelName={CONSTANT_TEXTS.MESSAGE}>
                            <Textarea name='message'
                                      className='message'
                                      disabled={false}
                                      resize={false}
                                      value={message}
                                      onChange={(e) => setMessage(e.target.value)}
                                      hookId='send-email-text-message'
                            />
                        </FormField>
                        <div className={CLASS_NAMES.BUTTON_WRAPPER}>
                            <Button size='lg'
                                    className={cx({
                                        [CLASS_NAMES.SENDING]: send.state === appStates.LOADING,
                                        [CLASS_NAMES.SENT]: send.state === appStates.LOADED
                                    },
                                        CLASS_NAMES.SEND_BUTTON)}
                                    onClick={handleSubmit}
                                    disabled={!recipients || !message}
                                    hookId='send-email-submit-button'
                            >
                                {getButtonText[send.state]}
                            </Button>
                        </div>
                    </form>
                </div>
            }
        </div>
    );
};

SendEmail.propTypes = {
    displayForm: PropTypes.bool,
    submitEmail: PropTypes.func,
    resetEmailState: PropTypes.func,
    sender: PropTypes.string,
    placeholder: PropTypes.string,
    send: PropTypes.object,
    shouldClose: PropTypes.bool
};

SendEmail.defaultProps = {
    sender: 'no-reply@undertone.com',
    title: CONSTANT_TEXTS.TITLE
};

const mapStateToProps = ({email}) => ({
    send: email.send
});

const mapDispatchToProps = ({
    submitEmail,
    resetEmailState
});

export default connect(mapStateToProps, mapDispatchToProps)(SendEmail);