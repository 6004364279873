import React, {useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from './Icon';
import Image from './Image';
import {textProvider} from './imports';

const CLASS_NAMES = {
    WRAPPER_BACKGROUND: 'wrapper-background',
    TITLE: 'preview-title',
    VIDEO_WRAPPER: 'video-wrapper',
    VIDEO: 'video',
    SELECT_TITLE: 'select-title',
    OPEN_NEW_TAB: 'open-new-tab',
    PREVIEW_NA_WRAPPER: 'preview-na-wrapper',
    PREVIEW_NA_TXT: 'preview-na-txt',
    PREVIEW_ARROW: 'preview-arrow',
    SIDEBAR_WRAPPER: 'sidebar-wrapper',
    OPEN_SIDEBAR: 'open-sidebar',
    CLOSE_SIDEBAR: 'close-sidebar',
    PREVIEW_SIDEBAR_CONTAINER: 'preview-sidebar-container',
    HIDDEN_PREVIEW_CONTENT: 'hidden-preview-content',
    ARROW_ANIMATION: 'arrow-animation',
    PLACEHOLDER_IMG: 'placeholder-img',
    SIDEBAR_PLACEHOLDER: 'sidebar-placeholder'
};

const CONSTANT_TEXTS = {
    PREVIEW: textProvider.getText('previewSidebar', 'preview'),
    PREVIEW_NA: textProvider.getText('previewSidebar', 'previewNA'),
    TITLE: textProvider.getText('previewSidebar', 'title'),
    VIEW_IN_NEW_TAB: textProvider.getText('previewSidebar', 'viewInNewTab'),
    SELECT_AD_UNIT: textProvider.getText('previewSidebar', 'selectAdUnit')
};

const PREVIEW_NA = () => {
    return (
        <div className={CLASS_NAMES.PREVIEW_NA_WRAPPER}>
            <Icon icon='sidebar_placeholder.png' />
            <div className={CLASS_NAMES.PREVIEW_NA_TXT}>
                {CONSTANT_TEXTS.PREVIEW_NA}
            </div>
        </div>
    );
};

const VIDEO = (props) => {
    const {video} = props;

    return (
        <video key={video} autoPlay loop >
            <source src={video} type='video/mp4' />
        </video>
    );
};

VIDEO.propTypes = {
    video: PropTypes.string
};

const getFirstSelectedAdUnit = (adUnits, currentGroup) => {
    let matchingElements, foundElement;
    let firstAdUnit = currentGroup.adUnits[0];

    if (firstAdUnit) {
        Object.values(adUnits).map(adUnit => {
            matchingElements = adUnit.filter(el => el.id === firstAdUnit.id);
            if (matchingElements.length > 0) {
                foundElement = matchingElements[0];
            }
        });
    }
    return foundElement;
};

const renderAdUnitInfo = (adUnit) => {
    if (!adUnit) return;

    const {name, video} = adUnit;

    return (
        <div>
            <p className={CLASS_NAMES.TITLE}>
                {`${CONSTANT_TEXTS.PREVIEW} - ${name}`}
            </p>
            <div className={CLASS_NAMES.VIDEO_WRAPPER}>
                <div className={CLASS_NAMES.VIDEO}>
                    <Image name='iphone.png' />
                    {video ? <VIDEO video={video} /> : <PREVIEW_NA />}
                </div>
                <div className={CLASS_NAMES.OPEN_NEW_TAB}>
                    {video && (
                        <a target='_blank'
                           rel='noopener noreferrer'
                           href={video}
                        >
                            {CONSTANT_TEXTS.VIEW_IN_NEW_TAB}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

const PreviewPlaceholder = () => {
    return (
        <div className={CLASS_NAMES.SIDEBAR_PLACEHOLDER}>
            <p className={CLASS_NAMES.TITLE}>
                {CONSTANT_TEXTS.PREVIEW}
            </p>
            <div className={CLASS_NAMES.VIDEO_WRAPPER}>
                <div className={CLASS_NAMES.PLACEHOLDER_IMG}>
                    <Icon icon='sidebar_placeholder.png' />
                </div>
                <span className={CLASS_NAMES.SELECT_TITLE}>
                    {CONSTANT_TEXTS.SELECT_AD_UNIT}
                </span>
            </div>
        </div>
    );
};

const PreviewSideBar = (props) => {
    const {adUnits, currentGroup, previewAdUnit} = props;
    const [active, setActive] = useState(true);

    const element = adUnits && getFirstSelectedAdUnit(adUnits, currentGroup, element);
    const wrapperClasses = cx(CLASS_NAMES.SIDEBAR_WRAPPER, active ? CLASS_NAMES.CLOSE_SIDEBAR : CLASS_NAMES.OPEN_SIDEBAR);
    const containerClasses = cx(CLASS_NAMES.PREVIEW_SIDEBAR_CONTAINER, {[CLASS_NAMES.HIDDEN_PREVIEW_CONTENT]: !active});

    const toggleClass = () => {
       setActive(!active);
    };

    return (
        <div className={wrapperClasses}>
            <div className={CLASS_NAMES.WRAPPER_BACKGROUND}>
                <div className={containerClasses}>
                    {active && previewAdUnit
                        ? renderAdUnitInfo(previewAdUnit)
                        : currentGroup.adUnits.length > 0
                            ? renderAdUnitInfo(element)
                            : <PreviewPlaceholder />
                    }
                </div>
            </div>
            <div className={CLASS_NAMES.PREVIEW_ARROW}
                    onClick={toggleClass}
            >
                <div className={CLASS_NAMES.ARROW_ANIMATION}>
                    <Icon icon='arrow_down.svg' />
                </div>
            </div>
        </div>
    );
};

PreviewSideBar.propTypes = {
    previewAdUnit: PropTypes.object,
    currentGroup: PropTypes.object,
    adUnits: PropTypes.object
};

const mapStateToProps = ({placements}) => ({
    previewAdUnit: placements.currentGroup.previewAdUnit,
    currentGroup: placements.currentGroup,
    adUnits: placements.adUnits.data
});

export default connect(mapStateToProps)(PreviewSideBar);