import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Input} from '@perion-undertone/ut-react-common';
import {filterGrid} from '../../../actions/gridCustomizationActions';
import textProvider from '../../../texts/textProvider';

const CLASS_NAMES = {
    CONTAINER: 'column-text-filter-container'
};

const CONSTANT_TEXTS = {
    SEARCH: textProvider.getText('common', 'search')
}

class ColumnSearchTextFilter extends Component {
    onChangeFilter = e => {
        const {name, filterGrid} = this.props;
        filterGrid(name, 'text', e.target.value);
    }
    render() {
        const {customization, name} = this.props;
        let existingFilter = '';
        if (customization && customization.filters) {
            const foundFilter = customization.filters.find(x => x.field == name);
            existingFilter = foundFilter ? foundFilter.value : '';
        }
        return (
            <div className={CLASS_NAMES.CONTAINER}>
                <Input icon="icon-search"
                       onChange={this.onChangeFilter}
                       placeholder={CONSTANT_TEXTS.SEARCH}
                       iconPosition="right"
                       value={existingFilter}
                       hookId="search-text-filter"
                />
            </div>
        );
    }
}
ColumnSearchTextFilter.propTypes = {
    onChangeFilter: PropTypes.func,
    customization: PropTypes.object,
    filterGrid: PropTypes.func,
    name: PropTypes.string
};

const mapStateToProps = ({grid}) => ({
    customization: grid.customization
})

const mapDispatchToProps = ({
    filterGrid
})
export default connect(mapStateToProps, mapDispatchToProps)(ColumnSearchTextFilter);