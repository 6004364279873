import React from 'react';
import PropTypes from 'prop-types';
import {StripePlaceholderLoader, LinearPlaceholderLoader, CirclePlaceholderLoader} from './PlaceholderLoaderConfig';

const CLASS_NAMES = {
  PLACEHOLDER_LOADER_WRAPPER: 'placeholder-loader-wrapper'
};

const widgetMapper = {
  'main-kpis': StripePlaceholderLoader,
  'revenue-chart': LinearPlaceholderLoader,
  'platform-chart': CirclePlaceholderLoader,
  'top-domains-chart': LinearPlaceholderLoader,
  'top-adunits-grid': LinearPlaceholderLoader
};

const PlaceholderLoader = (props) => {
    const Component = widgetMapper[props.type];

    return (
      <div className={CLASS_NAMES.PLACEHOLDER_LOADER_WRAPPER}>
        <Component />
      </div>
    );
};

PlaceholderLoader.propTypes = {
  type: PropTypes.string
};

export default PlaceholderLoader;