import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import uuid from 'uuid/v4';
import ReportViewer from "./ReportViewer";
import _ from "lodash";
import {changePage, setLastPage} from "./imports";

const CLASS_NAMES = {
    REPORT_VIEWER_WRAPPER: 'report-viewer-wrapper'
};

class PaginationScrollWrapper extends Component {

    constructor(props) {
        super(props);
        const {content, zoomFactor} = props;
        this.wrapperId = uuid();
        this.headerRowsCount = 6;
        this.footerRowsCount = 1;
        this.content = content;
        this.totalRows = (!_.isEmpty(this.content) ? this.content.length : 0) + this.headerRowsCount + this.footerRowsCount;
        this.rowHeight = 20 * zoomFactor;
        this.totalHeight = this.totalRows * this.rowHeight;
        this.rowsPerPage = 0;
        this.totalPages = 0;
        this.lastPosY = 0;
        this.canScroll = true;
        this.scrollHadler = null;
        this.resizeHandler = null;
        this.prevZoomFactor = 1;
    }

    componentDidMount() {
        setTimeout(() => {
            const {wrapperId} = this;
            this.wrapperDom = document.getElementById(wrapperId);
            if (!_.isEmpty(this.wrapperDom)) {
                this.wrapperDom.addEventListener('scroll', this.onScroll);
                this.wrapperDom.addEventListener('wheel', this.onWheel);
                document.body.addEventListener('keydown', this.onKeyDown);
                window.addEventListener('resize', this.onResize);
                this.updatePaginationData();
            }
        }, 500);
    }

    componentDidUpdate() {
        this.updatePaginationData();
        this.animateToPage();
    }

    componentWillUnmount() {
        if (!_.isEmpty(this.wrapperDom)) {
            this.wrapperDom.removeEventListener('scroll', this.onScroll);
            this.wrapperDom.removeEventListener('wheel', this.onWheel);
            document.body.removeEventListener('keydown', this.onKeyDown);
            window.removeEventListener('resize', this.onResize);
        }
        this.clearDelayedScroll();
        this.clearDelayedResize();
    }

    updatePaginationData = () => {
        const {zoomFactor} = this.props;
        const {rowHeight, totalRows} = this;
        if (!_.isEmpty(this.wrapperDom)) {
            this.rowHeight = 20 * zoomFactor;
            const wrapperHeight = this.wrapperDom.offsetHeight;
            this.rowsPerPage = Math.floor(wrapperHeight / rowHeight);
            const prevTotalPages = this.totalPages;
            this.totalPages = Math.ceil(totalRows / this.rowsPerPage);
            if (prevTotalPages !== this.totalPages) {
                this.props.setLastPage(this.totalPages);
            }
        }
    };

    goToPrevPage = () => {
        const {currentPage} = this.props;
        this.goToPage(currentPage - 1);
    };

    goToNextPage = () => {
        const {currentPage} = this.props;
        this.goToPage(currentPage + 1);
    };

    goToPage = (page) => {
        const {currentPage, changePage} = this.props;
        if (page < 1) {
            page = 1;
        }
        if (page > this.totalPages) {
            page = this.totalPages;
        }
        if (page !== currentPage) {
            return changePage(page);
        }
    };

    animateToPage = () => {
        const {zoomFactor} = this.props;
        if (this.prevZoomFactor !== zoomFactor) {
            this.prevZoomFactor = zoomFactor;
            return;
        }
        const {currentPage} = this.props;
        const {wrapperDom, rowsPerPage, rowHeight} = this;
        if (!_.isEmpty(wrapperDom)) {
            const pageY = Math.round((currentPage-1) * rowsPerPage * rowHeight);
            if (this.lastPosY !== pageY) {
                wrapperDom.scrollTop = pageY;
                this.canScroll = true;
                this.lastPosY = pageY;
                this.clearDelayedScroll();
            }
        }
    };

    linearInterpolation = (inVal, inMin, inMax, outMin, outMax) => {
        let res = (inVal - inMin) / (inMax - inMin) * (outMax - outMin) + outMin;
        if (res < outMin) {
            res = outMin
        } else if (res > outMax) {
            res = outMax;
        }
        return res;
    };

    updatePageFromScroll = (posY) => {
        const pageFromScroll = Math.ceil(this.linearInterpolation(posY, 0, this.totalHeight, 1, this.totalPages));
        this.goToPage(pageFromScroll);
    };

    onDelayedScroll = (event) => {
        this.clearDelayedScroll();
        if (!this.canScroll) {
            return;
        }
        // check if we are scrolling using mouse wheel. If yes, then we just need to jump to next or preview page
        if (typeof event.deltaY === 'number') {
            if (event.deltaY > 0) {
                this.goToNextPage();
            } else if (event.deltaY < 0) {
                this.goToPrevPage();
            }
            return;
        }
        // we have user mouse drag scroll, we will need to calculate the page from holder scroll position
        const {wrapperDom} = this;
        if (_.isEmpty(wrapperDom)) {
            return;
        }
        if (this.lastPosY !== wrapperDom.scrollTop) {
            this.lastPosY = wrapperDom.scrollTop;
            this.updatePageFromScroll(this.lastPosY);
        }
    };

    setupDelayedScroll = (event) => {
        this.clearDelayedScroll();
        this.scrollHadler = setTimeout(() => {
            this.onDelayedScroll(event);
        }, 300);
    };

    clearDelayedScroll = () => {
        clearTimeout(this.scrollHadler);
        this.scrollHadler = null;
    };


    setupDelayedResize = () => {
        this.clearDelayedResize();
        this.resizeHandler = setTimeout(this.updatePaginationData, 300);
    };

    clearDelayedResize = () => {
        clearTimeout(this.resizeHandler);
        this.resizeHandler = null;
    };

    onWheel = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (this.canScroll) {
            this.setupDelayedScroll(event);
        }
        return false;
    };

    onScroll = (event) => {
        if (!this.canScroll) {
            event.preventDefault();
            return false;
        }
        this.setupDelayedScroll(event);
    };

    onKeyDown = (event) => {
        if (!this.canScroll) {
            event.preventDefault();
            return false;
        }
        if (!event)
            event = window.event;
        let code = event.keyCode;
        if (event.charCode && code == 0)
            code = event.charCode;
        switch(code) {
            case 38: {
                this.goToPrevPage();
                event.preventDefault();
                break;
            }
            case 40: {
                // Key down.
                this.goToNextPage();
                event.preventDefault();
                break;
            }
        }
    };

    onResize = (event) => {
        if (!this.canScroll) {
            event.preventDefault();
            return false;
        }
        this.setupDelayedResize();
    };

    render() {
        const {reportViewerRef, reportPreview} = this.props;
        const {wrapperId} = this;
        return (
            <Fragment>
                <div id={wrapperId}
                     className={CLASS_NAMES.REPORT_VIEWER_WRAPPER}
                >
                    <ReportViewer reportViewerRef={reportViewerRef}
                                  reportPreview={reportPreview}
                    />
                </div>
            </Fragment>
        );
    }

    static propTypes = {
        reportPreview: PropTypes.object,
        reportViewerRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({current: PropTypes.any})
        ]),
        content: PropTypes.array,
        currentPage: PropTypes.number,
        zoomFactor: PropTypes.number,
        changePage: PropTypes.func,
        setLastPage: PropTypes.func
    }

}

const mapStateToProps = ({reportPreview}) => ({
    reportPreview,
    content: reportPreview.content,
    currentPage: reportPreview.currentPage,
    zoomFactor: reportPreview.zoomFactor
});

const mapDispatchToProps = ({
    changePage,
    setLastPage
});

export default connect(mapStateToProps, mapDispatchToProps)(PaginationScrollWrapper);