import React, {Component} from 'react';
import _ from "lodash";
import {RedirectLayout} from "@perion-undertone/ut-react-common";
import AppLogo from "./AppLogo";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import AppRouter from "./AppRouter";
import {selectFeatureFlag} from '@flopflip/react-redux';
import {getMenuLinksPermissions} from '@perion-undertone/ramp-helpers';

class AppLayout extends Component {

    shouldIncludeRedirectLayout = () => {
        const route = this.props.location.pathname;
        const regex = new RegExp(/([1-9])\w+/g)
        return (
            route === '/' ||
            regex.test(route)
        )
    }
    isInternalUser = (email) => {
        return (
            _.endsWith(email, '@undertone.com') ||
            _.endsWith(email, '@perion.com')
        )
    }

    render() {
        const {id, email, name} = _.get(this.props.user, 'data') || {};
        const {permissionsForMenuList} = this.props;
        return (this.isInternalUser(email) && this.shouldIncludeRedirectLayout() ?
            <RedirectLayout userId={id}
                email={email}
                userName={name}
                logoComponent={AppLogo}
                permissions={permissionsForMenuList}
            >
                <AppRouter/>
            </RedirectLayout> :
            <AppRouter/>
        )
    }

}

const mapStateToProps = (state) => ({
    user: state.app.user,
    permissionsForMenuList: getMenuLinksPermissions(selectFeatureFlag, state)
});

AppLayout.propTypes = {
    user: PropTypes.object,
    permissionsForMenuList: PropTypes.object
};
export default withRouter(connect(mapStateToProps)(AppLayout));
