import React from 'react';
import PropTypes from 'prop-types';
import {Animation} from '@perion-undertone/ut-react-common';

import AccountLayout from '../AccountLayout';
import {appStates, Image, textProvider} from '../imports';

const CLASS_NAMES = {
    EMAIL_SENT_IMAGE: 'email-sent-image',
    EMAIL_SENDING_LOADER: 'email-sending-loader',
    RESEND_EMAIL_SECTION: 'resend-email-section',
};

const CONSTANT_TEXTS = {
    HEADER: textProvider.getText('resetLinkSent', 'emailSent'),
    SUBHEADER: textProvider.getText('resetLinkSent', 'weSentYouAnEmailTo'),
    CHECK_YOUR_EMAIL: textProvider.getText('resetLinkSent', 'checkEmailForResetInstructions'),
    EMAIL_NOT_RECEIVED: textProvider.getText('resetLinkSent', 'emailNotReceived'),
    CHECK_YOUR_SPAM_FOLDER: textProvider.getText('resetLinkSent', 'checkYourSpamFolder'),
    RESEND_EMAIL: textProvider.getText('resetLinkSent', 'resendEmail'),
    BACK_TO_LOGIN: textProvider.getText('resetPasswordComponent', 'backToLogin')
};



const ResetLinkSent = (props) => {
    const {email, resendRequest, resetPassword} = props;

    const Subheader = () => {
        return (
            <div>
                {CONSTANT_TEXTS.SUBHEADER}
                <p>{email}</p>
                {CONSTANT_TEXTS.CHECK_YOUR_EMAIL}
            </div>
        )
    };

    return (
        <AccountLayout size='small'
                       className='reset-password-email-sent-layout'
                       backToRoute='/'
                       backToText={CONSTANT_TEXTS.BACK_TO_LOGIN}
                       headerProps={{
                        Subheader,
                        header: CONSTANT_TEXTS.HEADER
                       }}
        >
            {resendRequest && resendRequest.state === appStates.LOADING ?
                <div className={CLASS_NAMES.EMAIL_SENDING_LOADER}>
                    <Animation type="loadingCircle"
                               height={100}
                               width={100}
                               hookId='resend-email-loading'
                               rendererSettings={{preserveAspectRatio: 'xMaxYMax'}}
                    />
                </div> :
                <div className={CLASS_NAMES.EMAIL_SENT_IMAGE}>
                    <Image name='email-sent.png' hookId='enter-code-img' />
                </div>
            }
            <div className={CLASS_NAMES.RESEND_EMAIL_SECTION}>
                <div>{CONSTANT_TEXTS.EMAIL_NOT_RECEIVED}</div>
                <div>
                    {CONSTANT_TEXTS.CHECK_YOUR_SPAM_FOLDER}
                    <span className='link-underline' onClick={() => resetPassword(email, true)}>
                        {CONSTANT_TEXTS.RESEND_EMAIL}
                    </span>
                </div>
            </div>
        </AccountLayout>
    );
}

ResetLinkSent.propTypes = {
    resetPassword: PropTypes.func,
    email: PropTypes.string,
    resendRequest: PropTypes.object
}

export default ResetLinkSent;
