import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {selectFeatureFlag} from '@flopflip/react-redux';
import {Checkbox, GAProvider} from '@perion-undertone/ut-react-common';
import AccountLayout from './AccountLayout';
import EmailField from './EmailField';
import PasswordField from './PasswordField';
import NavigateToCreateAccount from './NavigateToCreateAccount';
import {loadFromLocalStorage, loginUser, redirectTo, saveToLocalStorage, textProvider} from './imports';
import QRCodeField from "./QRCodeField";

const CLASS_NAMES = {
    INPUT_CONTAINER: 'input-container',
    LOGIN_FORM: 'login-form',
    LOGIN_FORM_ACTIONS: 'login-form-actions',
    LOGIN_FORM_ACTION:'login-form-action',
    ERROR_MESSAGE : 'error-message',
    ERROR: 'error'
};

const CONSTANT_TEXTS = {
    RESET_PASSWORD: textProvider.getText('loginComponent', 'resetPassword'),
    LOGIN: textProvider.getText('loginComponent', 'submitText'),
    LOGIN_SUBHEADER: textProvider.getText('loginComponent', 'subheader'),
    ERROR_MESSAGE: textProvider.getText('loginComponent', 'errorMessage'),
    KEEP_ME_SIGNED_IN: textProvider.getText('loginComponent', 'keepMeSignedIn'),
    LOGIN_CATEGORY: textProvider.getText('ga', 'category.login'),
    CLICKED_RESET_PASSWORD: textProvider.getText('ga', 'actions.clickedResetPassword'),
    SELECT_KEEP_ME_SIGNED_IN: textProvider.getText('ga', 'actions.selectKeepMeSignedIn')
};

const Login = (props) => {
    const {isOnboardingEnabled, isMFAFeatureEnabled, loginError, qrCode, token, serverMfaEnabled} = props;
    const [keepMeSignedIn, setKeepMeSignedIn] = useState();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [mfaEnabled, setMfaEnabled] = useState(false);
    const [inputsTouched, setInputsTouched] = useState({
        email: false,
        password: false,
        code: false
    });
    const [emptyCodeError, setEmptyCodeError] = useState(false);

    useEffect(() => {
        const defaultKeepMeSignedIn = loadFromLocalStorage('keepMeSignedIn');
        const mfaEnabled = loadFromLocalStorage('mfaEnabled') || serverMfaEnabled;
        if (keepMeSignedIn === undefined) {
            setKeepMeSignedIn(defaultKeepMeSignedIn !== undefined ? defaultKeepMeSignedIn : true);
        }
        if (isMFAFeatureEnabled && !_.isNil(mfaEnabled)) {
            setMfaEnabled(true);
        }
    }, [serverMfaEnabled, isMFAFeatureEnabled]);

    const handleBlurEmail = () => {
        email === '' && setInputsTouched({...inputsTouched, email: false});
    };

    const handleBlurPassword = () => {
        password === '' && setInputsTouched({...inputsTouched, password: false});
    };

    const handleBlurCode = () => {
        code === '' && setInputsTouched({...inputsTouched, code: false});
    };

    const handleUsernameChange = (e) => {
        setEmail(e.target.value.trim());
        setInputsTouched({...inputsTouched, email: true});
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setInputsTouched({...inputsTouched, password: true});
    };

    const handleCodeChange = (e) => {
        if(emptyCodeError && !_.isEmpty(e.target.value)) {
            setEmptyCodeError(false);
        }
        setCode(e.target.value);
        setInputsTouched({...inputsTouched, code: true});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        saveToLocalStorage('keepMeSignedIn', keepMeSignedIn);
        if(!code && qrCode) {
            setEmptyCodeError(true);
        }
        if (code && qrCode) {
            return props.loginUser(email, password, code, token, keepMeSignedIn);
        }
        return props.loginUser(email, password, code, keepMeSignedIn);
    };

    const handleKeepMeSignedInChange = () => {
        if (!keepMeSignedIn) {
            GAProvider.notifyEvent(CONSTANT_TEXTS.LOGIN_CATEGORY, CONSTANT_TEXTS.SELECT_KEEP_ME_SIGNED_IN, ' ');
        }
        setKeepMeSignedIn(!keepMeSignedIn);
    };

    return (
        <AccountLayout size='small'
            headerProps={{
                header: CONSTANT_TEXTS.LOGIN,
                subheader: CONSTANT_TEXTS.LOGIN_SUBHEADER
            }}
            footerProps={{
                buttonText: CONSTANT_TEXTS.LOGIN,
                onSubmit: handleSubmit
            }}
            customRedirect={isOnboardingEnabled ? <NavigateToCreateAccount /> : null}
        >
            <div className={CLASS_NAMES.LOGIN_FORM}>
                {!qrCode && <EmailField autoFocus
                    value={email}
                    onBlur={handleBlurEmail}
                    onChange={handleUsernameChange}
                    hasError={!_.isEmpty(loginError)}
                />}
                {!qrCode && <PasswordField hideCriteria
                    password={password}
                    onChange={handlePasswordChange}
                    handleBlur={handleBlurPassword}
                    inputClass={cx({[CLASS_NAMES.ERROR]: !_.isEmpty(loginError)})}
                    labelClass={cx({[CLASS_NAMES.ERROR]: !_.isEmpty(loginError)})}
                />}
                {(qrCode || mfaEnabled) && isMFAFeatureEnabled && <QRCodeField
                    code={code}
                    qrCode={qrCode}
                    onChange={handleCodeChange}
                    handleBlur={handleBlurCode}
                    emptyCodeError={emptyCodeError}
                />}
            </div>
            <div className={CLASS_NAMES.LOGIN_FORM_ACTIONS}>
                <div className={CLASS_NAMES.LOGIN_FORM_ACTION}>
                    <Checkbox squared
                        label={CONSTANT_TEXTS.KEEP_ME_SIGNED_IN}
                        checked={keepMeSignedIn}
                        onChange={handleKeepMeSignedInChange}
                        hookId='login-signed-in-checkbox'
                    />
                </div>
                <div className={cx(CLASS_NAMES.LOGIN_FORM_ACTION, 'link-underline')}
                     onClick={() => redirectTo('/password-reset')}
                >
                    <a data-analytics={`${CONSTANT_TEXTS.LOGIN_CATEGORY};${CONSTANT_TEXTS.CLICKED_RESET_PASSWORD}; `}>
                        {CONSTANT_TEXTS.RESET_PASSWORD}
                    </a>
                </div>
            </div>
        </AccountLayout>
    );
};

Login.propTypes = {
    loginUser: PropTypes.func,
    mfaUser: PropTypes.func,
    isOnboardingEnabled: PropTypes.bool,
    isMFAFeatureEnabled: PropTypes.bool,
    loginError: PropTypes.string,
    qrCode: PropTypes.string,
    serverMfaEnabled: PropTypes.bool,
    token: PropTypes.string,
};

const mapStateToProps = (state) => ({
    loginError: state.app.user.loginError,
    qrCode: state.app.user.qrCode,
    serverMfaEnabled: state.app.user.mfaEnabled,
    token: state.app.user.token,
    isOnboardingEnabled: selectFeatureFlag('publishersOnboarding')(state),
    isMFAFeatureEnabled: selectFeatureFlag('useMfaInUdrive')(state)
});

const mapDispatchToProps = ({
    loginUser
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
