import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Checkbox} from '@perion-undertone/ut-react-common';
import {setSelectedPlacementGroupsIds} from '../imports';

const CLASS_NAMES = {
    REACT_GRID_CHECKBOX_CONTAINER: 'react-grid-checkbox-container'
};

class PlacementGroupCheckbox extends Component {
    setSelectedRows = placementGroupId => {
        this.props.setSelectedPlacementGroupsIds(placementGroupId);
    }

    render() {
        const {selectedGroupsIds, row} = this.props;
        const isChecked = selectedGroupsIds && selectedGroupsIds.includes(row.placementGroupId);
        return (
            <div className={CLASS_NAMES.REACT_GRID_CHECKBOX_CONTAINER}>
                <Checkbox squared
                      className={CLASS_NAMES.SELECT_BOX}
                      checked={isChecked}
                      onChange={() => this.setSelectedRows(row.placementGroupId)}
                      disabled={row.isAuto}
                      hookId='select-placement-group-checkbox'
                />
            </div>);
    }
}

PlacementGroupCheckbox.propTypes = {
    row: PropTypes.object,
    setSelectedPlacementGroupsIds: PropTypes.func,
    selectedGroupsIds: PropTypes.array
};

const mapStateToProps = (state) => ({
    selectedGroupsIds: state.placements.selectedGroupsIds
});

const mapDispatchToProps = ({
    setSelectedPlacementGroupsIds
});

export default connect(mapStateToProps, mapDispatchToProps)(PlacementGroupCheckbox);
