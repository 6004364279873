import React, {useState} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Icon} from '@perion-undertone/ut-react-common';

import AccountLayout from './AccountLayout';
import EmailField from './EmailField';
import PasswordField from './PasswordField';
import {loginUser, redirectTo, resetAppState, textProvider} from './imports';

const TXT = {
    TITLE: textProvider.getText('welcomeBack', 'welcomeBack'),
    SUBTITLE: textProvider.getText('welcomeBack', 'youAlreadyHaveAccount'),
    LOGIN: textProvider.getText('loginComponent', 'submitText'),
    ERROR_MESSAGE: textProvider.getText('loginComponent', 'errorMessage'),
    CREATE_ANOTHER_ACCOUNT: textProvider.getText('welcomeBack', 'createAnotherAccount'),
    EMAIL_SUPPORT: textProvider.getText('common', 'emailSupport'),
    OR: textProvider.getText('common', 'or')
};

const NavigateToCreateAccount = (props) => {
    const onClick = () => {
        redirectTo('/create-account');
        props.resetAppState();
    }
    return (
        <div className='redirect-bottom-container'
             onClick={onClick}
             style={{color: 'white', textAlign: 'left', textDecoration: 'underline'}}
        >
            <Icon icon='icon-pagging_arrow_back'/>
            &nbsp; <span>{'Back to register form'}</span>
        </div>
    );
};

NavigateToCreateAccount.propTypes = {
    resetAppState: PropTypes.func
};

const WelcomeBack = (props) => {
    const {loginError} = props;
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState(props.account && props.account.username);

    return (
        <AccountLayout navigateBack
                       headerProps={{header: TXT.TITLE, subheader: TXT.SUBTITLE}}
                       footerProps={{
                           onSubmit: () => props.loginUser(email, password, true),
                           buttonText: TXT.LOGIN
                       }}
                       customRedirect={<NavigateToCreateAccount resetAppState={props.resetAppState} />}
                       className='welcome-back-layout'
        >
            <EmailField value={email}
                        hasError={loginError}
                        onChange={(e) => setEmail(e.target.value)}
            />
            <PasswordField hideCriteria
                           autoFocus
                           password={password}
                           onChange={(e) => setPassword(e.target.value)}
                           inputClass={cx({'error': loginError})}
            />
            <div className='error-message'>
                {loginError && TXT.ERROR_MESSAGE}
            </div>
            <p className='content-divider'>{TXT.OR}</p>
            <div className='text-footer'>
                {TXT.CREATE_ANOTHER_ACCOUNT}
                <span className='link-underline' >
                    {TXT.EMAIL_SUPPORT}
                </span>
            </div>
        </AccountLayout>
    );
}

WelcomeBack.propTypes = {
    account: PropTypes.object,
    loginUser: PropTypes.func,
    loginError: PropTypes.bool,
    resetAppState: PropTypes.func
};

const mapStateToProps = ({app}) => ({
    account: app.account,
    loginError: app.user.loginError
});

const mapDispatchToProps = ({
    loginUser,
    resetAppState
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeBack);