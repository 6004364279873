import React from 'react';
import {Button, Modal} from '@perion-undertone/ut-react-common';
import PropTypes from 'prop-types';

import Image from './../Image';
import {CN, TXT} from './surveyUtils';

const SurveyPopup = (props) => {
    const {closeSurveyPopup, link} = props;

    const onAnswerNow = () => {
        window.open(link, '_blank');
        closeSurveyPopup();
    };

    return (
        <Modal className={CN.SURVEY_POPUP}
               open={true}
               closeButton={false}
               closeOnOutsideClick={false}
               hookId='answer-survey-popup-modal'
        >
            <span className={CN.TAKES_TWO_MIN_LABEL}>
                {TXT.IT_WILL_TAKE_2_MIN}
            </span>
            <div className={CN.SURVEY_TITLE}>
                {TXT.BE_PART_OF_UDRIVE_DESIGN}
            </div>
            <div className={CN.SURVEY_DESCRIPTION}>
                {TXT.HELP_US_IMPROVE}{TXT.BY_ANSWERING}
                <span className={CN.BOLD_TEXT}>
                    {TXT.FIVE_SHORT}
                </span>
                {TXT.QUESTIONS}
            </div>
            <Image name='hearts.png' />
            <Button size='lg'
                    onClick={onAnswerNow}
                    className={CN.ANSWER_SURVEY_ACTION}
                    hookId='answer-survey-action-button'
            >
                {TXT.ANSWER_NOW}
            </Button>
            <div className={CN.REMIND_ME_LATER_LABEL}
                 onClick={closeSurveyPopup}
            >
                {TXT.REMIND_ME_LATER}
            </div>
        </Modal>
    );
}

SurveyPopup.propTypes = {
    link: PropTypes.string,
    closeSurveyPopup: PropTypes.func
};

export default SurveyPopup;