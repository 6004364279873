import {analyticsConstants} from '../constants/analyticsConstants';
import {appStates} from '../constants/appStates';
import _ from 'lodash';

const initialState = {};

export const analyticsReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case analyticsConstants.PREDEFINED_REPORTS_LIST_REQUEST: {
            return {
                ...state,
                predefinedReportsList: {
                    data: undefined,
                    state: appStates.LOADING
                }
            };
        }
        case analyticsConstants.PREDEFINED_REPORTS_LIST_SUCCESS:
            return {
                ...state,
                predefinedReportsList: {
                    data: payload.data,
                    state: appStates.LOADED
                }
            };
        case analyticsConstants.PREDEFINED_REPORTS_LIST_FAILURE:
            return {
                ...state,
                predefinedReportsList: {
                    data: undefined,
                    state: appStates.ERROR,
                }
            };
        case analyticsConstants.PREDEFINED_REPORT_DATA_REQUEST: {
            const {exportType, isDownloading} = action.payload;

            return {
                ...state,
                predefinedReportData: {
                    data: undefined,
                    state: appStates.LOADING,
                    exportType,
                    isDownloading
                }
            };
        }
        case analyticsConstants.PREDEFINED_REPORT_DATA_SUCCESS: {
            const {exportType} = action.payload;

            return {
                ...state,
                predefinedReportData: {
                    data: payload.data,
                    state: appStates.LOADED,
                    exportType,
                    isDownloading: false
                }
            };
        }
        case analyticsConstants.PREDEFINED_REPORT_DATA_FAILURE:
            return {
                ...state,
                predefinedReportData: {
                    data: undefined,
                    state: appStates.ERROR,
                }
            };
        case analyticsConstants.CLEAR_PREDEFINED_REPORT_DATA:
            return {
                ...state,
                predefinedReportData: {
                    data: undefined,
                    state: appStates.LOADED,
                }
            };
        case analyticsConstants.PUBLISHER_REPORTS_CONFIG_REQUEST:
            return {
                ...state,
                publisherReportsConfig: {
                    data: undefined,
                    state: appStates.LOADING,
                }
            };
        case analyticsConstants.PUBLISHER_REPORTS_CONFIG_SUCCESS:
            return {
                ...state,
                publisherReportsConfig: {
                    data: payload.data,
                    state: appStates.LOADED,
                }
            };
        case analyticsConstants.PUBLISHER_REPORTS_CONFIG_FAILURE:
            return {
                ...state,
                publisherReportsConfig: {
                    data: undefined,
                    state: appStates.ERROR,
                }
            };

        case analyticsConstants.SET_REPORT_SCHEDULING_SUCCESS: {
            const updatedReport = payload.data;

            return {
                ...state,
                predefinedReportsList: {
                    ...state.predefinedReportsList,
                    data: state.predefinedReportsList.data.map(report => {
                        if (report.id.toString() === updatedReport.id.toString()) {
                            return updatedReport;
                        }
                        return report;
                    })
                }
            }
        }
        case analyticsConstants.SET_REPORT_SCHEDULING_FAILED: {
            return {
                ...state,
                predefinedReportsList: {
                    data: undefined,
                    state: appStates.ERROR
                }
            };
        }
        case analyticsConstants.SET_PREDEFINED_REPORT_PERIOD: {
            return {
                ...state,
                period: {
                    ...state.period,
                    ...payload
                }
            }
        }

        case analyticsConstants.UPDATE_REPORT_QUERY_REQUEST: {
            return {
                ...state,
                updatedReportQuery: {
                    state: appStates.LOADING
                }
            };
        }
        case analyticsConstants.UPDATE_REPORT_QUERY_SUCCESS: {
            const {updatedReportQuery} = action.payload;

            const predefinedReportsListCopy = _.cloneDeep(state.predefinedReportsList.data)
            // [...state.predefinedReportsList.data];
            const updatedReportIndex = predefinedReportsListCopy.findIndex(report => report.id === updatedReportQuery.reportId);
            predefinedReportsListCopy[updatedReportIndex].query = updatedReportQuery.query;

            return {
                ...state,
                updatedReportQuery: {
                    state: appStates.LOADED
                },
                predefinedReportsList: {
                    changedQueries: state.predefinedReportsList.changedQueries,
                    data: [...predefinedReportsListCopy]
                }
            };
        }
        case analyticsConstants.UPDATE_REPORT_QUERY_FAILED:
            return {
                ...state,
                updatedReportQuery: {
                    state: appStates.ERROR
                }
            };
        case analyticsConstants.CHANGE_REPORT_QUERY: {
            const {reportId, query} = action.payload;
            const changedQueries = state.predefinedReportsList.changedQueries ? state.predefinedReportsList.changedQueries : {}; 
            const changedQueriesCopy = {...changedQueries};
            changedQueriesCopy[reportId] = query;
            return {
                ...state,
                predefinedReportsList: {
                    data: state.predefinedReportsList.data,
                    state: state.predefinedReportsList.state,
                    changedQueries: changedQueriesCopy
                }
            }
        }
        case analyticsConstants.DELETE_CHANGED_QUERY: {
            const {reportId} = action.payload;
            const changedQueries = state.predefinedReportsList.changedQueries ? state.predefinedReportsList.changedQueries : {}; 
            const changedQueriesCopy = {...changedQueries};
            delete changedQueriesCopy[reportId];
            return {
                ...state,
                predefinedReportsList: {
                    data: state.predefinedReportsList.data,
                    state: state.predefinedReportsList.state,
                    changedQueries: changedQueriesCopy
                }
            }
        }
        default:
            return state
    }
}