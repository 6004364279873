import {gridCustomizationConstants} from '../constants/gridCustomizationConstants';

export const sortGrid = (field, direction) => ({
    type: gridCustomizationConstants.SORT_GRID,
    payload: {
        field,
        direction
    }
});

export const filterGrid = (field, type, value) => ({
    type: gridCustomizationConstants.FILTER_GRID,
    payload: {
        field,
        type,
        value
    }
});

export const filterOptionsGrid = (field, type, value, filterOptions) => ({
    type: gridCustomizationConstants.FILTER_OPTIONS_GRID,
    payload: {
        field,
        type,
        value,
        filterOptions
    }
});

export const toggleAllOptions = (field, type, value, filterOptions) => ({
    type: gridCustomizationConstants.TOGGLE_FILTER_ALL,
    payload: {
        field,
        type,
        value,
        filterOptions
    }
});

export const clearAllGridFilters = () => ({
    type: gridCustomizationConstants.CLEAR_ALL_GRID_FILTERS
});

export const setFilterOptions = state => ({
    type: gridCustomizationConstants.SET_FILTER_OPTIONS,
    payload: state
});
