import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import {RangeDatePicker} from '@perion-undertone/ut-react-common';
import {calendarPresets, isPresetOptionDisabled} from './imports';

const CLASS_NAMES = {
    CONTAINER: 'reports-calendar-container',
    PRESETS: 'reports-calendar-presets',
    PRESET: 'reports-calendar-preset',
    PRESET_ACTIVE: 'reports-calendar-preset-active',
    PRESET_DISABLED: 'reports-calendar-preset-disabled',
    HEADER: 'reports-calendar-header',
    CALENDAR: 'reports-calendar-calendar'
};

const DATE_FORMAT = 'MM/DD/YYYY';
const DATA_AVAILABLE_SINCE = moment().subtract(15, 'months');

class ReportsCalendar extends Component {
    isNotFutureDate = (date) => date.isBefore(moment());

    formatPeriod = (period) => moment(period).format('MM/DD/YYYY');

    presetsRenderer = (props) => {
        const presets = calendarPresets.filter(preset => preset.isReportPreset);
        const {onPresetClick, activePreset} = this.props;
        return (<div className={CLASS_NAMES.PRESETS}>
            {presets.map((preset, index) => {
                const {period, startDate, endDate, label} = preset;
                const isPresetDisabled = isPresetOptionDisabled(label);
                return (
                    <div key={index}
                         className={cx(CLASS_NAMES.PRESET, {[CLASS_NAMES.PRESET_ACTIVE]: activePreset === period, [CLASS_NAMES.PRESET_DISABLED]: isPresetDisabled})}
                         onClick={isPresetDisabled ? undefined : onPresetClick.bind(this, period, startDate, endDate, props.updateViewDateForPreset)}
                    >
                        {label}
                    </div>
                )
            })}
        </div>);
    };


    headerRenderer = () => {
        const {period} = this.props;
        return (<div className={CLASS_NAMES.HEADER}>
            {`${this.formatPeriod(period.from)} - ${this.formatPeriod(period.to)}`}
        </div>);
    }

    shouldDisableNavigation = (viewDate, direction) => {
        if (direction === 'prev') {
            return viewDate.isBefore(moment(DATA_AVAILABLE_SINCE, DATE_FORMAT).add(2, "month"));
        }
        if (direction === 'next') {
            return viewDate.isAfter(moment().utc().startOf('month').subtract(1, "month"));
        }
        return false;
    };

    render() {
        const {period, onCustomPeriodChange} = this.props;
        return (<div className={CLASS_NAMES.CONTAINER}>
            <div className={CLASS_NAMES.CALENDAR}>
                <RangeDatePicker value={[period.from, period.to]}
                                 presetsRenderer={this.presetsRenderer}
                                 headerRenderer={this.headerRenderer}
                                 startDate={period.from}
                                 endDate={period.to}
                                 isValidDate={this.isNotFutureDate}
                                 onChange={onCustomPeriodChange}
                                 open={true}
                                 input={false}
                                 shouldDisableNavigation={this.shouldDisableNavigation}
                                 reverseMonthView={true}
                                 hookId="reports-range-date-picker"
                />
            </div>
        </div>);
    }
}

ReportsCalendar.propTypes = {
    io: PropTypes.object,
    handleChangeDate: PropTypes.func,
    onPresetClick: PropTypes.func,
    onCustomPeriodChange: PropTypes.func,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    period: PropTypes.object,
    activePreset: PropTypes.string
};

export default ReportsCalendar;