import _ from 'lodash';

import {placementsConstants} from '../constants/placementsConstants';
import {adUnitsConstants} from '../constants/adUnitsConstants';
import {appStates} from "../constants/appStates";
import {reducerHelper} from './reducerHelper';
import {deepCopy} from '../utils/helpers';
const initialState = {
    selectedGroupsIds: [],
    placementsPagination: {
        limit: 20,
        page: 1,
        count: 0,
    }
};

export const placementsReducer = (state = initialState, action) => {
    const {type, payload} = action;
    const pgs = 'placementGroups';
    const adUnits = 'adUnits';
    const file = 'file';
    switch (type) {
        // Get Placement groups
        case placementsConstants.PLACEMENT_GROUPS_REQUEST:
            return {
                ...state,
                [pgs]: {
                    data: payload.searchTerm || payload.sort || !payload.shouldShowLoadingAnimation ? state[pgs].data : undefined,
                    state: appStates.LOADING
                },
                placementsPagination: {
                    ...state.placementsPagination,
                    limit: payload.limit,
                    page: payload.page
                }
            };            
        case placementsConstants.PLACEMENT_GROUPS_SUCCESS:
            return {
                ...state,
                [pgs]: {
                    data: payload.placementGroups,
                    state: appStates.LOADED
                },
                placementsPagination: {
                    ...state.placementsPagination,
                    count: payload.placementGroupsCount
                }
            }
        case placementsConstants.PLACEMENT_GROUPS_FAILURE:
            return reducerHelper(state, pgs, undefined, appStates.ERROR);

        // Check on initial load if a file already exist
        case placementsConstants.PLACEMENT_FILE_REQUEST:
            return reducerHelper(state, file, undefined, appStates.DEFAULT);
        case placementsConstants.PLACEMENT_FILE_SUCCESS:
            return {
                ...state,
                file: {
                    state: appStates.LOADED,
                    name: payload.file.name
                }
            }
        case placementsConstants.PLACEMENT_FILE_FAILURE:
            return reducerHelper(state, file, undefined, appStates.ERROR);

        // Upload File
        case placementsConstants.UPLOAD_FILE_REQUEST:
            return {
                ...state,
                file: {
                    state: appStates.LOADING
                }
            };
        case placementsConstants.UPLOAD_FILE_PROGRESS:
            return {
                ...state,
                file: {
                    state: appStates.LOADING,
                    progress: payload.progressInfo
                }
            };
        case placementsConstants.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                file: {
                    state: appStates.LOADED,
                    name: payload.file
                }
            };
        case placementsConstants.UPLOAD_FILE_FAILURE:
            return {
                ...state,
                file: {
                    state: appStates.ERROR,
                    message: payload.error
                }
            };
        case placementsConstants.REMOVE_UPLOAD_ERROR_MESSAGE:
            return {
                ...state,
                file: {
                    state: appStates.ERROR
                }
            };

        // Download File
        case placementsConstants.DOWNLOAD_FILE_REQUEST:
            return {
                ...state,
                file: {
                    state: appStates.DOWNLOADING
                }
            };
        case placementsConstants.DOWNLOAD_FILE_SUCCESS:
            return {
                ...state,
                file: {
                    state: appStates.DOWNLOADED,
                    data: payload.data.content,
                    name: payload.data.name
                }
            };
        case placementsConstants.MARK_AS_DOWNLOADED:
            return {
                ...state,
                file: {
                    state: appStates.DOWNLOADED,
                    name: payload.name
                }
            };
        case placementsConstants.DOWNLOAD_FILE_FAILURE:
            return reducerHelper(state, file, undefined, appStates.ERROR);

        // Delete file
        case placementsConstants.DELETE_FILE_REQUEST:
            return {
                ...state
            };
        case placementsConstants.DELETE_FILE_SUCCESS:
            return reducerHelper(state, file, payload, appStates.LOADED);
        case placementsConstants. DELETE_FILE_FAILURE:
            return reducerHelper(state, file, undefined, appStates.ERROR);

        // Create placement
        case placementsConstants.CREATE_PLACEMENT_GROUP_REQUEST:
            //todo - effect change in state to increase percieved speed
            return {
                ...state,
                placementGroups: {
                    ...state.placementGroups,
                    state: appStates.LOADING
                }
            }
        case placementsConstants.CREATE_PLACEMENT_GROUP_SUCCESS: {
            const activeGroup = payload.placementGroups.find(pg => pg.name === state.currentGroup.name);

            return {
                ...state,
                placementGroups: {
                    ...state.placementGroups,
                    data: payload.placementGroups,
                    state: appStates.LOADED
                },
                activeGroup,
                currentGroup: undefined,
                placementsPagination: {
                    ...state.placementsPagination,
                    count: payload.placementGroupsCount
                }
            };
        }
        case placementsConstants.CREATE_PLACEMENT_GROUP_FAILURE:
            return reducerHelper(state, pgs, undefined, appStates.ERROR);

        // Update placement
        case placementsConstants.UPDATE_PLACEMENT_GROUP_REQUEST:
            //todo - effect change in state to increase percieved speed
            return {
                ...state
            };
        case placementsConstants.UPDATE_PLACEMENT_GROUP_SUCCESS: {
            const activeGroup = payload.placementGroups.find(pg => pg.placementGroupId === state.currentGroup.placementGroupId);
            return {
                ...state,
                placementGroups: {
                    data: payload.placementGroups,
                    state: appStates.LOADED
                },
                activeGroup: {
                    ...activeGroup,
                    isUpdated: true
                },
                currentGroup: undefined,
                placementsPagination: {
                    ...state.placementsPagination,
                    count: payload.placementGroupsCount
                }
            };
        }
        case placementsConstants.UPDATE_PLACEMENT_GROUP_FAILURE:
            return reducerHelper(state, pgs, undefined, appStates.ERROR);

        // Delete placement groups
        case placementsConstants.DELETE_PLACEMENT_GROUPS_SUCCESS:
            return {
                ...state,
                placementGroups: {
                    data: payload.placementGroups,
                    state: appStates.LOADED
                },
                placementsPagination: {
                    ...state.placementsPagination,
                    count: payload.placementGroupsCount
                }
                
            }
        case placementsConstants.DELETE_PLACEMENT_GROUPS_FAILURE:
            return reducerHelper(state, pgs, undefined, appStates.ERROR);

        // Get adUnits
        case adUnitsConstants.GET_AD_UNITS_REQUEST:
            return reducerHelper(state, adUnits, undefined, appStates.LOADING)
        case adUnitsConstants.GET_AD_UNITS_SUCCESS: {
            const {adUnits, placementTypes, placementTags} = payload;
           return {
                ...state,
                placementTypes,
                placementTags,
                adUnits: {
                    data: adUnits,
                    state: appStates.LOADED
                }
            };
        }
        case adUnitsConstants.GET_AD_UNITS_FAILURE:
            return reducerHelper(state, adUnits, undefined, appStates.ERROR)

        // Get placement status
        case placementsConstants.SET_PLACEMENT_GROUP_STATUS_REQUEST: {
            const data = state.placementGroups.data.map(pgroup =>
                (pgroup.placementGroupId === payload.placementGroupId)
                ? {...pgroup, status: payload.status}
                : pgroup
            );
            const activeGroupState = state.activeGroup
                ? {activeGroup: {...state.activeGroup, status: payload.status}}
                : {};
            return {
                ...state,
                placementGroups: {
                    ...state.placementGroups,
                    data
                },
                ...activeGroupState
            };
        }
        case placementsConstants.SET_PLACEMENT_GROUP_STATUS_SUCCESS: {
            const activeGroupState = state.activeGroup
                ? {activeGroup: {
                    ...payload.placementGroups.find(pg => pg.placementGroupId === state.activeGroup.placementGroupId),
                    isUpdated: state.activeGroup.isUpdated
                }}
                : {};
            return {
                ...state,
                placementGroups: {
                    data: payload.placementGroups,
                    state: appStates.LOADED
                },
                ...activeGroupState,
                placementsPagination: {
                    ...state.placementsPagination,
                    count: payload.placementGroupsCount
                }
            };
        }
        case placementsConstants.SET_PLACEMENT_GROUP_STATUS_FAILURE: {
            return {
                ...state,
                placementGroups: {
                    data: undefined,
                    state: appStates.ERROR,
                }
            };
        }

         // Open and close placement group modal
        case placementsConstants.OPEN_PLACEMENT_GROUP_MODAL: {
            return {
                ...state,
                currentGroup: payload.currentGroup
            }
        }
        case placementsConstants.CLOSE_PLACEMENT_GROUP_MODAL:
        case placementsConstants.CLOSE_CONFIRMATION_MODAL: {
            return {
                ...state,
                placementGroups: {
                    ...state.placementGroups,
                    state: appStates.DEFAULT
                },
                activeGroup: undefined,
                currentGroup: undefined
            }
        }
        // Behavior inside placement groups
        case placementsConstants.SEARCH_PLACEMENT_GROUPS: {
            return {
                ...state,
                searchTerm: payload.searchTerm,
                placementsPagination: {
                    ...state.placementsPagination,
                    limit: payload.limit,
                    page: payload.page
                }
            };
        }
        case placementsConstants.TOGGLE_SELECT_AD_UNIT: {
            const {id, name, isSelected} = payload.adUnit;
            const adUnits = (isSelected)
                ? state.currentGroup.adUnits.filter(adUnit => adUnit.id !== id)
                : [...state.currentGroup.adUnits, {name, id}]
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    adUnits
                }
            }
        }
        case placementsConstants.TOGGLE_SELECT_ALL_AD_UNITS: {
            const {adUnits, isAllSelected, adUnitKey} = payload;

            const adUnitsGroupData = state.adUnits.data[adUnitKey].reduce((res, au) => {
                res.push(Object.assign({}, au, {isSelected: isAllSelected}));
                return res;
            }, []);

            const adUnitsData = deepCopy(state.adUnits.data);
            adUnitsData[adUnitKey] = adUnitsGroupData;

            const currAdUnitsData = state.currentGroup.adUnits.reduce((res, au) => {
                const selectedAdUnit = adUnits.find(a => a.id === au.id);
                if (!selectedAdUnit) {
                    res.push(au);
                }
                return res;
            }, []);

            !isAllSelected && adUnits.map(au => {
                currAdUnitsData.push({name: au.name, id: au.id});
                return au;
            });

            return {
                ...state,
                adUnits: {
                    ...state.adUnits,
                    data: adUnitsData
                },
                currentGroup: {
                    ...state.currentGroup,
                    adUnits: currAdUnitsData
                }
            }
        }
        case placementsConstants.CLEAR_SELECTED_AD_UNITS: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    adUnits: []
                }
            }
        }
        case placementsConstants.PREVIEW_AD_UNIT_INFO: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    previewAdUnit: payload.previewAdUnit
                }
            }
        }
        case placementsConstants.SET_PLACEMENT_GROUP_TITLE: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    name: payload.title
                }
            }
        }

        case placementsConstants.SET_ONE_OFF_PUBLISHER: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    isOneOff: payload.isOneOff
                }
            }
        }

        case placementsConstants.SET_PASSBACK_TAG: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    passbackTag: payload.passbackTag
                }
            }
        }

        case placementsConstants.SET_USE_SYSTEM_APPEND_PIXEL: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    ignoreSystemAppendPixel: payload.ignoreSystemAppendPixel
                }
            }
        }

        case placementsConstants.SET_IS_AUCTION_PRICE_MACRO: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    isAuctionPriceMacro: payload.isAuctionPriceMacro
                }
            }
        }

        case placementsConstants.SET_WIN_TYPE_EVENT: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    winEventType: payload.winEventType
                }
            }
        }

        case placementsConstants.SET_AUCTION_PRICE_MACRO_TYPE: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    actionPriceMacroType: payload.actionPriceMacroType
                }
            }
        }

        case placementsConstants.SET_IS_PREFER_VIDAZOO_PLAYER: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    isPreferVidazoo: payload.isPreferVidazoo
                }
            }
        }

        case placementsConstants.SET_AUCTION_PRICE_MACRO: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    auctionPriceMacro: payload.auctionPriceMacro
                }
            }
        }

        case placementsConstants.SET_IS_THROTTELING: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    isOverrideThrottling: payload.isOverrideThrottling
                }
            }
        }

        case placementsConstants.SET_THROTTELING: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    throttling: payload.throttling
                }
            }
        }


        // Create and Delete Ad Slots
        case placementsConstants.IMPORT_FILE_SUCCESS: {
            const adSlots = [...payload.adSlots, ...state.currentGroup.adSlots];
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    adSlots: _.uniqBy(adSlots, 'name'),
                    err: undefined
                }
            }
        }
        case placementsConstants.IMPORT_FILE_FAILURE: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    err: payload.error
                }
            }
        }
        case placementsConstants.SAVE_PLACEMENT_GROUP_AD_SLOTS: {
            const match = _.find(state.currentGroup.adSlots, adSlot => {
                return !_.isNil(adSlot.id) && adSlot.id === payload.adSlots[0].id;
            });
            const adSlots = match ? 
            _.map(state.currentGroup.adSlots, adSlot => {
                return adSlot.id === payload.adSlots[0].id ? {...payload.adSlots[0]} : adSlot
            }) : 
            [...payload.adSlots, ...state.currentGroup.adSlots];
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    adSlots,
                    adSlotToEdit: null,
                    adSlotsChanges: {},
                    newAdSlot: []
                }
            }
        }
        case placementsConstants.CREATE_NEW_PLACEMENT_GROUP_AD_SLOTS: {
            const newAdSlot = [...payload.adSlots];
            const adSlotToEdit = payload.adSlots[0].id;
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    newAdSlot,
                    adSlotToEdit
                }
            }
        }
        case placementsConstants.TOGGLE_AD_SLOTS_EDIT_MODE: {
            const adSlotToEdit = payload;
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    adSlotToEdit,
                    adSlotsChanges: {},
                    newAdSlot: []
                }
            }
        }
        case placementsConstants.AD_SLOTS_CHANGES: {
            const {adSlotToEdit, changes} = payload;
            const adSlotsChanges = {[adSlotToEdit]: changes};
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    adSlotsChanges
                }
            }
        }
        case placementsConstants.DELETE_PLACEMENT_GROUP_AD_SLOTS: {
            const adSlots = state.currentGroup.adSlots.filter(adSlot => {
                return (adSlot.name !== payload.adSlot.name);
            });
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    adSlots,
                    adSlotToEdit: null
                }
            }
        }
        case placementsConstants.BULK_DELETE_PLACEMENT_GROUP_AD_SLOTS: {
            const adSlots = state.currentGroup.adSlots;
            const filteredAdSlots = _.filter(adSlots, adSlot => {
                return !payload.adSlotIds.includes(adSlot.id);
            });
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    adSlots: filteredAdSlots
                }
            }
        }
        case placementsConstants.SEARCH_AD_SLOTS: {
            return {
                ...state,
                currentGroup: {
                    ...state.currentGroup,
                    searchTerm: payload.searchTerm
                }
            };
        }
        case placementsConstants.SET_SELECTED_PLACEMENT_GROUPS_IDS: {
            let newSelectedGroupsIds = [];
            if (!_.isNull(payload.placementGroupId)) {
                newSelectedGroupsIds = [...state.selectedGroupsIds];
                const index = newSelectedGroupsIds.indexOf(payload.placementGroupId);
                if (index === -1) {
                    newSelectedGroupsIds.push(payload.placementGroupId);
                } else {
                    newSelectedGroupsIds.splice(index, 1);
                }
            }

            return {
                ...state,
                selectedGroupsIds: newSelectedGroupsIds
            };
        }
        default:
            return state
    }
}