import React from 'react';
import PropTypes from 'prop-types';
import {Select, Highlighter, Tooltip} from '@perion-undertone/ut-react-common';
import {textProvider} from '../imports';

const CLASS_NAMES = {
    LEFT: 'advertiser-option-left',
    NAME: 'advertiser-option-left--name',
    RIGHT: 'advertiser-option-right',
    TOOLTIP: 'advertiser-option-tooltip',
}

const CONSTANT_TEXTS = {
    DISABLED_ADVERTISER: textProvider.getText('supplySourceSettingsModal', 'disabledBlockedAdvertiser'),
}

const BlockListAdvertiserOption = (props) => {
    const option = (<span className={CLASS_NAMES.ADVERTISER_NAME}>
        {props.data.name}
    </span>)
    return (<Select.components.Option {...props} hookId="block-sales-accounts-select">
        <div className={CLASS_NAMES.LEFT}>
            {!props.isDisabled && <Highlighter searchWords={[props.selectProps.inputValue]}
                                               textToHighlight={props.children}
                                               hookId="block-sales-accounts-highlighter"
            />}
            {props.isDisabled
                && (<Tooltip trigger={option}
                            placement='right'
                            appendToBody={true}
                            className={CLASS_NAMES.TOOLTIP}
                            hookId="block-sales-accounts-tooltip"
                >
                    {CONSTANT_TEXTS.DISABLED_ADVERTISER}
                </Tooltip>)}
        </div>
        <div className={CLASS_NAMES.RIGHT}>
            {props.data.id}
        </div>
    </Select.components.Option>);
};

BlockListAdvertiserOption.propTypes = {
    selectProps: PropTypes.object,
    data: PropTypes.object,
    isDisabled: PropTypes.bool
}

export default BlockListAdvertiserOption;