import {of} from "rxjs";
import ajax from '../utils/ajaxProxy';
import {ofType} from "redux-observable";
import {catchError, map, mergeMap} from "rxjs/operators";
import {emailConstants} from "../constants/emailConstants";
import {submitEmailFailed, submitEmailSuccess} from "../actions/emailActions";

export const sendEmailEpic = actions => (
    actions.pipe(
        ofType(emailConstants.SUBMIT_EMAIL),
        mergeMap(action => {
            return ajax.post('/api/email/send-email', action.payload,
                {'Content-Type': 'application/json'}).pipe(
                map(data => submitEmailSuccess(data.response)),
                catchError(error => of(submitEmailFailed(error)))
            )
        })
    )
);