import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';
import {Button, Select} from '@perion-undertone/ut-react-common';
import {searchAdvertisersBlockList, getAdvertisers} from '../actionImports';
import {textProvider, MIN_SEARCH_QUERY_LENGTH,
        Icon, SearchBar, appStates, GridNoRowsTemplate} from '../imports';
import BlockListAdvertiserOption from './BlockListAdvertiserOption';
import BlockList from './BlockList';
import BlockedAdvertisersGrid from './BlockedAdvertisersGrid';

const CLASS_NAMES = {
    INNER_CONTAINER: 'supply-settings-modal-inner-container',
    BLOCK_LIST_INNER: 'block-list-inner',
    SECTION_WRAPPER: 'section-wrapper',
    SECTION_WRAPPER_SEPARATOR: 'section-wrapper-separator',
    BLOCK_LIST_TITLE: 'block-list-title',
    BLOCK_LIST_DESCRIPTION: 'block-list-description',
    BLOCK_LIST_SELECT_ADVERTISER: 'block-list-select-advertiser',
    SELECT_LIST_CONTAINER: 'select-list-container',
    SELECT_SALES_ACCOUNTS: 'select-sales-accounts',
    BLOCKED_ADVERTISERS: 'blocked-advertisers',
    BLOCKED_ADVERTISERS_LABEL: 'blocked-advertisers-label',
    BLOCKED_ADVERTISERS_LABEL_NUMBER: 'blocked-advertisers-label-number',
    NO_ADVERTISERS: 'no-advertisers-template',
    FOOTER: 'supply-settings-modal-footer',
    DONE_BUTTON: 'supply-settings-modal-done-button',
};

const CONSTANT_TEXTS = {
    BLOCK_ADVERTISERS: textProvider.getText('supplySourceSettingsModal', 'blockAdvertisers'),
    BLOCK_ADVERTISERS_DESCRIPTION: textProvider.getText('supplySourceSettingsModal', 'blockAdvDescription'),
    SELECT_ADVERTISER: textProvider.getText('supplySourceSettingsModal', 'selectAdvertiser'),
    BLOCK: textProvider.getText('supplySourceSettingsModal', 'block'),
    BLOCKED: textProvider.getText('supplySourceSettingsModal', 'blocked'),
    ADVERTISERS: textProvider.getText('supplySourceSettingsModal', 'advertisers'),
    SEARCH_BY_BLOCKED_ADVERTISER: textProvider.getText('adUnits', 'searchByBlockedAdvertiser'),
    NO_ADVERTISERS: textProvider.getText('supplySourceSettingsModal', 'noAdvertisers'),
    MUTUAL_VALUES: textProvider.getText('supplySourceSettingsModal', 'bulkBlockMutualValues'),
    CONFIRM_BULK_ACTION_TITLE: textProvider.getText('supplySourceSettingsModal', 'confirmBulkActionTitle'),
    CONFIRM_BULK_ACTION_BODY: textProvider.getText('supplySourceSettingsModal', 'confirmBulkActionBody'),
    CONFIRM_BULK_ACTION_BUTTON_TEXT: textProvider.getText('supplySourceSettingsModal', 'confirmBulkActionButtonText'),
    DONE: textProvider.getText('supplySourceSettingsModal', 'doneButton')
}

class BlockListAdvertisers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            searchIconClass: 'search_icon.svg',
            openBulkSaveConfirmationModal: false
        }
    }

    handleInputChange = (inputValue) => {
        const {getAdvertisers} = this.props;
        this.setState({inputValue});
        if (inputValue.length >= MIN_SEARCH_QUERY_LENGTH) {
            getAdvertisers(inputValue);
        }
    }

    searchAdvertisersGroup = ({target: {value}}) => {
        this.props.searchAdvertisersBlockList(value);
        this.setState({searchIconClass: !value ? 'search_icon.svg' : ''});
    }

    onOpenBulkSaveConfirmationModal = () => {
        this.setState({openBulkSaveConfirmationModal: true})
    }

    onCloseBulkSaveConfirmationModal = () => {
        this.setState({openBulkSaveConfirmationModal: false});
    }

    isOptionDisabled = option => {
        const {blockedAdvertisers} = this.props;
        return blockedAdvertisers.data && blockedAdvertisers.data.some(adv => adv.advertiserId === option.id);
    };

    getOptionLabel = (opt) => (opt.name);

    getOptionValue = (opt) => (opt.id);

    render() {
        const {publisherId, publishersSupplySourcesId, blockedAdvertisers,
            onViewChange, advertisers, isPublisherLevel, isBulkBlock, bulkBlockMessage,
            publishersSupplySourcesIds, advertiserValues, handleAdvertisersValueChange,
            onOpenBulkBlockAdvertisersConfirmation} = this.props;
        const {inputValue} = this.state;
        const numOfBlockedAdvertisers = blockedAdvertisers.data
            ? (<div>
                <SearchBar result={this.searchAdvertisersGroup}
                           placeholder={CONSTANT_TEXTS.SEARCH_BY_BLOCKED_ADVERTISER}
                           icon={this.state.searchIconClass}
                />
                <div className={CLASS_NAMES.BLOCKED_ADVERTISERS_LABEL}>
                    <span className={CLASS_NAMES.BLOCKED_ADVERTISERS_LABEL_NUMBER}>{`${blockedAdvertisers.data.length} ${CONSTANT_TEXTS.BLOCKED}`}</span>
                    {CONSTANT_TEXTS.ADVERTISERS}
                    {isBulkBlock && bulkBlockMessage}
                </div>
            </div>)
            : null;
        const blockListItem = {component: BlockList, label: 'block-list', viewId: 'block-list'}
        const options = advertisers && advertisers.data && inputValue.length >= MIN_SEARCH_QUERY_LENGTH ? advertisers.data : [];

        return (<Fragment>
            <div className={CLASS_NAMES.INNER_CONTAINER}>
                <div className={cx(CLASS_NAMES.SECTION_WRAPPER, CLASS_NAMES.BLOCK_LIST_INNER)}>
                    <span onClick={onViewChange.bind(this, blockListItem)}>
                        <Icon icon="pagging_arrow_back" hookId="blocklist-advertisers-paging-arrow-back"/>
                    </span>
                    <span className={CLASS_NAMES.BLOCK_LIST_TITLE}>{CONSTANT_TEXTS.BLOCK_ADVERTISERS}</span>
                    <div className={CLASS_NAMES.BLOCK_LIST_DESCRIPTION}>
                        {CONSTANT_TEXTS.BLOCK_ADVERTISERS_DESCRIPTION}
                    </div>
                    <div className={CLASS_NAMES.BLOCK_LIST_SELECT_ADVERTISER}>{CONSTANT_TEXTS.SELECT_ADVERTISER}</div>
                    <div className={CLASS_NAMES.SELECT_LIST_CONTAINER}>
                        <div className={CLASS_NAMES.SELECT_SALES_ACCOUNTS}>
                            <Select className={CLASS_NAMES.SELECT}
                                    isMulti
                                    value={advertiserValues}
                                    cacheOptions
                                    getOptionLabel={this.getOptionLabel}
                                    getOptionValue={this.getOptionValue}
                                    isOptionDisabled={this.isOptionDisabled}
                                    options={options}
                                    onChange={handleAdvertisersValueChange}
                                    onInputChange={this.handleInputChange}
                                    noOptionsMessage={() => ('Please start typing')}
                                    components={
                                        {Option: BlockListAdvertiserOption}
                                    }
                                    hookId="sales-account-select"
                            />
                        </div>
                        <Button transparent
                                onClick={() => onOpenBulkBlockAdvertisersConfirmation()}
                                className={CLASS_NAMES.CANCEL_BUTTON}
                                disabled={advertiserValues && advertiserValues.length < 1}
                                hookId='handle-block-sales-accounts-btn'
                        >
                            {CONSTANT_TEXTS.BLOCK}
                        </Button>
                    </div>
                </div>
                <div className={CLASS_NAMES.SECTION_WRAPPER_SEPARATOR}></div>
                {blockedAdvertisers.state === appStates.LOADING ||
                    blockedAdvertisers.data && blockedAdvertisers.data.length > 0 ?
                        <div className={CLASS_NAMES.BLOCKED_ADVERTISERS}>
                            {numOfBlockedAdvertisers}
                            <BlockedAdvertisersGrid publisherId={publisherId}
                                                    publishersSupplySourcesId={publishersSupplySourcesId}
                                                    blockedAdvertisers={blockedAdvertisers}
                                                    isPublisherLevel={isPublisherLevel}
                                                    isBulkBlock={isBulkBlock}
                                                    publishersSupplySourcesIds={publishersSupplySourcesIds}
                            />
                        </div> :
                        <GridNoRowsTemplate className={CLASS_NAMES.NO_ADVERTISERS}
                                            message={CONSTANT_TEXTS.NO_ADVERTISERS}
                        />
                }
            </div>
            <div className={CLASS_NAMES.FOOTER}>
                <Button onClick={this.props.cancelModal}
                        className={CLASS_NAMES.DONE_BUTTON}
                        hookId='done-button'
                >
                    {CONSTANT_TEXTS.DONE}
                </Button>
            </div>
        </Fragment>)
    }
}

BlockListAdvertisers.propTypes = {
    publisherId: PropTypes.number,
    publishersSupplySourcesId: PropTypes.number,
    onViewChange: PropTypes.func,
    blockedAdvertisers: PropTypes.object,
    searchAdvertisersBlockList: PropTypes.func,
    getAdvertisers: PropTypes.func,
    advertisers: PropTypes.object,
    isPublisherLevel: PropTypes.bool,
    isBulkBlock: PropTypes.bool,
    bulkBlockMessage: PropTypes.object,
    publishersSupplySourcesIds: PropTypes.array,
    advertiserValues: PropTypes.array,
    onOpenBulkBlockAdvertisersConfirmation: PropTypes.func,
    handleAdvertisersValueChange: PropTypes.func,
    cancelModal: PropTypes.func
};

const mapStateToProps = ({advertisers}) => ({
    advertisers: advertisers.advertisersList
});

const mapDispatchToProps = ({
    searchAdvertisersBlockList,
    getAdvertisers
})

export default connect(mapStateToProps, mapDispatchToProps)(BlockListAdvertisers);