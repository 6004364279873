import React from 'react';
import PropTypes from "prop-types";
import OnboardingSteps from "./OnboardingSteps";
import textProvider from '../../../../texts';

const CLASS_NAMES = {
    ONBOARDING_HEADER: 'ut-onboard-header',
    LABEL: 'label'
};

const CONST_TEXTS = {
    ENTER_SITE: textProvider.getText('onboarding.steps', 'enterSite'),
    SITE_REVIEW: textProvider.getText('onboarding.steps', 'siteReview'),
    ADS_TXT: textProvider.getText('onboarding.steps', 'adsTxt'),
    ACCOUNT_SETUP: textProvider.getText('onboarding.steps', 'accountSetup')
}

const OnboardingHeader = (props) => {
    const {step} = props;

    const getClassState = (currentStep) => {
        return OnboardingSteps.getHeaderStepClass(step, currentStep);
    };

    return (
        <div className={CLASS_NAMES.ONBOARDING_HEADER}>
            <div className={getClassState("1_1")}>
                <p className={CLASS_NAMES.LABEL}>{CONST_TEXTS.ENTER_SITE}</p>
            </div>
            <div className={getClassState("2_1")}>
                <p className={CLASS_NAMES.LABEL}>{CONST_TEXTS.SITE_REVIEW}</p>
            </div>
            <div className={getClassState("3_1")}>
                <p className={CLASS_NAMES.LABEL}>{CONST_TEXTS.ADS_TXT}</p>
            </div>
            <div className={getClassState("4_1")}>
                <p className={CLASS_NAMES.LABEL}>{CONST_TEXTS.ACCOUNT_SETUP}</p>
            </div>
            {props.children}
        </div>
    );
};

OnboardingHeader.propTypes = {
    step: PropTypes.string
};

export default OnboardingHeader;