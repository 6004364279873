import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Modal, Button} from '@perion-undertone/ut-react-common';
import {getAdsTxtCode} from '@perion-undertone/ramp-helpers';
import {setAdsTxtDomain, closeAdsTxtModal} from '../../features/supply/actionImports';
import Icon from '../../Icon';
import textProvider from '../../../texts/textProvider';
import CopyToClipboard from '../../CopyToClipboard';

const CONSTANT_TEXTS = {
    TOP_CONTAINER_MESSAGE_MISSING: textProvider.getText('validateAdsTxtModal', 'missingAdstxt'),
    TOP_CONTAINER_MESSAGE_FOUND: textProvider.getText('validateAdsTxtModal', 'codeFound'),
    TEXT_MESSAGE_MISSING_PART1: textProvider.getText('validateAdsTxtModal', 'missingAdstxtMessagePart1'),
    TEXT_MESSAGE_MISSING_PART2: textProvider.getText('validateAdsTxtModal', 'missingAdstxtMessagePart2'),
    TEXT_MESSAGE_MISSING_PART3: textProvider.getText('validateAdsTxtModal', 'missingAdstxtMessagePart3'),
    TEXT_MESSAGE_MISSING_PART4: textProvider.getText('validateAdsTxtModal', 'missingAdstxtMessagePart4'),
    TEXT_MESSAGE_FOUND_APPROVED: textProvider.getText('validateAdsTxtModal', 'codeFoundApprovedMessage'),
    TEXT_MESSAGE_FOUND_NOT_APPROVED: textProvider.getText('validateAdsTxtModal', 'codeFoundNotApprovedMessage'),
    BUTTON_CLOSE: textProvider.getText('validateAdsTxtModal', 'btnClose'),
    BUTTON_CHECK: textProvider.getText('validateAdsTxtModal', 'btnCheckAgain'),
};

const CLASS_NAMES = {
    VALIDATE_ADSTXT_MODAL_WRAPPER: "validate-adstext-modal-wrapper",
    VALIDATE_ADSTXT_TOP_CONTAINER: "validate-adstext-top-container",
    TOP_IMAGE_WRAPPER: "top-image-wrapper",
    TOP_MESSAGE_WRAPPER: "top-message-wrapper",
    TOP_TEXT_MISSING: "text-missing",
    TOP_TEXT_FOUND_APPROVED: "text-found-approved",
    TOP_TEXT_FOUND_NOT_APPROVED: "text-found-not-approved",
    VALIDATE_ADSTXT_BOTTOM_CONTAINER: "validate-adstext-bottom-container",
    BOTTOM_TEXT_WRAPPER: "bottom-text-wrapper",
    BOTTOM_TEXT_MISSING: "text-bottom-missing",
    BOTTOM_TEXT_FOUND: "text-bottom-found",
    BUTTONS_TEXT_WRAPPER: "buttons-bottom-wrapper",
    BUTTON_CLOSE: "close",
    BUTTON_CHECK: "check",
    VALIDATING_ADS_TXT: "validating-ads-txt",
    MAGNIFIER_ICON_WRAPPER: "magnifier-icon-wrapper"
};

const STATUS = {
    PENDING: 0,
    APPROVED: 1,
    NOT_APPROVED: 2,
    MISSING_ADS_TXT: 3
};

class ValidateAdsTxtModal extends Component {
    constructor(props) {
        super(props);
        this.checkDomainAdsTxt = this.checkDomainAdsTxt.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
    }

    cancelModal = () => {
        this.props.closeAdsTxtModal();
    }

    checkDomainAdsTxt() {
        const {hasValidAdsTxt, publisherId, publishersSupplySourcesId, SupplySource: {url}} = this.props.validatedDomain;
        this.props.setAdsTxtDomain(publisherId, publishersSupplySourcesId, url, hasValidAdsTxt);
    }

    renderHeader() {
        const {status} = this.props.validatedDomain;
        const imageType = status === STATUS.MISSING_ADS_TXT ? 'missing' : (status === STATUS.APPROVED ? 'found-approved' : 'found-not-approved');
        const classNameText = status === STATUS.MISSING_ADS_TXT
            ? CLASS_NAMES.TOP_TEXT_MISSING
            : (status === STATUS.APPROVED ? CLASS_NAMES.TOP_TEXT_FOUND_APPROVED : CLASS_NAMES.TOP_TEXT_FOUND_NOT_APPROVED);
        const headerMessage = status === STATUS.MISSING_ADS_TXT ? CONSTANT_TEXTS.TOP_CONTAINER_MESSAGE_MISSING : CONSTANT_TEXTS.TOP_CONTAINER_MESSAGE_FOUND;

        return(
            <div className={CLASS_NAMES.VALIDATE_ADSTXT_TOP_CONTAINER}>
                <div className={CLASS_NAMES.TOP_IMAGE_WRAPPER}>
                    <Icon icon={`missing-adstext-${imageType}.png`} />
                </div>
                <div className={CLASS_NAMES.TOP_MESSAGE_WRAPPER}>
                    <div className={classNameText}>{headerMessage}</div>
                </div>
            </div>
        )
    }

    renderBodyMessage() {
        const {publisherId, status} = this.props.validatedDomain;
        const adsTxtCode = getAdsTxtCode(publisherId);
        const copyAdsTxtCode = <CopyToClipboard copyValue={adsTxtCode} copyTarget={'Copy ads txt'} copy={CONSTANT_TEXTS.TEXT_MESSAGE_MISSING_PART3} noWrap />;

        const textMissingAdsTxt = status === STATUS.MISSING_ADS_TXT ? (
            <div>
                <div className={CLASS_NAMES.BOTTOM_TEXT_MISSING}>{CONSTANT_TEXTS.TEXT_MESSAGE_MISSING_PART1}</div>
                <div className={CLASS_NAMES.BOTTOM_TEXT_MISSING}> {CONSTANT_TEXTS.TEXT_MESSAGE_MISSING_PART2} &nbsp;
                    {copyAdsTxtCode}
                    {CONSTANT_TEXTS.TEXT_MESSAGE_MISSING_PART4}
                </div>
            </div>
        ) : (
            <div>
                <div className={CLASS_NAMES.BOTTOM_TEXT_FOUND}>
                    {status === STATUS.APPROVED ?
                    CONSTANT_TEXTS.TEXT_MESSAGE_FOUND_APPROVED :
                    CONSTANT_TEXTS.TEXT_MESSAGE_FOUND_NOT_APPROVED}
                </div>
            </div>
        )

        return (
            <div className={CLASS_NAMES.BOTTOM_TEXT_WRAPPER}>
                {textMissingAdsTxt}
            </div>
        )
    }

    render() {
        const {isOpenAdsTxtModal, validatingAdsTxt} = this.props;

        if (!isOpenAdsTxtModal) {
            return null;
        }

        return (
            <div>
            <Modal className={CLASS_NAMES.VALIDATE_ADSTXT_MODAL_WRAPPER}
                   open={isOpenAdsTxtModal}
                   closeOnOutsideClick={false}
                   onModalClose={this.cancelModal}
                   closeButton={false}
                   hookId="validate-modal"
            >
                <div className={cx({[CLASS_NAMES.VALIDATING_ADS_TXT]: validatingAdsTxt})}>
                    {this.renderHeader()}
                    <div className={CLASS_NAMES.VALIDATE_ADSTXT_BOTTOM_CONTAINER}>
                        {this.renderBodyMessage()}
                        <div className={CLASS_NAMES.BUTTONS_TEXT_WRAPPER}>
                            <Button className={CLASS_NAMES.BUTTON_CLOSE}
                                    onClick={this.cancelModal}
                                    hookId='validate-modal-close'
                            >
                                {CONSTANT_TEXTS.BUTTON_CLOSE}
                            </Button>
                            <Button className={CLASS_NAMES.BUTTON_CHECK}
                                    hookId='validate-modal-check'
                                    onClick={this.checkDomainAdsTxt}
                            >
                                {CONSTANT_TEXTS.BUTTON_CHECK}
                            </Button>
                        </div>
                    </div>
                </div>
                {validatingAdsTxt &&
                    <div className={CLASS_NAMES.MAGNIFIER_ICON_WRAPPER}>
                        <Icon className='magnifier' icon='magnifier.png' />
                    </div>
                }
            </Modal>
            </div>
        )
    }
}

ValidateAdsTxtModal.propTypes = {
    validatedDomain: PropTypes.object,
    setAdsTxtDomain: PropTypes.func,
    closeAdsTxtModal: PropTypes.func,
    isOpenAdsTxtModal: PropTypes.bool,
    validatingAdsTxt: PropTypes.bool
};

const mapStateToProps = ({supply}) => ({
    isOpenAdsTxtModal: supply.isOpenAdsTxtModal,
    validatingAdsTxt: supply.validatingAdsTxt,
    validatedDomain: supply.validatedDomain
});

const mapDispatchToProps = ({
    setAdsTxtDomain,
    closeAdsTxtModal
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidateAdsTxtModal);
