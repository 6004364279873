import React from 'react';
import PropTypes from 'prop-types';
import format from 'string-template';
import cx from 'classnames';

import {textProvider} from '../imports';

const CONSTANT_TEXTS = {
    SELECT_ADUNIT: textProvider.getText('previewSidebar', 'selectAdUnit'),
    SELECTED: textProvider.getText('common', 'countSelected'),
};

const CLASS_NAMES = {
    SELECTED_ADUNITS_WRAPPER: 'selected-adunits-wrapper',
    SELECTED_ADUNITS_TITLE: 'selected-adunits-title',
    SELECTED_ADUNITS_COUNTER: 'selected-adunits-counter'
};

const getDisplayCounter = (count) => count > 0 && format(CONSTANT_TEXTS.SELECTED, count);

const AdunitsCounter = (props) => {
    const {counter, className} = props;

    return (
        <div className={CLASS_NAMES.SELECTED_ADUNITS_WRAPPER}>
            <span className={cx(CLASS_NAMES.SELECTED_ADUNITS_TITLE, className)}>
                {CONSTANT_TEXTS.SELECT_ADUNIT}
            </span>
            <div className={CLASS_NAMES.SELECTED_ADUNITS_COUNTER}>
                {getDisplayCounter(counter)}
            </div>
        </div>
    );
};

AdunitsCounter.propTypes = {
    counter: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    className: PropTypes.string
};

export default AdunitsCounter;