import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '../../../Icon';
import {Tooltip} from '@perion-undertone/ut-react-common';
import SupplySettingsModal from '../SupplySettingsModal';
import {textProvider} from '../imports';

const CLASS_NAMES = {
    SETTINGS_ACTIONS_WRAPPER: 'settings-actions-wrapper',
    SETTINGS_ICON_WRAPPER: 'settings-icon-wrapper',
    SETTINGS_ICON: 'settings-icon',
};

const CONSTANT_TEXTS = {
    SETTINGS: textProvider.getText('supplySourceSettingsFormatter', 'settings'),
    PUBLISHER_LEVEL_SETTINGS: textProvider.getText('supplySourceSettingsFormatter', 'publisherLevelSettings')
}

class SupplyActionsFormatter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSettingsModal: false,
            supplySourceId: [],
            isPublisherLevel: this.props.isPublisherLevel,
            id: this.props.row.id,
            isRedirectedFromSupply: false
        }
        this.onOpenSettingsModal = this.onOpenSettingsModal.bind(this);
        this.onCloseSettingsModal = this.onCloseSettingsModal.bind(this);
    }

    componentDidMount() {
        const {publisher, isPublisherLevel, location} = this.props;
        let urlSearchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.interstitialSettings && isPublisherLevel) {
            this.setState(
                {
                    isPublisherLevel: true,
                    openSettingsModal: true,
                    id: publisher.id,
                    isRedirectedFromSupply: true
                }
            );
        }
    }

    onOpenSettingsModal(supplySourceId) {
        this.setState({openSettingsModal: true, supplySourceId: [supplySourceId]});
    }

    onCloseSettingsModal() {
        this.setState({openSettingsModal: false});
    }

    render() {
        const {className, row} = this.props;
        const {isPublisherLevel, id, isRedirectedFromSupply} = this.state;
        const {displayName, publishersSupplySourcesId} = row;
        const settingsTrigger = (
            <div className={CLASS_NAMES.SETTINGS_ICON}
                 onClick={() => this.onOpenSettingsModal(id)}
            >
                <Icon icon="general"
                      hookId={`supply-actions-${isPublisherLevel && 'publisher-'}settings-icon`}
                />
            </div>
        );

        return (<div className={cx(CLASS_NAMES.SETTINGS_ACTIONS_WRAPPER, className)}>
            <div className={CLASS_NAMES.SETTINGS_ICON_WRAPPER}>
                <Tooltip trigger={settingsTrigger}
                         placement='top'
                         appendToBody={true}
                         hookId={`supply-actions-${isPublisherLevel && 'publisher-'}settings-tooltip`}
                >
                    {isPublisherLevel ? CONSTANT_TEXTS.PUBLISHER_LEVEL_SETTINGS : CONSTANT_TEXTS.SETTINGS}
                </Tooltip>
            </div>
            {this.state.openSettingsModal ?
                <SupplySettingsModal 
                                     openModal={this.state.openSettingsModal}
                                     closeModal={this.onCloseSettingsModal}
                                     closeOnOutsideClick={false}
                                     supplySourceNames={[displayName]}
                                     publishersSupplySourcesId={publishersSupplySourcesId}
                                     isPublisherLevel={isPublisherLevel}
                                     isRedirectedFromSupply={isRedirectedFromSupply}
                />
            : null}
        </div>);
    }
}

const mapStateToProps = ({app}) => ({
    publisher: app.publisher
});

SupplyActionsFormatter.propTypes = {
    row: PropTypes.object,
    id: PropTypes.number,
    displayName: PropTypes.string,
    publishersSupplySourcesId: PropTypes.number,
    className: PropTypes.string,
    isPublisherLevel: PropTypes.bool,
    publisher: PropTypes.object
};

export default withRouter(connect(mapStateToProps)(SupplyActionsFormatter));
