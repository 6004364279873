import {appConstants} from '../constants/appConstants';
import {accountConstants} from '../constants/accountConstants';

export const createAccount = (data) => ({
    type: accountConstants.CREATE_ACCOUNT_REQUEST,
    payload: {data}
});
export const createAccountSuccess = (data) => ({
    type: accountConstants.CREATE_ACCOUNT_SUCCESS,
    payload: {data}
});
export const createAccountFailed = (error) => ({
    type: accountConstants.CREATE_ACCOUNT_FAILURE,
    payload: {error}
});

export const loginUser = (email, password, code, keepMeSignedIn) => ({
    type: appConstants.LOGIN_REQUEST,
    payload: {email, password, code, keepMeSignedIn}
});
export const loginUserSuccess = (profileData) => ({
    type: appConstants.LOGIN_SUCCESS,
    payload: {profileData}
});
export const mfaChallenge  = ({code, token}) => ({
    type: appConstants.MFA_CHALLENGE,
    payload: {code, token}
});
export const loginUserFailed = error => ({
    type: appConstants.LOGIN_FAILURE,
    payload: {error}
});
export const logoutUser = (user) => ({
    type: appConstants.LOGOUT_USER,
    payload: {user}
});

export const resetPassword = (email, isResend) => ({
    type: appConstants.RESET_PASSWORD_REQUEST,
    payload: {email, isResend}
});
export const resetPasswordSuccess = (response, isResend) => ({
    type: appConstants.RESET_PASSWORD_SUCCESS,
    payload: {response, isResend}
});
export const resetPasswordFailed = error => ({
    type: appConstants.RESET_PASSWORD_FAILURE,
    payload: {error}
});

export const setPassword = (id, password) => ({
    type: appConstants.SET_PASSWORD_REQUEST,
    payload: {id, password}
});
export const setPasswordSuccess = response => ({
    type: appConstants.SET_PASSWORD_SUCCESS,
    payload: {response}
});
export const setPasswordFailed = error => ({
    type: appConstants.SET_PASSWORD_ERROR,
    payload: {error}
});