import React from 'react';
import {SearchPublisher, textProvider} from './imports';

const CLASS_NAMES = {
    PUBLISHER_SEARCH_HOLDER: 'publisher-search-holder centered',
    TITLE: 'title'
};

const CONSTANT_TEXTS = {
    SELECT_PUBLISHER: textProvider.getText('selectPublisher', 'pleaseSelectPublisher')
};

const SelectPublisher = () => {
    return (
        <div className={CLASS_NAMES.PUBLISHER_SEARCH_HOLDER}>
            <p className={CLASS_NAMES.TITLE}>
                {CONSTANT_TEXTS.SELECT_PUBLISHER}
            </p>
            <SearchPublisher autoFocus />
        </div>
    );
}

export default SelectPublisher;