export const permissionConstants = {
    VIEW_DASHBOARD: 'view-dashboard',
    VIEW_PLACEMENT_GROUPS: 'view-placement-groups',
    VIEW_SUPPLY_SOURCES: 'view-supply-sources',
    VIEW_ANALYTICS: 'view-analytics',
    CREATE_PLACEMENT_GROUPS: 'create-placement-groups',
    VIEW_PLACEMENT_THROTTLING: 'view-placement-throttling',
    SUPPLY_SOURCES_BLOCK_CATEGORIES: 'supply-sources-block-categories',
    SUPPLY_SOURCES_BLOCK_ADVERTISERS: 'supply-sources-block-advertisers',
    VIEW_PLACEMENT_GROUP_DESCRIPTION: 'view-placement-group-description',
    EDIT_PLACEMENT_GROUP: 'edit-placement-groups',
    DELETE_PLACEMENT_GROUPS: 'delete-placement-groups'
};
