import _ from 'lodash';

const applySort = (initialRows, sortColumn, sortDirection) => {
    const comparer = (a, b) => {
        const x = _.isString(a[sortColumn]) ? a[sortColumn].toLowerCase() : a[sortColumn];
        const y = _.isString(b[sortColumn]) ? b[sortColumn].toLowerCase() : b[sortColumn];
        if (sortDirection === "asc") {
            return x > y ? 1 : -1;
        } else if (sortDirection === "desc") {
            return x < y ? 1 : -1;
        }
    };
    return sortDirection === "none" ? initialRows : [...initialRows].sort(comparer);
}

export const filterAdSlots = (adSlots, searchTerm, customization, isInEditMode) => {
    if (!adSlots) {
        return [];
    }
    let data = adSlots;
    if (customization && customization.sort) {
        const {sort} = customization;
        data = sort.length > 0 ? applySort(data, sort[0].field, sort[0].direction) : adSlots;
    }
    if (searchTerm) {
        const lcaseTerm = searchTerm.toLowerCase();
        return data.filter(adSlot =>
            adSlot.name.toLowerCase().includes(lcaseTerm) ||
            adSlot.id === isInEditMode
        )
    }
    return data;
}