import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from '../../../CopyToClipboard';

const IdColumnFormatter = (props) => {
    const {row} = props;
    return (
        <div className='id-cell-wrapper' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CopyToClipboard copyValue={row.displayId}
                             copyTarget={props.rowIndex}
                             copyMessage='Copy ID'
                             copiedMessage='ID copied!'
                             placement='right'
                             hookId='copy-to-clipboard-id'
            />
            {row.displayId}
        </div>
    );
};

IdColumnFormatter.propTypes = {
    row: PropTypes.object,
    rowIndex: PropTypes.integer
};

export default IdColumnFormatter;
