import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {connect} from 'react-redux';

import {uploadFile, downloadFileRequest, deleteFile,
        uploadFileFailed, removeUploadErrorMessage} from './imports';
import {appStates} from './imports';
import FileUploadProgressBar from './FileUploadProgressBar';
import FileUploadOverlay from './FileUploadOverlay';
import Icon from '../../Icon';
import ConfirmationModal from '../../ConfirmationModal';
import {textProvider} from './imports';
import {fileUploadConstants} from './imports';

const CLASS_NAMES = {
    FILE_UPLOAD_WRAPPER: 'file-upload-wrapper',
    UPLOAD_BACKGROUND_BLUR: 'upload-background-blur',
    UPLOAD_ACTIONS: 'upload-actions',
    ATTACHED_FILE_CONTAINER: 'attached-file-container',
    UPLOAD_FILE_CONTAINER: 'upload-file-container',
    ATTACH_FILE_TITLE: 'attach-file-title',
    UPLOAD_ZONE: 'upload-zone',
    HOVER_ACTIONS: 'hover-actions',
    ATTACHED_FILE: 'attached-file',
    ATTACHMENT_CONTAINER: 'attachment-container',
    ATTACHED_IMAGE: 'attached-image',
    ATTACHED_FILE_NAME: 'attached-file-name',
    DRAG_FILE: 'drag-file',
    DRAG_FILE_IMAGE: 'drag-file-image',
    DRAG_FILE_ERROR: 'drag-file-error'
}

const CONSTANT_TEXTS = {
    ATTACHED_FILE: textProvider.getText('fileUpload', 'attachedFile'),
    DRAG_FILE: textProvider.getText('fileUpload', 'dragFile'),
    OR: textProvider.getText('fileUpload', 'or'),
    BROWSE: textProvider.getText('fileUpload', 'browse'),
    REPLACE_FILE_MESSAGE: textProvider.getText('fileUpload', 'replaceFileMessage'),
    KEEP_FILE: textProvider.getText('fileUpload', 'keepFile'),
    CONFIRM_REPLACE: textProvider.getText('fileUpload', 'confirmReplace'),
    ATTACHED_MANAGEMENT_FILE: textProvider.getText('fileUpload', 'attachedManagementFile'),
    HEADER_TEXT: textProvider.getText('fileUpload', 'headerText')
}

const ALLOWED_FILE_FORMATS = ['.csv', '.xls', '.xlsx'];
const MAX_SIZE_BYTES = 1048576;

class FileUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            acceptedFiles: [],
            dragEntered: false,
            openDeleteModal: false,
            openReplaceModal: false,
            deleteOrReplace: ''
        };
        this.dropzoneRef = null;
        this.uploadFile = this.uploadFile.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.fileRef = React.createRef();
        this.onChangeFileReplace = this.onChangeFileReplace.bind(this);
        this.triggerFileRef = this.triggerFileRef.bind(this);
        this.startFileDownload = this.startFileDownload.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.deleteExistingFile = this.deleteExistingFile.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onDropRejected = this.onDropRejected.bind(this);
        this.onDropAccepted = this.onDropAccepted.bind(this);
    }

    deleteFile() {
        const {publisherId, deleteFile} = this.props;
        deleteFile(publisherId);
    }

    deleteExistingFile() {
        const {deleteFile, publisherId} = this.props;
        deleteFile(publisherId);
        this.triggerFileRef();
    }

    onOpenModal(deleteOrReplace) {
        return this.setState({
            openDeleteModal: deleteOrReplace === fileUploadConstants.DELETE,
            openReplaceModal: deleteOrReplace === fileUploadConstants.REPLACE,
            deleteOrReplace
        });
    }

    onCloseModal() {
        return this.setState({
            openDeleteModal: this.state.deleteOrReplace !== fileUploadConstants.DELETE,
            openReplaceModal: this.state.deleteOrReplace !== fileUploadConstants.REPLACE
        });
    }

    onChangeFileReplace(event) {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            this.onDropAccepted([file]);
        }
    }

    triggerFileRef() {
        this.fileRef.current.click();
    }

    uploadFile(file) {
        this.props.uploadFile(file, this.props.publisherId);
    }

    onDragEnter() {
        this.setState({dragEntered: true});
    }

    onDragLeave() {
        this.setState({dragEntered: false});
    }

    downloadFileFromBuffer(content, name) {
        const base64data = Buffer.from(content, 'binary');
        const url = window.URL.createObjectURL(new Blob([base64data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    startFileDownload(){
        this.props.downloadFileRequest(this.props.publisherId);
    }

    componentDidUpdate(prevProps) {
        const prevDownload = prevProps.fileObject && prevProps.fileObject.data;
        if (prevDownload) {
            return;
        }

        const {fileObject} = this.props;
        const fileBuffer = fileObject && fileObject.data && fileObject.data.data;
        if (fileBuffer) {
            const {name} = fileObject;
            this.downloadFileFromBuffer(fileBuffer, name);
        }
    }

    onDropRejected(files) {
        if(files[0].size > MAX_SIZE_BYTES) {
            this.props.uploadFileFailed('File is bigger than 1MB');
            this.removeErrorMessage();
        }
        else {
            this.props.uploadFileFailed('File is not supported');
            this.removeErrorMessage();
        }
    }

    removeErrorMessage() {
        setTimeout(() => {
            this.props.removeUploadErrorMessage();
        }, 3000);
    }

    onDropAccepted(files) {
        const {fileObject} = this.props;
        if (!(fileObject && fileObject.name)) {
            this.uploadFile(files[0]);
            this.setState({
                dragEntered: false
            });
        }
    }

    render() {
        const {onDragEnter, onDragLeave, onDropRejected, onDropAccepted} = this;
        const {fileObject} = this.props;
        const uploadState = fileObject ? fileObject.state : '';
        const percentageInfo = fileObject ? fileObject.progress : undefined;
        const acceptedFile = fileObject && fileObject.name ? fileObject.name : '';
        const isRejected = fileObject && fileObject.message;
        const transitiveStates = [appStates.LOADING, appStates.DOWNLOADING];
        const currentLoadingState = transitiveStates.includes(uploadState) ? uploadState : undefined;

        return (<div className={CLASS_NAMES.FILE_UPLOAD_WRAPPER}>
            {this.state.deleteOrReplace === fileUploadConstants.REPLACE
            ? (<ConfirmationModal openModal={this.state.openReplaceModal}
                                 closeModal={this.onCloseModal}
                                 onConfirm={this.deleteExistingFile}
                                 confirmationMessage={CONSTANT_TEXTS.REPLACE_FILE_MESSAGE}
                                 cancelButtonText={CONSTANT_TEXTS.KEEP_FILE}
                                 confirmButtonText={CONSTANT_TEXTS.CONFIRM_REPLACE}
                                 headerText={CONSTANT_TEXTS.HEADER_TEXT}
            />)
            : (<ConfirmationModal openModal={this.state.openDeleteModal}
                                 closeModal={this.onCloseModal}
                                 onConfirm={this.deleteFile}
            />)}
            <Dropzone onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    multiple={false}
                    maxSize={MAX_SIZE_BYTES}
                    accept={ALLOWED_FILE_FORMATS}
                    onDropRejected={onDropRejected}
                    onDropAccepted={onDropAccepted}
            >
                {({getRootProps, getInputProps}) => (
                    <div className={CLASS_NAMES.UPLOAD_FILE_CONTAINER}>
                        <span className={CLASS_NAMES.ATTACH_FILE_TITLE}>{CONSTANT_TEXTS.ATTACHED_MANAGEMENT_FILE}</span>
                        {transitiveStates.includes(uploadState)
                            ? (<FileUploadProgressBar percent={percentageInfo} type={currentLoadingState}/>)
                            : (<section className={cx(CLASS_NAMES.UPLOAD_ZONE, CLASS_NAMES.HOVER_ACTIONS)}>
                                <div className={acceptedFile ? CLASS_NAMES.ATTACHED_FILE_CONTAINER : null} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div>
                                        <input type="file"
                                               ref={this.fileRef}
                                               onChange={(e) => this.onChangeFileReplace(e)}
                                               style={{display: 'none'}}
                                        />
                                        {acceptedFile ? (<div className={CLASS_NAMES.ATTACHED_FILE}>
                                            <p className={CLASS_NAMES.ATTACHMENT_CONTAINER}>
                                                <span className={CLASS_NAMES.ATTACHED_IMAGE}>
                                                    <Icon icon="attach.svg"></Icon>
                                                </span>
                                                {CONSTANT_TEXTS.ATTACHED_FILE} {' '}
                                                <span className={CLASS_NAMES.ATTACHED_FILE_NAME} title={acceptedFile}>
                                                    {acceptedFile}
                                                </span>
                                            </p>
                                        </div>)
                                        : isRejected ? (<div className={CLASS_NAMES.DRAG_FILE}>
                                                <p className={CLASS_NAMES.DRAG_FILE_ERROR}>
                                                    <span className={CLASS_NAMES.DRAG_FILE_IMAGE}>
                                                        <Icon icon="error_exclamation" hookId="upload-file-error-icon"/>
                                                    </span>
                                                    {fileObject.message}
                                                </p>
                                            </div>)
                                            : (<div className={CLASS_NAMES.DRAG_FILE}>
                                                <p>
                                                    <span className={CLASS_NAMES.DRAG_FILE_IMAGE}>
                                                        <Icon icon='upload' hookId='file-upload-icon' />
                                                    </span>
                                                    {CONSTANT_TEXTS.DRAG_FILE} {CONSTANT_TEXTS.OR} <a>{CONSTANT_TEXTS.BROWSE}</a>
                                                </p>
                                            </div>)}
                                    </div>
                                </div>
                                {acceptedFile
                                    ? (<div className={CLASS_NAMES.UPLOAD_ACTIONS}>
                                        <FileUploadOverlay triggerFileRef={this.triggerFileRef}
                                                       publisherId={this.props.publisherId}
                                                       startDownload={this.startFileDownload}
                                                       onOpenModal={this.onOpenModal}
                                         />
                                    </div>)
                                : null}
                        </section>)}
                    </div>
                )}
            </Dropzone>
        </div>);
    }
}

FileUpload.propTypes = {
    publisherId: PropTypes.number,
    uploadFile: PropTypes.func,
    acceptedFile: PropTypes.string,
    uploadFileOnLocalServer: PropTypes.func,
    isLoading: PropTypes.bool,
    fileObject: PropTypes.object,
    downloadFileRequest: PropTypes.func,
    deleteFile: PropTypes.func,
    uploadFileFailed: PropTypes.func,
    removeUploadErrorMessage: PropTypes.func
};

const mapStateToProps = ({placements}) => ({
    fileObject: placements.file
});

const mapDispatchToProps = ({
    uploadFile,
    uploadFileFailed,
    downloadFileRequest,
    deleteFile,
    removeUploadErrorMessage
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);