import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import PlacementsStatusToggle from './PlacementsStatusToggle';
import {Button, Modal} from '@perion-undertone/ut-react-common';
import {selectFeatureFlag} from '@flopflip/react-redux';
import cx from 'classnames';
import TooltipWithScrollbar from '../../TooltipWithScrollbar';
import Icon from '../../Icon';
import get from "lodash/get"

import CopyToClipboard from '../../CopyToClipboard';

import {closeConfirmationModal, setPlacementGroupStatus} from './imports';
import {textProvider} from './imports';
import {statusConstants} from './imports';

const CLASS_NAMES = {
    UT_MODAL_INNER:'ut-modal-inner',
    PLACEMENT_CONFIRMATION_MODAL:'placement-confirmation-modal',
    PLACEMENT_CONFIRMATION_MODAL_CONTAINER:'placement-confirmation-modal-container',
    PLACEMENT_CONFIRMATION_MODAL_HEADER: 'placement-confirmation-modal-header',
    PLACEMENT_CONFIRMATION_MODAL_IMAGE: 'placement-confirmation-modal-image',
    PLACEMENT_CONFIRMATION_MODAL_TITLE: 'placement-confirmation-modal-title',
    PLACEMENT_CONFIRMATION_MODAL_SUBTITLE: 'placement-confirmation-modal-subtitle',
    PLACEMENT_CONFIRMATION_MODAL_CONTENT: 'placement-confirmation-modal-content',
    PLACEMENT_CONFIRMATION_MODAL_COPY_CONTENT: 'placement-confirmation-modal-copy-content',
    PLACEMENT_CONFIRMATION_MODAL_WRAPPER: 'placement-confirmation-modal-wrapper',
    LABEL: 'label',
    CONTENT: 'content',
    PLACEMENT_CONFIRMATION_MODAL_STATUS: 'placement-confirmation-modal-status',
    PLACEMENT_CONFIRMATION_MODAL_STATUS_ACTIVE: 'placement-confirmation-modal-status--active',
    PLACEMENT_CONFIRMATION_MODAL_TOGGLE: 'placement-confirmation-modal-toggle',
    PLACEMENT_CONFIRMATION_MODAL_ID: 'placement-confirmation-modal-id',
    PLACEMENT_CONFIRMATION_MODAL_TAG:'placement-confirmation-modal-tag',
    PLACEMENT_CONFIRMATION_MODAL_ADUNITS:'placement-confirmation-modal-adUnits',
    PLACEMENT_CONFIRMATION_MODAL_FOOTER: 'placement-confirmation-modal-footer'
}

const CONSTANT_TEXTS = {
    SUCCESS: textProvider.getText('common', 'success'),
    SUCCESSFULLY: textProvider.getText('confirmationModal', 'successfully'),
    TAGS: textProvider.getText('confirmationModal', 'tags'),
    STATUS: textProvider.getText('confirmationModal', 'status'),
    ID: textProvider.getText('confirmationModal', 'id'),
    AD_UNITS: textProvider.getText('confirmationModal', 'adUnits'),
    AD_SLOTS: textProvider.getText('confirmationModal', 'adSlots'),
    TAG_CODE: textProvider.getText('confirmationModal', 'tagCode'),
    PLACEMENT_GROUP: textProvider.getText('adUnits', 'placementGroup'),
    CLOSE: textProvider.getText('confirmationModal', 'close'),
    ACTIVE: textProvider.getText('confirmationModal', 'active'),
    INACTIVE: textProvider.getText('confirmationModal', 'inactive'),
}
class PlacementConfirmationModal extends Component {
    constructor(props) {
        super(props);

        this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
        this.onPlacementGroupStatusChange = this.onPlacementGroupStatusChange.bind(this);
    }

    closeConfirmationModal() {
        this.props.closeConfirmationModal();
    }

    onPlacementGroupStatusChange(event, placementGroupId, isChecked) {
        const {setPlacementGroupStatus, publisherId, placementsPagination: {limit, page}, searchTerm, customization} = this.props;
        const sort = get(customization, 'sort') || [];
        setPlacementGroupStatus(publisherId, placementGroupId, isChecked, limit, page, searchTerm, sort);
    }

    render() {

        const {activeGroup, isPlacementGroupTagTypesEnabled} = this.props;

        if (!activeGroup) {
            return null;
        }

        const tagCode = "";
        const modalTitleSuffix = activeGroup.isUpdated ? 'updated' : 'created';

        const isOn = activeGroup.status === statusConstants.ACTIVE ? true : false;

        const adSlotsTooltipTrigger = <div className='more-content-trigger' >{`+${activeGroup.adSlots.length-1} more`}</div>
        const adUnitsTooltipTrigger = <div className='more-content-trigger' >{`+${activeGroup.adUnits.length-1} more`}</div>

        const adSlotsTooltipContent = activeGroup.adSlots.slice(1).map((adSlot, index) =>
            <div key={index} className='placement-confirmation-modal-tooltip-item'>{adSlot.name}</div>);
        const adUnitsTooltipContent = activeGroup.adUnits && activeGroup.adUnits.slice(1).map((adUnit, index) =>
            <div key={index} className='placement-confirmation-modal-tooltip-item'>{adUnit.name}</div>);

        return (
            <div>
                <Modal open={!!activeGroup}
                       className ={cx(CLASS_NAMES.UT_MODAL_INNER, CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL)}
                       onModalClose={this.closeConfirmationModal}
                       hookId="placement-confirmation-modal"
                >
                <div>
                    <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_CONTAINER}>
                        <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_HEADER}>
                            <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_IMAGE}>
                                <Icon icon="success-conffeti.svg" />
                            </div>
                            <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_TITLE}>
                                {CONSTANT_TEXTS.SUCCESS}
                            </div>
                            <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_SUBTITLE}>
                                {CONSTANT_TEXTS.SUCCESSFULLY} {modalTitleSuffix} {CONSTANT_TEXTS.PLACEMENT_GROUP}
                            </div>
                        </div>

                        <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_WRAPPER}>

                            {isPlacementGroupTagTypesEnabled &&
                                <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_COPY_CONTENT}>
                                    <div className={CLASS_NAMES.LABEL}>{CONSTANT_TEXTS.TAGS}</div>
                                    <div className={cx(CLASS_NAMES.CONTENT, 'content-tag')}>
                                        <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_ID}>
                                            {activeGroup.placementTagCode}
                                        </div>
                                        <CopyToClipboard copyValue={activeGroup.placementTagCode}
                                                         copyTarget={'Copied tag'}
                                                         hookId='copy-to-clipboard-placement-group-tag'
                                        />
                                    </div>
                                </div>
                            }


                            <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_COPY_CONTENT}>
                                <div className={CLASS_NAMES.LABEL}>{CONSTANT_TEXTS.ID}</div>
                                <div className={cx(CLASS_NAMES.CONTENT, 'content-placement-group-id')}>
                                    <span className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_ID}>{activeGroup.displayId}</span>
                                    <CopyToClipboard copyValue={activeGroup.displayId}
                                                     copyTarget={'Copied id'}
                                                     hookId='copy-to-clipboard-placement-group-id'
                                    />
                                </div>
                            </div>

                            <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_CONTENT}>
                                <span className={CLASS_NAMES.LABEL}>{CONSTANT_TEXTS.STATUS}</span>
                                <span className={CLASS_NAMES.CONTENT}>
                                    <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_STATUS}>
                                        {CONSTANT_TEXTS.INACTIVE}
                                    </div>
                                    <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_TOGGLE}>
                                        <PlacementsStatusToggle placementGroupId={activeGroup.placementGroupId}
                                                                isOn={isOn} onStatusChange={this.onPlacementGroupStatusChange}
                                                                disabled={false} />
                                    </div>
                                    <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_STATUS_ACTIVE}>
                                        {CONSTANT_TEXTS.ACTIVE}
                                    </div>
                                </span>
                            </div>

                            <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_CONTENT}>
                                <span className={CLASS_NAMES.LABEL}>{CONSTANT_TEXTS.AD_UNITS}</span>
                                <span  className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_ADUNITS}>
                                    <div>{activeGroup.adUnits && activeGroup.adUnits.length > 0 ? activeGroup.adUnits[0].name : ''}</div>
                                    {activeGroup.adUnits && activeGroup.adUnits.length > 1 &&
                                        <TooltipWithScrollbar trigger={adUnitsTooltipTrigger} contents={adUnitsTooltipContent} />
                                    }
                                </span>
                            </div>
                            <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_CONTENT}>
                                <div className={CLASS_NAMES.LABEL}>
                                    {activeGroup.adSlots && activeGroup.adSlots.length === 0 ? '' : CONSTANT_TEXTS.AD_SLOTS}
                                </div>
                                <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_ADUNITS}>
                                    <div>{activeGroup.adSlots && activeGroup.adSlots.length > 0 ? activeGroup.adSlots[0].name : ''}</div>
                                    {activeGroup.adSlots && activeGroup.adSlots.length > 1 &&
                                        <TooltipWithScrollbar trigger={adSlotsTooltipTrigger} contents={adSlotsTooltipContent} />
                                    }
                                </div>
                            </div>

                            {tagCode && <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_CONTENT}>
                                <span className={CLASS_NAMES.LABEL}>{CONSTANT_TEXTS.TAG_CODE}</span>
                                <span className={CLASS_NAMES.CONTENT}>
                                    <span className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_TAG}>{tagCode}</span>
                                    <CopyToClipboard copyValue={tagCode} copyTarget={'Copied tag'}/>
                                </span>
                            </div>}
                        </div>

                        <div className={CLASS_NAMES.PLACEMENT_CONFIRMATION_MODAL_FOOTER}>
                            <Button onClick={this.closeConfirmationModal}
                                    hookId='confirmation-close-button'
                            >
                                {CONSTANT_TEXTS.CLOSE}
                            </Button>
                        </div>
                    </div>
                </div>
                </Modal>
            </div>
        );
    }
}

PlacementConfirmationModal.propTypes = {
    activeGroup: PropTypes.object,
    publisherId: PropTypes.number,
    closeConfirmationModal: PropTypes.func,
    setPlacementGroupStatus: PropTypes.func,
    isPlacementGroupTagTypesEnabled: PropTypes.bool,
    placementsPagination: PropTypes.object,
    searchTerm: PropTypes.string,
    customization: PropTypes.object,
};

const mapStateToProps = (state) => ({
    activeGroup: state.placements.activeGroup,
    isPlacementGroupTagTypesEnabled: selectFeatureFlag('placementGroupTagTypes')(state),
    placementsPagination: state.placements.placementsPagination,
    searchTerm: state.placements.searchTerm,
    customization: state.grid.customization,
})

const mapDispatchToProps = ({
    closeConfirmationModal,
    setPlacementGroupStatus
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlacementConfirmationModal));