export const API_REQUEST = 'API_REQUEST';

export const REQUEST_STARTED = '@@Requests/REQUEST_STARTED';
export const REQUEST_SUCCEED = '@@Requests/REQUEST_SUCCEED';
export const REQUEST_FAILED = '@@Requests/REQUEST_FAILED';

export const startRequest = (requestName, requestId, request) => {
    return {
        type: REQUEST_STARTED,
        payload: {requestName, requestId, request}
    };
};

export const requestFailed = (requestName, requestId, err) => {
    return {
        type: REQUEST_FAILED,
        payload: {requestName, requestId, err}
    };
};

export const requestSucceed = (requestName, requestId) => {
    return {
        type: REQUEST_SUCCEED,
        payload: {requestName, requestId}
    };
};