import OnboardStep_1_1 from "./OnboardingStep_1_1";
import OnboardStep_2_1 from "./OnboardingStep_2_1";
import OnboardStep_3_1 from "./OnboardingStep_3_1";
import OnboardStep_3_2 from "./OnboardingStep_3_2";
import OnboardStep_3_3 from "./OnboardingStep_3_3";
import OnboardStep_3_4 from "./OnboardingStep_3_4";
import OnboardStep_4_1 from "./OnboardingStep_4_1";
import {onboardingConstants} from '../../../../constants/onboardingConstants';

const mapSteps = {
    '1_1': OnboardStep_1_1,
    '2_1': OnboardStep_2_1,
    '3_1': OnboardStep_3_1,
    '3_2': OnboardStep_3_2,
    '3_3': OnboardStep_3_3,
    '3_4': OnboardStep_3_4,
    '4_1': OnboardStep_4_1
};

function getOnboardComponentStep (step) {
    return mapSteps[step];
}


function getHeaderStepClass (activeStep, stepToCheck) {
    const activeStepObj = {
        step: parseInt(activeStep.split("_")[0]),
        subStep: parseInt(activeStep.split("_")[1]),
        str: activeStep
    };
    const stepToCheckObj = {
        step: parseInt(stepToCheck.split("_")[0]),
        subStep: parseInt(stepToCheck.split("_")[1]),
        str: stepToCheck
    };
    let resClass = 'step';
    if (stepToCheckObj.step < activeStepObj.step) {
        resClass += " active";
    } else if (activeStepObj.step === stepToCheckObj.step) {
        if (onboardingConstants.isErrorStep(activeStepObj.str)) {
            resClass += " error";
        } else {
            resClass += " active";
        }
    }
    return resClass;
}

export default {
    getOnboardComponentStep,
    getHeaderStepClass
}