import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {textProvider, advertiserUidGenerator} from '../imports';
import {blockAdvertisers} from '../actionImports';

const CLASS_NAMES = {
    UNBLOCK_ADVERTISER: 'unblock-advertiser'
}

const CONSTANT_TEXTS = {
    UNBLOCK: textProvider.getText('supplySourceSettingsModal', 'unblock'),
}
class UnblockAdvertiserFormatter extends Component {

    handleUnblock = () => {
        const {blockAdvertisers, publisher, row, dependentValues} = this.props;
        const isPublisherLevel = dependentValues.columnInfo.isPublisherLevel;
        const uid = advertiserUidGenerator(row.publishersSupplySourcesId === 0, publisher.id, row.publishersSupplySourcesId, row.advertiserId);

        const advertiserToUnblock = [{
            publisherId: publisher.id,
            publishersSupplySourcesId: row.publishersSupplySourcesId,
            advertiserId: row.advertiserId,
            isBlocked: false,
            uid
        }];

        blockAdvertisers(publisher.id, advertiserToUnblock, isPublisherLevel);
    }

    handleBulkUnblock = () => {
        const {publisher, row, dependentValues, blockAdvertisers} = this.props;
        const publishersSupplySourcesIds = dependentValues.columnInfo.publishersSupplySourcesIds;

        const bulkAdvertisersToUnblock = publishersSupplySourcesIds.map(publishersSupplySourcesId => {
            const uid = advertiserUidGenerator(publishersSupplySourcesId === 0, publisher.id, publishersSupplySourcesId, row.advertiserId);
            return {
                publisherId: publisher.id,
                publishersSupplySourcesId,
                advertiserId: row.advertiserId,
                isBlocked: false,
                uid
            };
        });

        blockAdvertisers(publisher.id, bulkAdvertisersToUnblock);
    }

    render() {
        const {dependentValues} = this.props;
        const isPublisherLevel = dependentValues.columnInfo.isPublisherLevel;
        const isBulkBlock = dependentValues.columnInfo.isBulkBlock;
        const unblockAdvertiser = (
            <div className={CLASS_NAMES.UNBLOCK_ADVERTISER}
                 onClick={isBulkBlock ? this.handleBulkUnblock : this.handleUnblock}
            >
                {CONSTANT_TEXTS.UNBLOCK}
            </div>
        );
        return (<div>
            {isPublisherLevel ?
                unblockAdvertiser:
                (this.props.row.publishersSupplySourcesId !== 0 && unblockAdvertiser)}
        </div>);
    }
}

UnblockAdvertiserFormatter.propTypes = {
    blockAdvertisers: PropTypes.func,
    publisher: PropTypes.object,
    row: PropTypes.object,
    dependentValues: PropTypes.object
};

const mapStateToProps = ({app}) => ({
    publisher: app.publisher.data
});

const mapDispatchToProps = ({
    blockAdvertisers
});

export default connect(mapStateToProps, mapDispatchToProps)(UnblockAdvertiserFormatter);