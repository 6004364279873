import React, {useRef} from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {connect} from 'react-redux';
import {Button, Tooltip} from '@perion-undertone/ut-react-common';
import {importFile, importFileFailed, removeUploadErrorMessage, textProvider, appStates} from './imports';
import FileUploadProgressBar from './FileUploadProgressBar';

const CONSTANT_TEXTS = {
    IMPORT_BTN: textProvider.getText('adSlots', 'import'),
    IMPORT_MSG: textProvider.getText('adSlots', 'importMsg')
}
const CLASS_NAMES = {
    IMPORT_FILE_CONTAINER: 'placement-ad-slots-import-container',
    AD_SLOTS_IMPORT: 'placement-ad-slots-import',
    UPLOAD_ZONE: 'upload-zone',
    ATTACHMENT_CONTAINER: 'attachment-container',
    ATTACHED_IMAGE: 'attached-image',
    ATTACHED_FILE_NAME: 'attached-file-name',
    DRAG_FILE: 'import-file-wrapper',
    DRAG_FILE_IMAGE: 'drag-file-image',
    IMPORT_FILE_ERROR: 'import-file-error',
    IMPORT_TXT: 'import-txt'
};

const ALLOWED_FILE_FORMATS = ['.txt', '.csv', '.xls', '.xlsx'];

const ImportFile = (props) => {
    const {fileObject} = props;
    const uploadState = fileObject ? fileObject.state : '';
    const percentageInfo = fileObject ? fileObject.progress : undefined;
    const transitiveStates = [appStates.LOADING, appStates.DOWNLOADING];
    const currentLoadingState = transitiveStates.includes(uploadState) ? uploadState : undefined;

    const fileRef = useRef();

    const onChangeFileReplace = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        if (file) {
            this.onDropAccepted([file]);
        }
    };

    const uploadFile = (file) => {
        props.importFile(file, props.publisherId);
    };

    const onDropRejected = () => {
        props.importFileFailed('File is not supported');
        removeErrorMessage();
    };

    const removeErrorMessage = () => {
        setTimeout(() => {
            props.removeUploadErrorMessage();
        }, 3000);
    };

    const onDropAccepted = (files) => {
        if (!(fileObject && fileObject.name)) {
            uploadFile(files[0]);
        }
    };

    return (<div className={CLASS_NAMES.IMPORT_FILE_CONTAINER}>
        {props.err && 
        <div className={CLASS_NAMES.IMPORT_FILE_ERROR}>
            {props.err}
        </div>}
        <Dropzone multiple={false}
                  accept={ALLOWED_FILE_FORMATS}
                  onDropRejected={onDropRejected}
                  onDropAccepted={onDropAccepted}
        >
            {({getRootProps, getInputProps}) => (
                <div className={CLASS_NAMES.AD_SLOTS_IMPORT}>
                    {transitiveStates.includes(uploadState)
                        ? (<FileUploadProgressBar percent={percentageInfo} type={currentLoadingState}/>)
                        : (<section className={cx(CLASS_NAMES.UPLOAD_ZONE)}>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div>
                                    <input type="file"
                                            ref={fileRef}
                                            onChange={onChangeFileReplace}
                                            style={{display: 'none'}}
                                    />
                                    <div className={CLASS_NAMES.DRAG_FILE}>
                                        <Tooltip
                                            appendToBody
                                            placement='top'
                                            hookId="ad-slots-import-tooltip-message"
                                            trigger={<div>
                                                <Button 
                                                className="adslots-action-btn"
                                                hookId="ad-slots-action-btn"
                                                >
                                                    {CONSTANT_TEXTS.IMPORT_BTN}
                                                </Button>
                                            </div>}
                                        >
                                            {CONSTANT_TEXTS.IMPORT_MSG}
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                    </section>)}
                </div>
            )}
        </Dropzone>
    </div>);
};

ImportFile.propTypes = {
    className: PropTypes.string,
    publisherId: PropTypes.number,
    importFile: PropTypes.func,
    acceptedFile: PropTypes.string,
    fileObject: PropTypes.object,
    importFileFailed: PropTypes.func,
    removeUploadErrorMessage: PropTypes.func,
    err: PropTypes.string,
};

const mapStateToProps = ({placements}) => ({
    fileObject: placements.file
});

const mapDispatchToProps = ({
    importFile,
    importFileFailed,
    removeUploadErrorMessage
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportFile);