import {of} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {publisherConstants} from "../constants/publisherConstants"
import {updatePublisherSuccess, updatePublisherFailure} from "../actions/publisherActions";

export const updatePublisherEpic = actions$ => {
    return actions$.pipe(
        ofType(publisherConstants.UPDATE_PUBLISHER_REQUEST),
        mergeMap(action => {
            const {publisherId, updateOptions} = action.payload;

            return ajax.put(`/api/publisher/${publisherId}`, updateOptions, {'Content-Type': 'application/json'}).pipe(
                map(response => updatePublisherSuccess(response.response)),
                catchError(error => of(updatePublisherFailure(error)))
            )
        })
    )
}