import memoize from 'lodash/memoize';
import {NEW_REPORTS} from './imports';

const getActiveReportsList = memoize((data, flags) => {
    const newReports = [];
    const predefinedReports = [];

    const hbPerformanceReport = data.find(row => row.id === NEW_REPORTS.HB_PERFORMANCE);
    const invoiceReport = data.find(row => row.id === NEW_REPORTS.INVOICE);
    const revenueReport = data.find(row => row.id === NEW_REPORTS.REVENUE);
    const placementReport = data.find(row => row.id === NEW_REPORTS.PLACEMENT);
    const domainRevenueReport = data.find(row => row.id === NEW_REPORTS.DOMAIN_REVENUE);
    const purchaseOrderReport = data.find(row => row.id === NEW_REPORTS.PURCHASE_ORDER);
    const purchaseOrderDailyTrendsReport = data.find(row => row.id === NEW_REPORTS.PURCHASE_ORDER_DAILY_TRENDS);

    if (hbPerformanceReport && flags.isHbPerformanceReportEnabled) {
        newReports.push(hbPerformanceReport);
    }
    if (invoiceReport && flags.isInvoiceReportEnabled) {
        newReports.push(invoiceReport);
    }
    if (revenueReport && flags.isRevenueReportEnabled) {
        newReports.push(revenueReport);
    }
    if (placementReport && flags.isPlacementReportEnabled) {
        newReports.push(placementReport);
    }
    if (domainRevenueReport && flags.isDomainRevenueReportEnabled) {
        predefinedReports.push(domainRevenueReport);
    }
    if (purchaseOrderReport && flags.isPurchaseOrderReportEnabled) {
        predefinedReports.push(purchaseOrderReport);
    }
    if (purchaseOrderDailyTrendsReport && flags.isPurchaseOrderDailyTrendsReportEnabled) {
        predefinedReports.push(purchaseOrderDailyTrendsReport);
    }

    return {
        new: newReports,
        predefined: predefinedReports
    };
});

export default getActiveReportsList;
