import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Checkbox} from '@perion-undertone/ut-react-common';
import {setSelectedPlacementGroupsIds} from '../imports';

const CLASS_NAMES = {
    REACT_GRID_CHECKBOX_CONTAINER: 'react-grid-checkbox-container',
    REACT_GRID_CHECKBOX_CONTAINER_VISIBLE: 'react-grid-checkbox-container-visible',
};


class PlacementGroupHeaderCheckbox extends Component {
    onDeselectAllPlacementGroups = () => {
        this.props.setSelectedPlacementGroupsIds(null);
    }
    render() {
        const {selectedGroupsIds} = this.props;
        return (<div className={CLASS_NAMES.REACT_GRID_CHECKBOX_CONTAINER}>
            <Checkbox squared
                      className={cx({[CLASS_NAMES.REACT_GRID_CHECKBOX_CONTAINER_VISIBLE]: selectedGroupsIds.length > 0})}
                      checked={selectedGroupsIds.length > 0}
                      onChange={this.onDeselectAllPlacementGroups}
                      hookId='select-all-placement-groups-checkbox'
                />
        </div>);
    }
}

PlacementGroupHeaderCheckbox.propTypes = {
    setSelectedPlacementGroupsIds: PropTypes.func,
    selectedGroupsIds: PropTypes.array
};


const mapStateToProps = (state) => ({
    selectedGroupsIds: state.placements.selectedGroupsIds
});

const mapDispatchToProps = ({
    setSelectedPlacementGroupsIds
});

export default connect(mapStateToProps, mapDispatchToProps)(PlacementGroupHeaderCheckbox);
