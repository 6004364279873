import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';

import Icon from '../../Icon';
import {Dropdown, RangeDatePicker, Popover, Button} from '@perion-undertone/ut-react-common';
import {calendarPresets, isPresetOptionDisabled} from '../../calendarPresets';
import {loadFromLocalStorage, textProvider} from './imports';

const CONSTANT_TEXTS = {
    DROPDOWN_YESTERDAY: textProvider.getText('dropdownOptions', 'yesterday'),
    DROPDOWN_LAST_WEEK: textProvider.getText('dropdownOptions', 'lastWeek'),
    DROPDOWN_CURRENT_WEEK: textProvider.getText('dropdownOptions', 'currentWeek'),
    DROPDOWN_LAST_MONTH: textProvider.getText('dropdownOptions', 'lastMonth'),
    DROPDOWN_CURRENT_MONTH: textProvider.getText('dropdownOptions', 'currentMonth'),
    DROPDOWN_CUSTOM: textProvider.getText('dropdownOptions', 'custom'),
    APPLY: textProvider.getText('confirmationModal', 'apply'),
    CANCEL: textProvider.getText('confirmationModal', 'cancel'),
};

const CLASS_NAMES = {
    DROPDOWN: 'period-dropdown-select',
    DROPDOWN_WRAPPER: 'period-dropdown-select-wrapper',
    DROPDOWN_DIVIDER: 'period-dropdown-select-divider',
    DROPDOWN_POPOVER: 'period-dropdown-popover',
    DROPDOWN_POPOVER_HEADER: 'period-dropdown-popover-header',
    DROPDOWN_POPOVER_FOOTER: 'period-dropdown-popover-footer',
    DROPDOWN_POPOVER_FOOTER_BTN: 'period-dropdown-popover-footer-btn',
    DROPDOWN_POPOVER_FOOTER_BTN_APPLY: 'period-dropdown-popover-footer-btn-apply',
};

const DATE_FORMAT = 'MM/DD/YYYY';
const DATA_AVAILABLE_SINCE = moment().subtract(15, 'months');

class PeriodDropdownSelect extends Component {
    constructor(props) {
        super(props);
        const {from, to, type} = this.props.period;
        this.state = {
            periodFrom: from,
            periodTo: to,
            activeItem: type,
            isDatePickerOpen: false,
            isDropdownOpen: false
        }
    }

    componentDidMount() {
        const lsPeriod = JSON.parse(loadFromLocalStorage('defaultWidgetPeriod'));
        const defaultPeriod = this.checkLs(lsPeriod);
        if (defaultPeriod) {
            const {from, to, type} = lsPeriod;
            const periodFrom = this.convertToUtcDate(from);
            const periodTo = this.convertToUtcDate(to);
            this.setState({
                activeItem: type,
                periodFrom,
                periodTo
            });
            this.props.setPeriod({from: periodFrom, to: periodTo, type});
        }
    }

    componentDidUpdate(prevProps) {
        const {period} = this.props;
        if (prevProps.period && prevProps.period !== period) {
            this.setState({periodFrom: period.from, periodTo: period.to, activeItem: period.type});
        }
    }

    checkLs = lsPeriod => lsPeriod && lsPeriod.type !== 'custom';

    convertToUtcDate = (date) => {
        const timestampFrom = new Date(date);
        return moment.utc(timestampFrom);
    }

    getDisplayText = () => {
        const {period: {from, to}} = this.props;
        const {activeItem} = this.state;
        const activePreset = calendarPresets.find(preset => preset.period === activeItem);
        const periodFrom = activeItem === (CONSTANT_TEXTS.DROPDOWN_CUSTOM).toLowerCase() ? from : activePreset.startDate;
        const periodTo = activeItem === (CONSTANT_TEXTS.DROPDOWN_CUSTOM).toLowerCase() ? to: activePreset.endDate;
        return `${periodFrom.format(DATE_FORMAT)} - ${periodTo.format(DATE_FORMAT)}`;
    }

    isValidDate = (date) => date.isBefore(moment()) && date.isAfter(moment(DATA_AVAILABLE_SINCE, DATE_FORMAT));

    formatPeriod = (period) => moment(period).format(DATE_FORMAT);

    headerRenderer = (calendarProps) => {
        const from = this.formatPeriod(calendarProps.selectedDate[0]);
        const to = calendarProps.selectedDate[1] ? this.formatPeriod(calendarProps.selectedDate[1]) : '';
        return (<div className={CLASS_NAMES.DROPDOWN_POPOVER_HEADER}>
            {`${from} - ${to}`}
        </div>);
    }

    onDatePickerChange = (name, values) => {
        this.setState({periodFrom: values[0], periodTo: values[1]});
    }

    onDatePickerCancel = () => {
        const {period: {from, to}} = this.props;
        this.setState({isDatePickerOpen: false, periodFrom: from, periodTo: to});
    }

    onDatePickerApply = () => {
        const {periodFrom, periodTo} = this.state;
        this.props.onPeriodChange(periodFrom, periodTo);
        this.setState({isDatePickerOpen: false, isDropdownOpen: false});
    }

    onDatePickerOpen = () => {
        if(this.props.onDatePickerOpen){
            this.props.onDatePickerOpen(this.state.isDatePickerOpen)
        }
        this.setState({isDatePickerOpen: true});
    }

    renderCustomPeriodMenuItem = () => {
        return (<Dropdown.Item eventKey={6}
                               value='custom'
                               hookId="period-dropdown-select-custom"
        >
            <Icon icon='pagging_arrow_previous.svg' />
            {CONSTANT_TEXTS.DROPDOWN_CUSTOM}
        </Dropdown.Item>)
    }

    toggleMenuVisibility = () => this.setState({isDropdownOpen: !this.state.isDropdownOpen});

    hideMenu = () => this.setState({isDropdownOpen: false});

    shouldDisableNavigation = (viewDate, direction) => {
        if (direction === 'prev') {
            return viewDate.isBefore(moment(DATA_AVAILABLE_SINCE, DATE_FORMAT).add(2, "month"));
        }
        if (direction === 'next') {
            return viewDate.isAfter(moment().utc().startOf('month').subtract(1, "month"));
        }
        return false;
    };

    render() {
        const {onSelectPredefinedItem} = this.props;
        const {periodFrom, periodTo, isDropdownOpen} = this.state;
        const isCurrWeekDisabled = isPresetOptionDisabled(CONSTANT_TEXTS.DROPDOWN_CURRENT_WEEK);
        const isCurrMonthDisabled = isPresetOptionDisabled(CONSTANT_TEXTS.DROPDOWN_CURRENT_MONTH);

        return (<div className={CLASS_NAMES.DROPDOWN}>
            <Dropdown text={this.getDisplayText()}
                      open={isDropdownOpen}
                      toggleMenuVisibility={this.toggleMenuVisibility}
                      hideMenu={this.hideMenu}
                      onSelect={onSelectPredefinedItem}
                      appendToBody={false}
                      closeMenuOnOutsideClick={true}
                      onOutsideClick={this.onDatePickerCancel}
                      closeMenuOnSelect={true}
                      className={CLASS_NAMES.DROPDOWN_WRAPPER}
                      hookId="period-dropdown-select-menu"
            >
                <Dropdown.Item eventKey={0}
                               value='yesterday'
                               hookId="period-dropdown-select-yesterday"
                >
                    {CONSTANT_TEXTS.DROPDOWN_YESTERDAY}
                </Dropdown.Item>
                <Dropdown.Item eventKey={1}
                               value='last-week'
                               hookId="period-dropdown-select-last-week"
                >
                    {CONSTANT_TEXTS.DROPDOWN_LAST_WEEK}
                </Dropdown.Item>
                <Dropdown.Item eventKey={2}
                               value='current-week'
                               disabled={isCurrWeekDisabled}
                               hookId="period-dropdown-select-current-week"
                >
                    {CONSTANT_TEXTS.DROPDOWN_CURRENT_WEEK}
                </Dropdown.Item>
                <Dropdown.Item eventKey={3}
                               value='last-month'
                               hookId="period-dropdown-select-last-month"
                >
                    {CONSTANT_TEXTS.DROPDOWN_LAST_MONTH}
                </Dropdown.Item>
                <Dropdown.Item eventKey={4}
                               value='current-month'
                               disabled={isCurrMonthDisabled}
                               hookId="period-dropdown-select-current-month"
                >
                    {CONSTANT_TEXTS.DROPDOWN_CURRENT_MONTH}
                </Dropdown.Item>
                <Dropdown.Divider className={CLASS_NAMES.DROPDOWN_DIVIDER} hookId="period-dropdown-select-divider"/>
                <Popover open={this.state.isDatePickerOpen}
                         trigger={this.renderCustomPeriodMenuItem()}
                         onClick={this.onDatePickerOpen}
                         onClickOutside={this.onDatePickerCancel}
                         placement='left'
                         className={CLASS_NAMES.DROPDOWN_POPOVER}
                         appendToBody={false}
                         basic={true}
                         disabled={false}
                         hookId="period-dropdown-select-custom-popover"
                >
                    <RangeDatePicker value={[periodFrom, periodTo]}
                                     input={false}
                                     onChange={this.onDatePickerChange}
                                     isValidDate={this.isValidDate}
                                     headerRenderer={this.headerRenderer}
                                     shouldDisableNavigation={this.shouldDisableNavigation}
                                     reverseMonthView={true}
                                     hookId="period-dropdown-select-calendar"
                    />
                    <div className={CLASS_NAMES.DROPDOWN_POPOVER_FOOTER}>
                        <Button className={cx(CLASS_NAMES.DROPDOWN_POPOVER_FOOTER_BTN, CLASS_NAMES.DROPDOWN_POPOVER_FOOTER_BTN_APPLY)}
                                hookId='period-dropdown-select-calendar-apply'
                                onClick={this.onDatePickerApply}
                        >
                            {CONSTANT_TEXTS.APPLY}
                        </Button>
                        <Button className={CLASS_NAMES.DROPDOWN_POPOVER_FOOTER_BTN}
                                hookId='period-dropdown-select-calendar-cancel'
                                transparent={true}
                                onClick={this.onDatePickerCancel}
                        >
                            {CONSTANT_TEXTS.CANCEL}
                        </Button>
                    </div>
                </Popover>
            </Dropdown>
        </div>)
    }
}

PeriodDropdownSelect.propTypes = {
    onSelectPredefinedItem: PropTypes.func,
    activeItem: PropTypes.string,
    period: PropTypes.object,
    setPeriod: PropTypes.func,
    onPeriodChange: PropTypes.func,
    onDatePickerOpen: PropTypes.func,
    selectedDate: PropTypes.array
};

export default PeriodDropdownSelect;