import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {textProvider} from './imports';
import {appStates} from './imports';

const CONSTANT_TEXTS = {
    UPLOADING_FILE: textProvider.getText('fileUpload', 'uploadingFile'),
    DOWNLOADING_FILE: textProvider.getText('fileUpload', 'downloadingFile')
}

const statesDictionairy = {
    [appStates.LOADING]: CONSTANT_TEXTS.UPLOADING_FILE,
    [appStates.DOWNLOADING]: CONSTANT_TEXTS.DOWNLOADING_FILE
};

const FileUploadProgressBar = ({percent, type}) => (
    <div>
        <div className='progress-bar'>
            <div style={{width: `${percent}%`}} className='filler'></div>
            <div className='uploading-file-percentage'>
                {statesDictionairy[type]} {`(${percent ? percent : 100}%)`}
            </div>
        </div>
    </div>
);

FileUploadProgressBar.propTypes = {
    percent: PropTypes.number,
    type: PropTypes.string
}

export default memo(FileUploadProgressBar);