import {of} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {debounceTime, mergeMap, map, catchError} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {getWidgetDataFailed, getWidgetDataSuccess, getWidgetListSuccess, getWidgetListFailed,
    getWidgetLibrarySuccess, getWidgetLibraryFailed, updateWidgetPreferencesSuccess, updateWidgetPreferencesFailed} from '../actions/dashboardActions';

import {dashboardConstants} from '../constants/dashboardConstants';
const DEBOUNCE_TIME = 1000;

export const requestDataEpic = actions => (
    actions.pipe(
        ofType(dashboardConstants.WIDGET_DATA_REQUEST),
        mergeMap(action => {
            const {type, publisherId, period} = action.payload;
            const qs = period.type === 'custom' ? `period=${period.type}&from=${period.from}&to=${period.to}` : `period=${period.type}`;
            return ajax.getJSON(`api/dashboard/publisher/${publisherId}/${type}/data?${qs}`, {'Content-Type': 'application/json'}).pipe(
                map(res => {
                    const {data} = res;
                    return getWidgetDataSuccess(type, data)
                }),
        catchError(error => of(getWidgetDataFailed(type, error)))
            )}
        ),
    )
);

export const requestWidgetListEpic = actions => (
    actions.pipe(
        ofType(dashboardConstants.WIDGET_LIST_REQUEST),
        debounceTime(DEBOUNCE_TIME),
        mergeMap(() =>
            ajax.getJSON(`api/dashboard/config`, {'Content-Type': 'application/json'}).pipe(
                map(widgets => getWidgetListSuccess(widgets)),
                catchError(error => of(getWidgetListFailed(error)))
            )
        )
    )
);

export const requestWidgetLibraryEpic = actions => (
    actions.pipe(
        ofType(dashboardConstants.WIDGET_LIBRARY_REQUEST),
        debounceTime(DEBOUNCE_TIME),
        mergeMap(() =>
            ajax.getJSON(`api/dashboard/library`, {'Content-Type': 'application/json'}).pipe(
                map(widgetLibrary => getWidgetLibrarySuccess(widgetLibrary)),
                catchError(error => of(getWidgetLibraryFailed(error)))
            )
        )
    )
);

export const updateWidgetPreferencesEpic = actions => (
    actions.pipe(
        ofType(dashboardConstants.UPDATE_WIDGET_PREFERENCES_REQUEST),
        debounceTime(DEBOUNCE_TIME),
        mergeMap(action => {
            const {widgetSchema} = action.payload;
            return ajax.post(`api/dashboard/config`, widgetSchema, {'Content-Type': 'application/json'}).pipe(
                map(data => updateWidgetPreferencesSuccess(data.response)),
                catchError(error => of(updateWidgetPreferencesFailed(error)))
            )}
        )
    )
);