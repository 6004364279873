import React, {Component} from 'react';
import {connect} from 'react-redux';
import {WidthProvider, Responsive} from 'react-grid-layout';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Widget from './Widget';
import {loadFromLocalStorage, deepCopy, updateWidgetPreferences} from '../../imports';
const {isEmpty} = require('lodash');

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const GRID_OPTIONS = {
    ROW_HEIGHT: 1,
    COMPACT_TYPE: 'vertical'
}

const CLASS_NAMES = {
    WIDGET_BOX: 'widget-box',
    WIDGET_CONTAINER: 'widget-container',
    WIDGET_NEW: 'widget-new'
}

const SCREEN_SIZES = {
    BREAK_SMALL: 1366
}

class WidgetContainer extends Component {
    constructor(props) {
        super(props);
        const widgetDefs = deepCopy(props.widgetDefinitions);
        this.state = {
            widgetDefs
        };
    }
    onSwap = widgetDefinitions => this.props.updateWidgetPreferences(widgetDefinitions);

    generateDOM() {
        const {publisherId, period} = this.props;
        const widgetDefs = this.state.widgetDefs;
        if (isEmpty(widgetDefs)) {
            return;
        }
        const allowDrop = this.allowDrop.bind(this);
        const drag = this.drag.bind(this);
        const drop = this.drop.bind(this);
        let x = 0;
        const maxW = 12;
        const defs = widgetDefs.map((widgetDef, index) => {
            let lsWidgets = JSON.parse(loadFromLocalStorage('newlyAddedLibraryWidget')) || [];
            const isNewlyAdded = lsWidgets.find(x => x == widgetDef.i);
            const isNewClassName = isNewlyAdded ? CLASS_NAMES.WIDGET_NEW : '';
            const classnames = classNames(CLASS_NAMES.WIDGET_BOX, widgetDef.kind);

            const h = widgetDef.height ? widgetDef.height : widgetDef.h;
            const w = window.innerWidth <= SCREEN_SIZES.BREAK_SMALL ? widgetDef.smallWidth : widgetDef.regularWidth;
            if (x + w > maxW) {
                x = 0;
            }
            const gridInfo = {
                x: x,
                y: 0,
                w: w,
                h,
                static: widgetDef.static,
                component: widgetDef.component,
                order: widgetDef.i-1,
                order_def: widgetDef.i
            };
            x += w;
            return (
                <div
                    className={classnames}
                    id={index}
                    key={index}
                    data-grid={gridInfo}
                    draggable={!gridInfo.static}
                    onDragStart={drag}
                    onDrop={drop}
                    onDragOver={allowDrop}
                    data-static={gridInfo.static || false}
                    data-order={gridInfo.order}
                    data-order-def={gridInfo.order_def}>
                    <Widget type={widgetDef.type} publisherId={publisherId} initialPeriod={period} isNewClassName={isNewClassName} />
                </div>
            );
        });
        return defs;
    }
    render() {
        return (
                <ResponsiveReactGridLayout className={CLASS_NAMES.WIDGET_CONTAINER}
                draggableHandle=".widget-box"
                isDraggable={false}
                    rowHeight={GRID_OPTIONS.ROW_HEIGHT}
                    compactType={GRID_OPTIONS.COMPACT_TYPE}
                    cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
                    {...this.props}
                >
                    {this.generateDOM()}
                </ResponsiveReactGridLayout>
        );
    }
    allowDrop(ev) {
        ev.preventDefault();
    }
    drag(ev) {
        if (String(ev.target.getAttribute("data-static")) === 'true') {
            ev.preventDefault();
            ev.returnValue = false;
            return false;
        }
        ev.dataTransfer.setData("text", ev.target.id);
    }
    drop(ev) {
        ev.preventDefault();
        let data = ev.dataTransfer.getData("text");
        let elSource = document.getElementById(data);
        let elTarget = ev.target.closest(".widget-box");
        // Prevent static element to be dropped on
        if (isEmpty(elSource) || isEmpty(elTarget) || String(elTarget.getAttribute("data-static")) === 'true') {
            return;
        }
        this.swap(elSource, elTarget);
    }
    swap(elSource, elTarget) {
        const widgetDefs = this.state.widgetDefs;
        if (!isEmpty(widgetDefs)) {
            // Source order def
            const sOrderDef = elSource.getAttribute('data-order-def');
            // Source index
            const sInd = widgetDefs.findIndex(el => el.i === sOrderDef);
            // Target order def
            const tOrderDef = elTarget.getAttribute('data-order-def');
            // Target index
            const tInd = widgetDefs.findIndex(el => el.i === tOrderDef);
            if (sInd === -1 || tInd === -1) {
                return;
            }
            // Copy of source into temp obj
            let tmpSDef = {...widgetDefs[sInd]};
            // copy target to source
            widgetDefs[sInd] = {...widgetDefs[tInd]};
            // Set temp to target
            widgetDefs[tInd] = tmpSDef;

            this.onSwap(widgetDefs);
            this.setState({widgetDefs});
            this.props.rerenderWidgets(false);
        }
    }
}

WidgetContainer.propTypes = {
    defaultProps: PropTypes.object,
    onLayoutChange: PropTypes.func,
    widgetDefinitions: PropTypes.array,
    period: PropTypes.object,
    publisherId: PropTypes.number,
    noDraggable: PropTypes.bool,
    layout: PropTypes.array,
    onSwap: PropTypes.func,
    updateWidgetPreferences: PropTypes.func,
    rerenderWidgets: PropTypes.func
}

WidgetContainer.defaultProps = {
    isResizable: false,
    isDraggable: false,
    margin: [0, 0.02]
}
const mapStateToProps = (state) => ({
    widgetPreferences: state.dashboard.widgetPreferences
});

const mapDispatchToProps = ({
    updateWidgetPreferences
});
export default (connect(mapStateToProps, mapDispatchToProps)(WidgetContainer));