import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {validate} from '@perion-undertone/ramp-helpers';
import {map, every, isEmpty} from 'lodash';
import {Button, Modal, Select, RadioGroup, Radio} from '@perion-undertone/ut-react-common';

import {
    runningAtInfo, getIntervalSelectOptions, getPeriodSelectOptions,
    getSelectedPeriodOption, getSelectedRepeatOption, getPeriodOnRepeatSelection, TXT
} from './scheduleReportHelper';

const {isEmailValid} = validate;

const CLASS_NAMES = {
    MODAL: 'schedule-report-modal',
    HEADER: 'schedule-report-main-header',
    REPEAT_EVERY_SECTION: 'schedule-report-repeat-every-section',
    REPEAT_EVERY_DROPDOWN: 'schedule-report-repeat-every-dropdown',
    REPEAT_EVERY_INFO: 'schedule-report-repeat-every-info',
    DATE_RANGE_AND_FORMAT_SECTION: 'schedule-report-date-range-and-format-section',
    DATE_RANGE_AND_FORMAT_SECTION_INNER: 'schedule-report-date-range-and-format-section-inner',
    RECIPIENTS_SECTION: 'schedule-report-recipients-section',
    RECIPIENTS_SECTION_HEADER: 'schedule-report-recipients-section-header',
    RECIPIENTS_SECTION_SUBHEADER: 'schedule-report-recipients-section-subheader',
    FOOTER: 'schedule-report-footer',
    REMOVE_RECURRENCE_BTN: 'remove-recurrence-btn',
    CANCEL_BTN: 'cancel-btn',
    SAVE_BTN: 'save-btn'
};

const getCustomStyles = {
    multiValue: (provided, state) => {
        const value = state.data.value;
        const color = !isEmailValid(value) && '#ff5b5b !important';
        const borderColor = !isEmailValid(value) && '#ff5b5b !important';
        return {...provided, color, borderColor};
    }
};

const setDefaultRecipients = (emails = []) => map(emails, (value) => ({label: value, value, __isNew__: true}));

const ScheduleRecurrence = (props) => {
    const {isOpen, item} = props;
    const {emails, timePeriod, recurrenceInterval, formatType} = item;
    const [repeatOption, setRepeatOption] = useState({});
    const [periodOption, setPeriodOption] = useState({});
    const [formatOption, setFormatOption] = useState('xlsx');
    const [recipients, setRecipients] = useState([]);
    const [shouldSetPeriodOnRepeatSelection, updateShouldSetPeriodOnRepeatSelection] = useState(true);

    const areRecipientsValid = !isEmpty(recipients) && every(recipients, ({value}) => isEmailValid(value));
    const isFormValid = areRecipientsValid && repeatOption.value && periodOption.value;

    useEffect(() => {
        if (isOpen) {
            updateShouldSetPeriodOnRepeatSelection(recurrenceInterval ? false : true);
            setRecipients(setDefaultRecipients(emails));
            setPeriodOption(getSelectedPeriodOption(timePeriod || 'yesterday'));
            setRepeatOption(getSelectedRepeatOption(recurrenceInterval || 'daily'));
            setFormatOption(formatType || 'xlsx');
        }
    }, [isOpen, emails, timePeriod, recurrenceInterval]);

    const onModalClose = () => {
        props.closeRecurrenceModal();
        setRecipients([]);
    }

    const removeRecurrence = () => {
        const data = {
            ...item,
            isActive: false
        }
        props.setReportScheduling(data);
        onModalClose();
    }

    const setReportSchedule = () => {
        const recipientsArr = map(recipients, 'value');
        const emails = recipientsArr.toString().toLowerCase();

        const data = {
            ...item,
            reportId: item.id,
            emails,
            publisherId: props.publisherId,
            isActive: true,
            hasRecurrence: true,
            timePeriod: periodOption.value,
            recurrenceInterval: repeatOption.value,
            formatType: formatOption
        };

        props.setReportScheduling(data);
        onModalClose();
    }

    const onRepeatSelect = (option) => {
        if (shouldSetPeriodOnRepeatSelection) {
            const periodSelection = getPeriodOnRepeatSelection(option.value, 'interval');
            setPeriodOption(periodSelection);
        }
        setRepeatOption(option);
    };

    const onPeriodSelect = (option) => {
        updateShouldSetPeriodOnRepeatSelection(false);
        setPeriodOption(option);
    };

    const onFormatSelect = (name, value) => {
        setFormatOption(value);
    };

    return (
        <Modal open={isOpen}
               className={CLASS_NAMES.MODAL}
               closeOnOutsideClick={false}
               onModalClose={onModalClose}
               hookId='schedule-recurrence-modal'
        >
            <div className={CLASS_NAMES.HEADER}>
                {item.name} - {TXT.RECURRENCE}
            </div>
            <div className={CLASS_NAMES.REPEAT_EVERY_SECTION}>
                <div className={CLASS_NAMES.REPEAT_EVERY_DROPDOWN}>
                    <div>{TXT.REPEATS_EVERY}</div>
                    <Select type='select'
                            value={repeatOption}
                            placeholder=''
                            isFocused={false}
                            hookId='schedule-report-repeat-every-selection'
                            options={getIntervalSelectOptions()}
                            onChange={(option) => onRepeatSelect(option)}
                    />
                </div>
                <div className={CLASS_NAMES.REPEAT_EVERY_INFO}>
                    {runningAtInfo(repeatOption.value)}
                </div>
            </div>
            <div className={CLASS_NAMES.DATE_RANGE_AND_FORMAT_SECTION}>
                <div className={CLASS_NAMES.DATE_RANGE_AND_FORMAT_SECTION_INNER}>
                    <div>{TXT.REPORT_DATE_RANGE}</div>
                    <Select type='select'
                            value={periodOption}
                            placeholder=''
                            isFocused={false}
                            hookId='schedule-report-date-range-selection'
                            options={getPeriodSelectOptions()}
                            onChange={(option) => onPeriodSelect(option)}
                    />
                </div>
                <div className={CLASS_NAMES.DATE_RANGE_AND_FORMAT_SECTION_INNER}>
                    <div>{TXT.FORMAT}</div>
                    <RadioGroup hookId='schedule-report-format-selection'
                                name="format"
                                value={formatOption}
                                onChange={onFormatSelect}
                    >
                        <Radio as='div' value='xlsx'>
                            {TXT.EXCEL}
                        </Radio>
                        <Radio as='div' value='csv'>
                            {TXT.CSV}
                        </Radio>
                    </RadioGroup>
                </div>
            </div>
            <div className={CLASS_NAMES.RECIPIENTS_SECTION}>
                <div className={CLASS_NAMES.RECIPIENTS_SECTION_HEADER}>
                    {TXT.SEND_TO}
                </div>
                <div className={CLASS_NAMES.RECIPIENTS_SECTION_SUBHEADER}>
                    {TXT.RECIPIENTS}
                </div>
                <Select isMulti
                        creatable
                        forceSingleLine
                        onChange={(value) => setRecipients(value)}
                        styles={getCustomStyles}
                        value={recipients}
                        placeholder=''
                        isFocused={false}
                        noOptionsMessage={() => (null)}
                        hookId='schedule-report-recipients-select'
                />
            </div>
            <div className={CLASS_NAMES.FOOTER}>
                <Button disabled={!item.hasRecurrence}
                        className={CLASS_NAMES.REMOVE_RECURRENCE_BTN}
                        transparent={true}
                        onClick={removeRecurrence}
                        hookId='schedule-report-remove-recurrence-button'
                >
                    {TXT.REMOVE_RECURRENCE}
                </Button>
                <div>
                    <Button onClick={onModalClose}
                            className={CLASS_NAMES.CANCEL_BTN}
                            transparent={true}
                            hookId='schedule-report-cancel-button'
                    >
                        {TXT.CANCEL}
                    </Button>
                    <Button className={CLASS_NAMES.SAVE_BTN}
                            hookId='schedule-report-save-button'
                            disabled={!isFormValid}
                            onClick={setReportSchedule}
                    >
                        {TXT.SAVE}
                    </Button>
                </div>
            </div>
        </Modal>
    )
};

ScheduleRecurrence.propTypes = {
    isOpen: PropTypes.bool,
    closeRecurrenceModal: PropTypes.func,
    setReportScheduling: PropTypes.func,
    publisherId: PropTypes.number,
    item: PropTypes.object
};

export default ScheduleRecurrence;