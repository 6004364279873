import {analyticsConstants} from '../constants/analyticsConstants';
import {appStates} from '../constants/appStates';

const initialState = {
    rowsPerPage: 30,
    currentPage: 1,
    lastPage: 1,
    zoomFactor: 1,
    isWebPreviewOpen: false
};

export const reportPreviewReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case analyticsConstants.RAW_REPORT_DATA_REQUEST:
            return {
                ...state,
                isWebPreviewOpen: true,
                state: appStates.LOADING
            };
        case analyticsConstants.RAW_REPORT_DATA_SUCCESS: {
            const {content} = payload.data;
            const index = (state.currentPage - 1) * state.rowsPerPage;
            const rawDataPaginated = content.slice(index, index + state.rowsPerPage);
            return {
                ...state,
                ...payload.data,
                rawDataPaginated,
                state: appStates.LOADED
            };
        }
        case analyticsConstants.RAW_REPORT_DATA_FAILURE:
            return {
                ...state,
                state: appStates.ERROR,
            };
        case analyticsConstants.CHANGE_PAGE: {
            const {nextPage} = payload;
            const index = (nextPage - 1) * state.rowsPerPage;
            const rawDataPaginated = state.content.slice(index, index + state.rowsPerPage);

            return {
                ...state,
                rawDataPaginated,
                currentPage: nextPage
            }
        }
        case analyticsConstants.SET_LAST_PAGE: {
            const {lastPage} = payload;

            return {
                ...state,
                lastPage
            }
        }
        case analyticsConstants.SET_ZOOM_FACTOR: {
            const {zoomFactor} = payload;

            return {
                ...state,
                zoomFactor
            }
        }
        case analyticsConstants.CLOSE_WEB_PREVIEW: {
            return initialState
        }
        default:
            return state
    }
}