import {onboardingConstants} from '../constants/onboardingConstants';

export const getOnboardingData = () => ({
    type: onboardingConstants.GET_ONBOARDING_DATA_REQUEST
});
export const getOnboardingDataSuccess = data => ({
    type: onboardingConstants.GET_ONBOARDING_DATA_SUCCESS,
    payload: data
});
export const getOnboardingDataFailed = error => ({
    type: onboardingConstants.GET_ONBOARDING_DATA_FAILURE,
    payload: {error}
});

export const addMainSite = (site, updateSite) => ({
    type: onboardingConstants.ADD_MAIN_SITE_REQUEST,
    payload: {site, updateSite}
});
export const addMainSiteSuccess = data => ({
    type: onboardingConstants.ADD_MAIN_SITE_SUCCESS,
    payload: data
});
export const addMainSiteFailed = error => ({
    type: onboardingConstants.ADD_MAIN_SITE_FAILURE,
    payload: {error}
});

export const validateMainSite = (url, pssId, hasValidAdsTxt, isValidating) => ({
    type: onboardingConstants.VALIDATE_MAIN_SITE_REQUEST,
    payload: {url, pssId, hasValidAdsTxt, isValidating}
});
export const validateMainSiteSuccess = data => ({
    type: onboardingConstants.VALIDATE_MAIN_SITE_SUCCESS,
    payload: data
});
export const validateMainSiteFailed = error => ({
    type: onboardingConstants.VALIDATE_MAIN_SITE_FAILURE,
    payload: {error}
});

export const displayEnterSite = (shouldDisplay) => ({
    type: onboardingConstants.DISPLAY_ENTER_SITE,
    payload: {shouldDisplay}
});

export const setPrefilledAccountData = (prefillAccount) => ({
    type: onboardingConstants.SET_PREFILLED_ACCOUNT_DATA,
    payload: prefillAccount
});