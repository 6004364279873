import {combineReducers} from 'redux';
import {flopflipReducer, FLOPFLIP_STATE_SLICE} from '@flopflip/react-redux';
import {appConstants} from '../constants/appConstants';
import {appReducer} from './appReducer';
import {emailReducer} from './emailReducer';
import {featuresReducer} from './featuresReducer';
import {dashboardReducer} from "./dashboardReducer";
import {placementsReducer} from "./placementsReducer";
import {supplyReducer} from './supplyReducer';
import {gridCustomizationReducer} from './gridCustomizationReducer';
import {advertisersReducer} from './advertisersReducer';
import {categoriesReducer} from './categoriesReducer';
import {analyticsReducer} from './analyticsReducer';
import {paymentReducer} from './paymentReducer';
import {reportPreviewReducer} from './reportPreviewReducer';
import {adUnitConfigurationsReducer} from './adUnitConfigurationsReducer';

const allReducers = combineReducers({
    [FLOPFLIP_STATE_SLICE]: flopflipReducer,
    app: appReducer,
    email: emailReducer,
    features: featuresReducer,
    dashboard: dashboardReducer,
    placements: placementsReducer,
    supply: supplyReducer,
    grid: gridCustomizationReducer,
    advertisers: advertisersReducer,
    categories: categoriesReducer,
    predefinedReports: analyticsReducer,
    reportPreview: reportPreviewReducer,
    payment: paymentReducer,
    adUnitConfigurations: adUnitConfigurationsReducer
});

const rootReducer = (state, action) => {
    if (action.type === appConstants.LOGOUT_USER) {
        const {features} = state;
        state = {features, [FLOPFLIP_STATE_SLICE]: state[FLOPFLIP_STATE_SLICE]};
    }

    return allReducers(state, action);
};

export default rootReducer;