export const loadFromLocalStorage = (key, options = {isPrimitive: false}) => {
    if (options.isPrimitive) {
        return localStorage.getItem(key);
    }
    try {
        const serializedItem = localStorage.getItem(key);
        return JSON.parse(serializedItem);
    } catch (err) {
        return undefined;
    }
};

export const saveToLocalStorage = (key, item, options = {isPrimitive: false}) => {
    if (options.isPrimitive) {
        localStorage.setItem(key, item);
    } else {
        try {
            const serializedItem = JSON.stringify(item);
            localStorage.setItem(key, serializedItem);
        } catch (err) {
            throw new Error(err);
        }
    }
};
