import React from 'react';
import {Icon} from '@perion-undertone/ut-react-common';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {selectFeatureFlag} from '@flopflip/react-redux';
import {Tooltip} from '@perion-undertone/ut-react-common';
import {textProvider} from '../imports';

const CLASS_NAMES = {
	REACT_GRID_ICONS_CONTAINER: 'react-grid-icons-container',
	IS_AUTO_PLACEMENT_GROUP_ICON: 'is-auto-placement-group-icon',
	IS_ONE_OFF_PLACEMENT_GROUP_ICON: 'is-one-off-placement-group-icon'
};

const CONSTANT_TEXTS = {
	ONE_OFF_PLACEMENT: textProvider.getText('placementGroupGrid', 'oneOffPlacement'),
	AUTO_GENERATED_GROUP: textProvider.getText('placementGroupGrid', 'autoGeneratedGroup'),
};


const IconsFormatter = ({row, isPlacementGroupOneOffEnabled}) => {	
	return (
		<div className={CLASS_NAMES.REACT_GRID_ICONS_CONTAINER}>
			<div className={CLASS_NAMES.IS_AUTO_PLACEMENT_GROUP_ICON}>
				{row.isAuto && <Tooltip trigger={<div><Icon icon='icon-generated-automatically' /></div>}
					hookId='automatically-generated-placement-group-tooltip'
					>
					{CONSTANT_TEXTS.AUTO_GENERATED_GROUP}
				</Tooltip>}
			</div>
			<div className={CLASS_NAMES.IS_ONE_OFF_PLACEMENT_GROUP_ICON}>
				{row.isOneOff && isPlacementGroupOneOffEnabled ?
					<Tooltip trigger={<div><Icon icon="icon-star" /></div>} >
							{CONSTANT_TEXTS.ONE_OFF_PLACEMENT}
					</Tooltip> : null}
			</div>
		</div> 		
	)

	}

IconsFormatter.propTypes = {
	row: PropTypes.object,
	isPlacementGroupOneOffEnabled: PropTypes.bool
}	

const mapStateToProps = (state) => ({
	isPlacementGroupOneOffEnabled: selectFeatureFlag('placementGroupOneOff')(state),
})

export default connect(mapStateToProps)(IconsFormatter);

