import {emailConstants} from '../constants/emailConstants';

export const submitEmail = (sender, recipients, subject, body) => ({
    type: emailConstants.SUBMIT_EMAIL,
    payload: {sender, recipients, subject, body}
});
export const submitEmailSuccess = data => ({
    type: emailConstants.SUBMIT_EMAIL_SUCCESS,
    payload: {data}
});
export const submitEmailFailed = data => ({
    type: emailConstants.SUBMIT_EMAIL_FAILURE,
    payload: {data}
});

export const resetEmailState = () => ({
    type: emailConstants.RESET_EMAIL_STATE
});