import {get, startsWith, toNumber} from 'lodash';
import {createHashHistory} from 'history';

import {isAdmin, isPublisherUserOrPublisherAdmin, isPublisherDSP} from '../auth';
import {loadFromLocalStorage} from './../utils/localStorage';

const history = createHashHistory();
const hash = window.location.hash;
const hashParams = hash.split('/');

// hashParams[0] = #
// hashParams[1] = publisher ID
// hashParams[2] = sidebar or account route

/*
    Sidebar routes (auth)
        - dashboard
        - placements
        - supply
        - analytics
**/

/*
    Account routes (public)
        - / (login)
        - password-set
        - password-reset
        - create-account
        - welcome-back
        - activation-email-sent
**/

export const getPublisherId = () => {
    const hash = window.location.hash;
    const hashParams = hash.split('/');
    return toNumber(hashParams[1]);
};

export const redirectToLoginPage = () => {
    history.replace('/');
};

export const redirectTo = (route = '/') => {
    history.replace(route);
};

export const redirectToHomePage = () => {
    const user = loadFromLocalStorage('user');
    const publisherId = get(user, 'currentPublisherId');

    const publisherIdUrl = getPublisherId();
    const sidebarUrl = hashParams[2];

    // Reset after setting new password, auto login in the background
    if (startsWith(hash, 'password-set', 2)) {
        history.push('/');
    }

    // Display select publisher view as home page for direct users with multiple publishers
    // This will be displayed on first login, as the selected publisher will be saved as default
    if (isPublisherUserOrPublisherAdmin(user) && !publisherId) {
        return history.push('/');
    }
    // Display default publisher for publisher DSP users
    if (isPublisherDSP(user) && publisherId) {
        if (sidebarUrl) {
            return history.push(`/${publisherId}/${sidebarUrl}`);
        }
        return history.push(`/${publisherId}/placements`);
    }
    // Display default publisher for direct users
    if (isPublisherUserOrPublisherAdmin(user) && publisherId) {
        if (sidebarUrl) {
            return history.push(`/${publisherId}/${sidebarUrl}`);
        }
        return history.push(`/${publisherId}/dashboard`);
    }

    // Reset URL if non-admin users try to modify the publisher ID
    if (!isAdmin(user) && publisherIdUrl !== publisherId) {
        if (sidebarUrl) {
            return history.push(`/${publisherId}/${sidebarUrl}`);
        }
        return history.push(`/${publisherId}/dashboard`);
    }

    // Display select publisher view as home page for admins
    if (!publisherId && !publisherIdUrl && isAdmin(user)) {
        return history.push('/');
    }

    // Display dashboard as home page for non-admin users
    if (hash === '' || hash === '#/') {
        return history.push(`/${publisherId}/dashboard`);
    }
    if (!sidebarUrl || sidebarUrl === '') {
        return history.replace(`/${publisherId}/dashboard`);
    }
};