import React from 'react';
import {of} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {ToastProvider} from '@perion-undertone/ut-react-common';
import {supplyConstants} from '../constants/supplyConstants';
import {
    getAdUnitConfigurationsSuccess, getAdUnitConfigurationsFailed,
    saveAdUnitConfigurationsSuccess, saveAdUnitConfigurationsFailed
} from '../actions/adUnitConfigurationsActions';
import Toaster from '../components/Toaster';

export const adUnitConfigurationsEpic = actions => (
    actions.pipe(
        ofType(supplyConstants.GET_ADUNIT_CONFIGURATIONS_REQUEST),
        mergeMap(action => {
            const {publisherId, publishersSupplySourcesId} = action.payload;
            return ajax.getJSON(`/api/adunits/configurations/${publisherId}/${publishersSupplySourcesId}`).pipe(
                map(data => getAdUnitConfigurationsSuccess(data)),
                catchError(error => of(getAdUnitConfigurationsFailed(error)))
            )}
        )
    )
);

export const saveAdUnitConfigurationsEpic = actions => (
    actions.pipe(
        ofType(supplyConstants.SAVE_ADUNIT_CONFIGURATIONS_REQUEST),
        mergeMap(action => {
            const {publisherId, adUnitConfigurations} = action.payload;
            return ajax.post(`/api/adunits/configurations/${publisherId}/manage-configurations`,
                adUnitConfigurations,
                {'Content-Type': 'application/json'}).pipe(
                map(data => {
                    ToastProvider.success(
                        <Toaster notificationIcon='icon-updated_notifiction'
                                 notificationHookId='toaster-updated-icon'
                                 notificationMessage='Adunit configurations saved successfully'
                        />,
                    true);
                    return saveAdUnitConfigurationsSuccess(data.response)
                }),
                catchError(error => {
                    ToastProvider.error(
                        <Toaster notificationIcon='icon-error__notifiction'
                                 notificationHookId='toaster-error-icon'
                                 notificationMessage='Could not save adunit configurations'
                        />,
                    true);
                    return of(saveAdUnitConfigurationsFailed(error))
                })
            )}
        )
    )
);

