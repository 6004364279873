import React, {useRef} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Modal, Animation} from '@perion-undertone/ut-react-common';
import {isString} from 'lodash';

import PaginationScrollWrapper from './PaginationScrollWrapper';
import WebPreviewSettings from './WebPreviewSettings';

import {closeReportPreview, Image} from './imports';

const CLASS_NAMES = {
    WEB_PREVIEW_WRAPPER_NO_DATA: 'web-preview-wrapper-no-data',
    WEB_PREVIEW_WRAPPER: 'web-preview-wrapper',
    WEB_PREVIEW_WRAPPER_BACKGROUND: 'web-preview-wrapper-background',
    REPORT_VIEWER_HEADER: 'report-viewer-header'
};

const WebPreviewWrapper = (props) => {
    return (
        <Modal className={cx(
                    {[CLASS_NAMES.WEB_PREVIEW_WRAPPER_BACKGROUND]: props.isWithBackground},
                    props.reportPreview.content && props.reportPreview.content.length > 1 ?
                        CLASS_NAMES.WEB_PREVIEW_WRAPPER : CLASS_NAMES.WEB_PREVIEW_WRAPPER_NO_DATA
               )}
               open={props.reportPreview.isWebPreviewOpen}
               onModalClose={props.closeReportPreview}
               hookId='analytics-web-preview-modal'
        >
            <div className={CLASS_NAMES.REPORT_VIEWER_HEADER}>preview</div>
            {props.children}
        </Modal>
    )
};

WebPreviewWrapper.propTypes = {
    reportPreview: PropTypes.object,
    closeReportPreview: PropTypes.func,
    isWithBackground: PropTypes.bool
};

const WebPreview = (props) => {
    const reportViewerRef = useRef();
    const {onExportClick, reportPreview} = props;

    if (!reportPreview || !reportPreview.content) {
        return (
            <WebPreviewWrapper {...props} isWithBackground={true}>
                <Animation type='gridLoader'
                           height={200}
                           width={200}
                           rendererSettings={{preserveAspectRatio: 'xMaxYMax'}}
                           hookId='web-preview-loading'
                />
            </WebPreviewWrapper>
        )
    }

    // No data available
    if (reportPreview.content.length === 1 && isString(reportPreview.content[0][0])) {
        return (
            <WebPreviewWrapper {...props} isWithBackground={true}>
                <div className={CLASS_NAMES.REPORT_VIEWER_WRAPPER}>
                    <Image name='no-data.png' hookId='report-preview-no-data' />
                </div>
            </WebPreviewWrapper>
        );
    }

    return (
        <WebPreviewWrapper {...props} >
            <PaginationScrollWrapper reportViewerRef={reportViewerRef}
                          reportPreview={reportPreview}
            />
            <WebPreviewSettings {...props}
                                reportViewerRef={reportViewerRef}
                                onExportClick={onExportClick}
            />
        </WebPreviewWrapper>
    );
};

WebPreview.propTypes = {
    isWebPreviewOpen: PropTypes.bool,
    closeReportPreview: PropTypes.func,
    onExportClick: PropTypes.func,
    reportPreview: PropTypes.object
};

const mapStateToProps = ({reportPreview}) => ({
    reportPreview
});

const mapDispatchToProps = ({
    closeReportPreview
});

export default connect(mapStateToProps, mapDispatchToProps)(WebPreview);
