import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {withRouter} from 'react-router-dom';
import Image from './Image'

import NavLink from './NavLink';

const CLASS_NAMES = {
    SIDEBAR_WRAPPER: 'sidebar-wrapper',
    SIDEBAR_MENU: 'sidebar-menu',
    SIDEBAR_MENU_ITEM: 'sidebar-menu-item',
    SIDEBAR_MENU_ITEM_DISABLED: 'sidebar-menu-item--disabled',
    SIDEBAR_ACTIVE_MENU_ITEM: 'sidebar-active-menu-item',
};

const Sidebar = (props) => {
    const [activeMenu, setActiveMenu] = useState(props.location.pathname);

    const {filteredRoutes, publisherId, disabledMenu} = props;
    
    return (
        <div className={CLASS_NAMES.SIDEBAR_WRAPPER}>
            <ul className={CLASS_NAMES.SIDEBAR_MENU}>
                {filteredRoutes.map((route, index) => {
                    const isMenuActive = activeMenu === index || activeMenu === `/${publisherId}${route.url}`;
                    return (route.url !== '/' && route.isEnabled) && (
                        <li key={index}
                            className={cx(CLASS_NAMES.SIDEBAR_MENU_ITEM,
                                {[CLASS_NAMES.SIDEBAR_MENU_ITEM_DISABLED]: disabledMenu})}
                                onClick={() => {setActiveMenu(index)}}
                        >
                            <NavLink to={`/${publisherId}${route.url}`}
                                     activeClassName={cx({[CLASS_NAMES.SIDEBAR_ACTIVE_MENU_ITEM]:  isMenuActive})}
                            >
                                <Image name={isMenuActive ? route.imgActive : route.img}
                                       hookId={`${route.url.slice(1)}-menu-item-icon`}
                                />
                                {route.displayName}
                            </NavLink>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

Sidebar.propTypes = {
    filteredRoutes: PropTypes.array,
    disabledMenu: PropTypes.bool,
    publisherId: PropTypes.number
};

export default withRouter(Sidebar);
