import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon as UTIcon} from '@perion-undertone/ut-react-common';

export default class Icon extends Component {
    getImagePath = icon => {
        try {
            const path = require(`../../assets/${icon}`);
            return path;
        } catch (err) {
            return null;
        }
    };
    render() {
        const {icon, className, hookId, onClick} = this.props;

        const isImageType = (/\.(svg|jpg|png)$/i).test(icon);
        return (isImageType ?
            <img className={className} src={this.getImagePath(icon)} onClick={onClick} data-automation={hookId} ></img> :
            <UTIcon className={className} icon={`icon-${icon}`} hookId={hookId} onClick={onClick}></UTIcon>)
    }
}

Icon.propTypes = {
    icon: PropTypes.string,
    className: PropTypes.string,
    hookId: PropTypes.string,
    onClick: PropTypes.string,
};