import React from 'react';
import {Route, Switch} from "react-router-dom";
import {ActivationEmailSent, CreateAccount, CreatePassword, ResetPassword, WelcomeBack} from "./imports";
import PageNotFound from "./PageNotFound";
import PrivateRoute from "./PrivateRoute";
import Home from "./Home";

const AppRouter = () => {
    return (
        <div className="app">
            <Switch>
                <Route path='/password-set' component={CreatePassword}/>
                <Route path='/password-reset' component={ResetPassword}/>
                <Route path='/create-account' component={CreateAccount}/>
                <Route path='/welcome-back' component={WelcomeBack}/>
                <Route path='/activation-email-sent' component={ActivationEmailSent}/>
                <Route path='/not-found' component={PageNotFound}/>
                <PrivateRoute path="/:publisherId?" component={Home}/>
            </Switch>
        </div>
    )
};

export default AppRouter;
