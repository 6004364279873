import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@perion-undertone/ut-react-common';

const AccountFooter = (props) => {
    const {buttonText, isDisabled, onSubmit} = props;

    return (
        <div className='account-footer'>
            <Button size='lg'
                    onClick={onSubmit}
                    disabled={isDisabled}
                    className='account-footer-submit-button'
                    hookId='account-footer-submit-button'
            >
                {buttonText}
            </Button>
        </div>
    );
};

AccountFooter.propTypes = {
    buttonText: PropTypes.string,
    isDisabled: PropTypes.bool,
    onSubmit: PropTypes.func
};

export default AccountFooter;
