import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createEpicMiddleware} from 'redux-observable';
import rootReducer from './reducers';
import rootEpic from './epics';
import {apiRequestMiddleware, loggerMiddleware} from './middlewares';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const epicMiddleware = createEpicMiddleware();
const BASE_MIDDLEWARES = [apiRequestMiddleware, epicMiddleware, thunkMiddleware];

const getMiddlewares = () => {
    if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
        return [require('redux-immutable-state-invariant').default(), ...BASE_MIDDLEWARES, loggerMiddleware];
    }
    return BASE_MIDDLEWARES;
};

class Store {
    create (initialState) {
        return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...getMiddlewares())));
    }
}

export default (initialState) => {
    const configureStore = new Store();
    const store = configureStore.create(initialState);
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            // eslint-disable-next-line global-require
            const nextRootReducer = require('./reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }
    epicMiddleware.run(rootEpic);
    return store;
};
