import React from 'react';
import PropTypes from 'prop-types';

const BlockedAdvertiserIdFormatter = (props) => {
    const isPublisherLevel = props.dependentValues.columnInfo.isPublisherLevel;
    const blockedAdvertiserId = props.row.advertiserId;
    const supplySourceNameView = blockedAdvertiserId;
    const publisherNameView = (
            <span className='publisher-level-blocked-sales-account'>
                {blockedAdvertiserId}
            </span>
    );
    return (<div>
        {props.row.publishersSupplySourcesId === 0 && !isPublisherLevel ?
            publisherNameView :
            supplySourceNameView}
    </div>);
};

BlockedAdvertiserIdFormatter.propTypes = {
    row: PropTypes.object,
    dependentValues: PropTypes.object
};

export default BlockedAdvertiserIdFormatter;