import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {selectFeatureFlag} from '@flopflip/react-redux';
import {Select, RadioGroup, Radio, Animation, Button} from '@perion-undertone/ut-react-common';
import {textProvider} from '../imports';
import {getVideoLimitations, manageVideoLimitations} from '../actionImports';
import {getFilteredVideoLimitatoins} from '../supplyHelper';
import _ from 'lodash';
const CLASS_NAMES = {
    INNER_CONTAINER: 'supply-settings-modal-inner-container',
    TITLE: 'block-list-title',
    SECTION_WRAPPER: 'section-wrapper',
    VIDEO_LIMITATION_TITLE: 'video-limitation-title',
    VIDEO_LIMITATION_DESCRIPTION: 'video-limitation-description',
    VIDEO_LIMITATION_SKIPPABLE: 'video-limitation-skippable',
    PUB_LEVEL_VIDEO_LIMITATION: 'publisher-level-video-limitation',
    VIDEO_LIMITATION_SELECT: 'video-limitation-select',
    SEPARATOR: 'section-wrapper-separator',
    FOOTER: 'supply-settings-modal-footer',
    DONE_BUTTON: 'supply-settings-modal-done-button',
    CANCEL_BUTTON: 'supply-settings-modal-cancel-button',
};

const CONSTANT_TEXTS = {
    VIDEO_LIMITATION: textProvider.getText('supplySourceSettingsModal', 'videoLimitationTitle'),
    AD_DURATION: textProvider.getText('supplySourceSettingsModal', 'adDuration'),
    AD_DURATION_DESCRIPTION: textProvider.getText('supplySourceSettingsModal', 'adDurationDescription'),
    SKIPPABLE: textProvider.getText('supplySourceSettingsModal', 'skippable'),
    SKIPPABLE_DESCRIPTION: textProvider.getText('supplySourceSettingsModal', 'skippableDescription'),
    PUB_LEVEL_VIDEO_LIMITATION: textProvider.getText('supplySourceSettingsModal', 'pubLevelSetting'),
    YES: textProvider.getText('common', 'yes'),
    NO: textProvider.getText('common', 'no'),
    CANCEL: textProvider.getText('confirmationModal', 'cancel'),
    SAVE: textProvider.getText('confirmationModal', 'save')
}

const adDurationOptions = [
    {label: 'None', value: 0},
    {label: '6 sec', value: 6},
    {label: '15 sec', value: 15},
    {label: '30 sec', value: 30},
    {label: '60 sec', value: 60}
];

const skippableOptions = [
    {label: CONSTANT_TEXTS.YES, value: 1},
    {label: CONSTANT_TEXTS.NO, value: 0}
]

class VideoLimitations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            duration: {label: 'None', value: 0},
            skippable: null
        };
    }

    componentDidMount() {
        const {publisherId, isPublisherLevel, publishersSupplySourcesIds, publishersSupplySourcesId, getVideoLimitations} = this.props;
        const supplySourceId = isPublisherLevel ? 0 : publishersSupplySourcesId;
        const supplySourcesIds = publishersSupplySourcesIds || [supplySourceId];
        this.supplySourcesIds = supplySourcesIds;
        getVideoLimitations(publisherId, supplySourcesIds, 'videoLimitations');
    }

    componentDidUpdate(prevProps) {
        const {isPublisherLevel, isBulkBlock} = this.props;
        const {skippable} = this.state;


        const videoLimitations  = getFilteredVideoLimitatoins(isBulkBlock, _.get(this.props, 'videoLimitations.data', []), this.supplySourcesIds, isPublisherLevel, 'VideoLimitations');
        const PrevVideoLimitations = getFilteredVideoLimitatoins(isBulkBlock, _.get(prevProps, 'videoLimitations.data', []) , this.supplySourcesIds, isPublisherLevel, 'VideoLimitations');
        // Sets the default value of skipable after initial render so it's not 
        // set to 'No'  before checking if the user has chosen an option
        const propsVideoLimitationStrCurrent = _.map(videoLimitations, v => `${v.uid}_${v.skippable}_${v.duration}`).join('');
        const propsVideoLimitationStr = _.map(PrevVideoLimitations, v => `${v.uid}_${v.skippable}_${v.duration}`).join('');


        if(skippable === null && videoLimitations && videoLimitations.length === 0) {
            this.setState({skippable: 0});
        }
        if(propsVideoLimitationStrCurrent !== propsVideoLimitationStr) {
            
            // Setting the skippable and duration for supply or publisher level
            if(videoLimitations && videoLimitations.length === 1) {
                this.setDurationAndSkippable(videoLimitations[0]);
            }
            // Setting the skippable and duration if there is also publisher level data
            else if(videoLimitations && videoLimitations.length > 1) {
                let videoData = videoLimitations[1];
                // If no publisher level data, we set the skippable and duration
                // from supply level
                if(!videoLimitations[0].skippable) {
                    videoData.skippable = videoLimitations[1].skippable;
                } else {
                    videoData.skippable = videoLimitations[0].skippable;
                }

                if(videoLimitations[0].duration === 0) {
                    videoData.duration = videoLimitations[1].duration;
                }else {
                    videoData.duration = videoLimitations[0].duration;
                }
                this.setDurationAndSkippable(videoData);
            }
        }
    }

    setDurationAndSkippable = (videoLimitations) => {
        const {duration, skippable} = videoLimitations;
        const durationLabel = duration === 0 ? 'None' : `${duration} sec`;
        this.setState({duration: {label: durationLabel, value: duration}, skippable: _.isNil(skippable) ? false : skippable});
    }

    onDurationChange = (option) => {
        this.setState({duration: option});
    }

    onSkippableChange = (name, value) => {
        this.setState({skippable: value});
    }

    saveVideoLimitationsChanges = () => {
        const {publisherId, publishersSupplySourcesId, publishersSupplySourcesIds, manageVideoLimitations, isBulkBlock} = this.props;
        let videoLimitations = [];
        if (isBulkBlock) {
            publishersSupplySourcesIds.map(publishersSupplySourcesId => {
                return videoLimitations.push({
                    publisherId: publisherId,
                    uid: `${publisherId}-${publishersSupplySourcesId}`,
                    publishersSupplySourcesId,
                    skippable: !!Number(this.state.skippable),
                    duration: this.state.duration.value
                });
            });
        } else {
            videoLimitations = [{
                publisherId: publisherId,
                uid: `${publisherId}-${publishersSupplySourcesId}`,
                publishersSupplySourcesId,
                skippable: !!Number(this.state.skippable),
                duration: this.state.duration.value
            }];
        }

        manageVideoLimitations(publisherId, videoLimitations, 'videolimitation');
    }

    render() {
        const {isPublisherLevel,
            isDurationEnabled, isSkippableEnabled, isBulkBlock} = this.props;
        const videoLimitations  = getFilteredVideoLimitatoins(isBulkBlock, _.get(this.props, 'videoLimitations.data', []), this.supplySourcesIds, isPublisherLevel, 'VideoLimitations');
        const isPubLevel = videoLimitations && videoLimitations.length > 0 &&
        videoLimitations[0].publishersSupplySourcesId === 0;
        const isPubLevelDuration = isPubLevel && videoLimitations[0].duration > 0;
        const isPubLevelSkippable = isPubLevel && videoLimitations[0].skippable === 1;
        const isPublisherLevelDuration = !isPublisherLevel && isPubLevelDuration;
        const isPublisherLevelSkippable = !isPublisherLevel && isPubLevelSkippable;

        return (<Fragment>
            <div className={CLASS_NAMES.INNER_CONTAINER}>
                <div className={CLASS_NAMES.TITLE}>
                    {CONSTANT_TEXTS.VIDEO_LIMITATION}
                </div>
                {videoLimitations ?
                    <div>
                        {isDurationEnabled && <Fragment>
                            <div className={CLASS_NAMES.SECTION_WRAPPER}>
                                <div className={CLASS_NAMES.VIDEO_LIMITATION_TITLE}>
                                    {CONSTANT_TEXTS.AD_DURATION}
                                </div>
                                <div className={CLASS_NAMES.VIDEO_LIMITATION_DESCRIPTION}>
                                    {CONSTANT_TEXTS.AD_DURATION_DESCRIPTION}
                                    {isPublisherLevelDuration &&
                                        <div className={CLASS_NAMES.PUB_LEVEL_VIDEO_LIMITATION}>
                                            {CONSTANT_TEXTS.PUB_LEVEL_VIDEO_LIMITATION}
                                        </div>
                                    }
                                </div>
                                <Select className={CLASS_NAMES.VIDEO_LIMITATION_SELECT}
                                        options={adDurationOptions}
                                        onChange={this.onDurationChange}
                                        value={this.state.duration}
                                        type='select'
                                        disabled={isPublisherLevelDuration}
                                        hookId='ad-duration-select'
                                />
                            </div>
                            <div className={CLASS_NAMES.SEPARATOR}></div>
                        </Fragment>}
                        {isSkippableEnabled && <div className={CLASS_NAMES.SECTION_WRAPPER}>
                            <div className={CLASS_NAMES.VIDEO_LIMITATION_TITLE}>
                                {CONSTANT_TEXTS.SKIPPABLE}
                            </div>
                            <div className={CLASS_NAMES.VIDEO_LIMITATION_DESCRIPTION}>
                                {CONSTANT_TEXTS.SKIPPABLE_DESCRIPTION}
                                {isPublisherLevelSkippable &&
                                    <div className={CLASS_NAMES.PUB_LEVEL_VIDEO_LIMITATION}>
                                        {CONSTANT_TEXTS.PUB_LEVEL_VIDEO_LIMITATION}
                                    </div>
                                }
                            </div>
                            <div className={CLASS_NAMES.VIDEO_LIMITATION_SKIPPABLE}>
                                <RadioGroup hookId="video-limitation-skippable"
                                            name="skippable"
                                            onChange={this.onSkippableChange}
                                            inline={false}
                                            disabled={isPublisherLevelSkippable}
                                >
                                    {skippableOptions.map(option => {
                                        return <Radio key={option.label}
                                                      as="div"
                                                      value={option.value}
                                                      name={option.label}
                                                      checked={this.state.skippable == option.value}
                                                      hookId='video-limitation-radio'
                                        >
                                            {option.label}
                                        </Radio>;
                                    })}
                                </RadioGroup>
                            </div>
                        </div>}
                    </div> :
                    <Animation type="chasing"
                               height={100}
                               width={100}
                               rendererSettings={{preserveAspectRatio: 'xMaxYMax'}}
                               hookId='video-limitations-loading'
                    />
                }
            </div>
            <div className={CLASS_NAMES.FOOTER}>
                <Button onClick={this.props.cancelModal}
                        className={CLASS_NAMES.CANCEL_BUTTON}
                        transparent={true}
                        hookId='video-limitations-cancel-button'
                >
                    {CONSTANT_TEXTS.CANCEL}
                </Button>
                <Button onClick={this.saveVideoLimitationsChanges}
                        className={CLASS_NAMES.DONE_BUTTON}
                        hookId='video-limitations-save-button'
                >
                    {CONSTANT_TEXTS.SAVE}
                </Button>
            </div>
        </Fragment>)
    }
}

VideoLimitations.propTypes = {
    publisherId: PropTypes.number,
    isBulkBlock: PropTypes.bool,
    videoLimitations: PropTypes.object,
    isPublisherLevel: PropTypes.bool,
    onDurationChange: PropTypes.func,
    onSkippableChange: PropTypes.func,
    isDurationEnabled: PropTypes.bool,
    isSkippableEnabled: PropTypes.bool,
    publishersSupplySourcesIds: PropTypes.array,
    publishersSupplySourcesId: PropTypes.number,
    getVideoLimitations: PropTypes.func,
    manageVideoLimitations: PropTypes.func,
    cancelModal: PropTypes.func
};

const mapStateToProps = (state) => ({
    videoLimitations: state.supply.videoLimitations,
    isDurationEnabled: selectFeatureFlag('videoLimitationDuration')(state),
    isSkippableEnabled: selectFeatureFlag('videoLimitationSkippable')(state)
});

const mapDispatchToProps = ({
    getVideoLimitations,
    manageVideoLimitations
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoLimitations);