import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {validate} from '@perion-undertone/ramp-helpers';
import {selectFeatureFlag} from '@flopflip/react-redux';
import AccountLayout from '../AccountLayout';
import PasswordField from '../PasswordField';
import QRCodeField from '../QRCodeField';
import _ from 'lodash';
import UdriveLoader from '../../../UdriveLoader';
import {
    Image, isTimestampExpired, loginUser, redirectTo,
    resetAppState, setPassword, textProvider, loadFromLocalStorage
} from '../imports';

const {validatePassword} = validate;

const options = {
    minLength: 8,
    minLowerCaseLetters: 1,
    minUpperCaseLetters: 1,
    minSpecialChars: 1
};

const CLASS_NAMES = {
    PASSWORD_PLACEHOLDER: 'new-password-placeholder',
    NEW_PASSWORD_PLACEHOLDER_ERROR: 'ut-input error new-password-placeholder',
    NEW_PASSWORD_PLACEHOLDER_VALID: 'valid new-password-placeholder',
    ERROR : 'error-message',
    VALID_MESSAGE: 'valid-message'
};

const CONSTANT_TEXTS = {
    CONFIRM: textProvider.getText('updatePasswordComponent', 'submitText'),
    ERROR_MESSAGE: textProvider.getText('updatePasswordComponent', 'errorMessage'),
    COMPARE_ERROR_MESSAGE: textProvider.getText('updatePasswordComponent', 'compareErrorMessage'),
    VALIDATION_MESSAGE: textProvider.getText('addSitesModal', 'validationMessage'),
    NEW_PASSWORD_LABEL: textProvider.getText('updatePasswordComponent', 'newPasswordLabel'),
    REPEAT_PASSWORD_LABEL: textProvider.getText('updatePasswordComponent', 'repeatPasswordLabel'),
    TITLE: textProvider.getText('updatePasswordComponent', 'title'),
    LINK_EXPIRED: textProvider.getText('createPassword', 'linkExpired'),
    BACK_TO_LOGIN: textProvider.getText('resetPasswordComponent', 'backToLogin'),
    CONTINUE: textProvider.getText('updatePasswordConfirmation', 'hyperlinkMessage')
};

const renderValidationMessage = (conditionError, touched, compareError) => {
    let validationMsg = CONSTANT_TEXTS.VALIDATION_MESSAGE;
    let className = CLASS_NAMES.VALID_MESSAGE;
    if (!touched.password && !touched.repeatPassword) return <p className={className}></p>;
    if (touched.repeatPassword && compareError) {
        validationMsg = CONSTANT_TEXTS.COMPARE_ERROR_MESSAGE;
        className = CLASS_NAMES.ERROR;
    }
    if (conditionError) {
        validationMsg = CONSTANT_TEXTS.ERROR_MESSAGE;
        className = CLASS_NAMES.ERROR;
    }
    return <p className={className}>{validationMsg}</p>;
};

const checkInputClassName = (conditionError, touched, compareError, inputType) => {
    if (compareError || inputType === 'repeatPassword') {
        if (touched && compareError)
            return CLASS_NAMES.NEW_PASSWORD_PLACEHOLDER_ERROR;
        else if (!touched)
            return CLASS_NAMES.PASSWORD_PLACEHOLDER;
        else
            return CLASS_NAMES.NEW_PASSWORD_PLACEHOLDER_VALID;
    } else if (conditionError)
        return CLASS_NAMES.NEW_PASSWORD_PLACEHOLDER_ERROR;
    else if (!touched)
        return CLASS_NAMES.PASSWORD_PLACEHOLDER;
    else
        return CLASS_NAMES.NEW_PASSWORD_PLACEHOLDER_VALID;
};

const comparePasswords = (newPassword, repeatPassword) => newPassword.length > 0 && repeatPassword.length > 0 && newPassword === repeatPassword;

const CreatePassword = (props) => {
    const {resetRequestData, isMFAFeatureEnabled, qrCode, token} = props;
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [mfaEnabled, setMfaEnabled] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState('');
    const [inputsTouched, setInputsTouched] = useState({
        password: false,
        repeatPassword: false
    });
    const shouldDisplayImage = (!qrCode || !mfaEnabled);
    const [showImage, setShowImage] = useState(false);
    const [emptyCodeError, setEmptyCodeError] = useState(false);
    const [isButtonDisabled, setIsButtonIsDisabled] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        const mfaEnabled = loadFromLocalStorage('mfaEnabled');
        if (isMFAFeatureEnabled && !_.isNil(mfaEnabled)) {
            setMfaEnabled(true);
        }
    });

    useEffect(() => {
        if(isPasswordSet && isButtonDisabled && qrCode) {
            setIsButtonIsDisabled(false);
            setShowLoader(false);
        }
    }, [isPasswordSet, isButtonDisabled, qrCode]);

    const handleBlurCode = () => {
        code === '' && setInputsTouched({...inputsTouched, code: false});
    };

    const handleCodeChange = (e) => {
        if(emptyCodeError && !_.isEmpty(e.target.value)) {
            setEmptyCodeError(false);
        }
        setCode(e.target.value);
        setInputsTouched({...inputsTouched, code: true});
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setInputsTouched({...inputsTouched, password: true});
    };

    const handleRepeatPasswordChange = (e) => {
        setRepeatPassword(e.target.value);
        setInputsTouched({...inputsTouched, repeatPassword: true});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const shouldSubmit = comparePasswords(password, repeatPassword);
        const isPasswordValid = validatePassword(password, options).isValid;
        const conditionError = !isPasswordValid && inputsTouched.password;
        if(isPasswordSet && code && qrCode) {
            return props.loginUser(email, password, code, token);
        }else if(isPasswordSet && !code && qrCode) {
            setEmptyCodeError(true);
        }else if(isPasswordSet && !code && !qrCode) {
            if(_.isEmpty(code) && mfaEnabled) {
                return setEmptyCodeError(true);
            }
            props.loginUser(email, password, code);
            setIsButtonIsDisabled(true);
            setShowLoader(true);
            return setShowImage(true);
        }else if(isPasswordSet && code && !qrCode) {
            props.loginUser(email, password, code);
        }else {
            if (shouldSubmit && !conditionError) {
                return props.setPassword(resetRequestData.id, repeatPassword);
            }
        }
    };

    const backToReset = () => {
        props.resetAppState();
        redirectTo('/password-reset');
    };

    if (!resetRequestData || resetRequestData.data && resetRequestData.data.invalidToken) {
        backToReset();
        return <div></div>;
    }

    const email = resetRequestData && resetRequestData.data.username; // remove resetRequestData
    const isPasswordSet = props.setPassRequest && props.setPassRequest.isSet;

    const conditionError = !validatePassword(password, options).isValid && inputsTouched.password;
    const compareError = !comparePasswords(password, repeatPassword);
    const isDisabled = compareError || conditionError;
    const passwordInputClassName = checkInputClassName(conditionError, inputsTouched.password, null, 'password');
    const repeatPasswordInputClassName = checkInputClassName(null, inputsTouched.repeatPassword, compareError, 'repeatPassword');
    const shouldDisplayExpiredLink = resetRequestData && isTimestampExpired(resetRequestData.data.exp);
    const imgName = isPasswordSet ? 'password-success' : (shouldDisplayExpiredLink ? 'broken-link' : 'new-password');

    return (
        <AccountLayout size='small'
            className='create-password-layout'
            backToRoute={!isPasswordSet ? '/' : ''}
            backToText={CONSTANT_TEXTS.BACK_TO_LOGIN}
            headerProps={{
                header: !isPasswordSet ? CONSTANT_TEXTS.TITLE : '',
                subheader: shouldDisplayExpiredLink ? CONSTANT_TEXTS.LINK_EXPIRED : ' '
            }}
            footerProps={{
                buttonText: isPasswordSet ? CONSTANT_TEXTS.CONTINUE : CONSTANT_TEXTS.CONFIRM,
                isDisabled: isPasswordSet && isButtonDisabled ? true : isPasswordSet && !isButtonDisabled ? false : (shouldDisplayExpiredLink ? true : isDisabled),
                onSubmit: handleSubmit
            }}
        >
            <div className='create-password-img-wrapper'>
                {shouldDisplayImage && !showImage &&
                    <Image className={imgName}
                        name={`${imgName}.png`}
                        hookId='reset-password-expired-link'
                    />
                }
                {showLoader && <UdriveLoader />}
                {(qrCode || mfaEnabled) && isMFAFeatureEnabled && isPasswordSet && <QRCodeField
                    code={code}
                    qrCode={qrCode}
                    onChange={handleCodeChange}
                    handleBlur={handleBlurCode}
                    emptyCodeError={emptyCodeError}
                />}
            </div>
            {!isPasswordSet && !shouldDisplayExpiredLink && !code && !qrCode &&
                <div className='password-form-section'>
                    <PasswordField inputClass={passwordInputClassName}
                        password={password}
                        placeholder={'Enter password'}
                        onBlur={() => setInputsTouched({...inputsTouched, password: true})}
                        onChange={handlePasswordChange}
                    />
                    <PasswordField hideCriteria
                        inputClass={repeatPasswordInputClassName}
                        password={repeatPassword}
                        placeholder='Repeat password'
                        onBlur={() => setInputsTouched({...inputsTouched, repeatPassword: true})}
                        onChange={handleRepeatPasswordChange}
                    />
                    {renderValidationMessage(conditionError, inputsTouched, compareError)}
                </div>
            }
        </AccountLayout>
    );
};

CreatePassword.propTypes = {
    setPassword: PropTypes.func,
    setPassRequest: PropTypes.object,
    resetRequestData: PropTypes.object,
    resetAppState: PropTypes.func,
    loginUser: PropTypes.func,
    qrCode: PropTypes.string,
    token: PropTypes.string,
    isMFAFeatureEnabled: PropTypes.bool,
    loginError: PropTypes.string
};

const mapStateToProps = (state) => ({
    setPassRequest: state.app.setPassRequest,
    resetRequestData: state.app.resetRequestData,
    qrCode: state.app.user.qrCode,
    token: state.app.user.token,
    loginError: state.app.user.loginError,
    isMFAFeatureEnabled: selectFeatureFlag('useMfaInUdrive')(state)
});

const mapDispatchToProps = ({
    setPassword,
    loginUser,
    resetAppState
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword);
