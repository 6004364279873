import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Highlighter from 'react-highlight-words';
import {Input} from '@perion-undertone/ut-react-common';
import {changeAdSlotsValue, textProvider}  from '../imports';
import _ from 'lodash';

const CONSTANT_TEXTS = {
    INPUT_PLACEHOLDER: textProvider.getText('adSlots', 'adslotInputPlaceholder')
};

const AdslotsNameFormatter = (props) => {
    const {value, adSlotToEdit, row, changeAdSlotsValue, searchTerm} = props;
    const editMode = !_.isNil(row.id) && adSlotToEdit === row.id;

    const onChange = (event) => {
        return changeAdSlotsValue(row.id, {'name': event.target.value})
    }

    const isAutoFocusOn = !row.isPassBackFocused && !row.isSearchFocused && true;
    const adslotsInputHookId = value === '' ? `adslots-string-input-empty-field-${row.rowIndex}` : `adslots-string-input-${value}`;

    return (
        <div>
            {editMode ?
                <div>
                    <Input value={value}
                           onChange={onChange}
                           placeholder={CONSTANT_TEXTS.INPUT_PLACEHOLDER}
                           autoFocus={isAutoFocusOn}
                           hookId={adslotsInputHookId}
                    />
                </div> :
                <div className='ad-slots-highlight-search-term' data-automation={`ad-slot-string-value-${value}`}>
                    <Highlighter searchWords={[searchTerm]}
                                 autoEscape={true}
                                 textToHighlight={!row.name ? row : value}
                    />
                </div>
            }
        </div>
    )

}

AdslotsNameFormatter.propTypes = {
    value: PropTypes.string,
    adSlotToEdit: PropTypes.number,
    row: PropTypes.object,
    changeAdSlotsValue: PropTypes.func,
    searchTerm: PropTypes.string
};

const mapStateToProps = ({placements}) => ({
    adSlotToEdit: placements.currentGroup.adSlotToEdit,
    searchTerm: placements.currentGroup.searchTerm
})

const mapDispatchToProps = ({
    changeAdSlotsValue
})

export default connect(mapStateToProps, mapDispatchToProps)(AdslotsNameFormatter);