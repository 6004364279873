import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';

import {Image, textProvider} from './imports';

const CLASS_NAMES = {
    EXPORT_OPTIONS_CONTAINER: 'export-options-container',
    EXPORT_OPTION: 'export-option',
    EXPORT_OPTION_CONTENT: 'export-option-content'
};

const CONSTANT_TEXTS = {
    PRINT: textProvider.getText('exportOptions', 'print'),
    EXCEL: textProvider.getText('exportOptions', 'excel'),
    CSV: textProvider.getText('exportOptions', 'csv')
};

const PrintOption = () => {
    return (
        <div className={CLASS_NAMES.EXPORT_OPTION_CONTENT} >
            <Image className='img-export-icon' name='print.svg' hookId='export-option-print-icon' />
            {CONSTANT_TEXTS.PRINT}
        </div>
    );
};

const ExportOptions = (props) => {
    const {reportViewerRef, onExportClick} = props;

    const exportFile = (exportType) => {
        onExportClick(exportType);
    };

    return (
        <div className={CLASS_NAMES.EXPORT_OPTIONS_CONTAINER}>
            <div className={CLASS_NAMES.EXPORT_OPTION} >
                <ReactToPrint content={() => reportViewerRef.current}
                              trigger={PrintOption}
                />
            </div>
            <div className={CLASS_NAMES.EXPORT_OPTION} >
                <div className={CLASS_NAMES.EXPORT_OPTION_CONTENT}
                     onClick={() => exportFile('xlsx')}
                >
                    <Image className='img-export-icon' name='download-file.svg' hookId='export-option-excel-icon' />
                    {CONSTANT_TEXTS.EXCEL}
                </div>
            </div>
            <div className={CLASS_NAMES.EXPORT_OPTION} >
                <div className={CLASS_NAMES.EXPORT_OPTION_CONTENT}
                     onClick={() => onExportClick('csv')}
                >
                    <Image className='img-export-icon' name='download-file.svg' hookId='export-option-csv-icon' />
                    {CONSTANT_TEXTS.CSV}
                </div>
            </div>
        </div>
    );
};

ExportOptions.propTypes = {
    reportViewerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ]),
    onExportClick: PropTypes.func
};

export default ExportOptions;
