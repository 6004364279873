import Dashboard from './components/features/dashboard/Dashboard';
import Placements from './components/features/placements/Placements';
import Supply from './components/features/supply/Supply';
import Analytics from './components/features/analytics/Analytics';
import Payment from './components/features/payment/Payment';

export default [
    {
        path: '/:publisherId/dashboard',
        url: '/dashboard',
        exact: true,
        component: Dashboard,
        displayName: 'Dashboard',
        featureName: 'Dashboard',
        icon: 'icon-dashboard',
        img: 'img-dashboard.svg',
        imgActive: 'img-dashboard-active.svg'
    },
    {
        path: '/:publisherId/placements',
        url: '/placements',
        exact: true,
        component: Placements,
        displayName: 'Placements',
        featureName: 'Placements',
        icon: 'icon-placements',
        img: 'img-placements.svg',
        imgActive: 'img-placements-active.svg'
    },
    {
        path: '/:publisherId/certification',
        url: '/certification',
        exact: true,
        component: 'Certification',
        displayName: 'Certification',
        featureName: 'Certification',
        icon: 'icon-certification',
        img: 'img-certification.svg',
        imgActive: 'img-certification-active.svg'
    },
    {
        path: '/:publisherId/contract',
        url: '/contract',
        exact: true,
        component: 'Contract',
        displayName: 'Contract',
        featureName: 'Contract',
        icon: 'icon-contract',
        img: 'img-contract.svg',
        imgActive: 'img-contract-active.svg'
    },
    {
        path: '/:publisherId/supply',
        url: '/supply',
        exact: true,
        component: Supply,
        displayName: 'Supply',
        featureName: 'Supply',
        icon: 'icon-supply',
        img: 'img-supply.svg',
        imgActive: 'img-supply-active.svg'
    },
    {
        path: '/:publisherId/billing',
        url: '/billing',
        exact: true,
        component: 'Billing',
        displayName: 'Billing',
        featureName: 'Billing',
        icon: 'fas fa-dollar-sign',
        img: 'img-payment.svg',
        imgActive: 'img-payment-active.svg'
    },
    {
        path: '/:publisherId/payment',
        url: '/payment',
        exact: true,
        component: Payment,
        displayName: 'Payment',
        featureName: 'Payment',
        icon: 'icon-payment',
        img: 'img-payment.svg',
        imgActive: 'img-payment-active.svg'
    },
    {
        path: '/:publisherId/analytics',
        url: '/analytics',
        exact: true,
        component: Analytics,
        displayName: 'Analytics',
        featureName: 'Analytics',
        icon: 'icon-analytics',
        img: 'img-analytics.svg',
        imgActive: 'img-analytics-active.svg'
    },
];
