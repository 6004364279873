import React, {Component, Suspense} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';
import widgetTypes from './widgetType';
import PlaceholderLoader from './PlaceholderLoader';
import {getWidgetData, appStates, textProvider, RetryFetchData} from '../../imports';

const CONSTANT_TEXTS = {
    OOPS: textProvider.getText('errorMessages', 'oops'),
    SOMETHING_WENT_WRONG: textProvider.getText('errorMessages', 'somethingWentWrong'),
    CONTACT_ACCOUNT_MANAGER: textProvider.getText('errorMessages', 'contactAccountManager')
};

class Widget extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {getWidgetData, type, publisherId, initialPeriod} = this.props;
        getWidgetData(type, publisherId, initialPeriod);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.period !== this.props.period) {
            const {getWidgetData, type, publisherId, period} = this.props;
            getWidgetData(type, publisherId, period);
        }
    }

    fetchData = () => {
        const {getWidgetData, type, publisherId, period} = this.props;
        getWidgetData(type, publisherId, period);
    }

    render() {
        const {type, widgetData, user, isNewClassName} = this.props;
        const {component: Component} = widgetTypes.getType(type);
        const data = widgetData[type];
        if (!data || data.state === appStates.LOADING) {
            return (<PlaceholderLoader type={type}/>);
        }

        if (data.state === appStates.ERROR) {
            return <RetryFetchData classNames={cx({'centered': type !== 'main-kpis'}, 'white')}
                                   header={CONSTANT_TEXTS.OOPS}
                                   subheader={`${CONSTANT_TEXTS.SOMETHING_WENT_WRONG}. ${CONSTANT_TEXTS.CONTACT_ACCOUNT_MANAGER}`}
            />;
        }

        return (
            <Suspense fallback={<PlaceholderLoader type={type}/>}>
                <Component data={data.data} user={user} isNewClassName={isNewClassName}/>
            </Suspense>);
    }
}

Widget.propTypes = {
    type: PropTypes.string,
    widgetData: PropTypes.object,
    getWidgetData: PropTypes.func,
    publisherId: PropTypes.number,
    initialPeriod: PropTypes.object,
    period: PropTypes.object,
    user: PropTypes.object,
    isNewClassName: PropTypes.string
};

const mapStateToProps = ({dashboard, app}) => ({
    widgetData: dashboard,
    period: dashboard.period,
    user: app.user
});

const mapDispatchToProps = {
    getWidgetData
}

export default connect(mapStateToProps, mapDispatchToProps)(Widget);
