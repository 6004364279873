import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';

import {Image, textProvider, setZoomFactor} from './imports';

const CLASS_NAMES = {
    ZOOM_LEVEL_CONTAINER: 'zoom-level-container',
    ZOOM_LEVEL_RESET_TO_DEFAULT: 'zoom-level-reset-to-default',
    ZOOM_ICON_WRAPPER: 'zoom-icon-wrapper',
    ZOOM_ICON_MINUS: 'zoom-icon-minus',
    ZOOM_ICON_PLUS: 'zoom-icon-plus',
    ZOOM_TEXT: 'zoom-text',
    ZOOM_PERCENTAGE: 'zoom-percentage'
};

const CONSTANT_TEXTS = {
    ZOOM: textProvider.getText('webPreview', 'zoom'),
    RESET: textProvider.getText('webPreview', 'reset')
};

export const ZoomTools = (props) => {
    const {setZoomFactor, zoomFactor} = props;

    const zoomIn = () => {
        if (zoomFactor + 0.1 > 2) return;
        return setZoomFactor(zoomFactor + 0.1);
    };
    const zoomOut = () => {
        if (zoomFactor - 0.1 < 1) return;
        return setZoomFactor(zoomFactor - 0.1);
    };
    const resetZoom = () => {
        return setZoomFactor(1);
    };

    return (
        <div className={CLASS_NAMES.ZOOM_LEVEL_CONTAINER}>
            <div onClick={zoomOut}
                className={cx(CLASS_NAMES.ZOOM_ICON_WRAPPER, CLASS_NAMES.ZOOM_ICON_MINUS)}
            >
                <Image name='zoom-out.svg' hookId='zoom-icon-minus' />
            </div>
            <div className={CLASS_NAMES.ZOOM_TEXT} >
                {CONSTANT_TEXTS.ZOOM}
            </div>
            <div onClick={zoomIn}
                className={cx(CLASS_NAMES.ZOOM_ICON_WRAPPER, CLASS_NAMES.ZOOM_ICON_PLUS)}
            >
                <Image name='zoom-in.svg' hookId='zoom-icon-plus' />
            </div>
            <div className={CLASS_NAMES.ZOOM_PERCENTAGE} >
                {`${Math.round(zoomFactor * 100)}%`}
            </div>
            <div onClick={resetZoom} className={CLASS_NAMES.ZOOM_LEVEL_RESET_TO_DEFAULT} >
                {CONSTANT_TEXTS.RESET}
            </div>
        </div>
    );
};

ZoomTools.propTypes = {
    setZoomFactor: PropTypes.func,
    zoomFactor: PropTypes.number
};

const mapStateToProps = ({reportPreview}) => ({
    zoomFactor: reportPreview.zoomFactor
});

const mapDispatchToProps = ({
    setZoomFactor
});

export default connect(mapStateToProps, mapDispatchToProps)(ZoomTools);
