import React from 'react';
import PropTypes from 'prop-types';

import VideoDirection from './VideoDirection';
import {VIDEO_DIRECTION_DETAILS} from './utils';

const CLASS_NAMES = {
    SECTION_WRAPPER: 'section-wrapper',
    ADUNIT_CONFIGURATION_TYPE_TITLE: 'configuration-type-title',
    ADUNIT_CONFIGURATION_TYPE: 'configuration-type',
    ADUNIT_CONFIGURATION_TYPE_ACTION: 'configuration-type-action',
    ACTION_DESCRIPTION: 'action-description',
    ADUNIT_CONFIG_PREVIEW_CONTAINER: 'adunit-config-preview-container'
};

const AdUnitConfiguration = (props) => {
    const {adUnitKey, adUnitName, type, description} = props;

    return (
        <div className={CLASS_NAMES.SECTION_WRAPPER}>
            <div className={CLASS_NAMES.ADUNIT_CONFIGURATION_TYPE_TITLE}>
                <span className={CLASS_NAMES.ADUNIT_CONFIGURATION_TYPE}>
                    {`${adUnitName} - `}
                </span>
                <span className={CLASS_NAMES.ADUNIT_CONFIGURATION_TYPE_ACTION}>
                    {type}
                </span>
            </div>
            <div className={CLASS_NAMES.ACTION_DESCRIPTION}>
                {description}
            </div>
            <div className={CLASS_NAMES.ADUNIT_CONFIG_PREVIEW_CONTAINER}>
                <VideoDirection {...props}
                                details={VIDEO_DIRECTION_DETAILS[adUnitKey]}
                />
            </div>
        </div>
    );
};

AdUnitConfiguration.propTypes = {
    adUnitKey: PropTypes.string,
    adUnitName: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string
};

export default AdUnitConfiguration;