import React from 'react';
import {of} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {analyticsConstants} from '../constants/analyticsConstants';
import {ToastProvider} from '@perion-undertone/ut-react-common';
import {
    getPredefinedReportsListSuccess, getPredefinedReportsListFailed,
    getPredefinedReportDataSuccess, getPredefinedReportDataFailed,
    getReportPreviewSuccess, getReportPreviewFailed,
    setReportSchedulingSuccess, setReportSchedulingFailed,
    getPublisherReportsConfigSuccess, getPublisherReportsConfigFailed,
    updateReportQuerySuccess, updateReportQueryFailed
} from '../actions/analyticsActions';
import Toaster from '../components/Toaster';
import textProvider from '../texts/textProvider';

const headers = {
    'Content-Type': 'application/json'
};

const {
    PREDEFINED_REPORTS_LIST_REQUEST, PREDEFINED_REPORT_DATA_REQUEST,
    RAW_REPORT_DATA_REQUEST, SET_REPORT_SCHEDULING_REQUEST,
    PUBLISHER_REPORTS_CONFIG_REQUEST, UPDATE_REPORT_QUERY_REQUEST
} = analyticsConstants;

const CONSTANT_TEXTS = {
    DOWNLOAD_FAILED_NOTIFICATION: textProvider.getText('analytics', 'downloadFailedNotification'),
    DOWNLOAD_SUCCESSFUL_NOTIFICATION: textProvider.getText('analytics', 'downloadSuccessfulNotification'),
    UPDATE_SUCCESSFUL_NOTIFICATION: textProvider.getText('analytics', 'updateSuccessfulNotification'),
    UPDATE_FAILED_NOTIFICATION: textProvider.getText('analytics', 'updateFailedNotification')
};

export const requestPredefinedReportsListEpic = actions => (
    actions.pipe(
        ofType(PREDEFINED_REPORTS_LIST_REQUEST),
        mergeMap((action) => {
            const {publisherId} = action.payload;

            const route = `api/analytics/publisher/${publisherId}/reports`;

            return ajax.getJSON(route, headers).pipe(
                map(data => getPredefinedReportsListSuccess(data)),
                catchError(error => of(getPredefinedReportsListFailed(error)))
            )}
        )
    )
);

export const requestPredefinedReportDataEpic = actions => (
    actions.pipe(
        ofType(PREDEFINED_REPORT_DATA_REQUEST),
        mergeMap((action) => {
            const {publisherId, reportId, exportType, period, userEmail, reportQuery} = action.payload;
            const {type, from, to} = period;
            const periodQStr = type === 'custom' ? `period=${type}&from=${from}&to=${to}` : `period=${type}`;
            const expTypeQStr = `exportType=${exportType}`;
            const userEmailQStr = `publisherEmail=${userEmail}`;

            const queryStr = `${periodQStr}&${expTypeQStr}&${userEmailQStr}`;
            const route = `api/analytics/publisher/${publisherId}/reports/${reportId}?${queryStr}`;
            return ajax.post(route, {reportQuery}, headers).pipe(
                map(result => {
                    const data = result.response;
                    const message = data.message || CONSTANT_TEXTS.DOWNLOAD_SUCCESSFUL_NOTIFICATION;
                    ToastProvider.success(
                        <Toaster notificationIcon='icon-updated_notifiction'
                                 notificationHookId='toaster-updated-icon'
                                 notificationMessage={message}
                        />,
                    true);
                    return getPredefinedReportDataSuccess(data, exportType)
                }),
                catchError(error => {
                    ToastProvider.error(
                        <Toaster notificationIcon='icon-error__notifiction'
                                 notificationHookId='toaster-error-icon'
                                 notificationMessage={CONSTANT_TEXTS.DOWNLOAD_FAILED_NOTIFICATION}
                        />,
                    true);
                    return of(getPredefinedReportDataFailed(error))
                })
            )}
        )
    )
);


export const requestPublisherReportsConfigEpic = actions => (
    actions.pipe(
        ofType(PUBLISHER_REPORTS_CONFIG_REQUEST),
        mergeMap((action) => {
            const {publisherId} = action.payload;
            const route = `api/analytics/publisher/${publisherId}/publisher-reports-config`;

            return ajax.getJSON(route, headers).pipe(
                map(data => getPublisherReportsConfigSuccess(data)),
                catchError(error => of(getPublisherReportsConfigFailed(error)))
            )}
        )
    )
);

export const requestRawReportDataEpic = actions => (
    actions.pipe(
        ofType(RAW_REPORT_DATA_REQUEST),
        mergeMap((action) => {
            const {publisherId, reportId, period} = action.payload;
            const {type, from, to} = period;
            const rawContentStr = `rawContent=1`;
            const expTypeQStr = `exportType=web`;
            const periodQStr = type === 'custom' ? `period=${type}&from=${from}&to=${to}` : `period=${type}`;

            const queryStr = `${periodQStr}&${expTypeQStr}&${rawContentStr}`;
            const route = `api/analytics/publisher/${publisherId}/reports/${reportId}?${queryStr}`;

            return ajax.post(route, headers).pipe(
                map(data => getReportPreviewSuccess(data.response)),
                catchError(error => of(getReportPreviewFailed(error)))
            )}
        )
    )
);

export const setReportSchedulingEpic = actions => (
    actions.pipe(
        ofType(SET_REPORT_SCHEDULING_REQUEST),
        mergeMap(action => {
            const {data} = action.payload;
            const {publisherId, id} = data;

            const route = `api/analytics/publisher/${publisherId}/reports/${id}/set-schedule`;

            return ajax.post(route, data, headers).pipe(
                map(data => setReportSchedulingSuccess(data.response)),
                catchError(error => of(setReportSchedulingFailed(error.response)))
            )
        })
    )
);

export const updateReportQueryEpic = actions => (
    actions.pipe(
        ofType(UPDATE_REPORT_QUERY_REQUEST),
        mergeMap((action) => {
            const {publisherId, reportId, reportQuery} = action.payload;

            const route = `api/analytics/publisher/${publisherId}/reports/${reportId}/update-report-query`;
            return ajax.post(route, {reportQuery}, headers).pipe(
                map(result => {
                    const data = result.response;
                    const message = data.message || CONSTANT_TEXTS.UPDATE_SUCCESSFUL_NOTIFICATION;
                    ToastProvider.success(
                        <Toaster notificationIcon='icon-updated_notifiction'
                                 notificationHookId='toaster-updated-icon'
                                 notificationMessage={message}
                        />,
                    true);
                    return updateReportQuerySuccess(data);
                }),
                catchError(error => {
                    ToastProvider.error(
                        <Toaster notificationIcon='icon-error__notifiction'
                                 notificationHookId='toaster-error-icon'
                                 notificationMessage={CONSTANT_TEXTS.UPDATE_FAILED_NOTIFICATION}
                        />,
                    true);
                    return of(updateReportQueryFailed(error))
                })
            )}
        )
    )
);