import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {textProvider, Image, displayEnterSite} from './imports';
import ApprovalCounter from './ApprovalCounter';

const CLASS_NAMES = {
    SITE_UNDER_REVIEW_PAGE_WRAPPER: 'site-under-review-page-wrapper',
    SITE_REVIEW_DESCRIPTION_WRAPPER: 'site-review-description-wrapper',
    SITE_REVIEW_TITLE: 'site-review-title',
    SITE_REVIEW_TEXT_DIVIDER: 'site-review-text-divider',
    NOTE_HOLDER: 'note-holder',
    VISIT_HELP_CENTER_WRAPPER: 'visit-help-center-wrapper',
    VISIT_HELP_CENTER_TEXT: 'visit-help-center-text',
    LINK: 'link-underline'
};

const CONSTANT_TEXTS = {
    TITLE: textProvider.getText('onboardingModal', 'step_2_1.title'),
    YOUR_SITE: textProvider.getText('onboardingModal', 'step_2_1.approval1'),
    WAITING_APPROVAL: textProvider.getText('onboardingModal', 'step_2_1.approval2'),
    REVIEW_CAN_TAKE_UP_TO: textProvider.getText('onboardingModal', 'step_2_1.approvalMessage'),
    EMAIL_YOU_WHEN_READY: textProvider.getText('onboardingModal', 'step_2_1.approvalMessage1'),
    NOT_YOUR_SITE: textProvider.getText('onboardingModal', 'step_2_1.info1'),
    TRY_AGAIN: textProvider.getText('onboardingModal', 'step_2_1.info2'),
    NOTE_PREVIOUS_SITE: textProvider.getText('onboardingModal', 'step_2_1.info3'),
    WHAT_TO_DO: textProvider.getText('onboardingModal', 'step_2_1.whatToDo'),
    VISIT_OUR: textProvider.getText('onboardingModal', 'step_2_1.visitOur'),
    HELP_CENTER: textProvider.getText('onboardingModal', 'step_2_1.helpCenter'),
};

export const SiteReviewDescription = (props) => {
    const {address} = props;

    const renderEnterSite = () => {
        props.displayEnterSite();
    }

    return (
        <div className={CLASS_NAMES.SITE_REVIEW_DESCRIPTION_WRAPPER}>
            <h2 className={CLASS_NAMES.SITE_REVIEW_TITLE}>
                {CONSTANT_TEXTS.TITLE}
            </h2>
            <p>
                {CONSTANT_TEXTS.YOUR_SITE}
                <b>{` ${address} `}</b>
                {CONSTANT_TEXTS.WAITING_APPROVAL}
            </p>
            <hr className={CLASS_NAMES.SITE_REVIEW_TEXT_DIVIDER} />
            <p>
                {CONSTANT_TEXTS.REVIEW_CAN_TAKE_UP_TO}
                <br />
                {CONSTANT_TEXTS.EMAIL_YOU_WHEN_READY}
            </p>
            <div className={CLASS_NAMES.NOTE_HOLDER}>
                <Image name='exclamation-note.png'
                       hookId='exclamation-note'
                />
                <span>
                    {`${CONSTANT_TEXTS.NOT_YOUR_SITE} `}
                    <span className={CLASS_NAMES.LINK} onClick={() => renderEnterSite()} >
                        {CONSTANT_TEXTS.TRY_AGAIN}
                    </span>
                    <br/>
                    {CONSTANT_TEXTS.NOTE_PREVIOUS_SITE}
                </span>
            </div>
        </div>
    );
};

SiteReviewDescription.propTypes = {
    address: PropTypes.string,
    displayEnterSite: PropTypes.func
};

export const VisitHelpCenter = () => {
    return (
        <div className={CLASS_NAMES.VISIT_HELP_CENTER_WRAPPER}>
        <span>{CONSTANT_TEXTS.WHAT_TO_DO}</span>
        <span className={CLASS_NAMES.VISIT_HELP_CENTER_TEXT}>
            {` ${CONSTANT_TEXTS.VISIT_OUR} `}
        </span>
        <span className={CLASS_NAMES.LINK}>
            {CONSTANT_TEXTS.HELP_CENTER}
        </span>
    </div>
    );
};

const SiteUnderReviewPage = (props) => {
    const {onboarding} = props;

    return (
        <div className={CLASS_NAMES.SITE_UNDER_REVIEW_PAGE_WRAPPER} >
            <SiteReviewDescription address={onboarding.mainSite.SupplySource.address}
                                   displayEnterSite={props.displayEnterSite}
            />
            <ApprovalCounter date={onboarding.mainSite.createdAt} />
            <VisitHelpCenter />
        </div>
    );
};

SiteUnderReviewPage.propTypes = {
    user: PropTypes.object,
    onboarding: PropTypes.object,
    closeModal: PropTypes.func,
    displayEnterSite: PropTypes.func
};

const mapStateToProps = ({app}) => ({
    user: app.user,
    onboarding: app.onboarding
});

const mapDispatchToProps = ({
    displayEnterSite
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteUnderReviewPage);