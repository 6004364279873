import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';
import {Modal} from '@perion-undertone/ut-react-common';
import {selectFeatureFlag} from '@flopflip/react-redux';
import {isEmpty} from 'lodash';

import PlacementGroupHeader from './PlacementGroupHeader';
import PlacementGroupFooter from './PlacementGroupFooter';
import AdUnitsList from './AdUnitsList';
import AdvancedSettings from './AdvancedSettings';
import SspConfigurations from './SspConfigurations';
import {
    appStates,
    closePlacementGroupModal,
    clearSelectedAdUnits,
    PreviewSideBar
} from '../imports';

const CLASS_NAMES = {
    PLACEMENT_GROUP_MODAL: 'placement-group-modal',
    PLACEMENT_GROUP_MODAL_BODY: 'placement-group-modal-body',
    PLACEMENT_GROUP_MODAL_BODY_ADVANCED_SETTINGS: 'placement-group-modal-body-advanced-settings',
    PLACEMENT_GROUP_SSP_WRAPPER: 'placement-ssp-wrapper'
};

const PlacementGroupBody = (props) => {
    const isSSPAllowed = props.isPlacementGroupThrottelingOn;
    return (
        <div className={cx({[CLASS_NAMES.PLACEMENT_GROUP_MODAL_BODY_ADVANCED_SETTINGS]: props.activePlacementTab !== 'adUnitsTab', [CLASS_NAMES.PLACEMENT_GROUP_SSP_WRAPPER]: props.activePlacementTab === 'sspconfigTab'},
                CLASS_NAMES.PLACEMENT_GROUP_MODAL_BODY)}>
            {props.activePlacementTab === 'adUnitsTab' ?
                <Fragment>
                    <AdUnitsList tagKey={props.tagKey}/>
                    <PreviewSideBar />
                </Fragment> : props.activePlacementTab === 'sspconfigTab' ? <SspConfigurations isSSPAllowed={isSSPAllowed} placementTagTypeId={props.placementTagTypeId} /> 
                : <AdvancedSettings placementTagTypeId={props.placementTagTypeId}/>
            }
        </div>)
    ;
};
PlacementGroupBody.propTypes = {
    tagKey: PropTypes.string,
    placementTagTypeId: PropTypes.number,
    activePlacementTab: PropTypes.string,
    isPlacementGroupThrottelingOn: PropTypes.bool
};

const PlacementGroupModal = (props) => {
    const [isSaveClicked, setSaveClicked] = useState(false);
    const [showAdSlotsContent, setShowAdSlotsContent] = useState(true);
    const placementTagOptions = props.placementTags && 
    props.placementTags.map((item) => {return {value: item.id, label: item.name}});
    const [selectedPlacementTag, setSelectedPlacementTag] = useState({value: 1, label: ''});
    const [prevCurrentGroupId, setPrevCurrentGroupId] = useState(null);
    const [activePlacementTab, setActivePlacementTab] = useState('adUnitsTab');

    useEffect(
        () => {
            if(props.currentGroup && props.currentGroup.placementTagTypeId
                && prevCurrentGroupId !== props.currentGroup.placementTagTypeId) {
                const editModeTag = props.currentGroup.placementTagTypeId && 
                placementTagOptions.find(x=>x.value == props.currentGroup.placementTagTypeId);
                setSelectedPlacementTag(editModeTag);
                setPrevCurrentGroupId(editModeTag.value);
            }
            if (!selectedPlacementTag.label && placementTagOptions) {
                const defaultSelectedTag = placementTagOptions[0];
                setSelectedPlacementTag(defaultSelectedTag);
                setPrevCurrentGroupId(null);
            }
            if(props.activeGroup) {
                setActivePlacementTab('adUnitsTab');
            }
        },
        [props.placementTags, props.currentGroup]
    );
    const {publisherId, currentGroup, loadingState, isPlacementGroupTagTypesEnabled,
        placementsPagination, searchTerm, customization, isPlacementGroupThrottelingOn} = props;
    if (!currentGroup) {
        return null;
    }
    const cancelModal = () => {
        setSaveClicked(false);
        setPrevCurrentGroupId(null);
        setActivePlacementTab('adUnitsTab');
        props.closePlacementGroupModal();
    };

    const {adUnits, adSlots, name} = currentGroup;
    const isInvalidGroupName = isSaveClicked && (!name || name.length < 1);
    const isInvalidGroupAdUnits = isSaveClicked && (!adUnits || isEmpty(adUnits));

    const onPlacementTabChange = (activeTab) => {
        setActivePlacementTab(activeTab);
    }
    const isInvalidGroup = () => {
        if (loadingState === appStates.LOADING) return true;
        if (!name || name.length < 1) return true;
        if (!adUnits || isEmpty(adUnits)) return true;
        if (adSlots.find(adSlot => !isEmpty(adSlot) && adSlot.isDuplicate)) return true;
        return false;
    };
    const onPlacementTagChange = (event) => {
        props.clearSelectedAdUnits();
        setSelectedPlacementTag(event);
    };
    return (
        <Modal basic={true}
               open={!!currentGroup}
               className={CLASS_NAMES.PLACEMENT_GROUP_MODAL}
               closeOnOutsideClick={false}
               onModalClose={cancelModal}
               hookId='ad-units-modal'
        >
            <PlacementGroupHeader currentGroup={currentGroup}
                                  isInvalidGroupName={isInvalidGroupName}
                                  isInvalidGroupAdUnits={isInvalidGroupAdUnits}
                                  showAdSlotsContent={showAdSlotsContent}
                                  setShowAdSlotsContent={setShowAdSlotsContent}
                                  selectedPlacementTag={selectedPlacementTag}
                                  placementTagOptions={placementTagOptions}
                                  onPlacementTagChange={onPlacementTagChange}
                                  onPlacementTabChange={onPlacementTabChange}
                                  activePlacementTab={activePlacementTab}
            />
            <PlacementGroupBody placementTagTypeId={selectedPlacementTag.value} 
                                tagKey={selectedPlacementTag.label} 
                                activePlacementTab={activePlacementTab} 
                                isPlacementGroupThrottelingOn={isPlacementGroupThrottelingOn}
                                />
            <PlacementGroupFooter cancelModal={cancelModal}
                                  publisherId={publisherId}
                                  currentGroup={currentGroup}
                                  isInvalidGroup={isInvalidGroup}
                                  isSaveClicked={isSaveClicked}
                                  setSaveClicked={setSaveClicked}
                                  setShowAdSlotsContent={setShowAdSlotsContent}
                                  placementTagTypeId={selectedPlacementTag.value}
                                  isPlacementGroupTagTypesEnabled={isPlacementGroupTagTypesEnabled}
                                  setActivePlacementTab={setActivePlacementTab}
                                  placementsPagination={placementsPagination}
                                  searchTerm={searchTerm}
                                  customization={customization}
            />
        </Modal>
    );
};

PlacementGroupModal.propTypes = {
    name: PropTypes.string,
    currentGroup: PropTypes.object,
    adUnits: PropTypes.object,
    closePlacementGroupModal: PropTypes.func,
    clearSelectedAdUnits: PropTypes.func,
    loadingState: PropTypes.string,
    publisherId: PropTypes.number,
    tagKey: PropTypes.string,
    placementTags: PropTypes.array,
    isPlacementGroupTagTypesEnabled: PropTypes.bool,
    activeGroup: PropTypes.object,
    placementsPagination: PropTypes.object,
    searchTerm: PropTypes.string,
    customization: PropTypes.object,
    isPlacementGroupThrottelingOn: PropTypes.bool
};

const mapStateToProps = (state) => ({
    currentGroup: state.placements.currentGroup,
    activeGroup: state.placements.activeGroup,
    adUnits: state.placements.adUnits,
    placementTags: state.placements.placementTags,
    loadingState: state.placements.placementGroups ? state.placements.placementGroups.state : '',
    isPlacementGroupTagTypesEnabled: selectFeatureFlag('placementGroupTagTypes')(state),
    isPlacementGroupThrottelingOn: selectFeatureFlag('isPlacementGroupThrottelingOn')(state),
    placementsPagination: state.placements.placementsPagination,
    searchTerm: state.placements.searchTerm,
    customization: state.grid.customization,
})

const mapDispatchToProps = ({
    closePlacementGroupModal,
    clearSelectedAdUnits
});

export default connect(mapStateToProps, mapDispatchToProps)(PlacementGroupModal);
