import {adUnitsConstants} from '../constants/adUnitsConstants';

export const getAdUnits = (publisherId) => ({
    type: adUnitsConstants.GET_AD_UNITS_REQUEST,
    payload: {publisherId}
});
export const getAdUnitsSuccess = ({adUnits, placementTypes, placementTags}) => ({
    type: adUnitsConstants.GET_AD_UNITS_SUCCESS,
    payload: {adUnits, placementTypes, placementTags}
});
export const getAdUnitsFailed = (error) => ({
    type: adUnitsConstants.GET_AD_UNITS_FAILURE,
    payload: {error}
});