import React from 'react';
import PropTypes from 'prop-types';
import textProvider from '../texts/textProvider';

const CLASS_NAMES = {
    GRID_NO_ROWS_TEMPLATE_WRAPPER: 'grid-no-rows-template-wrapper',
    GRID_NO_ROWS_TEMPLATE_IMAGE: 'grid-no-rows-template-image',
    GRID_NO_ROWS_TEMPLATE_TEXT: 'grid-no-rows-template-text'
};

const CONSTANT_TEXTS = {
    NO_ROWS_MESSAGE: textProvider.getText('gridNoRowsTemplate', 'message')
}

const GridNoRowsTemplate = ({className, message=CONSTANT_TEXTS.NO_ROWS_MESSAGE}) => {
    return (
        <div className={CLASS_NAMES.GRID_NO_ROWS_TEMPLATE_WRAPPER}>
            <div>
                <div className={CLASS_NAMES.GRID_NO_ROWS_TEMPLATE_IMAGE}></div>
                <div className={className || CLASS_NAMES.GRID_NO_ROWS_TEMPLATE_TEXT}>{message}</div>
            </div>
        </div>
    );
};

GridNoRowsTemplate.propTypes = {
    message: PropTypes.string,
    className: PropTypes.string
};

export default GridNoRowsTemplate;