import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {enums} from '@perion-undertone/ramp-helpers';
import {Modal, Animation} from '@perion-undertone/ut-react-common';

import OnboardingHeader from "./OnboardingHeader";
import OnboardingSteps from "./OnboardingSteps";
import {appStates} from "../../../../constants/appStates";
import {displayEnterSite} from './imports';

const {PUBLISHER_ONBOARDING_STEPS} = enums;
const {ENTER_SITE} = PUBLISHER_ONBOARDING_STEPS;

const CLASS_NAMES = {
    ONBOARD_INNER: 'ut-onboard-wizard',
    ONBOARDING_LOADING_WRAPPER: 'onboarding-loading-wrapper'
};

class OnboardingModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: true
        };

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.props.displayEnterSite(false);
        this.setState({isOpen: false});
    }

    render() {
        const {user, onboarding, shouldDisplayEnterSite} = this.props;
        const step = shouldDisplayEnterSite ? ENTER_SITE : user.data.onBoardingStep;
        const Component = OnboardingSteps.getOnboardComponentStep(step);

        return (
            <Modal className={CLASS_NAMES.ONBOARD_INNER}
                   open={this.state.isOpen}
                   closeOnOutsideClick={false}
                   closeButton={shouldDisplayEnterSite ? true : false}
                   onModalClose={this.closeModal}
                   hookId='onboarding-modal'
            >
                <div className="layout-top">
                    <OnboardingHeader user={user} step={step} />
                </div>
                <div className="ut-onboard-content">
                    {!onboarding || onboarding.state === appStates.LOADING ?
                        <div className={CLASS_NAMES.ONBOARDING_LOADING_WRAPPER}>
                            <Animation type='loadingCircle'
                                       height={200}
                                       width={200}
                                       hookId='onboarding-modal-loading'
                                       rendererSettings={{preserveAspectRatio: 'xMaxYMax'}}
                            />
                        </div> :
                        <Component closeModal={this.closeModal} />
                    }
                </div>
            </Modal>
        );
    }
}

OnboardingModal.propTypes = {
    shouldDisplayEnterSite: PropTypes.bool,
    user: PropTypes.object,
    onboarding: PropTypes.object,
    displayEnterSite: PropTypes.func
};

const mapStateToProps = ({app}) => ({
    user: app.user,
    onboarding: app.onboarding,
    shouldDisplayEnterSite: app.shouldDisplayEnterSite
});

const mapDispatchToProps = ({
    displayEnterSite
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingModal);