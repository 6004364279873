import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';
import {get, startsWith} from 'lodash';
import App from './components/App';
import {appStates} from './constants/appStates';
import {getCurrentUser} from './auth';
import {GAProvider} from '@perion-undertone/ut-react-common';

import './fontPreLoader';

import 'normalize.css';
import 'react-virtualized/styles.css';
import '@perion-undertone/ut-ui-resources/utIcon/ut-icon.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '../stylesheets/main.scss';

const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
const inspectUpdates = urlParams.get('inspectUpdates');

if (process.env.NODE_ENV !== 'production' && inspectUpdates) {
    const {whyDidYouUpdate} = require('why-did-you-update');
    whyDidYouUpdate(React);
}

// const getConfig = async () => {
//     const response = await fetch('/config');
//     const data = await response.json();
//     return data;
// };

const getFeatureConfig = async () => {
    const response = await fetch('/api/features');
    const data = await response.json();
    return data;
};

const getResetRequestData = async () => {
    let resetRequestData;

    if (startsWith(window.location.hash, 'password-set', 2)) {
        const id = urlParams.get('id');
        const tokenData = await fetch(`/api/auth/token-data?id=${id}`);
        const data = await tokenData.json();
        resetRequestData = {id, data};
    }

    return resetRequestData;
}

const prerender = async () => {
    // const config = await getConfig();
    // window.clientConfig = config;

    return Promise.all([
        getCurrentUser(),
        getFeatureConfig(),
        getResetRequestData()
    ]);
};

const prerenderer = async () => {

    const [profileData, features, resetRequestData] = await prerender();

    const initialState = {
        app: {
            user: {
                data: profileData,
                state: profileData ? appStates.LOADED : appStates.DEFAULT
            },
            resetRequestData
        },
        features: features ? features : []
    };
    try{
        const res = await fetch(`/config`);
        const config = await res.json();
        window.clientConfig = config;
        GAProvider.initialize(get(window, 'clientConfig.googleAnalyticsSuiteId'));
    } catch (err) {
        initialState.app = {error: 'App could not load'};
    }
    ReactDOM.render(<App initialState={initialState} />, document.getElementById('app'));
};

prerenderer();
