import React from 'react';
import {Animation} from '@perion-undertone/ut-react-common';

import {textProvider} from './imports';

const LOADING = textProvider.getText('common', 'loading');

const UdriveLoader = () => {
    return (
        <div className='udrive-loader-wrapper'>
            <Animation type='udriveLogo'
                       height={100}
                       width={100}
                       hookId='udrive-logo-loader'
                       rendererSettings={{preserveAspectRatio: 'xMaxYMax'}}
            />
            <div className='loading-text'>{LOADING}</div>
        </div>
    );
};

export default UdriveLoader;