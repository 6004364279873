import {map} from 'lodash';

import {formatString, PERIOD_TYPES, textProvider} from '../imports';

const RECURRENCE_TYPES = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly'
};

const FORMAT_TYPES = {
    EXCEL: 'xlsx',
    CSV: 'csv'
};

export const TXT = {
    RECURRENCE: textProvider.getText('analytics', 'recurrence'),
    REPEATS_EVERY: textProvider.getText('analytics', 'repeatsEvery'),
    END_SCHEDULING: textProvider.getText('analytics', 'endScheduling'),
    REMOVE_RECURRENCE: textProvider.getText('analytics', 'removeRecurrence'),
    RUNS_AT: textProvider.getText('analytics', 'runsAtBeginningOf'),
    REPORT_DATE_RANGE: textProvider.getText('analytics', 'reportDateRange'),
    SEND_TO: textProvider.getText('sendEmail', 'sendTo'),
    RECIPIENTS: textProvider.getText('sendEmail', 'recipients'),
    CANCEL: textProvider.getText('buttonsTxt', 'cancel'),
    SAVE: textProvider.getText('buttonsTxt', 'save'),
    FORMAT: textProvider.getText('analytics', 'format'),
    EXCEL: textProvider.getText('analytics.formatTypes', 'excel'),
    CSV: textProvider.getText('analytics.formatTypes', 'csv'),
};

export const PERIOD_VALUES = {
    [PERIOD_TYPES.YESTERDAY]: textProvider.getText('analytics.presets', 'yesterday'),
    [PERIOD_TYPES.LAST_7_DAYS]: textProvider.getText('analytics.presets', 'last7days'),
    [PERIOD_TYPES.LAST_30_DAYS]: textProvider.getText('analytics.presets', 'last30days'),
    [PERIOD_TYPES.THIS_MONTH]: textProvider.getText('analytics.presets', 'thisMonth'),
    [PERIOD_TYPES.LAST_MONTH]: textProvider.getText('analytics.presets', 'lastMonth')
};

export const RECURRENCE_INTERVALS = {
    [RECURRENCE_TYPES.DAILY]: textProvider.getText('analytics.recurrenceIntervals', 'day'),
    [RECURRENCE_TYPES.WEEKLY]: textProvider.getText('analytics.recurrenceIntervals', 'week'),
    [RECURRENCE_TYPES.MONTHLY]: textProvider.getText('analytics.recurrenceIntervals', 'month')
};

export const FORMAT_VALUES = {
    [FORMAT_TYPES.EXCEL]: textProvider.getText('analytics.formatTypes', 'excel'),
    [FORMAT_TYPES.CSV]: textProvider.getText('analytics.formatTypes', 'csv')
};

const intervalPeriodMap = {
    [RECURRENCE_TYPES.DAILY]: PERIOD_TYPES.YESTERDAY,
    [RECURRENCE_TYPES.WEEKLY]: PERIOD_TYPES.LAST_7_DAYS,
    [RECURRENCE_TYPES.MONTHLY]: PERIOD_TYPES.LAST_MONTH
};

export const getPeriodOnRepeatSelection = (value) => getSelectedPeriodOption(intervalPeriodMap[value]);

export const getSelectedRepeatOption = (recurrenceInterval) => {
    return {label: RECURRENCE_INTERVALS[recurrenceInterval], value: recurrenceInterval};
}

export const getIntervalSelectOptions = () => {
    const result = map(RECURRENCE_INTERVALS, (value, key) => {
        return {label: value, value: key}
    });
    return result;
}

export const getSelectedPeriodOption = (timePeriod) => {
    return {label: PERIOD_VALUES[timePeriod], value: timePeriod};
}

export const getPeriodSelectOptions = () => {
    const result = map(PERIOD_VALUES, (value, key) => {
        return {label: value, value: key}
    });
    return result;
}

export const runningAtInfo = (interval) => {
    const runsAtInfo = textProvider.getText('analytics', `${interval}Run`);
    return formatString(`${TXT.RUNS_AT} {0} (09:00 AM). `, runsAtInfo);
};