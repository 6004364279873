import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Button, Textarea, Popover, confirm} from '@perion-undertone/ut-react-common';
import Icon from '../../Icon';
import {textProvider} from './imports';
import {updateReportQuery, changeReportQuery, deleteChangedQuery} from '../../../actions/analyticsActions';

const CLASS_NAMES = {
	CONTAINER: 'sql-report-container',
	BANNER: 'banner',
	BANNER_ELEMENTS: 'banner-elements',
	CODE_ICON: 'code-icon',
	SQL_TITLE: 'sql-title',
	ARROW_ICON: 'arrow-icon',
	EMPTY_PLACEHOLDER: 'empty-placeholder-div',
	BORDER_CLOSED_VIEW: 'border-closed-view',
	CONTENT_OPENED_VIEW: 'content-opened-view',
	HEADER: 'header',
	TEXT_AREA: 'text-area',
	REPORT_NAME: 'report-name',
	ACTIONS_CONTAINER: 'actions-container',
	PREVIEW_CONTAINER: 'preview-container',
	PREVIEW_ICON: 'preview-icon',
	COPY_CONTAINER: 'copy-container',
	COPY_ICON: 'copy-icon',
	FOOTER: 'footer',
	CANCEL_BTN: 'cancel-btn',
	SAVE_BTN: 'save-btn',
	TEXTAREA: 'textarea',
};

const CONSTANT_TEXTS = {
	SQL_TITLE: textProvider.getText('analytics.sqlReportQuery', 'sqlCode'),
	PREVIEW: textProvider.getText('analytics.sqlReportQuery', 'preview'),
	COPY: textProvider.getText('analytics.sqlReportQuery', 'copyCode'),
	CANCEL: textProvider.getText('analytics.sqlReportQuery', 'cancel'),
	SAVE: textProvider.getText('analytics.sqlReportQuery', 'save'),
	COPIED: textProvider.getText('analytics.sqlReportQuery', 'copied'),
	YES: textProvider.getText('analytics.sqlReportQuery.confirmationModal', 'yes'),
	NO: textProvider.getText('analytics.sqlReportQuery.confirmationModal', 'no'),
	HEADER: textProvider.getText('analytics.sqlReportQuery.confirmationModal', 'cancelChanges'),
	MESSAGE: textProvider.getText('analytics.sqlReportQuery.confirmationModal', 'confirmationMessage'),
	DISABLE_SAVE_MESSAGE: textProvider.getText('analytics.sqlReportQuery', 'disableSaveMessage'),
	MISSING_PARAMS_MESSAGE: textProvider.getText('analytics.sqlReportQuery', 'missingParamsMessage'),
}


class SQLReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			previewQuery: '',
			reportName: '',
			isSQLTextareaOpen: false,
            isQueryChanged: false,
			isSaveDisabled: false
		}
	}

	componentDidMount() {
		this.setLocalStateQueryToQueryFromDB();
	}

	componentDidUpdate(prevProps) {
		const isQueryChanged = this.isQueryChanged();
		if (prevProps.reportId !== this.props.reportId) {
			this.onUpdateReport(this.props.predefinedReportsList.data, this.props.reportId);
			if(_.has(this.props.predefinedReportsList.changedQueries, this.props.reportId) && this.state.isSQLTextareaOpen === false) {
				this.setState({isSQLTextareaOpen: true});
			}	
			if(!_.has(this.props.predefinedReportsList.changedQueries, this.props.reportId) && this.state.isSQLTextareaOpen === true) {
				this.setState({isSQLTextareaOpen: false});
			}
		}
		if(!isQueryChanged && this.state.isQueryChanged) {
			this.setState({isQueryChanged: false});
		}
		if(isQueryChanged && !this.state.isQueryChanged) {
			this.setState({isQueryChanged: true});
		}
	}

    setLocalStateQueryToQueryFromDB = (shouldDelete) => {
        const {predefinedReportsList, reportId, deleteChangedQuery} = this.props;
		if(shouldDelete) {
			deleteChangedQuery(reportId);
		}
		this.onUpdateReport(predefinedReportsList.data, reportId, shouldDelete)
		this.setState({isSQLTextareaOpen: false, isQueryChanged: false});
    }

	onUpdateReport = (reports, id, shouldDelete) => {
		const report = _.find(reports, {'id': id})
		const name = report.name;
		const query = report.query;
		const changedQuery = _.has(this.props.predefinedReportsList.changedQueries, id) ? this.props.predefinedReportsList.changedQueries[id] : query;
		const isQueryValid = this.validateReportQuery(changedQuery);
		this.setState({
			previewQuery: shouldDelete ? query : changedQuery,
			reportName: name,
			isSaveDisabled: !isQueryValid
		});
	}

	startPreviewQuery = () => {
		this.props.startPreviewQuery('xlsx', this.state.previewQuery);
	}

	toggleSQLTextarea = async () => {
		if (this.state.isSQLTextareaOpen && this.isQueryChanged()) {
            return await confirm({
				onConfirm: () => this.setLocalStateQueryToQueryFromDB(true),
				confirmationMessage: CONSTANT_TEXTS.MESSAGE,
				headerText: CONSTANT_TEXTS.HEADER,
				cancelButtonText: CONSTANT_TEXTS.NO,
				confirmButtonText: CONSTANT_TEXTS.YES,
				className: 'sql-cancel-changes-modal'
			});
        }
		this.setState({isSQLTextareaOpen: !this.state.isSQLTextareaOpen});
	}
	copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
	};
	onChangeQuery = (e) => {
		const {reportId, changeReportQuery} = this.props;
        this.setState({previewQuery: e.target.value});
        const isQueryChanged = this.isQueryChanged();
        this.setState({isQueryChanged});
		changeReportQuery(reportId, e.target.value);
		const isQueryValid = this.validateReportQuery(e.target.value);
		this.setState({isSaveDisabled: !isQueryValid});
    }
	validateReportQuery = (query) => {
		// const publisherIdRegex = /(?<=publisher_id = ).*/g;
		// const publisherIdMatch = query.match(publisherIdRegex);
		// console.log(publisherIdMatch);

		// const dateBetweenRegex = /(?<=[\s]BETWEEN).*/g;
		// const dateBetweenMatch = query.match(dateBetweenRegex);
		// console.log(dateBetweenMatch);

		// const dateBiggerThanRegex = /(?<=[\s]>=).*/g;
		// const dateBiggerThanMatch = query.match(dateBiggerThanRegex);
		// console.log(dateBiggerThanMatch);

		//--------------------------------------------------------------

		const isPublisherIdPresent = _.includes(query, ':publisherId');
		const isFromPresent = _.includes(query, ':from');
		const isToPresent = _.includes(query, ':to');

		const isQueryValid = isPublisherIdPresent && isFromPresent && isToPresent;

		return isQueryValid;
	}
	savePreviewQuery = () => {
		const {publisherId, reportId, updateReportQuery} = this.props;
		const {previewQuery, isSaveDisabled} = this.state;
		!isSaveDisabled && updateReportQuery(publisherId, reportId, previewQuery);
	}

    cancelQueryChanges = () => {
        if (!this.isQueryChanged()) {
            this.setState({isSQLTextareaOpen: false});
        }
    }

    isQueryChanged = () => {
        const {predefinedReportsList, reportId} = this.props;
        const {previewQuery} = this.state;
        const reportQueryFromDb = _.find(predefinedReportsList.data, {'id': reportId});
        return reportQueryFromDb.query.trim() !== previewQuery.trim();
    }

	render() {
		return (
			<div className={CLASS_NAMES.CONTAINER}>
				<div className={CLASS_NAMES.BANNER}>
				<div className={CLASS_NAMES.BANNER_ELEMENTS} onClick={this.toggleSQLTextarea}>
					<Icon icon="adslot-icon.svg" className={CLASS_NAMES.CODE_ICON} />
					<div className={CLASS_NAMES.SQL_TITLE}>{CONSTANT_TEXTS.SQL_TITLE}</div>
					<div className={CLASS_NAMES.ARROW_ICON}>
						{this.state.isSQLTextareaOpen ?
							<Icon icon="pagging_arrow_next"/>
							: <Icon icon="pagging_arrow_back"/>
						}
					</div>
				</div>
				<div className={CLASS_NAMES.EMPTY_PLACEHOLDER}/>
				</div>
				{!this.state.isSQLTextareaOpen
					? <div className={CLASS_NAMES.BORDER_CLOSED_VIEW} />
					: <div className={CLASS_NAMES.CONTENT_OPENED_VIEW}>
						<div className={CLASS_NAMES.HEADER}>
							<div className={CLASS_NAMES.REPORT_NAME}>{this.state.reportName}</div>
							<div className={CLASS_NAMES.ACTIONS_CONTAINER}>
								<div className={CLASS_NAMES.PREVIEW_CONTAINER}>
								<Icon icon="preview" className={CLASS_NAMES.PREVIEW_ICON} onClick={this.startPreviewQuery} />
								<span>{CONSTANT_TEXTS.PREVIEW}</span>
								</div>
								<div className={CLASS_NAMES.COPY_CONTAINER}>
									<Popover
                                        autoDismissAfter={1000}
                                        basic
                                        trigger={
                                            <div className={CLASS_NAMES.COPY_ICON}>
                                                <Icon
                                                    icon="copy_transparent.svg"
                                                    onClick={() => this.copyToClipboard(this.state.previewQuery)}
												/>
                                            </div>
                                        }
									>
										{CONSTANT_TEXTS.COPIED}
									</Popover>
									<span>{CONSTANT_TEXTS.COPY}</span>
								</div>
							</div>
						</div>
						<div className={CLASS_NAMES.TEXT_AREA}>
						<Textarea
							key={'queryTextarea'}
							className={CLASS_NAMES.TEXTAREA}
							onChange={this.onChangeQuery}
							value={this.state.previewQuery}
							hookId="query-textarea"
							/>
						</div>
						<div className={CLASS_NAMES.FOOTER}>
							<Button className={CLASS_NAMES.CANCEL_BTN}
                                onClick={this.cancelQueryChanges}
								hookId="cancel-button-query-code"
							>
								{CONSTANT_TEXTS.CANCEL}
							</Button>
							<Button className={CLASS_NAMES.SAVE_BTN}
                                onClick={this.savePreviewQuery}
								hookId="save-button-query-code"
                                disabled={this.state.isSaveDisabled || !this.state.isQueryChanged}
                                disableMessage={this.state.isSaveDisabled ? CONSTANT_TEXTS.MISSING_PARAMS_MESSAGE : CONSTANT_TEXTS.DISABLE_SAVE_MESSAGE}
                            >
								{CONSTANT_TEXTS.SAVE}
							</Button>
						</div>
					</div>
				}
			</div>
		)
	}
}

SQLReport.propTypes = {
	predefinedReportsList: PropTypes.object,
	reportId: PropTypes.string,
	publisherId: PropTypes.number,
	startPreviewQuery: PropTypes.func,
	updateReportQuery: PropTypes.func,
	changeReportQuery: PropTypes.func,
	deleteChangedQuery: PropTypes.func,
};

const mapDispatchToProps = {
	updateReportQuery,
	changeReportQuery,
	deleteChangedQuery
};

export default connect(null, mapDispatchToProps)(SQLReport);
