import {ajax as Ajax} from "rxjs/ajax";
import Cookies from 'js-cookie'
const getHeadersPos = (ajaxMethod) => {
    switch (ajaxMethod) {
        case 'get':
        case 'getJSON':
        case 'delete':
            return 1;
        case 'patch':
        case 'post':
        case 'put':
            return 2;
        default:
            return -1;
    }
};
const ajaxProxy = (getDefaultHeadersFn = ()=> {}) => {
    return (obj) => {
        return new Proxy(obj, {
            get(target, propKey) {
                const origProp = target[propKey];
                const headersPos = getHeadersPos(propKey);
                if (headersPos === -1 || typeof origProp !== 'function') {
                    return origProp;
                }
                return function (...args) {
                    args[headersPos] = {...args[headersPos], ...getDefaultHeadersFn()};
                    // @ts-ignore
                    return origProp.apply(this, args);
                };
            }
        });
    }
};

const getDefaultHeadersFn = () => ({'x-xsrf-token': Cookies.get('x-xsrf-token')});
const ajax = ajaxProxy(getDefaultHeadersFn)(Ajax);
export default ajax;