import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {selectFeatureFlag} from '@flopflip/react-redux';
import {Checkbox, Textarea, Input} from '@perion-undertone/ut-react-common';
import {
    setPlacementGroupOneOffPublisher,
    setPlacementGroupPassbackTag,
    textProvider,
    setIsThrotteling,
    setThrotteling,
    setUseSystemAppendPixel,
    setIsPreferVidzaooPlayer
} from '../imports';
import AdSlotsList from './AdSlotsList';
import _ from 'lodash';
import {PUBLISHER_TYPES_MAP, getDecimalValue} from '../../../../utils/globals';


const CLASS_NAMES = {
    CONTAINER: 'advanced-settings-container',
    GENERAL: 'advanced-settings-general',
    GENERAL_TITLE: 'advanced-settings-general-title',
    GENERAL_INNER: 'advanced-settings-general-inner',
    ONE_OFF_PUBLISHER: 'advanced-settings-one-off-publisher',
    PASSBACK_TAG_TITLE: 'advanced-settings-passback-tag-title',
    PASSBACK_TAG: 'advanced-settings-passback-tag'
};

const CONSTANT_TEXTS = {
    ONE_OFF_PUBLISHER: textProvider.getText('advancedSettings', 'oneOffPublisher'),
    GENERAL: textProvider.getText('advancedSettings', 'general'),
    PASSBACK_TAG: textProvider.getText('advancedSettings', 'passbackTag'),
    PASSBACK_TAG_PLACEHOLDER: textProvider.getText('advancedSettings', 'passbackTagPlaceholder'),
    IGONRE_APPEND_PIXEL: textProvider.getText('advancedSettings', 'ignoreappendpixel'),
    OVERRIDE_THROTTLING: textProvider.getText('advancedSettings', 'overrideThrottling'),
    SSP_TITLE: textProvider.getText('advancedSettings', 'sspconfigtitle'),
    WINEVENT: textProvider.getText('advancedSettings', 'winevent'),
    AUCTIONPRICE: textProvider.getText('advancedSettings', 'auctionprice'),
    VIDAZOOPLAYER: textProvider.getText('advancedSettings', 'vidazooplayer')

}

const BASE_PUBLISHER_TYPES = ['Aggregator', 'Direct', 'Test', 'SSP'];


class AdvancedSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocused: false
        };
    }

    setOneOffPublisher = () => {
        this.props.setPlacementGroupOneOffPublisher(!this.props.currentGroup.isOneOff);
    };
    setPassbackTag = (event) => {
        const passbackTag = event.target.value;
        this.props.setPlacementGroupPassbackTag(passbackTag);
    };

    setIsThrotteling =  () =>{
        this.props.setIsThrotteling(!this.props.currentGroup.isOverrideThrottling);
    }

    setUseSystemAppendPixel = () =>{
        this.props.setUseSystemAppendPixel(!this.props.currentGroup.ignoreSystemAppendPixel);
    }

    setIsPreferVidzaooPlayer = () =>{
        this.props.setIsPreferVidzaooPlayer(!this.props.currentGroup.isPreferVidazoo);
    }


    setThrotteling = event =>{
        const value = event.target.value;
        const {isFocused} = this.state;
        if( (!_.isNaN(value) && value >= 0 && value <= 100) || _.isEmpty(event.target.value)){
            const nextValue = getDecimalValue(_.isEmpty(event.target.value) ? '' : value , 2);
            if(!nextValue.isValid){
                return;
            }

            if(!isFocused){
                this.setState({isFocused: true}, () => this.props.setThrotteling(nextValue.value))
            }else{
                this.props.setThrotteling(nextValue.value);
            }
        }
    }

    render () {
        const {isPlacementGroupOneOffEnabled, publisher, currentGroup, placementGroups, isPlacementGroupThrottelingOn, placementTagTypeId} = this.props;
        const {isOneOff, passbackTag, placementGroupId, isOverrideThrottling, throttling, 
            ignoreSystemAppendPixel, isPreferVidazoo} = currentGroup;
        const shouldShowCheckbox = publisher.data && BASE_PUBLISHER_TYPES.find(type => type === PUBLISHER_TYPES_MAP[publisher.data.publisherTypeId]);
        const placementGroupForEdit = placementGroups && placementGroups.data &&
            placementGroups.data.find(group => group.placementGroupId === placementGroupId);
        const isSelectedDisabledOneOff = placementGroupForEdit && placementGroupForEdit.isOneOff;
        
        return (
            <div className={CLASS_NAMES.CONTAINER}>
                <div className={CLASS_NAMES.GENERAL}>
                    <div className={CLASS_NAMES.GENERAL_TITLE}>{CONSTANT_TEXTS.GENERAL}</div>
                    <div className={CLASS_NAMES.GENERAL_INNER}>
                        {isPlacementGroupOneOffEnabled && shouldShowCheckbox &&
                        <div className={CLASS_NAMES.ONE_OFF_PUBLISHER}>
                            <Checkbox squared={true}
                                      transparent={true}
                                      inverted={true}
                                      checked={currentGroup && isOneOff ? isOneOff : false}
                                      disabled={isSelectedDisabledOneOff}
                                      onChange={this.setOneOffPublisher}
                                      hookId='one-off-publisher'
                                >
                                    {CONSTANT_TEXTS.ONE_OFF_PUBLISHER}
                            </Checkbox>
                        </div>}
                        <div className={CLASS_NAMES.PASSBACK_TAG_TITLE}>{CONSTANT_TEXTS.PASSBACK_TAG}</div>
                        <div className={CLASS_NAMES.PASSBACK_TAG}>
                            <Textarea cols={5}
                                      rows={5}
                                      placeholder={CONSTANT_TEXTS.PASSBACK_TAG_PLACEHOLDER}
                                      value={passbackTag}
                                      onFocus={() => this.setState({isFocused: true})}
                                      onBlur={() => this.setState({isFocused: false})}
                                      onChange={this.setPassbackTag}
                                      hookId='passback-tag'
                            />
                        </div>
                        {isPlacementGroupThrottelingOn && <div className='throtteling-wrapper'>
                        <Checkbox squared={true}
                                      transparent={true}
                                      inverted={true}
                                      checked={isOverrideThrottling}
                                      onChange={this.setIsThrotteling}
                                      hookId='thortteling-override'
                                >
                                     {CONSTANT_TEXTS.OVERRIDE_THROTTLING}
                            </Checkbox>
                            <Input onChange={this.setThrotteling} min="0" max="100" disabled={!isOverrideThrottling} hookId="thortteling-percentage" value={throttling} className="thortteling-percentage-input" />%
                        </div>
                        }
                        {isPlacementGroupThrottelingOn && <div className='use-pixel-wrapper'>
                               <Checkbox squared={true}
                                      transparent={true}
                                      inverted={true}
                                      checked={ignoreSystemAppendPixel}
                                      onChange={this.setUseSystemAppendPixel}
                                      hookId='system-append-pixel'>
                                     {CONSTANT_TEXTS.IGONRE_APPEND_PIXEL}
                            </Checkbox> 
                        </div>}
                        {isPlacementGroupThrottelingOn && 
                       <>
                       {placementTagTypeId === 2 && <div>
                            <Checkbox squared={true}
                                      transparent={true}
                                      inverted={true}
                                      checked={isPreferVidazoo}
                                      onChange={this.setIsPreferVidzaooPlayer}
                                      hookId='prefer-vidazoo-player'>
                                         {CONSTANT_TEXTS.VIDAZOOPLAYER}
                            </Checkbox>
                        </div>}
                       </>}
                    </div>
                </div>
                <AdSlotsList isPassBackFocused={this.state.isFocused}/>
            </div>
        )
    }
}

AdvancedSettings.propTypes = {
    isPlacementGroupOneOffEnabled: PropTypes.bool,
    publisher: PropTypes.object,
    currentGroup: PropTypes.object,
    placementGroups: PropTypes.object,
    setPlacementGroupOneOffPublisher: PropTypes.func,
    setPlacementGroupPassbackTag: PropTypes.func,
    isDisabledThrottlingFlagEnabled: PropTypes.bool,
    setIsThrotteling: PropTypes.func,
    setThrotteling: PropTypes.func,
    isPlacementGroupThrottelingOn: PropTypes.bool,
    setUseSystemAppendPixel: PropTypes.func,
    setIsPreferVidzaooPlayer: PropTypes.func,
    placementTagTypeId: PropTypes.number
};

const mapStateToProps = (state) => ({
    isPlacementGroupOneOffEnabled: selectFeatureFlag('placementGroupOneOff')(state),
    isPlacementGroupThrottelingOn: selectFeatureFlag('isPlacementGroupThrottelingOn')(state),
    publisher: state.app.publisher,
    currentGroup: state.placements.currentGroup,
    placementGroups: state.placements.placementGroups
});

const mapDispatchToProps = ({
    setPlacementGroupOneOffPublisher,
    setPlacementGroupPassbackTag,
    setIsThrotteling,
    setThrotteling,
    setUseSystemAppendPixel,
    setIsPreferVidzaooPlayer
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSettings);