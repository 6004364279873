import _ from 'lodash';

export const getFilteredVideoLimitatoins = (isBulkEdit, limitationsData, supplySourceIds, isPubliserLevelEdit ,tab) =>{

     if(!isBulkEdit){
        return limitationsData;
     }

     if(  (isPubliserLevelEdit && tab === 'ignoreSystemAppendPixel') || _.isEmpty(limitationsData)){
        return [];
     }

     const publisherLevelData = limitationsData.filter(x =>{
        return ((x.publishersSupplySourcesId === 0 && x.duration > 0) || (x.publishersSupplySourcesId === 0 && x.skippable == true));
    });
    const bulkLimitationsData = limitationsData.filter(x=>x.publishersSupplySourcesId !== 0);
    // const intIdsSupplySourceLevel = supplySourceIds.filter(x=>x !== 0);

    // Check for length bulkDataLength and requested ids
    const supplySourcesLength = bulkLimitationsData.length === _.get(supplySourceIds, 'length', 0);

    const fistLimitationData = _.first(bulkLimitationsData);

    const {duration, skippable, ignoreSystemAppendPixel} = fistLimitationData;

    let hasMutualData = false;
    if(tab === 'ignoreSystemAppendPixel'){
        hasMutualData = supplySourcesLength && bulkLimitationsData.every(item => item.ignoreSystemAppendPixel === ignoreSystemAppendPixel);
        return hasMutualData ? fistLimitationData : [];
    }else{
        hasMutualData = supplySourcesLength && bulkLimitationsData.every(item => item.duration === duration && item.skippable === skippable);
    }
    let mutualData;
    // Both publisher data and mutual data
    if(publisherLevelData.length && hasMutualData) {
        // Adding the first element from the mutual
        publisherLevelData.push(limitationsData[0]);
        mutualData = publisherLevelData;
    }else if(publisherLevelData.length && !hasMutualData){
        // No mutual data, we return publisher level data
        mutualData = publisherLevelData;
    }else if(!publisherLevelData.length && hasMutualData) {
        // No publisher level data, we return the first element from the mutual data
        mutualData = [bulkLimitationsData[0]];
    }else {
        // No data
        mutualData = [];
    }
    return mutualData;

}