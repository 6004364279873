import React, {Suspense, lazy} from 'react';
import PropTypes from 'prop-types';
import {Animation} from '@perion-undertone/ut-react-common';

const CLASS_NAMES = {
    GEO_WRAPPER: 'geo-wrapper',
    GEO_FLAG: 'geo-flag',
    GEO_NAME: 'geo-name'
};

const GEO = ['US', 'UK', 'MX', 'CA'];

const GEO_DATA = {
    US: {
        code: 'US',
        name: 'USA',
        img: 'usa.png'
    },
    CA: {
        code: 'CA',
        name: 'Canada',
        img: 'canada.svg'
    },
    MX: {
        code: 'MX',
        name: 'Mexico',
        img: 'mexico.svg'
    },
    UK: {
        code: 'UK',
        name: 'United Kingdom',
        img: 'uk.svg'
    }
};

const FlagIcon = lazy(() => import('./Icon'));
const FlagLoader = <Animation type="chasing" height={50} width={50} rendererSettings={{preserveAspectRatio: 'xMaxYMax'}} hookId='flag-loader' />;

export const Geo = (props) => {
    const {code} = props;
    const {img, name} = GEO_DATA[code];
    return (
        <div className={CLASS_NAMES.GEO_WRAPPER} >
            <Suspense fallback={FlagLoader} >
                <FlagIcon icon={img} />
            </Suspense>
            <span className={CLASS_NAMES.GEO_NAME} >
                {name}
            </span>
        </div>
    )
};

Geo.propTypes = {
    code: PropTypes.string
};

export const geoSelectOptions = GEO.map((code) => {
    const country = GEO_DATA[code];
    return {
        label: <Geo code={country.code} />,
        value: country.code
    }
});

export const getGeos = (geos = []) => {
    return geos.map((code) => {
        const country = GEO_DATA[code];
        return {
            label: <Geo code={country.code} />,
            value: country.code
        }
    });
}