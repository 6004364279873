import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import cx from 'classnames';
import {createSelector} from 'reselect';
import {AutoSizer} from 'react-virtualized';
import {getHookId, Tabs} from '@perion-undertone/ut-react-common';

import {textProvider, getPaymentData, appStates, RetryFetchData, UdriveLoader} from './imports';

const CLASS_NAMES = {
    PAYMENT_CONTAINER: 'payment-container',
    PAYMENT_WRAPPER: 'payment-wrapper',
    PAYMENT_HEADER_CONTAINER: 'payment-header-container',
    PAYMENT_HEADER_TITLE: 'payment-header-title',
    PAYMENT_BODY_CONTAINER: 'payment-body-container',
    PAYMENT_TIPALTI_FORM: 'payment-tipalti-form',
    PAYMENT_TABS: 'payment-tabs',
    PAYMENT_SIDEBAR: 'payment-sidebar',
    PAYMENT_SIDEBAR_HEADER: 'payment-sidebar-header',
    PAYMENT_SIDEBAR_MENU: 'payment-sidebar-menu',
    PAYMENT_SIDEBAR_MENU_ITEM: 'payment-sidebar-menu-item',
    PAYMENT_SIDEBAR_MENU_ITEM_ACTIVE: 'payment-sidebar-menu-item--active'
};

const CONSTANT_TEXTS = {
    PAYMENT: textProvider.getText('payment', 'payment'),
    PAYMENT_DETAILS: textProvider.getText('payment', 'paymentDetails'),
    PAYMENT_HISTORY: textProvider.getText('payment', 'paymentHistory'),
    DIVISION_TITLE: textProvider.getText('payment', 'divisionTitle'),
    MISSING_CONTRACT_SIGNATURE: textProvider.getText('payment', 'missingContractSignature'),
};

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDivision: null,
            isIframeLoaded: false
        };
    }

    componentDidMount() {
        const {getPaymentData, publisherId} = this.props;
        getPaymentData(publisherId);
    }

    onDivisionChanged = (item) => {
        this.setState((prevState) => (_.assign({}, prevState, {selectedDivision: item})));
    };

    iframeOnload = () => {
        this.setState((prevState) => (_.assign({}, prevState, {isIframeLoaded: true})));
    };

    render() {
        const {payment: {state}, divisionOptions, defaultDivisionValue} = this.props;
        const {isIframeLoaded} = this.state;
        const divisionCount = _.size(divisionOptions);

        if (state === appStates.ERROR || (!divisionCount && state === appStates.LOADED)) {
            return <RetryFetchData classNames='centered' header={CONSTANT_TEXTS.MISSING_CONTRACT_SIGNATURE} subheader='' />
        }

        const selectedDivision = this.state.selectedDivision || defaultDivisionValue || _.first(divisionOptions);
        const activeDivision = divisionOptions && divisionOptions.find(item => item.supplyDivisionId === selectedDivision.supplyDivisionId);

        return (
            <div className={CLASS_NAMES.PAYMENT_CONTAINER}
                 {...getHookId('payment-page')}
            >
                {(state === appStates.LOADING || !isIframeLoaded) && <UdriveLoader />}
                <div className={cx(CLASS_NAMES.PAYMENT_WRAPPER, {'hidden': !isIframeLoaded})}>
                    <div className={CLASS_NAMES.PAYMENT_HEADER_CONTAINER}>
                        <div className={CLASS_NAMES.PAYMENT_HEADER_TITLE}>
                            {CONSTANT_TEXTS.PAYMENT}
                        </div>
                    </div>
                        <Tabs defaultActiveIndex={0}  className={CLASS_NAMES.PAYMENT_TABS} hookId={`${activeDivision && activeDivision.name || 'payment-tabs'}`}>
                        <Tabs.Tab header={CONSTANT_TEXTS.PAYMENT_DETAILS} hookId="payment-details">
                            <div className={CLASS_NAMES.PAYMENT_BODY_CONTAINER}>
                            {divisionCount > 1 && <div className={CLASS_NAMES.PAYMENT_SIDEBAR}>
                                <div className={CLASS_NAMES.PAYMENT_SIDEBAR_HEADER}>
                                    {CONSTANT_TEXTS.DIVISION_TITLE}
                                </div>
                                <ul className={CLASS_NAMES.PAYMENT_SIDEBAR_MENU}>
                                    {divisionOptions.map((item, index) => {
                                        return (<li key={index}
                                                    onClick={this.onDivisionChanged.bind(this, item)}
                                                    className={cx(CLASS_NAMES.PAYMENT_SIDEBAR_MENU_ITEM,
                                                        {[CLASS_NAMES.PAYMENT_SIDEBAR_MENU_ITEM_ACTIVE]: item.supplyDivisionId === selectedDivision.supplyDivisionId})}
                                        >
                                            {item.name}
                                        </li>)
                                    })}
                                </ul>
                            </div>}
                            {divisionCount > 0 && <div className={cx(CLASS_NAMES.PAYMENT_TIPALTI_FORM, 'payment-details')}>
                                <AutoSizer>
                                    {({height}) => (<iframe src={selectedDivision.url} frameBorder="0" onLoad={this.iframeOnload} width={760} height={height}></iframe>)}
                                </AutoSizer>
                            </div>}
                        </div>
                        </Tabs.Tab>
                        <Tabs.Tab header={CONSTANT_TEXTS.PAYMENT_HISTORY} hookId="payment-history">
                            <div className={CLASS_NAMES.PAYMENT_BODY_CONTAINER}>
                                {divisionCount > 0 && <div className={cx(CLASS_NAMES.PAYMENT_TIPALTI_FORM, 'payment-history')}>
                                    <AutoSizer>
                                        {({height}) => (<iframe src={selectedDivision.historyUrl} frameBorder="0" onLoad={this.iframeOnload} width={841} height={height}></iframe>)}
                                    </AutoSizer>
                                </div>}
                            </div>
                        </Tabs.Tab>
                    </Tabs>
                </div>
            </div>
        );
    }
}

Payment.propTypes = {
    getPaymentData: PropTypes.func,
    publisherId: PropTypes.number,
    user: PropTypes.object,
    payment: PropTypes.object,
    divisionOptions: PropTypes.array,
    defaultDivisionValue: PropTypes.object,
    error: PropTypes.string
};
const paymentSelector = (payment) => payment.data;
const getDivisionOptionsSelector = createSelector([paymentSelector], data => data);
const getDivisionDefaultSelector = createSelector([getDivisionOptionsSelector], options => _.find(options, {isDefault: true}) || null);

const mapStateToProps = ({app, payment}) => ({
    publisherId: _.get(app, 'publisher.data.id'),
    user: app.user,
    payment,
    divisionOptions: getDivisionOptionsSelector(payment),
    defaultDivisionValue: getDivisionDefaultSelector(payment),
    error: payment.error
});
const mapDispatchToProps = ({
    getPaymentData
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment));
