import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {isEqual, uniq} from 'lodash';
import {Animation, Checkbox, Tooltip} from '@perion-undertone/ut-react-common';
import BlockList from './BlockList';
import Accordion from './accordion/Accordion';
import BlockListCategoriesHeader from './BlockListCategoriesHeader';
import {textProvider, deepCopy, Icon} from '../imports';
import {getCategoryName} from '../../../../utils/helpers';

const CLASS_NAMES = {
    INNER_CONTAINER: 'supply-settings-modal-inner-container',
    BLOCK_LIST_TITLE: 'block-list-title',
    BLOCK_LIST_DESCRIPTION: 'block-list-description',
    PUBLISHER_LEVEL_BLOCKED_SUB_CATEGORY: 'publisher-level-blocked-sub-category'
};

const CONSTANT_TEXTS = {
    PUBLISHER_LEVEL_BLOCKED_SUB_CATEGORY: textProvider.getText('supplySourceSettingsFormatter', 'publisherLevelBlockedSubCategory'),
    PUBLISHER_LEVEL_BLOCKED_CATEGORY: textProvider.getText('supplySourceSettingsFormatter', 'publisherLevelBlockedCategory')
}

const TYPE = {
    CATEGORY: 'category',
    SUBCATEGORY: 'subcategory'
}

class BlockListCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedCategories: [],
            checkedSubCategories: []
        };
    }

    getDoesCategoryHaveSubCategories = (category) => {
        return category.AdvertiserSubCategories.length > 0;
    }

    checkIfBlocked = (item, type, isPublisherLevel) => {
        const {blockedCategories} = this.props;
        return blockedCategories.data && blockedCategories.data.find(c => {
                const isPublisherLevelBlocked = isPublisherLevel ? c.isPublisherLevelBlocked : true;
                if (type === TYPE.CATEGORY) {
                    return c.advertiserCategoryId === item.id && c.subCategoryId === null && isPublisherLevelBlocked;
                }
                return (c.subCategoryId === item.advertiserSubCategoryId && isPublisherLevelBlocked) || false;
            });
    }

    shouldBlockCategory = (category) => {
        const {blockedCategories} = this.props;
        const doesCategoryHaveSubCategories = this.getDoesCategoryHaveSubCategories(category);
        const shouldCatBeBlocked = blockedCategories.data && blockedCategories.data.filter(cat => {
            return cat.advertiserCategoryId === category.id;
        });
        const blockedCatIds = shouldCatBeBlocked && shouldCatBeBlocked.map(cat => cat.subCategoryId);
        const catIds = doesCategoryHaveSubCategories && category.AdvertiserSubCategories.map(subCat => subCat.advertiserSubCategoryId);
        const matches = isEqual(uniq(blockedCatIds).sort(), catIds && catIds.sort());
        return matches;
    }

    loadCategories = (categories) => {
        const loadedCategories = categories.map(category => {

            const isCategoryBlocked = this.checkIfBlocked(category, TYPE.CATEGORY, false) || this.shouldBlockCategory(category);
            const isPublisherLevelCategoryBlocked = this.checkIfBlocked(category, TYPE.CATEGORY, true);
            category['isBlocked'] = !!isCategoryBlocked;
            category['isPublisherLevelBlocked'] = !!isPublisherLevelCategoryBlocked;

            category.AdvertiserSubCategories.map(subCategory => {

                const isPublisherLevelSubCategoryBlocked = this.checkIfBlocked(subCategory, TYPE.SUBCATEGORY, true);
                const isSubCategoryBlocked = this.checkIfBlocked(subCategory, TYPE.SUBCATEGORY, false);

                subCategory['isBlocked'] = isCategoryBlocked ? true : !!isSubCategoryBlocked;
                if (isPublisherLevelCategoryBlocked) {
                    subCategory['isPublisherLevelBlocked'] = true;
                }
                if (isPublisherLevelSubCategoryBlocked) {
                    subCategory['isPublisherLevelBlocked'] = !!isPublisherLevelSubCategoryBlocked;
                }
                return subCategory;
            });

            return category;
        })
        return loadedCategories;
    }

    onCategoryChange = (category) => {
        let isChecked = true;
        let blockedFromDb = false;
        const categoryChecked = this.state.checkedCategories.find(c => c.advertiserCategoryId === category.id);
        if (categoryChecked !== undefined) {
            isChecked = !categoryChecked.isBlocked;
        } else if (category.isBlocked) {
            isChecked = !category.isBlocked;
            blockedFromDb = true;
        }

        this.setCheckedCategories(categoryChecked, category, isChecked);

        category.AdvertiserSubCategories.map(subCategory => {
            return this.onSubCategoryChange(subCategory, category, blockedFromDb, isChecked);
        })
    }

    getPublisherLevelUid = (publishersSupplySourcesId) => {
        return publishersSupplySourcesId > 0 ? `${publishersSupplySourcesId}-` : '';
    }

    setCheckedCategories = (categoryChecked, category, isCategoryChecked) => {
        const {publisherId, publishersSupplySourcesId} = this.props;
        this.setState(prevState => ({
            checkedCategories: [
                ...prevState.checkedCategories.filter(c => c.advertiserCategoryId !== (categoryChecked && categoryChecked.advertiserCategoryId)),
                {
                    publisherId,
                    publishersSupplySourcesId,
                    uid: `${publisherId}-${this.getPublisherLevelUid(publishersSupplySourcesId)}${category.id}-00`,
                    advertiserCategoryId: category.id,
                    subCategoryId: null,
                    isBlocked: isCategoryChecked
                }
            ]
        }), () => {
            this.props.blockedCategoriesToSave([...this.state.checkedCategories, ...this.state.checkedSubCategories]);
        });
    }

    onSubCategoryChange = (subCategory, category, blockedFromDb, isCategoryChecked = false) => {
        const {checkedCategories, checkedSubCategories} = this.state;
        const {publisherId, publishersSupplySourcesId, isPublisherLevel} = this.props;
        let isChecked = true;

        const subcategoryChecked = checkedSubCategories.find(sc => sc.subCategoryId === subCategory.advertiserSubCategoryId);
        let categoryChecked = checkedCategories.find(sc => sc.advertiserCategoryId === category.id);

        if (!isPublisherLevel && subCategory.isPublisherLevelBlocked) {
            return;
        }
        if (subCategory.isBlocked && !isCategoryChecked) {
            if (subCategory.isPublisherLevelBlocked && !isPublisherLevel) {
                return;
            }
            isCategoryChecked = false;
            isChecked = !subCategory.isBlocked;
        }

        if (subcategoryChecked !== undefined && !isCategoryChecked) {
            isChecked = !subcategoryChecked.isBlocked;
            isCategoryChecked = false;
        }

        if (blockedFromDb) {
            categoryChecked = {
                advertiserCategoryId: category.id,
                isBlocked: category.isBlocked,
                subCategoryId: subCategory.advertiserSubCategoryId
            }
        }

        const checkedSubCategoriesByCatId = this.getBlockedSubcategoriesSumByCategory(category);
        const matchingSubCatLength = category.AdvertiserSubCategories.length === checkedSubCategoriesByCatId + 1;

        this.setState(prevState => ({
            checkedSubCategories: [
                ...prevState.checkedSubCategories.filter(sc => sc.subCategoryId !== (subcategoryChecked && subcategoryChecked.subCategoryId)),
                {
                    publisherId,
                    publishersSupplySourcesId,
                    uid: `${publisherId}-${this.getPublisherLevelUid(publishersSupplySourcesId)}${category.id}-${subCategory.advertiserSubCategoryId}`,
                    advertiserCategoryId: category.id,
                    subCategoryId: subCategory.advertiserSubCategoryId,
                    isBlocked: isChecked
                }
            ]
        }), () => {
            this.props.blockedCategoriesToSave([...this.state.checkedCategories, ...this.state.checkedSubCategories]);
        });

        if (isChecked && matchingSubCatLength) {
            isCategoryChecked = matchingSubCatLength;
            return this.setCheckedCategories(categoryChecked, category, isCategoryChecked);
        }

        if (!isCategoryChecked) {
            this.setCheckedCategories(categoryChecked, category, isCategoryChecked);
        }
    }

    getBlockedSubCategoriesSum = (loadedCategories) => {
        let blockedSubCategoriesSum = 0;
        loadedCategories.map(category => {
            const blockedSubCategoriesSumByCategory = this.getBlockedSubcategoriesSumByCategory(category);
            blockedSubCategoriesSum += blockedSubCategoriesSumByCategory;
        })
        return blockedSubCategoriesSum;
    }

    getBlockedSubcategoriesSumByCategory = (category) => {
        const dbBlocked = category.AdvertiserSubCategories.filter(sc => sc.isBlocked);
        const checkedSubcategoryMatch = this.state.checkedSubCategories.filter(sc => sc.advertiserCategoryId === category.id);
        let duplicates = 0;
        dbBlocked && dbBlocked.map(dbSubcategory => {
            const duplicate = checkedSubcategoryMatch.find(sc => sc.subCategoryId === dbSubcategory.advertiserSubCategoryId);
            duplicate && --duplicates;
        })
        const stateBlocked = checkedSubcategoryMatch.filter(sc => sc.isBlocked).length;
        return dbBlocked.length + stateBlocked + duplicates;
    }

    getAccordionHeader = (category) => {
        const {isPublisherLevel} = this.props;
        const numOfBlockedSubCat = this.getBlockedSubcategoriesSumByCategory(category);
        const doesCategoryHaveSubCategories = this.getDoesCategoryHaveSubCategories(category)
        const numOfSubCat = category.AdvertiserSubCategories.length;
        const isFewSelected = numOfBlockedSubCat > 0 && numOfBlockedSubCat < numOfSubCat;
        const categoryChecked = this.state.checkedCategories.find(c => c.advertiserCategoryId === category.id);
        const isBlocked = (categoryChecked && categoryChecked.isBlocked) !== undefined ? categoryChecked.isBlocked : category.isBlocked;
        const categoryName = getCategoryName(category);
        return (<div key={category.id}
                     id={category.id}
                     className="header-container"
                >
            <Checkbox checked={isBlocked || false}
                      onChange={() => this.onCategoryChange(category)}
                      squared={true}
                      className={cx({["header-checkbox-few-selected"]: isFewSelected})}
                      hookId= {`block-category-checkbox-${categoryName.toLowerCase().replace(/ /g,"-")}`}
                      disabled={category.isPublisherLevelBlocked && !isPublisherLevel}
            >
                {category.isPublisherLevelBlocked && !isPublisherLevel ?
                    this.getPublihserLevelView(categoryName, CONSTANT_TEXTS.PUBLISHER_LEVEL_BLOCKED_CATEGORY) :
                    categoryName}
            </Checkbox>
            {doesCategoryHaveSubCategories ? (
                <span className={cx({
                    ["header-selected-number"]: true,
                    ["header-selected-number-zero"]: numOfBlockedSubCat === 0,
                    ["header-selected-number-publisher-level"]: category.isPublisherLevelBlocked && !isPublisherLevel
                })}>
                    {`${numOfBlockedSubCat} selected`}
                </span>
            )
             : null}
            
        </div>)
    }

    getPublihserLevelView = (name, type) => {
        const trigger = (<div>
            <Icon icon='pub_level_categories.svg' />
            <span className={CLASS_NAMES.PUBLISHER_LEVEL_BLOCKED_SUB_CATEGORY}>
                {name}
            </span>
        </div>);
        return (<Tooltip trigger={trigger}
                         placement='bottom'
                         appendToBody={true}
                         hookId="block-category-tooltip"
        >
            {type}
        </Tooltip>)
    }

    getChildren = (categories) => {
        const {isPublisherLevel} = this.props;
        const publisherLevelBlockedSubCategory = CONSTANT_TEXTS.PUBLISHER_LEVEL_BLOCKED_SUB_CATEGORY;
        const children = categories.map(category => {
            return (
                <div 
                    headerRenderer={this.getAccordionHeader.bind(this, category)}
                    doesCategoryHaveSubCategories={this.getDoesCategoryHaveSubCategories.bind(this, category)}                
                    key={category.id}
                >
                    {category.AdvertiserSubCategories.map(subCategory => {
                    const subcategoryChecked = this.state.checkedSubCategories.find(sc => sc.subCategoryId === subCategory.advertiserSubCategoryId);
                    const isBlocked = (subcategoryChecked && subcategoryChecked.isBlocked) !== undefined ? subcategoryChecked.isBlocked : subCategory.isBlocked;
                    const subcategoryName = getCategoryName(subCategory);
                        return (
                            <div key={subCategory.advertiserSubCategoryId} className="accordion-section-body-element">
                                <Checkbox checked={isBlocked || false}
                                          onChange={() => this.onSubCategoryChange(subCategory, category)}
                                          squared={true}
                                          hookId={`block-subcategory-checkbox-${subcategoryName.toLowerCase().replace(/ /g,"-")}`}
                                          disabled={subCategory.isPublisherLevelBlocked && !isPublisherLevel}
                                >
                                    {subCategory.isPublisherLevelBlocked && !isPublisherLevel ?
                                        this.getPublihserLevelView(subcategoryName, publisherLevelBlockedSubCategory) :
                                        subcategoryName}
                                </Checkbox>
                            </div>
                        )
                    })}
                </div>
            )
        });
        return children;
    }

    splitArrInHalf = (categories) => {
        if (!categories) {
            return;
        }
        const lengthInHalf = Math.floor(categories.length / 2);

        const firstHalfArray = categories.slice(0, lengthInHalf);
        const secondHalfArray = categories.slice(lengthInHalf, categories.length);

        return {
            firstHalfArray,
            secondHalfArray
        }
    }

    render() {
        const {onViewChange, categories, blockedCategories, isBulkBlock, bulkBlockMessage} = this.props;
        const item = {component: BlockList, label: 'block-list', viewId: 'block-list'}
        const loadedCategories = categories && categories.data && this.loadCategories(deepCopy(categories.data));
        const blockedSubCategoriesSum = loadedCategories && this.getBlockedSubCategoriesSum(loadedCategories);

        const categoriesToSplit = this.splitArrInHalf(loadedCategories);

        return (<div className={CLASS_NAMES.INNER_CONTAINER}>
            <BlockListCategoriesHeader onViewChange={onViewChange}
                                       viewItem={item}
                                       numOfAllBlocked={blockedSubCategoriesSum}
                                       showNumOfBlockedIndicator={!!blockedCategories.data}
                                       isBulkBlock={isBulkBlock}
                                       bulkBlockMessage={bulkBlockMessage}
            />
            {categories && categories.data && blockedCategories.data ?
                <div className="block-categories-action-container">
                    <div className="left-side-categories">
                        <Accordion className="accordion-left" type="left-side-categories">
                            {this.getChildren(categoriesToSplit.firstHalfArray)}
                        </Accordion>
                    </div>
                    <div className="right-side-categories">
                        <Accordion className="accordion-right" type="right-side-categories">
                            {this.getChildren(categoriesToSplit.secondHalfArray)}
                        </Accordion>
                    </div>
                </div> :
                <Animation type="chasing"
                        height={100}
                        width={100}
                        rendererSettings={{preserveAspectRatio: 'xMaxYMax'}}
                        hookId='categories-loading'
                />
            }
        </div>)
    }
}

BlockListCategories.propTypes = {
    onViewChange: PropTypes.func,
    getCategories: PropTypes.func,
    categories: PropTypes.object,
    publishersSupplySourcesId: PropTypes.number,
    blockedCategories: PropTypes.object,
    stateBlockedCategories: PropTypes.array,
    blockedCategoriesToSave: PropTypes.func,
    isPublisherLevel: PropTypes.bool,
    publisherId: PropTypes.number,
    isBulkBlock: PropTypes.bool,
    bulkBlockMessage: PropTypes.object
};


export default BlockListCategories;