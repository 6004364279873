import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {textProvider} from '../imports';
import cx from 'classnames';
import {Tooltip} from '@perion-undertone/ut-react-common';

import {Image} from '../imports';

const CLASS_NAMES = {
    ADUNIT_CONFIG_PREVIEW_INNER_CONTAINER: 'adunit-config-preview-inner-container',
    ADUNIT_CONFIG_PREVIEW_TITLE_CONTAINER: 'adunit-config-preview-title-container',
    ADUNIT_CONFIG_DEVICE_TYPE: 'adunit-config-device-type',
    ADUNIT_CONFIG_PREVIEW_LINK: 'adunit-config-preview-link',
    ADUNIT_CONFIG_PREVIEW_DESCRIPTION: 'adunit-config-preview-description',
    ADUNIT_CONFIG_PREVIEW_IMG_CONTAINER: 'adunit-config-preview-img-container',
    ADUNIT_CONFIG_PREVIEW_IMG: 'adunit-config-preview-img',
    ADUNIT_CONFIG_PREVIEW_IMG_ACTIVE: 'adunit-config-preview-img-active',
    ADUNIT_CONFIG_PREVIEW_IMAGE_LABEL: 'adunit-config-preview-img-label',
    ADUNIT_SELECTED_CONFIG: 'adunit-selected-config',
    ADUNIT_SELECTED_DISABLED_CONFIG: 'adunit-selected-disabled-config'
};

const CONSTANT_TEXTS = {
    VIEW_EXAMPLE: textProvider.getText('supplySourceSettingsModal', 'viewExample'),
    VIDEO_SNAP_MSG: textProvider.getText('supplySourceSettingsModal', 'videoSnapMsg'),
    DEFAULT_SETTING: textProvider.getText('supplySourceSettingsModal', 'defaultSetting'),
    PUBLISHER_LEVEL_SETTING: textProvider.getText('supplySourceSettingsModal', 'pubLevelSetting'),
};

const SelectVideoDirection = (props) => {
    const {details, videoMessage, selectOrDeselect, inlineVideoOrientation} = props;
    const [currentExample, setCurrentExample] = useState();

    useEffect(() => {
        if (!currentExample && details && details.images[details.selectedPosition]) {
            setCurrentExample(details.images[details.selectedPosition].example);
        }
    });

    const getTooltipTrigger = (imgDetails, isDisabled) => {
        const isSelected = imgDetails[1].isSelected || props.isSelected(details.name, imgDetails[0]);

        return (<div className={cx(CLASS_NAMES.ADUNIT_CONFIG_PREVIEW_IMG,
                        {[CLASS_NAMES.ADUNIT_CONFIG_PREVIEW_IMG_ACTIVE]: isSelected,
                        [CLASS_NAMES.ADUNIT_SELECTED_DISABLED_CONFIG]: isDisabled}
                     )}
                     onClick={isDisabled ? null : () => {
                        selectOrDeselect(details.name, details, imgDetails[0])
                        setCurrentExample(imgDetails[1].example)
                     }}
        >
            {isSelected && <Image name={isDisabled ? 'adunit-selected-disabled-config.svg' : 'adunit-selected-config.svg'}
                                    className={CLASS_NAMES.ADUNIT_SELECTED_CONFIG}
            />}
            <Image name={(isSelected ? imgDetails[1].imgSelected : imgDetails[1].img)}
            />
            <p className={CLASS_NAMES.ADUNIT_CONFIG_PREVIEW_IMAGE_LABEL}>
                {imgDetails[1].label}
            </p>
        </div>)
    }

    return (
        <div className={CLASS_NAMES.ADUNIT_CONFIG_PREVIEW_INNER_CONTAINER}>
            <div className={CLASS_NAMES.ADUNIT_CONFIG_PREVIEW_TITLE_CONTAINER}>
                <span className={CLASS_NAMES.ADUNIT_CONFIG_DEVICE_TYPE}>
                    {details.deviceType}
                </span>
                <span>
                    {details.name === 'inlineVideoOrientation' &&
                        <span>
                            <a className={CLASS_NAMES.ADUNIT_CONFIG_PREVIEW_LINK}
                                        href={details.images['horizontal-only'].example}
                                        target="_blank"
                                        rel="noopener noreferrer"
                            >
                                {details.images['horizontal-only'].exampleText}
                            </a>
                            <a className={CLASS_NAMES.ADUNIT_CONFIG_PREVIEW_LINK}
                                        href={details.images['both'].example}
                                        target="_blank"
                                        rel="noopener noreferrer"
                            >
                                {details.images['both'].exampleText}
                            </a>
                        </span>
                    }
                    {currentExample && details.name !== 'inlineVideoOrientation' &&
                        <a className={CLASS_NAMES.ADUNIT_CONFIG_PREVIEW_LINK}
                           href={currentExample}
                           target="_blank"
                           rel="noopener noreferrer"
                        >
                            {CONSTANT_TEXTS.VIEW_EXAMPLE}
                        </a>
                    }
                </span>
            </div>
            <div className={CLASS_NAMES.ADUNIT_CONFIG_PREVIEW_DESCRIPTION}>
                {videoMessage || CONSTANT_TEXTS.VIDEO_SNAP_MSG}
            </div>
            <div className={CLASS_NAMES.ADUNIT_CONFIG_PREVIEW_IMG_CONTAINER}>
                {Object.entries(details.images).map((imgDetails, index) => {
                    const isDisabledByDefault = imgDetails[1].isDisabled;
                    const isPublisherLevelSetting = inlineVideoOrientation && inlineVideoOrientation.isDisabled;

                    if (isDisabledByDefault) {
                        return (<div key={index}>
                            <Tooltip trigger={getTooltipTrigger(imgDetails, isDisabledByDefault)}
                                     placement='top'
                                     appendToBody={true}
                                     hookId="schedule-info-tooltip"
                            >
                                {CONSTANT_TEXTS.DEFAULT_SETTING}
                            </Tooltip>
                        </div>)
                    }

                    if (isPublisherLevelSetting) {
                        return (<div key={index} className='adunit-config-publisher-level-setting-wrapper'>
                            <Tooltip trigger={getTooltipTrigger(imgDetails, true)}
                                     placement="top-end"
                                     hookId="publisher-level-setting-popover"
                                     className='adunit-config-publisher-level-setting'
                            >
                                {CONSTANT_TEXTS.PUBLISHER_LEVEL_SETTING}
                            </Tooltip>
                        </div>)
                    }

                    return getTooltipTrigger(imgDetails, isDisabledByDefault);
                })}
            </div>
        </div>
    );
};

SelectVideoDirection.propTypes = {
    details: PropTypes.object,
    displayDetails: PropTypes.array,
    isSelected: PropTypes.func,
    selectOrDeselect: PropTypes.func,
    isDisabled: PropTypes.func,
    currentExample: PropTypes.string,
    setCurrentExample: PropTypes.func,
    videoMessage: PropTypes.string,
    adUnitKey: PropTypes.string,
    inlineVideoOrientation: PropTypes.object,
    cancelModal: PropTypes.func
};

export default SelectVideoDirection;