import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const CLASS_NAMES = {
    FORM_LABEL: 'form-label',
    FORM_INPUT: 'form-input',
    FORM_FIELD: 'form-field',
    FORM_GROUP: 'form-group'
};

export const FormField = (props) => {
    const {field, labelName, className} = props;
    return (
        <div className={cx(className, CLASS_NAMES.FORM_FIELD)}>
            {labelName && <div className={CLASS_NAMES.FORM_LABEL}>
                <label htmlFor={field}>
                    {labelName}
                </label>
            </div>}
            <div className={CLASS_NAMES.FORM_INPUT}>
                <div className={CLASS_NAMES.INPUT}>
                    {props.children}
                </div>
            </div>
        </div>
    );
};

FormField.propTypes = {
    field: PropTypes.string,
    className: PropTypes.string,
    labelName: PropTypes.string,
    inputComponent: PropTypes.func
};

export const FormGroup = (props) => {
    return (
        <div className={CLASS_NAMES.FORM_GROUP}>
            {props.children}
        </div>
    );
};