import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Icon from '../../Icon';
import {Animation, Tooltip} from '@perion-undertone/ut-react-common';
import {enums} from '@perion-undertone/ramp-helpers';
import {validateDomainInAdsTxt, deleteSingleSite} from './actionImports';
import {appStates} from '../../../constants/appStates';
import {textProvider} from './imports';
import cx from 'classnames';

const {SUPPLY_SOURCE_TYPE, STORES} = enums;

const CONSTANT_TEXTS = {
    LABEL_URL: textProvider.getText('addSitesModal', 'inputLabelUrl'),
    LABEL_NAME: textProvider.getText('addSitesModal', 'inputLabelName'),
    WARNING_MESSAGE: textProvider.getText('addSitesModal', 'warningMessage'),
    SUCCESS_MESSAGE: textProvider.getText('addSitesModal', 'successMessage'),
    TRY_AGAIN: textProvider.getText('addSitesModal', 'tryAgain')
};

const CLASS_NAMES = {
    SITE_WRAPPER: 'added-sites',
    VALID_SITE_BORDER: 'valid-site',
    SUCCESS_SITE_BORDER: 'success-site-border',
    WARNING_SITE_BORDER: 'warning-site-border',
    ICON_NAME_WRAPPER: 'icon-name-wrapper',
    NAME_WRAPPER: 'name-wrapper',
    ICON_WRAPPER: 'icon-wrapper',
    URL_WRAPPER: 'url-wrapper',
    DELETE_ICON_WRAPPER: 'delete-action-wrapper',
    URL_NAME_FIELD_CONTAINER: 'url-name-field-container',
    ADD_SITE_LABEL_URL_NAME: 'add-site-label-url-name',
    URL_NAME_FIELD: 'url-name-field',
    TOOLTIP_URL: 'tooltip-url',
    SITE_TOOLTIP: 'site-tooltip',
    ALERT: 'alert',
    WARNING_ALERT: 'warning-alert',
    SUCCESS_ALERT: 'success-alert',
    WARNING_MESSAGE: 'warning-message',
    SUCCESS_MESSAGE: 'success-message'

};
class Site extends Component {
    constructor(props) {
        super(props);

        this.state = {
            foundAdsTxtCode: false,
            validateOnTryAgain: false
        };
        this.deleteSelectedSite = this.deleteSelectedSite.bind(this);
        this.validateDomain = this.validateDomain.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {site: {hasValidAdsTxt}} = this.props;
        if (hasValidAdsTxt !== prevProps.site.hasValidAdsTxt && this.state.validateOnTryAgain) {
            this.setState({foundAdsTxtCode: hasValidAdsTxt});
        }
    }


    deleteSelectedSite() {
        this.props.deleteSingleSite(this.props.site);
        this.setState({validateOnTryAgain: true});
    }

    validateDomain() {
        const {publisher} = this.props;
        const publisherId = publisher.data.id;
        const {site: {url}} = this.props;
        this.props.validateDomainInAdsTxt(url, publisherId);
        this.setState({validateOnTryAgain: true});
    }

    render() {
        const {site: {url, displayName, type, store, hasValidAdsTxt}} = this.props;
        const {foundAdsTxtCode} = this.state;
        if (this.props.state === appStates.LOADING) {
            return <Animation type="loadingCircle"
                              height={100}
                              width={100}
                              rendererSettings={{preserveAspectRatio: 'xMaxYMax'}}
                              hookId='widget-loading'
            />
        }

        const urlTrigger = (
            <div className={CLASS_NAMES.URL_NAME_FIELD_CONTAINER}>
                <h4 className={CLASS_NAMES.ADD_SITE_LABEL_URL_NAME}>{CONSTANT_TEXTS.LABEL_URL}</h4>
                <p className={CLASS_NAMES.URL_NAME_FIELD}>{url}</p>
            </div>
        );

        return (
            <div className={cx(CLASS_NAMES.SITE_WRAPPER,
                {[CLASS_NAMES.VALID_SITE_BORDER]: hasValidAdsTxt},
                {[CLASS_NAMES.WARNING_SITE_BORDER]: !hasValidAdsTxt && type === SUPPLY_SOURCE_TYPE.DOMAIN},
                {[CLASS_NAMES.SUCCESS_SITE_BORDER]: foundAdsTxtCode},
            )}>
                <div className={CLASS_NAMES.ICON_NAME_WRAPPER}>
                    {type === SUPPLY_SOURCE_TYPE.APP ?
                        (store === STORES.GOOGLE ?
                                <div className={CLASS_NAMES.NAME_WRAPPER}>Android</div> :
                                <div className={CLASS_NAMES.NAME_WRAPPER}>Ios</div>
                        ) :
                        <div className={CLASS_NAMES.NAME_WRAPPER}>Web</div>
                    }
                    {type === SUPPLY_SOURCE_TYPE.APP ?
                        <div className={CLASS_NAMES.ICON_WRAPPER}><Icon icon={`${store}.svg`} /></div> :
                        <div className={CLASS_NAMES.ICON_WRAPPER}><Icon icon={'www.svg'} /></div>
                    }
                </div>
                <div className={CLASS_NAMES.URL_WRAPPER}>
                    {url.length >= 65 ?
                        <Tooltip trigger={urlTrigger}
                                 placement='bottom'
                                 appendToBody={true}
                                 className={CLASS_NAMES.SITE_TOOLTIP}
                                 hookId="site-url-tooltip"
                        >
                            <div className={CLASS_NAMES.TOOLTIP_URL}>{url}</div>
                        </Tooltip> :
                        <div className={CLASS_NAMES.URL_NAME_FIELD_CONTAINER}>
                            <h4 className={CLASS_NAMES.ADD_SITE_LABEL_URL_NAME}>{CONSTANT_TEXTS.LABEL_URL}</h4>
                            <p className={CLASS_NAMES.URL_NAME_FIELD}>{url}</p>
                        </div>
                    }
                    {displayName &&
                    <div className={CLASS_NAMES.URL_NAME_FIELD_CONTAINER}>
                        <h4 className={CLASS_NAMES.ADD_SITE_LABEL_URL_NAME}>{CONSTANT_TEXTS.LABEL_NAME}</h4>
                        <p className={CLASS_NAMES.URL_NAME_FIELD}>{displayName}</p>
                    </div>
                    }
                    <div className={CLASS_NAMES.DELETE_ICON_WRAPPER}
                         onClick={this.deleteSelectedSite}
                    >
                        <Icon icon={'delete.svg'} />
                    </div>
                </div>
                {type === SUPPLY_SOURCE_TYPE.DOMAIN  && !hasValidAdsTxt &&
                <div className={cx(CLASS_NAMES.ALERT, {[CLASS_NAMES.WARNING_ALERT]: !foundAdsTxtCode})}>
                    <div className={cx({[CLASS_NAMES.WARNING_MESSAGE]: !foundAdsTxtCode})}>
                        {CONSTANT_TEXTS.WARNING_MESSAGE}
                    </div>
                    <div className="link"><a onClick={this.validateDomain}>{CONSTANT_TEXTS.TRY_AGAIN}</a></div>
                </div>
                }
                {type === SUPPLY_SOURCE_TYPE.DOMAIN  && foundAdsTxtCode  &&
                <div className={cx(CLASS_NAMES.ALERT, {[CLASS_NAMES.SUCCESS_ALERT]: foundAdsTxtCode})}>
                    <div className={cx({[CLASS_NAMES.SUCCESS_MESSAGE]: foundAdsTxtCode})}>
                        {CONSTANT_TEXTS.SUCCESS_MESSAGE}
                    </div>
                </div>
                }
            </div>
        );
    }
}

Site.propTypes = {
    index: PropTypes.number,
    site: PropTypes.object,
    deleteSelectedSite: PropTypes.func,
    validateDomainInAdsTxt: PropTypes.func,
    publisher: PropTypes.object,
    validateDomain: PropTypes.object,
    state: PropTypes.string,
    deleteSingleSite: PropTypes.func
};

const mapStateToProps = (state) => ({
    publisher: state.app.publisher,
    validateDomain: state.supply
});

const mapDispatchToProps = ({
    validateDomainInAdsTxt,
    deleteSingleSite
});

export default connect(mapStateToProps, mapDispatchToProps)(Site);
