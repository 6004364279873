import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Button, getHookId, DataGrid} from '@perion-undertone/ut-react-common';
import {get, isEqual} from 'lodash';

import AddSitesModal from './AddSitesModal';
import {textProvider, SearchBar} from './imports';
import SupplySourcesGrid from './SupplySourcesGrid';
import {
    openAddSitesModal, getPublisherSupplySources, searchSupplySites,
    clearAllGridFilters, changePage, changeItemsPerPage, getPlacementGroups
} from './actionImports';
import ValidateAdsTxtModal from './ValidateAdsTxtModal';
import SupplyActionsFormatter from './grid/SupplyActionsFormatter';
import {filterGrid} from "../../../actions/gridCustomizationActions";

const CONSTANT_TEXTS = {
    BUTTON_TEXT: textProvider.getText('addSitesModal', 'uploadSitesButton'),
    SEARCH_BY_SITES: textProvider.getText('supplySourceGrid', 'searchBySites'),
    SEARCH_BY_GROUP_NAME: textProvider.getText('adUnits', 'searchByGroupName'),
    SEARCH_NAME: textProvider.getText('supplySourceGrid', 'name')
};

const CLASS_NAMES = {
    SUPPLY_WRAPPER: 'supply-wrapper',
    SUPPLY_HEADER_CONTAINER: 'supply-header-container',
    SUPPLY_HEADER_TITLE: 'supply-header-title',
    ACTIONS_WRAPPER: 'actions-wrapper',
    SEARCH_ICON: 'search-icon',
    SEARCH_GROUPS: 'search-groups',
    SUPPLY_SETTINGS_WRAPPER: 'settings-actions-wrapper-placement-level'
};

class Supply extends Component {
    constructor(props) {
        super(props);

        this.openAddSites = this.openAddSites.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeItemsPerPage = this.handleChangeItemsPerPage.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);

        this.state = {
            searchIconClass: 'search_icon.svg'
        }
    }

    handleChange({target: {value}}) {
        this.setState({searchIconClass: !value ? 'search_icon.svg' : ''});
        const {filterGrid} = this.props;
        filterGrid(CONSTANT_TEXTS.SEARCH_NAME, 'text', value);
    }

    handleChangeItemsPerPage(val) {
        this.props.changeItemsPerPage(val);
    }

    handleChangePage(page) {
        this.props.changePage(page);
    }

    componentDidMount() {
        const {getPublisherSupplySources, clearAllGridFilters, publisher, getPlacementGroups} = this.props;
        const publisherId = publisher.id;
        getPublisherSupplySources({publisherId});
        getPlacementGroups(publisherId);
        clearAllGridFilters();
    }

    componentDidUpdate(prevProps) {
        const {page, itemsPerPage, getPublisherSupplySources, customization, publisher} = this.props;
        const publisherId = publisher.id
        const filters = get(customization, 'filters') || [];
        const prevFilters = get(prevProps.customization, 'filters') || [];
        const sort = get(customization, 'sort') || [];
        const prevSort = get(prevProps.customization, 'sort') || [];
        if(prevProps.page !== page || prevProps.itemsPerPage !== itemsPerPage || !isEqual(filters, prevFilters) || !isEqual(sort, prevSort) ) {
            getPublisherSupplySources({publisherId});
        }
    }

    openAddSites() {
        this.props.openAddSitesModal();
    }

    render() {
        const {supplySources, publisher, page, itemsPerPage, customization} = this.props;
        const rowCount = get(this.props, 'supplySources.count', '...');
        let searchValue = '';
        if (customization && customization.filters) {
            const foundFilter = customization.filters.find(x => x.field === CONSTANT_TEXTS.SEARCH_NAME);
            searchValue = foundFilter ? foundFilter.value : '';
        }
        return (
            <div className={CLASS_NAMES.SUPPLY_WRAPPER}
                 {...getHookId('supply-page')}
            >
                <div className={CLASS_NAMES.SUPPLY_HEADER_CONTAINER}>
                    <div className={CLASS_NAMES.SUPPLY_HEADER_TITLE}>
                        {supplySources && supplySources.data && `Sites (${rowCount})`}
                    </div>
                    <div className={CLASS_NAMES.ACTIONS_WRAPPER}>
                        <Button icon="icon-add"
                                iconPosition="right"
                                onClick={this.openAddSites}
                                hookId='open-add-sites-button'
                        >
                            {CONSTANT_TEXTS.BUTTON_TEXT}
                        </Button>
                        <SearchBar result={this.handleChange}
                                   placeholder={CONSTANT_TEXTS.SEARCH_BY_GROUP_NAME}
                                   icon={this.state.searchIconClass}
                                   value={searchValue}
                        />
                        <SupplyActionsFormatter row={{
                                                    id: publisher.id,
                                                    displayName: publisher.name,
                                                    publishersSupplySourcesId: 0
                                                }}
                                                className={CLASS_NAMES.SUPPLY_SETTINGS_WRAPPER}
                                                isPublisherLevel={true}
                        />
                    </div>
                </div>
                <SupplySourcesGrid />
                <AddSitesModal publisherId={publisher.id}/>
                <ValidateAdsTxtModal />
                <footer>
                    <DataGrid.PagingPanel handleChangeItemsPerPage={this.handleChangeItemsPerPage}
                                        handleChangePage={this.handleChangePage}
                                        itemsPerPage={itemsPerPage}
                                        itemsPerPageOptions={[12, 20, 50, 100]}
                                        itemsPerPageText="Rows per page"
                                        page={page}
                                        rowCount={Number(rowCount)}
                    />
                </footer>
            </div>
        );
    }
}

Supply.propTypes = {
    openAddSitesModal: PropTypes.func,
    clearAllGridFilters: PropTypes.func,
    getPublisherSupplySources: PropTypes.func,
    supplySources: PropTypes.object,
    supplySourcesCount: PropTypes.object,
    searchSupplySites: PropTypes.func,
    publisher: PropTypes.object,
    changeItemsPerPage: PropTypes.func,
    changePage: PropTypes.func,
    page: PropTypes.number,
    itemsPerPage: PropTypes.number,
    customization: PropTypes.object,
    filterGrid: PropTypes.func,
    getPlacementGroups: PropTypes.func
};

const mapStateToProps = ({supply, app, grid}) => ({
    supplySources: supply.supplySources,
    supplySourcesCount: supply.supplySourcesCount,
    publisher: app.publisher.data,
    page: supply.page,
    itemsPerPage: supply.itemsPerPage,
    customization: grid.customization
});

const mapDispatchToProps = ({
    openAddSitesModal,
    clearAllGridFilters,
    getPublisherSupplySources,
    searchSupplySites,
    changeItemsPerPage,
    changePage,
    filterGrid,
    getPlacementGroups
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Supply));
