import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import {Popover} from '@perion-undertone/ut-react-common';

import {Icon, textProvider} from './imports';

const CONSTANT_TEXTS = {
    AD_SLOTS: textProvider.getText('adSlots', 'adSlots'),
};

const CLASS_NAMES = {
    AD_SLOTS_TOOLTIP_TRIGGER: 'ad-slots-tooltip-trigger',
    AD_SLOTS_EMPTY_SPACE: 'ad-slots-empty-space',
    AD_SLOTS_CONTAINER: 'ad-slots-container',
    AD_SLOTS_CONTENT: 'ad-slots-content',
};

const adSlotsTooltipTrigger = (
    <span className={CLASS_NAMES.AD_SLOTS_TOOLTIP_TRIGGER}>
        <Icon icon='adslot-icon.svg' />
    </span>
);

const AdSlotsView = ({adSlots}) => {
    if (isEmpty(adSlots)) {
        return (
            <span className={CLASS_NAMES.AD_SLOTS_EMPTY_SPACE}></span>
        );
    }
    return (
        <Popover basic={true}
                 appendToBody={true}
                 trigger={adSlotsTooltipTrigger}
                 className={CLASS_NAMES.AD_SLOTS_CONTAINER}
        >
            <div className={CLASS_NAMES.AD_SLOTS_CONTENT}>
                <span>{`${CONSTANT_TEXTS.AD_SLOTS} (${adSlots.length}): `}</span>
                {adSlots.map((adSlot, index) => <div key={index}>{adSlot.name}</div>)}
            </div>
        </Popover>
    );
};

AdSlotsView.propTypes = {
    adSlots: PropTypes.array
};

export default AdSlotsView;