import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {validate} from '@perion-undertone/ramp-helpers';
import {Icon, Input, Popover} from '@perion-undertone/ut-react-common';

import {textProvider} from './imports';

const CLASS_NAMES = {
    PASSWORD_CRITERIA: 'password-criteria',
    PASSWORD_VALID: 'valid',
    PASSWORD_INVALID: 'invalid',
    PASSWORD_CRITERIA_POPOVER: 'password-criteria-popover',
    TRIANGLE: 'triangle'
};

const TXT = {
    PLACEHOLDER: textProvider.getText('inputFields', 'passwordPlaceholder'),
    VALID_LENGTH: textProvider.getText('passwordCriteria', 'validLength'),
    SPECIAL_CHARACTERS: textProvider.getText('passwordCriteria', 'specialCharacters'),
    UPPERCASE_LETTERS: textProvider.getText('passwordCriteria', 'upperCaseLetters'),
    LOWERCASE_LETTERS: textProvider.getText('passwordCriteria', 'lowerCaseLetters')
};

const options = {
    minLength: 8,
    minLowerCaseLetters: 1,
    minUpperCaseLetters: 1,
    minSpecialChars: 1
};

const getCriteriasList = (password) => {
    const passCriteriasValidation = validate.validatePassword(password, options);
    const {hasSpecialCharacters, hasValidLength, hasUpperCaseLetters, hasLowerCaseLetters} = passCriteriasValidation;
    const passCriterias = [
        {text: TXT.SPECIAL_CHARACTERS, isOptionValid: hasSpecialCharacters},
        {text: TXT.VALID_LENGTH, isOptionValid: hasValidLength},
        {text: TXT.UPPERCASE_LETTERS, isOptionValid: hasUpperCaseLetters},
        {text: TXT.LOWERCASE_LETTERS, isOptionValid: hasLowerCaseLetters},
    ];

    return passCriterias.map((criteria, index) => {
        return (
            <li key={index}
                className={cx(CLASS_NAMES.PASSWORD_CRITERIA, {
                    [CLASS_NAMES.PASSWORD_VALID]: criteria.isOptionValid,
                    [CLASS_NAMES.PASSWORD_INVALID]: !criteria.isOptionValid})
                }
            >
                {criteria.text}
            </li>
        )
    });
};

const PasswordField = (props) => {
    const [placeholder, setPlaceholder] = useState(props.placeholder);
    const [isVisible, setIsVisible] = useState(false);
    const [eyeIconClass, setEyeIconStyle] = useState('visibility-icon--blur');
    const {autoFocus, hideCriteria, label, password, onChange, handleBlur, inputClass, labelClass} = props;
    const displayIcon = isVisible ? 'icon-eye_visible' : 'icon-eye_hidden';
    const inputType = isVisible ? 'text' : 'password';

    const onBlur = () => {
        setPlaceholder(props.placeholder);
        setEyeIconStyle('visibility-icon--blur');
        handleBlur && handleBlur();
    }

    const onFocus = () => {
        setPlaceholder('');
        setEyeIconStyle('visibility-icon--focus');
    }

    const PasswordInput = (
        <div className='password-input'>
            <Input type={inputType}
                   value={password}
                   autoFocus={autoFocus}
                   placeholder={placeholder}
                   className={inputClass}
                   onChange={onChange}
                   onFocus={onFocus}
                   onBlur={onBlur}
                   icon='icon-locked'
                   iconPosition="left"
                   hookId="password-field-input"
            />
            <Icon icon={displayIcon}
                  className={`visibility-icon ${eyeIconClass}`}
                  onClick={() => setIsVisible(!isVisible)}
            />
        </div>
    );

    return (
        <Fragment>
            <div className='password-field form-field'>
                {label && <div className='form-label'>
                    <label className={labelClass}>{label}</label>
                </div>}
                {hideCriteria ?
                    PasswordInput :
                    <Popover basic
                             padding={0}
                             appendToBody
                             placement='right'
                             trigger={PasswordInput}
                             className={CLASS_NAMES.PASSWORD_CRITERIA_POPOVER}
                             hookId="password-field-popover"
                    >
                        <div className={CLASS_NAMES.TRIANGLE}></div>
                        <ul>{getCriteriasList(password)}</ul>
                    </Popover>
                }
            </div>
        </Fragment>
    );
};

PasswordField.defaultProps = {
    placeholder: TXT.PLACEHOLDER,
    autoFocus: false,
    hideCriteria: false
};

PasswordField.propTypes = {
    label: PropTypes.string,
    labelClass: PropTypes.string,
    inputClass: PropTypes.string,
    password: PropTypes.string,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    hideCriteria: PropTypes.bool,
    onChange: PropTypes.func,
    handleBlur: PropTypes.func
};

export default PasswordField;