import {boundClass} from 'autobind-decorator';
import texts from './appTexts';
import _ from 'lodash';

@boundClass
class ConstantTextProvider {
    getText(moduleOrComponentName, constantName) {
        return _.get(texts, `${moduleOrComponentName}.${constantName}`, `${moduleOrComponentName}_${constantName}`);
    }

    getSideBarText(constantName) {
        const moduleOrComponentName = 'sideBar';
        return this.getText(moduleOrComponentName, constantName);
    }

    getFiltersDashboardText(constantName) {
        const moduleOrComponentName = 'filtersDashboard';
        return this.getText(moduleOrComponentName, constantName);
    }

    getGridHeadersText(constantName) {
        const moduleOrComponentName = 'gridHeaders';
        return this.getText(moduleOrComponentName, constantName);
    }
}

const textProvider = new ConstantTextProvider();
export default textProvider;
