import React from 'react'
import {textProvider} from '../../imports';
import {Button, Checkbox} from '@perion-undertone/ut-react-common';
import PropTypes from 'prop-types';
import {getVideoLimitations, manageVideoLimitations} from  './actionImports';
import {connect} from 'react-redux';
import _ from 'lodash';
import {getFilteredVideoLimitatoins} from './supplyHelper';



const CLASS_NAMES = {
    INNER_CONTAINER: 'supply-settings-modal-inner-container',
    TITLE: 'block-list-title',
    SECTION_WRAPPER: 'section-wrapper',
    SEPARATOR: 'section-wrapper-separator',
    FOOTER: 'supply-settings-modal-footer',
    DONE_BUTTON: 'supply-settings-modal-done-button',
    CANCEL_BUTTON: 'supply-settings-modal-cancel-button',
};

const CONSTANT_TEXTS = {
    SYSTEM_APPEND_TITLE: textProvider.getText('supplySourceSettingsModal', 'systemAppendTitle'),
    APPEND_PIXEL_CHECKBOX: textProvider.getText('supplySourceSettingsModal', 'appendPixelChk'),
    CANCEL: textProvider.getText('confirmationModal', 'cancel'),
    SAVE: textProvider.getText('confirmationModal', 'save')
}



class SystemAppend extends React.Component {
    state = {
        systemPixelEnabled: false
    }

    componentDidMount(){
        const {publishersSupplySourcesId, isPublisherLevel,publisherId, getVideoLimitations, publishersSupplySourcesIds} = this.props;
        const supplySourceId = isPublisherLevel ? 0 : publishersSupplySourcesId;
        const supplySourcesIds = publishersSupplySourcesIds || [supplySourceId];
        this.updatedByServer = false;
        getVideoLimitations(publisherId, supplySourcesIds, 'ignoreSystemAppendPixel');
        
    }

    componentDidUpdate(prevProps) {
        const {publisherId, publishersSupplySourcesId, isBulkBlock, isPublisherLevel, publishersSupplySourcesIds} = this.props;
        const {systemPixelEnabled} = this.state;

        const supplySourceId = isPublisherLevel ? 0 : publishersSupplySourcesId;
        const supplySourcesIds = publishersSupplySourcesIds || [supplySourceId];


        const videoLimitations  = getFilteredVideoLimitatoins(isBulkBlock, this.props.videoLimitations, supplySourcesIds, isPublisherLevel, 'ignoreSystemAppendPixel');
        const PrevVideoLimitations  = getFilteredVideoLimitatoins(isBulkBlock, prevProps.videoLimitations, supplySourcesIds, isPublisherLevel, 'ignoreSystemAppendPixel');
        const currentVideoLimitation = _.get(videoLimitations, 'length', 0) > 1 ?  _.first(videoLimitations) : videoLimitations;
        const mutualIgnoreVideoLimitation = _.get(currentVideoLimitation, 'ignoreSystemAppendPixel', false);
        
        

        if(isBulkBlock && systemPixelEnabled !== mutualIgnoreVideoLimitation && !this.updatedByServer && !_.isEmpty(videoLimitations)){
                    this.updatedByServer = true;
                    this.setState({
                        systemPixelEnabled: mutualIgnoreVideoLimitation
                    })
        }else{

        const currentVideoLimitation = _.find(videoLimitations, {publisherId, publishersSupplySourcesId});
        const ignoreSystemAppendPixel = _.get(currentVideoLimitation, 'ignoreSystemAppendPixel', false)

        const preVcurrentVideoLimitation = _.find(PrevVideoLimitations, {publisherId, publishersSupplySourcesId});
        const prevIgnoreSystemAppendPixel = _.get(preVcurrentVideoLimitation, 'ignoreSystemAppendPixel', false)
        if(_.isEmpty(currentVideoLimitation)){
            return;
        }


        if (prevIgnoreSystemAppendPixel !== ignoreSystemAppendPixel && !this.updatedByServer) {
            this.updatedByServer = true;
            this.setState({
                systemPixelEnabled: ignoreSystemAppendPixel
            })
        }

        }

        
      }


   onCheckboxSystemPixelChange = () =>{
        this.setState({
            systemPixelEnabled: !this.state.systemPixelEnabled
        })
    }


    updateSystemPixel = () =>{
        const {publishersSupplySourcesId, publisherId, publishersSupplySourcesIds, isBulkBlock} = this.props;
        const videoLimitations = [];
        if (isBulkBlock) {
            publishersSupplySourcesIds.map(publishersSupplySourcesId => {
                return videoLimitations.push({
                    publisherId: publisherId,
                    uid: `${publisherId}-${publishersSupplySourcesId}`,
                    publishersSupplySourcesId,
                    ignoreSystemAppendPixel: this.state.systemPixelEnabled
                });
            });
        } else{
            videoLimitations.push({
                publisherId: publisherId,
                uid: `${publisherId}-${publishersSupplySourcesId}`,
                publishersSupplySourcesId,
                ignoreSystemAppendPixel: this.state.systemPixelEnabled
            });
        }     
        
       
        this.props.manageVideoLimitations(publisherId, videoLimitations, 'ignoreSystemAppendPixel');
    }


  render() {
    return (
        <>
        <div className={CLASS_NAMES.INNER_CONTAINER}>
        <div className={CLASS_NAMES.TITLE}>
                {CONSTANT_TEXTS.SYSTEM_APPEND_TITLE}
        </div>
         <div className={CLASS_NAMES.SECTION_WRAPPER}>
         <Checkbox squared={true}
                    transparent={true}
                    inverted={true}
                    checked={this.state.systemPixelEnabled}
                    onChange={this.onCheckboxSystemPixelChange}
                    hookId='append-pixel'
            >
                {CONSTANT_TEXTS.APPEND_PIXEL_CHECKBOX}
        </Checkbox>
         </div>
      </div>
      <div className={CLASS_NAMES.FOOTER}>
                    <Button 
                            onClick={this.props.cancelModal}
                            className={CLASS_NAMES.CANCEL_BUTTON}
                            transparent={true}
                            hookId='system-append-cancel-button'
                    >
                        {CONSTANT_TEXTS.CANCEL}
                    </Button>
                    <Button onClick={this.updateSystemPixel}
                            className={CLASS_NAMES.DONE_BUTTON}
                            hookId='system-append-save-button'
                    >
                        {CONSTANT_TEXTS.SAVE}
                    </Button>
                </div>
        </>
    )
  }
}


SystemAppend.propTypes = {
    getVideoLimitations: PropTypes.func,
    manageVideoLimitations:  PropTypes.func,
    cancelModal: PropTypes.func,
    publishersSupplySourcesId: PropTypes.number,
    useSystemAppendPixel: PropTypes.bool,
    publisherId: PropTypes.number,
    videoLimitations: PropTypes.any,
    publishersSupplySourcesIds: PropTypes.array,
    isBulkBlock: PropTypes.bool,
    isPublisherLevel: PropTypes.bool
}
const mapStateToProps = (state) => ({
    videoLimitations: _.get(state, 'supply.videoLimitations.data', [])
});


export default connect(mapStateToProps, {manageVideoLimitations, getVideoLimitations})(SystemAppend);