import _ from 'lodash';
export const LOCALE = 'en-US';

export const MIN_REQUESTS = 1000;

export const MIN_SEARCH_QUERY_LENGTH = 2;

import metadataProvider from '../utils/MetadataProvider';

export const supplySourceStoreNames = {};

export const decimalTwoDigitRegex = /^[0-9]+\.?([0-9]{1,2})?$/;
export const getDecimalValue = (input, round) =>{
    const data = input.length === 2 && input[0] === '0' && input[1] !== '.'  ? input[1] : input;
    const currentValue = _.round(data, round);
    const testCase = decimalTwoDigitRegex.test(data);
    const str = _.trim(input.toString());
    const isValid = !_.isNaN(currentValue);
    const forceOverride =  (testCase && !isValid) || _.isEmpty(str)
    return {
        isValid: (isValid && testCase) || forceOverride,
        value: forceOverride ? 0 : data
    }
}




export const rampFetch = ({url, body, headers = {}, qs, method = 'GET', onSuccessCallback, onErrorCallback}) => {
    const urlWebApi = new URL(url, `${window.location.protocol}/${window.location.host}`);
    if (qs) {
        const searchParams = urlWebApi.searchParams;
        _.keys(qs)
            .forEach(key => (_.isArray(qs[key]) ? qs[key].forEach(item => searchParams.append(key, item)) : searchParams.append(key, qs[key])));
    }
    return fetch(urlWebApi, {
        method,
        headers: new Headers({
            ...headers,
            Accept: '*/*',
            'Content-Type': 'application/json',
            Cache: 'no-cache'
        }),
        body: JSON.stringify(body),
        credentials: 'include'
    })
        .then(response => {
            if (!response.ok) {
                if (_.isFunction(onErrorCallback)) {
                    onErrorCallback(response.status);
                }
                if (response.status === 401) {
                    return response.json().then(err => ({
                        err, status: response.status
                    }));
                }
                throw new Error(response.status);
            }
            return response.json();
        })
        .then(data => {
            if (_.isFunction(onSuccessCallback)) {
                return onSuccessCallback(data);
            }
            return data;
        });
};


export const saveToLocalStorage = (key, item, options = {isPrimitive: false}) => {
    if (options.isPrimitive) {
        localStorage.setItem(key, item);
    } else {
        try {
            const serializedItem = JSON.stringify(item);
            localStorage.setItem(key, serializedItem);
        } catch (err) {
            throw new Error(err);
        }
    }
};

export const loadFromLocalStorage = (key, options = {isPrimitive: false}) => {
    if (options.isPrimitive) {
        return localStorage.getItem(key);
    }
    try {
        const serializedItem = localStorage.getItem(key);
        return JSON.parse(serializedItem);
    } catch (err) {
        return undefined;
    }
};
export const PUBLISHER_TYPES_MAP = {};
const VERSION_STORAGE_KEY = 'metadataVersion';
const METADATA_STORAGE_KEY = 'metadata';
export const METADATA_ITEMS = ['PlacementWinEventTypes', 'SupplyStores', 'PlacementPriceMacroType', 'Publisher_target'];

export const publisherTypesInit = () =>{
    const pubTypes = metadataProvider.getAsList('Publisher_target');
    for(let publisherType of pubTypes){
        PUBLISHER_TYPES_MAP[publisherType.id] = publisherType.name;
    }
}

export const getMetadata = async(token) => {
    const {version} = await rampFetch({url: '/api/metadata/version', token, qs: {}});
    const metadataFromStorage = loadFromLocalStorage(METADATA_STORAGE_KEY);
    const versionFromStorage = loadFromLocalStorage(VERSION_STORAGE_KEY);
    const notAllItemsExistInStorage = !metadataFromStorage ||
        METADATA_ITEMS.some((key) => _.size(_.get(metadataFromStorage, `${key}`)) > 0);
    let metadata = metadataFromStorage;
    if (notAllItemsExistInStorage || !versionFromStorage || version > versionFromStorage || version < 0) {
        try {
            metadata = await rampFetch({
                url: '/api/metadata',
                qs: {md: _.map(METADATA_ITEMS, item => _.get(item, 'name', item))},
                token,
                failOnSecurity: true
            });
            if (!metadata.OppStage || metadata.OppStage.length === 0) {
                metadata.OppStage = [
                    {id: 'stage_1', text: 'Closed Won', value: 100},
                    {id: 'stage_2', text: 'Waiting on IO', value: 80},
                    {id: 'stage_3', text: 'Order Likely', value: 60}
                ];
            }
            saveToLocalStorage(METADATA_STORAGE_KEY, metadata);
            if (version > 0) {
                saveToLocalStorage(VERSION_STORAGE_KEY, version);
            }

            return metadataProvider.init(metadata, METADATA_ITEMS);
        } catch(err) {
            window.localStorage.removeItem('metadata');
            window.localStorage.removeItem('metadataVersion');
            return Promise.reject(err);
        }
    }

};
