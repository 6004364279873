import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const CLASS_NAMES = {
    BAR_LOADER_WRAPPER: 'bar-loader-wrapper',
    BAR_LOADER: 'bar-loader',
    BAR_LOADER_PROGRESS_ONE: 'bar-loader-progress-one',
    BAR_LOADER_PROGRESS_TWO: 'bar-loader-progress-two',
    BAR_LOADER_PROGRESS_THREE: 'bar-loader-progress-three',
    HIDDEN: 'hidden'
};

const LoadingBar = ({isLoading}) => (
    <div className={cx(CLASS_NAMES.BAR_LOADER_WRAPPER, {[CLASS_NAMES.HIDDEN]: !isLoading})}>
        <div className={cx(CLASS_NAMES.BAR_LOADER, CLASS_NAMES.BAR_LOADER_PROGRESS_ONE)}/>
        <div className={cx(CLASS_NAMES.BAR_LOADER, CLASS_NAMES.BAR_LOADER_PROGRESS_TWO)}/>
        <div className={cx(CLASS_NAMES.BAR_LOADER, CLASS_NAMES.BAR_LOADER_PROGRESS_THREE)}/>
    </div>
);

LoadingBar.propTypes = {
    isLoading: PropTypes.bool,
};

export default LoadingBar;