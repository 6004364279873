export const analyticsConstants = {
    PREDEFINED_REPORTS_LIST_REQUEST: 'PREDEFINED_REPORTS_LIST_REQUEST',
    PREDEFINED_REPORTS_LIST_SUCCESS: 'PREDEFINED_REPORTS_LIST_SUCCESS',
    PREDEFINED_REPORTS_LIST_FAILURE: 'PREDEFINED_REPORTS_LIST_FAILURE',

    PREDEFINED_REPORT_DATA_REQUEST: 'PREDEFINED_REPORT_DATA_REQUEST',
    PREDEFINED_REPORT_DATA_SUCCESS: 'PREDEFINED_REPORT_DATA_SUCCESS',
    PREDEFINED_REPORT_DATA_FAILURE: 'PREDEFINED_REPORT_DATA_FAILURE',

    PUBLISHER_REPORTS_CONFIG_REQUEST: 'PUBLISHER_REPORTS_CONFIG_REQUEST',
    PUBLISHER_REPORTS_CONFIG_SUCCESS: 'PUBLISHER_REPORTS_CONFIG_SUCCESS',
    PUBLISHER_REPORTS_CONFIG_FAILURE: 'PUBLISHER_REPORTS_CONFIG_FAILURE',

    CLEAR_PREDEFINED_REPORT_DATA: 'CLEAR_PREDEFINED_REPORT_DATA',

    RAW_REPORT_DATA_REQUEST: 'RAW_REPORT_DATA_REQUEST',
    RAW_REPORT_DATA_SUCCESS: 'RAW_REPORT_DATA_SUCCESS',
    RAW_REPORT_DATA_FAILURE: 'RAW_REPORT_DATA_FAILURE',

    CHANGE_PAGE: 'CHANGE_PAGE',
    SET_LAST_PAGE: 'SET_LAST_PAGE',
    SET_ZOOM_FACTOR: 'SET_ZOOM_FACTOR',
    CLOSE_WEB_PREVIEW: 'CLOSE_WEB_PREVIEW',

    SET_PREDEFINED_REPORT_PERIOD: 'SET_PREDEFINED_REPORT_PERIOD',

    SET_REPORT_SCHEDULING_REQUEST: 'SET_REPORT_SCHEDULING_REQUEST',
    SET_REPORT_SCHEDULING_SUCCESS: 'SET_REPORT_SCHEDULING_SUCCESS',
    SET_REPORT_SCHEDULING_FAILED: 'SET_REPORT_SCHEDULING_FAILED',

    UPDATE_REPORT_QUERY_REQUEST: 'UPDATE_REPORT_QUERY_REQUEST',
    UPDATE_REPORT_QUERY_SUCCESS: 'UPDATE_REPORT_QUERY_SUCCESS',
    UPDATE_REPORT_QUERY_FAILED: 'UPDATE_REPORT_QUERY_FAILED',
    CHANGE_REPORT_QUERY: 'CHANGE_REPORT_QUERY',
    DELETE_CHANGED_QUERY: 'DELETE_CHANGED_QUERY'
};