import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import AccordionSection from './AccordionSection';
import {setOpenCategorySections} from '../../../../../actions/categoriesActions';

class Accordion extends Component {
    onClick = header => {
        const {setOpenCategorySections, type} = this.props;
        const isOpen = this.getOpenState(header);
        setOpenCategorySections(type, header.props.id.toString(), isOpen)
    };

    getOpenState = (header) => {
        const {openSections, type} = this.props;
        const section = openSections && openSections[type]
        const isOpen = section && !!section[header.props.id.toString()];
        return isOpen;
    }

    render() {
        const {children, className} = this.props;

        return (
        <div className={className}>
            {children.map(child => {
                const header = child.props.headerRenderer();
                const isExpandable = child.props.doesCategoryHaveSubCategories();
                const isOpen = this.getOpenState(header);
                return <AccordionSection isOpen={isOpen}
                                         header={header}
                                         isExpandable={isExpandable}
                                         onClick={this.onClick}
                                         key={header.key}
                >
                    {child.props.children}
                </AccordionSection>
            })}
        </div>
        );
    }
}

Accordion.propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    className: PropTypes.string,
    openSections: PropTypes.object,
    setOpenCategorySections: PropTypes.func,
    type: PropTypes.string
};

const mapStateToProps = ({categories}) => ({
    openSections: categories.openCategoriesSections
});

const mapDispatchToProps = ({
    setOpenCategorySections
})

export default connect(mapStateToProps, mapDispatchToProps)(Accordion);