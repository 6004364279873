import {paymentConstants} from '../constants/paymentConstants';
import {appStates} from '../constants/appStates';

const initialState = {error: null, url: null};

export const paymentReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case paymentConstants.PAYMENT_DATA_REQUEST: {
            return {
                ...state,
                data: undefined,
                state: appStates.LOADING
            };
        }
        case paymentConstants.PAYMENT_DATA_SUCCESS: {
            return {
                ...state,
                data: payload.data,
                state: appStates.LOADED
            };
        }
        case paymentConstants.PAYMENT_DATA_FAILURE:
            return {
                ...state,
                data: undefined,
                state: appStates.ERROR,
            };
        default:
            return state;
    }
};
