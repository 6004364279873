import React from "react";
import PropTypes from 'prop-types';
import {Icon, Tooltip} from "@perion-undertone/ut-react-common";

const CLASS_NAMES = {
    LI_DESCRIPTION_TOOLTIP: "li-description-tooltip",
    LI_DESCRIPTION_ICON: "li-description-icon"
}

export default function LiDescriptionFormatter({value}) {
    if(value) {

        return (
            <Tooltip 
            trigger={
                <div>
                    <Icon className={CLASS_NAMES.LI_DESCRIPTION_ICON} icon="icon-additional_details" />
                </div>
            }
            placement='top'
            className={CLASS_NAMES.LI_DESCRIPTION_TOOLTIP}
            appendToBody={true}
            hookId="schedule-info-tooltip"
            padding={0}
            >
            {value}
        </Tooltip>
        )
    }
    return null
}

LiDescriptionFormatter.propTypes = {
    value: PropTypes.string,
};