import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from "lodash/get";

import {Button} from '@perion-undertone/ut-react-common';
import {textProvider} from './imports';

import {openPlacementGroupModal, searchPlacementGroups} from './imports';
import SearchBar from '../../SearchBar';
import {isAuthorized, permissionConstants} from '../../imports';

const {CREATE_PLACEMENT_GROUPS} = permissionConstants;

const CONSTANT_TEXTS = {
    PLACEMENT_GROUPS: textProvider.getText('adUnits', 'placementGroups'),
    CREATE_GROUP: textProvider.getText('adUnits', 'createGroup'),
    SEARCH_BY_GROUP_NAME_OR_ID: textProvider.getText('adUnits', 'searchByGroupNameorID'),
}

class PlacementHeader extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.createGroup = this.createGroup.bind(this);

        this.state = {
            searchIconClass: 'search_icon.svg'
        }
    }

    handleChange({target: {value}}) {
        const {publisherId, placementsPagination: {limit}, customization} = this.props;
        const sort = get(customization, 'sort') || [];
        this.props.searchPlacementGroups(value, publisherId, limit, 1, sort);
        this.setState({searchIconClass: !value ? 'search_icon.svg' : ''});
    }

    createGroup() {
        this.props.openPlacementGroupModal({adUnits: [], adSlots: [], isOverrideThrottling: true,
            isAuctionPriceMacro: false, ignoreSystemAppendPixel: false, 
            isPreferVidazoo: false, auctionPriceMacro: null, winEventType: 'nurl'});
    }

    render() {

        const CLASS_NAMES = {
            PLACEMENT_GROUP_CONTAINER: 'placement-group-header-container',
            PLACEMENT_GROUP_TITLE: 'placement-group-title',
            ACTIONS_WRAPPER: 'actions-wrapper',
            CREATE_GROUP_BUTTON: 'create-group-button',
            SEARCH_GROUPS: 'search-groups',
            SEARCH_ICON: 'search-icon'
        }

        const createPlacement = isAuthorized({permission: CREATE_PLACEMENT_GROUPS});

        return (
            <div className={CLASS_NAMES.PLACEMENT_GROUP_CONTAINER}>
                <h1 className={CLASS_NAMES.PLACEMENT_GROUP_TITLE}>{CONSTANT_TEXTS.PLACEMENT_GROUPS}</h1>
                <div className={CLASS_NAMES.ACTIONS_WRAPPER}>
                    {createPlacement && <Button icon="icon-add"
                            iconPosition="left"
                            onClick={this.createGroup}
                            hookId='create-placement-group-button'
                    >
                        {CONSTANT_TEXTS.CREATE_GROUP}
                    </Button>}
                    <SearchBar result={this.handleChange}
                               placeholder={CONSTANT_TEXTS.SEARCH_BY_GROUP_NAME_OR_ID}
                               icon={this.state.searchIconClass}
                    />
                </div>
            </div>
        );
    }
}

PlacementHeader.propTypes = {
    openPlacementGroupModal: PropTypes.func,
    searchPlacementGroups: PropTypes.func,
    publisherId: PropTypes.number,
    placementsPagination: PropTypes.object,
    customization: PropTypes.object,
};


const mapStateToProps = ({grid, placements, app}) => ({
    placementGroups: placements.placementGroups,
    publisherId: app.publisher.data.id,
    placementsPagination: placements.placementsPagination,
    customization: grid.customization,
});

const mapDispatchToProps = ({
    openPlacementGroupModal,
    searchPlacementGroups,
});

export default connect(mapStateToProps, mapDispatchToProps)(PlacementHeader);