import moment from 'moment';
import {dashboardConstants} from '../constants/dashboardConstants';
import {appStates} from '../constants/appStates';

const initialState = {
    period: {
        type: 'last-month',
        from: moment().utc().subtract(1, 'months').startOf('month'),
        to: moment().utc().subtract(1, 'months').endOf('month')
    }
};

export const dashboardReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case dashboardConstants.WIDGET_DATA_REQUEST: {
            return {
                ...state,
                [payload.type]: {
                    data: undefined,
                    state: appStates.LOADING
                }
            };
        }
        case dashboardConstants.WIDGET_DATA_SUCCESS:
            return {
                ...state,
                [payload.type]: {
                    data: payload.data,
                    state: appStates.LOADED
                }
            };
        case dashboardConstants.WIDGET_DATA_FAILURE:
            return {
                ...state,
                [payload.type]: {
                    data: undefined,
                    state: appStates.ERROR,
                }
            };
        case dashboardConstants.WIDGET_LIST_REQUEST:
            return {
                ...state,
                widgetPreferences: {
                    data: undefined,
                    state: appStates.LOADING
                }
            };
        case dashboardConstants.WIDGET_LIST_SUCCESS:
            return {
                ...state,
                widgetPreferences: {
                    data: payload.widgetPreferences,
                    state: appStates.LOADED
                }
            };
        case dashboardConstants.WIDGET_LIST_FAILURE:
            return {
                ...state,
                widgetPreferences: {
                    data: undefined,
                    state: appStates.ERROR
                }
            };

        case dashboardConstants.UPDATE_WIDGET_PREFERENCES_REQUEST:
            return {
                ...state,
                widgetPreferences: {
                    data: undefined,
                    state: appStates.LOADING
                }
            };
        case dashboardConstants.UPDATE_WIDGET_PREFERENCES_SUCCESS:
            return {
                ...state,
                widgetPreferences: {
                    data: payload.widgetPreferences,
                    state: appStates.LOADED
                }
            };
        case dashboardConstants.UPDATE_WIDGET_PREFERENCES_FAILURE:
            return {
                ...state,
                widgetPreferences: {
                    data: undefined,
                    state: appStates.ERROR
                }
            };

        case dashboardConstants.WIDGET_LIBRARY_REQUEST:
            return {
                ...state,
                widgetLibrary: {
                    data: undefined,
                    state: appStates.LOADING
                }
            };
        case dashboardConstants.WIDGET_LIBRARY_SUCCESS:
            return {
                ...state,
                widgetLibrary: {
                    data: payload.widgetLibrary,
                    state: appStates.LOADED
                }
            };
        case dashboardConstants.WIDGET_LIBRARY_FAILURE:
            return {
                ...state,
                widgetLibrary: {
                    data: undefined,
                    state: appStates.ERROR
                }
            };

        case dashboardConstants.SET_PERIOD: {
            return {
                ...state,
                period: {
                    ...state.period,
                    ...payload
                }
            }
        }
        case dashboardConstants.SELECTED_VIEW: {
            return {
                ...state,
                selectedView: {
                    ...state.selectedView,
                    [payload.type]: payload.value
                }
            }
        }
        default:
            return state
    }
}