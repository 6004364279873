import {advertisersConstants} from '../constants/advertisersConstants';

export const getAdvertisers = (inputValue) => ({
    type: advertisersConstants.GET_ADVERTISERS_REQUEST,
    payload: {inputValue}
});
export const getAdvertisersSuccess = advertisersList => ({
    type: advertisersConstants.GET_ADVERTISERS_SUCCESS,
    payload: {advertisersList}
});
export const getAdvertisersFailed = error => ({
    type: advertisersConstants.GET_ADVERTISERS_FAILURE,
    payload: {error}
});