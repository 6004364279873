import _ from 'lodash';
import {API_REQUEST, requestFailed, requestSucceed, startRequest} from '../actions/requestsActions';
import {insertToDb} from '../actions/dbActions';
import {logoutUser} from '../actions/userActions';
import {refreshSession} from '../auth';
import {loadFromLocalStorage} from '../utils';

const isApiRequestAction = action => action.type === API_REQUEST;
const isUnauthorized = payload => payload.error && payload.error.status === 401;

const BASE_HEADERS = {
    Accept: '*/*',
    Cache: 'no-cache'
};

const getHeaders = (isMultipart = false) => {
    const headers = isMultipart ? BASE_HEADERS : {
        ...BASE_HEADERS,
        'Content-Type': 'application/json'
    };
    return new Headers(headers);
};

const getUrl = (url, qs) => {
    const {protocol, host} = window.location;
    const urlWebApi = new URL(url, `${protocol}/${host}`);
    if (qs) {
        const {searchParams} = urlWebApi;
        _.keys(qs)
            .forEach(key => (_.isArray(qs[key]) ?
                qs[key].forEach(item => searchParams.append(key, item)) :
                searchParams.append(key, qs[key])));
    }
    return urlWebApi;
};

const fetchUrl = (action, getState) => {
    return dispatch => {
        const {
            payload:
                {
                    body,
                    qs,
                    url,
                    skipAutoInsertToDb = false,
                    method = 'GET',
                    onSuccess,
                    onFail,
                    dataTransform,
                    requestName,
                    requestId
                }
        } = action;
        const isMultipart = body instanceof FormData;
        const headers = getHeaders(isMultipart);
        const jsonRequestId = JSON.stringify(requestId);
        const req = fetch(getUrl(url, qs), {
            method,
            body: isMultipart ? body : JSON.stringify(body),
            headers,
            credentials: 'include'
        });
        dispatch(startRequest(requestName, jsonRequestId, req));
        return req.then(response => {
            if (!response.ok) {
                dispatch(requestFailed(requestName, jsonRequestId, response.statusText));
                if (_.isFunction(onFail)) {
                    onFail(response.statusText, dispatch, getState);
                }
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
            .then((data) => {
                const transformedData = _.isFunction(dataTransform) ? dataTransform(data) : data;
                if (_.isFunction(onSuccess)) {
                    onSuccess(data, transformedData, dispatch, getState);
                }
                if (!skipAutoInsertToDb) {
                    dispatch(insertToDb(transformedData));
                }
                dispatch(requestSucceed(requestName, jsonRequestId));
                return data;
            });
    };
};

const createApiRequestMiddleware = store => next => async (action) => {
    await refreshSession();

    const user = loadFromLocalStorage('user');
    if (user && action.payload && isUnauthorized(action.payload)) {
        return next((logoutUser()));
    }

    if (!isApiRequestAction(action)) {
        return next(action);
    }
    return next(fetchUrl(action, store.getState));
};

export default createApiRequestMiddleware;
