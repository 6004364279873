import {of} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {categoriesConstants} from '../constants/categoriesConstants';
import {
    getCategoriesSuccess, getCategoriesFailed
} from '../actions/categoriesActions';

export const requestCategoriesEpic = actions => (
    actions.pipe(
        ofType(categoriesConstants.GET_CATEGORIES_REQUEST),
        mergeMap(() => {
            return ajax.getJSON(`/api/categories`).pipe(
                map(data => getCategoriesSuccess(data)),
                catchError(error => of(getCategoriesFailed(error)))
            )}
        )
    )
);