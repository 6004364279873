import React, {Fragment, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Animation, Select} from '@perion-undertone/ut-react-common';

import {appStates, getCurrentPublisher, textProvider} from './imports';

const CLASS_NAMES = {
    TITLE: 'title',
    LOADER_WRAPPER: 'loader-wrapper'
};

const CONSTANT_TEXTS = {
    SELECT_PUBLISHER: textProvider.getText('selectPublisher', 'pleaseSelectPublisherToStart')
};

const publisherOptions = (data) => {
     return data.map((publisher) => {
        return {
            label: `${publisher.name} ${publisher.publisherId}`,
            value: publisher
        }
    });
};

const getDefaultSelection = (publisher) => {
    return {
        label: `${publisher.data.name} ${publisher.data.id}`,
        value: publisher
    };
}

const nameContainsValue = (name, value) => {
    return name.toString().toLowerCase().includes(value.toLowerCase());
};

const SelectAllowedPublisher = (props) => {
    const {publisher, publishers, getCurrentPublisher, userUpdateState} = props;
    const publisherData = publisher && publisher.data;
    const allowedPublishers = publishers && publishers.data;

    const [currentPublisher, setCurrentPublisher] = useState(publisherData ? getDefaultSelection(publisher) : {});
    const [filteredPublishers, setFilteredPublishers] = useState(allowedPublishers);

    const selectCurrentPublisher = (inputValue) => {
        setCurrentPublisher(inputValue);
        getCurrentPublisher(inputValue.value.publisherId, true);
    };

    useEffect(() => {
        if (allowedPublishers && !filteredPublishers) {
            setFilteredPublishers(allowedPublishers)
        }
    });

    const handleInputChange = (value) => {
        if (!value) {
            return setFilteredPublishers(publishers.data);
        }
        const data = publishers.data.filter(publisher => {
            const {name, publisherId} = publisher;
            if (nameContainsValue(name, value) || nameContainsValue(publisherId, value)) {
                return publisher;
            }
        });
        setFilteredPublishers(data);
    };

    if (!filteredPublishers ||
        (publisher && publisher.state === appStates.LOADING) ||
        props.hasLoading && userUpdateState === appStates.LOADING) {
        return (
            <div className={CLASS_NAMES.LOADER_WRAPPER}>
                <Animation type="chasing"
                           height={150}
                           width={150}
                           rendererSettings={{preserveAspectRatio: 'xMaxYMax'}}
                           hookId='select-allowed-publisher-loading'
                />
            </div>
        )
    }

    return (
        <Fragment>
            {props.hasLoading &&
                <p className={CLASS_NAMES.TITLE}>
                    {CONSTANT_TEXTS.SELECT_PUBLISHER}
                </p>
            }
            <Select options={publisherOptions(filteredPublishers)}
                    type='select'
                    value={currentPublisher}
                    onChange={selectCurrentPublisher}
                    onInputChange={handleInputChange}
                    components={{Option: Select.components.Option}}
                    hookId='select-allowed-publisher'
            />
        </Fragment>
    );
};

SelectAllowedPublisher.propTypes = {
    userUpdateState: PropTypes.string,
    getCurrentPublisher: PropTypes.func,
    hasLoading: PropTypes.bool,
    publisher: PropTypes.object,
    publishers: PropTypes.object
};

SelectAllowedPublisher.defaultProps = {
    hasLoading: true
};

const mapStateToProps = ({app}) => ({
    publisher: app.publisher,
    publishers: app.publishers,
    userUpdateState: app.userUpdateState,
});

const mapDispatchToProps = {
    getCurrentPublisher
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectAllowedPublisher);