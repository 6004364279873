import React, {Component} from 'react';
import textProvider from "../../../../texts";
import {Button, Input} from "@perion-undertone/ut-react-common";
import PropTypes from "prop-types";
import Icon from "../../../Icon";
import {parseUrl} from '@perion-undertone/ramp-helpers';
import {connect} from "react-redux";
import {addMainSite} from '../../../../actions/onboardingActions';
import {setViewSiteUnderReviewPage} from '../../../../actions/appActions';
import OnboardingSideBar from './OnboardingSideBar';

const CLASS_NAMES = {
    STEP_COMPONENT: 'step-comp',
    STEP_LAYOUT_LEFT: 'layout-left',
    INPUT_WRAPPER: 'input-wrapper',
    ERROR: 'input-error',
    CODE_ERROR_MESSAGE: 'error-message',
};

const CONSTANT_TEXTS = {
    TITLE: textProvider.getText('onboardingModal', 'step_3_1.title'),
    DOMAIN_INFO_LABEL: textProvider.getText('onboardingModal', 'step_3_1.domainInfoLabel'),
    BUTTON: textProvider.getText('onboardingModal', 'step_3_1.button'),
    URL_SITE: textProvider.getText('onboardingModal', 'step_3_1.urlSite'),
    ERROR_SUGGESTIONS: textProvider.getText('onboardingModal', 'step_1_1.validationUrlMessage')
};

class OnboardingStep_3_1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            touched: {
                url: false
            }
        };
        this.submitSite = this.submitSite.bind(this);
        this.handleUrlChange = this.handleUrlChange.bind(this);
        this.shouldMarkError = this.shouldMarkError.bind(this);
        this.shouldMarkValid = this.shouldMarkValid.bind(this);
    }

    submitSite() {
        const {onboarding} = this.props;
        const site = parseUrl(this.state.url);
        site.isMain = true;

        const updateSite = onboarding.mainSite && {
            publishersSupplySourcesId: onboarding.mainSite.publishersSupplySourcesId,
            isMain: false
        };
        this.props.setViewSiteUnderReviewPage(true);
        this.props.addMainSite(site, updateSite);
    }

    handleUrlChange(event) {
        this.setState({url: event.target.value});
    }

    handleBlur = field => () => {
        this.setState({
            touched: {...this.state.touched, [field]: true}
        });
    };

    shouldMarkError(hasErrors) {
        const shouldShow = this.state.touched['url'];
        return hasErrors && shouldShow;
    }

    shouldMarkValid(hasErrors) {
        const shouldShow = this.state.touched['url'];
        return !hasErrors && shouldShow;
    }

    render() {
        const {handleUrlChange, submitSite} = this;
        const {url, touched} = this.state;
        const hasErrors = !parseUrl(url);
        const disableAddingSite = hasErrors;
        const markError = this.shouldMarkError(hasErrors);
        const markValid = this.shouldMarkValid(hasErrors);

        return (
            <div id="step_3_1" className={CLASS_NAMES.STEP_COMPONENT}>
                <div className={CLASS_NAMES.STEP_LAYOUT_LEFT}>
                    <h2>{CONSTANT_TEXTS.TITLE}</h2>
                    <p>{CONSTANT_TEXTS.DOMAIN_INFO_LABEL}</p>
                    <br />
                    <br />
                    <br />
                    <form onSubmit={submitSite}>
                        <label htmlFor="url">{CONSTANT_TEXTS.URL_SITE}</label>
                        <div className={CLASS_NAMES.INPUT_WRAPPER}>
                            <Input type='url'
                                   name='url'
                                   className={markError ? 'error' : (markValid ? 'valid': 'url-input')}
                                   value={url}
                                   placeholder={CONSTANT_TEXTS.PLACEHOLDER_URL}
                                   onChange={handleUrlChange}
                                   onBlur={this.handleBlur('url')}
                                   iconPosition={'right'}
                                   hookId='onboarding-third-step-url-input'
                            />
                            {touched['url'] && markValid ? <Icon icon={'valid_url.svg'} className={'input-error-img'} /> : (markError ? <Icon icon={'invalid_url.svg'} className={'input-error-img'} /> : null)}
                            <div className={CLASS_NAMES.ERROR_MESSAGE_CONTAINER}>
                                {markError ? <p className={CLASS_NAMES.ERROR}>{CONSTANT_TEXTS.ERROR_SUGGESTIONS}</p> : null}
                            </div>

                            {markValid ?
                                <p className={CLASS_NAMES.VALID}>{CONSTANT_TEXTS.VALIDATION_MESSAGE}</p>
                                : null}
                        </div>
                        <Button disabled={disableAddingSite}
                                hookId='add-site-button'
                                onClick={this.submitSite}
                        >
                            {CONSTANT_TEXTS.BUTTON}
                        </Button>
                    </form>
                </div>
                <OnboardingSideBar img='step_3_1.png' />
            </div>
        )
    }
}

OnboardingStep_3_1.propTypes = {
    onboarding: PropTypes.object,
    addMainSite: PropTypes.func,
    setViewSiteUnderReviewPage: PropTypes.func
};

const mapStateToProps = ({app}) => ({
    onboarding: app.onboarding
});

const mapDispatchToProps = ({
    addMainSite,
    setViewSiteUnderReviewPage
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingStep_3_1);