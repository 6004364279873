import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {HashRouter as Router} from 'react-router-dom';
import metadataProvider from '../utils/MetadataProvider';
import configureStore from '../configureStore';
import {Auth0Provider} from '@auth0/auth0-react';
import _ from "lodash";
import AppLayout from "./AppLayout";
import {METADATA_ITEMS, getMetadata, publisherTypesInit} from '../utils/globals';
import {useAuth0} from '@auth0/auth0-react';
import {FeatureToggleAdapter} from "@perion-undertone/ut-react-common";
import {ConfigureFlopFlip} from '@flopflip/react-redux';
import {enums} from '@perion-undertone/ramp-helpers';
import {featureFlags} from './imports';

const App = (props) => {
    const clientId =  _.get(window, 'clientConfig.auth0ClientId');
    const domainUrl = _.get(window, 'clientConfig.domain');
    const {isAuthenticated, isLoading, user: auth0User} = useAuth0();
    useEffect(async () =>{
        if (isAuthenticated && !isLoading && auth0User) {
            const metadata = await getMetadata();
            metadataProvider.init(metadata, METADATA_ITEMS);
            publisherTypesInit();
        }
    }, [isAuthenticated, isLoading, auth0User]);

    useEffect(async () =>{
        const metadata = await getMetadata();
        metadataProvider.init(metadata, METADATA_ITEMS);
        publisherTypesInit();
    }, []);

    return (
        <Provider store={configureStore(props.initialState)}>
            <ConfigureFlopFlip user={window.UdriveAppUser} adapter={FeatureToggleAdapter}
                adapterArgs={{user: 'longtail-publisher', flags: featureFlags.concat(enums.FLAGS_FOR_MENU_LINKS)}}
            >
                <Auth0Provider
                    domain={domainUrl}
                    clientId={clientId}
                    cacheLocation="localstorage"
                    authorizationParams={{
                        scope: 'profile email offline-access',
                        audience: 'ut-services',
                        redirect_uri: `${window.location.href}`
                    }}
                >
                    <Router><AppLayout /></Router>
                </Auth0Provider>
            </ConfigureFlopFlip>
        </Provider>
    );
};

App.propTypes = {
    initialState: PropTypes.object
};
export default App;
