import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Image, textProvider} from '../../imports';
import {Checkbox} from '@perion-undertone/ut-react-common';

const CLASS_NAMES = {
    WIDGET: 'widget-preview',
    HEADER: 'widget-preview-header',
    CHECKBOX: 'widget-preview-checkbox',
    DESCRIPTION: 'widget-preview-description',
    IMAGE: 'widget-preview-image',
    KPIS_WIDGET: 'widget-main-kpis'
}

const CONSTANT_TEXTS = {
    WIDGET_DESCRIPTION: textProvider.getText('widgetsLibraryModal', 'widgetGalleryDescription'),
}

const WIDGET_NAMES = {
    'main-kpis': 'Main Kpis',
    'header-bidding-stripe': 'Header Bidding',
    'revenue-chart': 'Revenue',
    'requests-chart': 'Requests',
    'platform-chart': 'Platform',
    'header-bidding-chart': 'Header Bidding',
    'top-adunits-grid': 'Top Adunits',
    'top-domains-chart': 'Top Domains'
}

class WidgetPreviewCard extends Component {
    render() {
        const {libraryWidget, onWidgetChange, widgets} = this.props;
        const isWidgetSelected = widgets.find(widget => widget.type === libraryWidget.type);

        return (
            <div className={cx(CLASS_NAMES.WIDGET, {[CLASS_NAMES.KPIS_WIDGET]: libraryWidget.type === 'main-kpis'})}>
                <div className={CLASS_NAMES.HEADER}>
                    <Checkbox squared
                              checked={!!isWidgetSelected}
                              onChange={onWidgetChange}
                              label={WIDGET_NAMES[libraryWidget.type]}
                              className={CLASS_NAMES.CHECKBOX}
                              hookId='widget-preview-checkbox'
                    />
                    <div className={CLASS_NAMES.DESCRIPTION}>
                        {CONSTANT_TEXTS.WIDGET_DESCRIPTION}
                    </div>
                </div>
                <div className={CLASS_NAMES.IMAGE}>
                    <Image name={`${libraryWidget.type}.png`}
                           hookId={`widget-${libraryWidget.type}-preview-img`}
                    />
                </div>
            </div>
        )
    }
}

WidgetPreviewCard.propTypes = {
    libraryWidget: PropTypes.object,
    widgets: PropTypes.array,
    onWidgetChange: PropTypes.func
};


export default WidgetPreviewCard;