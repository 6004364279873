import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {isEqual} from 'lodash';

import SelectVideoDirection from './SelectVideoDirection';

const VideoDirection = (props) => {
    const {details, isSelected} = props;

    const selectOrDeselect = (name, value, position) => {
        if (isEqual(props[name], value) && value.selectedPosition === position && position === 'both') {
            value.selectedPosition = 'horizontal-only';
        } else {
            value.selectedPosition = position;
        }
        props.setSelected(name, value);
    };

    return (
        <Fragment>
            {details.desktop &&
                <SelectVideoDirection details={details.desktop}
                                      isSelected={isSelected}
                                      selectOrDeselect={selectOrDeselect}
                />
            }
            {details.mobile &&
                <SelectVideoDirection details={details.mobile}
                                      isSelected={isSelected}
                                      selectOrDeselect={selectOrDeselect}
                />
            }
            {details.orientation &&
                <SelectVideoDirection {...props}
                                      videoMessage='Horizontal / vertical Inline video'
                                      details={details.orientation}
                                      isSelected={isSelected}
                                      selectOrDeselect={selectOrDeselect}
                />
            }
        </Fragment>
    )
}

VideoDirection.propTypes = {
    details: PropTypes.object,
    removeSelected: PropTypes.func,
    setSelected: PropTypes.func,
    isSelected: PropTypes.func,
    isDisabled: PropTypes.func
};

export default VideoDirection;