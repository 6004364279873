import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Icon, textProvider, formatString} from '../imports';
// SearchBar redundant
const CLASS_NAMES = {
    SECTION_WRAPPER: 'section-wrapper',
    BLOCK_LIST_INNER: 'block-list-inner',
    BLOCK_LIST_CATEGORIES_DESCRIPTION: 'block-list-categories-description',
    BLOCK_LIST_TITLE: 'block-list-title',
    BLOCK_LIST_CATEGORIES: 'block-list-categories',
    BLOCK_LIST_DESCRIPTION: 'block-list-description',
    SECTION_WRAPPER_SEPARATOR: 'section-wrapper-separator',
    BLOCK_CATEGORIES_DESCRIPTION: 'block-categories-description',
    BLOCKED_CATEGORIES_SUM: 'blocked-categories-sum'
};

const CONSTANT_TEXTS = {
    BLOCK_CATEGORIES: textProvider.getText('supplySourceSettingsModal', 'blockCategories'),
    BLOCK_CATEGORIES_DESCRIPTION: textProvider.getText('supplySourceSettingsModal', 'blockCategoriesDescription'),
    WHAT_TO_BLOCK: textProvider.getText('supplySourceSettingsModal', 'whatToBlock'),
    SUM_OF_BLOCKED_SUBCATEGORIES: textProvider.getText('supplySourceSettingsModal', 'numberOfBlockedSubcategories'),
    SEARCH_BY_CATEOGORY: textProvider.getText('supplySourceSettingsModal', 'searchByCategory'),
}

const BlockListCategoriesHeader = ({onViewChange, viewItem, numOfAllBlocked, showNumOfBlockedIndicator, isBulkBlock, bulkBlockMessage}) => {
    const numOfAllBlockedIndicatior = formatString(CONSTANT_TEXTS.SUM_OF_BLOCKED_SUBCATEGORIES, numOfAllBlocked);
    return (<div>
        <div className={cx(CLASS_NAMES.SECTION_WRAPPER, CLASS_NAMES.BLOCK_LIST_INNER, CLASS_NAMES.BLOCK_LIST_CATEGORIES_DESCRIPTION)}>
            <span onClick={onViewChange.bind(this, viewItem)}>
                <Icon icon="pagging_arrow_back" hookId="blocklist-categories-paging-arrow-back"/>
            </span>
            <span className={CLASS_NAMES.BLOCK_LIST_TITLE}>{CONSTANT_TEXTS.BLOCK_CATEGORIES}</span>
            <div className={CLASS_NAMES.BLOCK_LIST_DESCRIPTION}>{CONSTANT_TEXTS.BLOCK_CATEGORIES_DESCRIPTION}</div>
        </div>
        <div className={CLASS_NAMES.SECTION_WRAPPER_SEPARATOR}></div>
        {showNumOfBlockedIndicator && <div className={cx(CLASS_NAMES.SECTION_WRAPPER, CLASS_NAMES.BLOCK_LIST_CATEGORIES)}>
            <div>
                <div className={CLASS_NAMES.BLOCK_CATEGORIES_DESCRIPTION}>{CONSTANT_TEXTS.WHAT_TO_BLOCK}</div>
                <div className={CLASS_NAMES.BLOCKED_CATEGORIES_SUM}>
                    {numOfAllBlockedIndicatior}
                    {isBulkBlock && bulkBlockMessage}
                </div>
            </div>
            {/* <SearchBar placeholder={CONSTANT_TEXTS.SEARCH_BY_CATEOGORY}
                       icon='search_icon.svg'
            /> */}
        </div>}
    </div>);
};

BlockListCategoriesHeader.propTypes = {
    onViewChange: PropTypes.func,
    viewItem: PropTypes.object,
    numOfAllBlocked: PropTypes.number,
    showNumOfBlockedIndicator: PropTypes.bool,
    isBulkBlock: PropTypes.bool,
    bulkBlockMessage: PropTypes.object
};

export default BlockListCategoriesHeader;