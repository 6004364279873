import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Button} from '@perion-undertone/ut-react-common';
import textProvider from './../texts/textProvider';

const CLASSES = {
  RETRY_FETCH_DATA_WRAPPER: 'retry-fetch-data-wrapper',
  RETRY_FETCH_DATA_HEADER_WRAPPER: 'retry-fetch-data-header-wrapper',
  RETRY_FETCH_DATA_HEADER: 'retry-fetch-data-header',
  RETRY_FETCH_DATA_SUBHEADER: 'retry-fetch-data-subheader',
  RETRY_BUTTON_WRAPPER: 'retry-button-wrapper',
  RETRY_BUTTON: 'retry-button'
}

const TEXTS = {
  SOMETHING_WENT_WRONG: textProvider.getText('errorMessages', 'somethingWentWrongOnOurEnd'),
  TRY_PAGE_REFRESH: textProvider.getText('errorMessages', 'tryPageRefresh'),
  CONTACT_ACCOUNT_MANAGER: textProvider.getText('errorMessages', 'contactAccountManager'),
  RETRY: textProvider.getText('common', 'retry')
}

const RetryFetchData = (props) => {
    return (
      <div className={cx(CLASSES.RETRY_FETCH_DATA_WRAPPER, props.classNames)}>
        <div className={CLASSES.RETRY_FETCH_DATA_HEADER_WRAPPER}>
          <h1 className={CLASSES.RETRY_FETCH_DATA_HEADER}>
            {props.header}
          </h1>
          <span className={CLASSES.RETRY_FETCH_DATA_SUBHEADER}>
            {props.subheader}
          </span>
        </div>
        {props.fetchData &&
          <div className={CLASSES.RETRY_BUTTON_WRAPPER}>
          <Button hookId='retry-btn'
                  onClick={props.fetchData}
                  className={CLASSES.RETRY_BUTTON}
          >
            {TEXTS.RETRY}
          </Button>
          </div>
        }
      </div>
    );
}

RetryFetchData.propTypes = {
  fetchData: PropTypes.func,
  classNames: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string
};

RetryFetchData.defaultProps = {
  header: TEXTS.SOMETHING_WENT_WRONG,
  subheader: `${TEXTS.TRY_PAGE_REFRESH}. ${TEXTS.CONTACT_ACCOUNT_MANAGER}.`
}

export default RetryFetchData;
