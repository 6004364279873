import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Icon} from '@perion-undertone/ut-react-common';

const CLASS_NAMES = {
    ACCORDION_SECTION: 'accordion-section',
    ACCORDION_SECTION_SELECTION: 'accordion-section-selection',
    ACCORDION_SECTION_HEADER: 'accordion-section-header',
    ACCORDION_SECTION_BODY: 'accordion-section-body',
}

class AccordionSection extends Component {
    constructor(props) {
        super(props);
    }

    onClick = () => {
        this.props.onClick(this.props.header);
    };

    render() {
        const {onClick, props: {isOpen, header, isExpandable}} = this;
        return (
            <div className={cx({[CLASS_NAMES.ACCORDION_SECTION_SELECTION]: isOpen}, CLASS_NAMES.ACCORDION_SECTION)}>
                <div className={CLASS_NAMES.ACCORDION_SECTION_HEADER}>
                    {header}
                   {isExpandable ? (
                        <div style={{float: 'right', cursor: 'pointer'}} onClick={onClick}>
                            {!isOpen && <Icon icon="icon-arrow_collapse_table" hookId="accordion-colapse-table-icon"></Icon>}
                            {isOpen && <Icon icon="icon-arrow_expand_table" hookId="accordion-expand-table-icon"></Icon>}
                        </div>
                   ) : null} 
                </div>
                {isOpen && (
                    <div className={CLASS_NAMES.ACCORDION_SECTION_BODY}>
                        {this.props.children}
                    </div>
                )}
            </div>
        );
    }
}

AccordionSection.propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool,
    header: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    isExpandable: PropTypes.bool,
};

export default AccordionSection;