import {of} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {map, mergeMap, catchError} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {loginUserSuccess, loginUserFailed,
        resetPasswordSuccess, resetPasswordFailed, mfaChallenge,
        setPasswordSuccess, setPasswordFailed} from '../actions/userActions';
import {getAuthorizationDataSuccess, getAuthorizationDataFailed} from '../actions/appActions';
import {appConstants} from '../constants/appConstants';
import {authorization} from '@perion-undertone/ut-permissions-module';

export const loginEpic = actions$ => (
    actions$.pipe(
        ofType(appConstants.LOGIN_REQUEST),
        mergeMap(action => {
            return ajax.post(`api/auth/login`, action.payload, {'Content-Type': 'application/json'}).pipe(
                map(data => loginUserSuccess(data.response)),
                catchError(error => {
                    if (error.status === 401) {
                        const {code, token} = error.response;
                        return of(mfaChallenge({code, token}));
                    } else if ( error.status === 403) {
                        return of(mfaChallenge({code: null, token: null}));
                    } else {
                        // Handle other errors
                        return of(loginUserFailed(error));
                    }
                })
            )
        })
    )
);

export const authorizeEpic = actions$ => {
    return actions$.pipe(
        ofType(appConstants.AUTHORIZATION_DATA_REQUEST),
        mergeMap(() => {
            return ajax.getJSON(`/api/permissions`).pipe(
                map(data => {
                    authorization.initializeResolver(data);
                    return getAuthorizationDataSuccess(data)
                }),
                catchError(error => of(getAuthorizationDataFailed(error)))
            )}
        )
    )
};

export const resetPasswordEpic = actions$ => {
    return actions$.pipe(
        ofType(appConstants.RESET_PASSWORD_REQUEST),
        mergeMap(action => {
            const {email, isResend} = action.payload;
            return ajax.get(`api/auth/password/reset?username=${email}`, {'Content-Type': 'application/json'}).pipe(
                map(data => resetPasswordSuccess(data, isResend)),
                catchError(error => of(resetPasswordFailed(error.response)))
            )
        })
    )
};

export const setPasswordEpic = actions$ => {
    return actions$.pipe(
        ofType(appConstants.SET_PASSWORD_REQUEST),
        mergeMap(action => {
            return ajax.post(`api/auth/password/set`, action.payload,
                {'Content-Type': 'application/json'}).pipe(
                map(data => setPasswordSuccess(data.response)),
                catchError(error => of(setPasswordFailed(error)))
            )
        }),
        catchError(error => of(setPasswordFailed(error)))
    )
};
