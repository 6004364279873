const widgetRepo = {};

const widgetTypes = {
    registerType: (widgetData) => {
        const type = widgetData.type;
        widgetRepo[type] = widgetData;
    },
    getType: (type) => {
        return widgetRepo[type];
    }
}

export default widgetTypes;