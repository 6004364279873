import React from 'react';
import PropTypes from "prop-types";
import moment from 'moment';

import {textProvider, Image} from './imports';

const DATE_FORMAT = 'MM/DD/YYYY h:mm:ss A';

const CLASS_NAMES = {
    APPROVAL_COUNTER_WRAPPER: 'approval-counter-wrapper',
    DATE: 'date'
};

const CONSTANT_TEXTS = {
    TIME_FROM: textProvider.getText('onboarding', 'waitingApprovalFrom')
};

const ApprovalCounter = (props) => {
    const {date} = props;

    return (
        <div className={CLASS_NAMES.APPROVAL_COUNTER_WRAPPER}>
            <Image name='site-approval-counter.png'
                   hookId='approval-counter'
            />
            <p className={CLASS_NAMES.DATE}>
                {CONSTANT_TEXTS.TIME_FROM}<br/>
                <span>
                    {date && moment(date).format(DATE_FORMAT)}
                </span>
            </p>
        </div>
    );
};

ApprovalCounter.propTypes = {
    date: PropTypes.string
};

export default ApprovalCounter;