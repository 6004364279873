import moment from 'moment';
import textProvider from '../texts/textProvider';
import {PERIOD_TYPES} from '../constants/reportsConstants';

const CONSTANT_TEXTS = {
    TODAY: textProvider.getText('analytics.presets', 'today'),
    YESTERDAY: textProvider.getText('analytics.presets', 'yesterday'),
    LAST_7_DAYS: textProvider.getText('analytics.presets', 'last7days'),
    LAST_30_DAYS: textProvider.getText('analytics.presets', 'last30days'),
    THIS_MONTH: textProvider.getText('analytics.presets', 'thisMonth'),
    LAST_MONTH: textProvider.getText('analytics.presets', 'lastMonth'),
    LAST_WEEK: textProvider.getText('analytics.presets', 'lastWeek'),
    CURRENT_WEEK: textProvider.getText('analytics.presets', 'currentWeek'),
    CURRENT_MONTH: textProvider.getText('analytics.presets', 'currentMonth'),
    CUSTOM: textProvider.getText('analytics.presets', 'custom')
};

export const getCurrentDate = () =>  moment().utc();

export const isPresetOptionDisabled = (presetOption) => {
    if (presetOption === CONSTANT_TEXTS.CURRENT_WEEK || presetOption === CONSTANT_TEXTS.CURRENT_MONTH) {
        const activePreset = calendarPresets.find(preset => preset.label === presetOption);
        const isSameDate = (getCurrentDate().startOf('day')).diff(activePreset.startDate.startOf('day'));
        return !isSameDate;
    }
    return false;
}

export const calendarPresets = [
    {
        isReportPreset: true,
        label: CONSTANT_TEXTS.YESTERDAY,
        period: PERIOD_TYPES.YESTERDAY,
        startDate: getCurrentDate().subtract(1, 'days'),
        endDate: getCurrentDate().subtract(1, 'days')
    },
    {
        label: CONSTANT_TEXTS.LAST_7_DAYS,
        period: PERIOD_TYPES.LAST_7_DAYS,
        startDate: getCurrentDate().subtract(6, 'days'),
        endDate: getCurrentDate()
    },
    {
        label: CONSTANT_TEXTS.LAST_30_DAYS,
        period: PERIOD_TYPES.LAST_30_DAYS,
        startDate: getCurrentDate().subtract(29, 'days'),
        endDate: getCurrentDate()
    },
    {
        label: CONSTANT_TEXTS.THIS_MONTH,
        period: PERIOD_TYPES.THIS_MONTH,
        startDate: getCurrentDate().startOf('month'),
        endDate: getCurrentDate()
    },
    {
        isReportPreset: true,
        label: CONSTANT_TEXTS.LAST_WEEK,
        period: PERIOD_TYPES.LAST_WEEK,
        startDate: getCurrentDate().startOf('isoWeek').subtract(7, 'days'),
        endDate: getCurrentDate().endOf('isoWeek').subtract(7, 'days')
    },
    {
        isReportPreset: true,
        label: CONSTANT_TEXTS.CURRENT_WEEK,
        period: PERIOD_TYPES.CURRENT_WEEK,
        startDate: getCurrentDate().startOf('isoWeek'),
        endDate: getCurrentDate().subtract(1, 'days')
    },
    {
        isReportPreset: true,
        label: CONSTANT_TEXTS.LAST_MONTH,
        period: PERIOD_TYPES.LAST_MONTH,
        startDate: getCurrentDate().subtract(1, 'months').startOf('month'),
        endDate: getCurrentDate().subtract(1, 'months').endOf('month')
    },
    {
        isReportPreset: true,
        label: CONSTANT_TEXTS.CURRENT_MONTH,
        period: PERIOD_TYPES.CURRENT_MONTH,
        startDate: getCurrentDate().startOf('month'),
        endDate: getCurrentDate().subtract(1, 'days')
    },
    {
        label: CONSTANT_TEXTS.CUSTOM,
        period: PERIOD_TYPES.CUSTOM,
        startDate: getCurrentDate(),
        endDate: getCurrentDate()
    },
];