import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Modal, Button, Input, Tabs} from '@perion-undertone/ut-react-common';
import {parseUrl, getAdsTxtCode, enums} from '@perion-undertone/ramp-helpers';
import {textProvider} from './imports';
import {openAddSitesModal, closeAddSitesModal, addSingleSite, deleteSingleSite, submitSites, validateDomainInAdsTxt, clearAllGridFilters} from './actionImports';
import Site from './Site';
import Icon from '../../Icon';
import CopyToClipboard from '../../CopyToClipboard';

const {SUPPLY_SOURCE_TYPE, STORES} = enums;

const CONSTANT_TEXTS = {
    HEADER: textProvider.getText('addSitesModal', 'addSitesTitle'),
    NAV_ITEM_ADD : textProvider.getText('addSitesModal', 'addSitesNavItemAdd'),
    NAV_ITEM_IMPORT_FILE : textProvider.getText('addSitesModal', 'addSitesNavItemImportFile'),
    BODY_PARAGRAPH : textProvider.getText('addSitesModal', 'bodyParagraph'),
    ADD_BUTTON_TEXT: textProvider.getText('addSitesModal', 'addButtonText'),
    CANCEL_BUTTON_TEXT: textProvider.getText('addSitesModal', 'cancelButtonText'),
    LABEL_URL: textProvider.getText('addSitesModal', 'inputLabelUrl'),
    LABEL_NAME_OPTIONAL: textProvider.getText('addSitesModal', 'inputLabelNameOptional'),
    PLACEHOLDER_URL: textProvider.getText('addSitesModal', 'inputPlaceholderUrl'),
    PLACEHOLDER_NAME: textProvider.getText('addSitesModal', 'inputPlaceholderName'),
    ERROR_SUGGESTIONS: textProvider.getText('addSitesModal', 'errorSuggestions'),
    VALIDATION_MESSAGE: textProvider.getText('addSitesModal', 'validationMessage'),
    ALREADY_EXIST: textProvider.getText('addSitesModal', 'alreadyExist'),
    LEARN_MORE: textProvider.getText('addSitesModal', 'learnMore'),
    COPY_ADS_TXT_MESSAGE: textProvider.getText('addSitesModal', 'copyAdsTxtCodeMessage'),
    COPY: textProvider.getText('tooltip', 'copy')
};

const CLASS_NAMES = {
    MODAL: 'add-sites-modal',
    TOP_CONTAINER: 'add-sites-top-container',
    BOTTOM_CONTAINER: 'add-sites-bottom-container',
    HEADER_TITLE: 'header-title',
    HEADER_TITLE_TEXT: 'header-title-text',
    BODY_WRAPPER: 'add-tab-body-wrapper',
    BODY_PARAGRAPH : 'add-tab-body-paragraph',
    FORM : 'input-form',
    ERROR : 'error-message',
    ALREADY_EXIST: 'error-already-exist',
    VALID: 'valid-message',
    FOOTER: 'modal-footer',
    ADD_BUTTON: 'add-sites-locally',
    INPUT_LABEL: 'input-label',
    URL_FIELD_CONTAINER: 'url-field-container',
    NAME_FIELD_CONTAINER: 'name-field-container',
    ERROR_MESSAGE_CONTAINER: 'error-message-container',
    ADS_TXT_CODE_AND_MESSAGE: 'ads-txt-code-and-message',
    ADS_TXT_COPY_CODE: 'ads-txt-copy-code',
    COPY_ADS_TXT: 'copy-ads-txt',
    LEARN_MORE_ABOUT_ADS_TXT: 'learn-more-about-ads-txt'
}

class AddSitesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: '',
            name: '',
            copy: 'Copy',
            adsTxtCode: '',
            exists: '',

            touched: {
                url: false,
                name: false
            }
        }

        this.openModal = this.openModal.bind(this);
        this.addSites = this.addSites.bind(this);
        this.addSelectedSite = this.addSelectedSite.bind(this);
        this.handleUrlChange = this.handleUrlChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.shouldMarkError = this.shouldMarkError.bind(this);
        this.shouldMarkValid = this.shouldMarkValid.bind(this);
        this.suggestName = this.suggestName.bind(this);
        this.handleClearAllFilters = this.handleClearAllFilters.bind(this);
    }

    openModal() {
        this.props.openAddSitesModal();
    }

    handleClearAllFilters = () => {
        this.props.clearAllGridFilters();
    };


    addSites() {
        const {sitesToAdd, publisher, submitSites} = this.props;
        const sites = sitesToAdd.map(siteData => siteData.data);
        const publisherId = publisher.data.id;
        submitSites(sites, publisherId);
        this.handleClearAllFilters();
    }

    addSelectedSite() {
        const {publisher} = this.props;
        const {url, name} = this.state;
        const publisherId = publisher.data.id;
        const parsedSite = parseUrl(url);
        const type = parsedSite.type;

        const currentSite = {
            ...parsedSite,
            url,
            displayName: name
        }

        this.props.addSingleSite(currentSite);

        if (type === SUPPLY_SOURCE_TYPE.DOMAIN) {
            this.props.validateDomainInAdsTxt(url, publisherId);
        }

        this.setState({
            url: '',
            name: '',
            touched: {
                url: false,
                name: false
            }
        })
    }

    closeModal() {
        this.setState({
            url: '',
            name: '',
            touched: {...this.state.touched, url: false, name: false}
        });
        this.props.closeAddSitesModal();
    }

    handleUrlChange(event) {
        this.setState({url: event.target.value});
    }

    handleNameChange(event) {
        this.setState({name: event.target.value});
    }

    handleBlur = field => () => {
        this.setState({
            touched: {...this.state.touched, [field]: true}
        });
        if(field === 'url'){
            this.suggestName(this.state.url);
        }
    }

    suggestName(url) {
        const site = parseUrl(url);
        let suggestedName;
        if (site.type === 1) {
            suggestedName = site.address;
        } else if (site.type === 2 && site.store === STORES.APPLE) {
            suggestedName = _.capitalize(site.appName.split('-').join(' '));
        } else if (site.type === 2 && site.store === STORES.GOOGLE) {
            suggestedName = site.address
        } else suggestedName = '';

        this.setState({
            name: suggestedName
        })
    }

    shouldMarkError(hasErrors, isSiteExisting) {
        const shouldShow = this.state.touched['url'];
        return (hasErrors && shouldShow) || isSiteExisting;
    }

    shouldMarkValid(hasErrors, isSiteExisting) {
        const shouldShow = this.state.touched['url'];
        return !hasErrors && shouldShow && !isSiteExisting;
    }

    renderSite = () => {
        const {sitesToAdd} = this.props;

        return sitesToAdd && sitesToAdd.map((siteData, index) => {
            const {data: site, state} = siteData;
            return (<Site key={index}
                          site={site}
                          state={state}
                          index={index}
            />)
        });
    }



    render() {
        const {closeModal, handleUrlChange, handleNameChange, addSelectedSite, addSites} = this;
        const {url, name, touched, copy} = this.state;
        const {sitesToAdd, publisherId} = this.props;

        const adsTxtCode = getAdsTxtCode(publisherId);

        const hasErrors = !parseUrl(url) ;
        const disableAddingSites = sitesToAdd && sitesToAdd.length <= 0;

        const markError = this.shouldMarkError(hasErrors);
        const markValid = this.shouldMarkValid(hasErrors);

        const disableAddingSelectedSites = markError || !touched['url'];
        const copyAdsTxtCode = <CopyToClipboard copyValue={adsTxtCode} copyTarget={'Copy ads txt'} copy={copy} noWrap />;

        if (!this.props.openModal) {
            return null;
        }

        return (
            <div>
                <Modal className={CLASS_NAMES.MODAL}
                       open={this.props.openModal}
                       basic={true}
                       closeOnOutsideClick={false}
                       onModalClose={closeModal}
                       hookId="add-sites-modal"
                >
                    <div className={CLASS_NAMES.TOP_CONTAINER}>
                        <div className={CLASS_NAMES.HEADER_TITLE}>
                            <Icon icon={'add.svg'} />
                            <h3 className={CLASS_NAMES.HEADER_TITLE_TEXT}>{CONSTANT_TEXTS.HEADER}</h3>
                        </div>
                        <Tabs defaultActiveIndex={0} hookId="add-sites-modal-tabs">
                            <Tabs.Tab header={CONSTANT_TEXTS.NAV_ITEM_ADD} hookId="add-sites-modal-add-site-tab">
                            <div className={CLASS_NAMES.BODY_WRAPPER}>
                                <div className={CLASS_NAMES.ADS_TXT_CODE_AND_MESSAGE}>
                                    <div className={CLASS_NAMES.ADS_TXT_COPY_CODE}>
                                        {copyAdsTxtCode}
                                        {CONSTANT_TEXTS.COPY_ADS_TXT_MESSAGE}
                                    </div>
                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                    <a href='https://www.google.com/' target='_blank' className={CLASS_NAMES.LEARN_MORE_ABOUT_ADS_TXT}>{CONSTANT_TEXTS.LEARN_MORE}</a>
                                </div>
                                <div className={CLASS_NAMES.FORM}>
                                    <div className={CLASS_NAMES.URL_FIELD_CONTAINER}>
                                        <div className={CLASS_NAMES.INPUT_LABEL}>
                                            {CONSTANT_TEXTS.LABEL_URL}
                                        </div>
                                        <Input type='text'
                                            name='url'
                                            className={markError ? 'error' : (markValid ? 'valid': 'url-input')}
                                            value={url}
                                            placeholder={CONSTANT_TEXTS.PLACEHOLDER_URL}
                                            onChange={handleUrlChange}
                                            onBlur={this.handleBlur('url')}
                                            iconPosition={'right'}
                                            hookId="add-sites-modal-url-input"
                                        />
                                        {touched['url'] && markValid ? <Icon icon={'valid_url.svg'} /> : (markError ? <Icon icon={'invalid_url.svg'} /> : null)}
                                        <div className={CLASS_NAMES.ERROR_MESSAGE_CONTAINER}>
                                        {markError ? <p className={CLASS_NAMES.ERROR}>{CONSTANT_TEXTS.ERROR_SUGGESTIONS}</p> : null}
                                        </div>

                                        {markValid ?
                                            <p className={CLASS_NAMES.VALID}>{CONSTANT_TEXTS.VALIDATION_MESSAGE}</p>
                                            : null}
                                    </div>
                                    <div className={CLASS_NAMES.NAME_FIELD_CONTAINER}>
                                        <div className={CLASS_NAMES.INPUT_LABEL}>
                                            {CONSTANT_TEXTS.LABEL_NAME_OPTIONAL}
                                        </div>
                                        <Input type='text'
                                            name='name'
                                            className={'name-input'}
                                            value={name}
                                            placeholder={CONSTANT_TEXTS.PLACEHOLDER_NAME}
                                            onChange={handleNameChange}
                                            onBlur={this.handleBlur('name')}
                                            hookId="add-sites-modal-name-input"
                                        />
                                    </div>
                                    <Button className={CLASS_NAMES.ADD_BUTTON}
                                            onClick={addSelectedSite}
                                            disabled={disableAddingSelectedSites}
                                            icon={'icon-add'}
                                            iconPosition={'right'}
                                            hookId='add-site-button'
                                    />
                                </div>
                            </div>
                            </Tabs.Tab>
                            <Tabs.Tab header={CONSTANT_TEXTS.NAV_ITEM_IMPORT_FILE}
                                      disabled={true}
                                      hookId="add-sites-modal-import-file-tab"
                            >
                                <div><p>Import File Component</p></div>
                            </Tabs.Tab>
                        </Tabs>
                    </div>
                    <div className={CLASS_NAMES.BOTTOM_CONTAINER}>
                        {this.renderSite()}
                    </div>
                    <div className={CLASS_NAMES.FOOTER}>
                        <Button transparent
                                onClick={closeModal}
                                hookId='add-sites-close-button'
                        >
                            {CONSTANT_TEXTS.CANCEL_BUTTON_TEXT}
                        </Button>
                        <Button onClick={addSites}
                                disabled={disableAddingSites}
                                hookId='add-sites-button'
                        >
                            {CONSTANT_TEXTS.ADD_BUTTON_TEXT}
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

AddSitesModal.propTypes = {
    openAddSitesModal: PropTypes.func,
    closeAddSitesModal: PropTypes.func,
    openModal: PropTypes.bool,
    addSingleSite: PropTypes.func,
    deleteSingleSite: PropTypes.func,
    submitSites: PropTypes.func,
    sitesToAdd: PropTypes.array,
    loadedSites: PropTypes.object,
    publisher: PropTypes.object,
    validateDomainInAdsTxt: PropTypes.func,
    validateDomain: PropTypes.object,
    publisherId: PropTypes.number,
    clearAllGridFilters: PropTypes.func
};

const mapStateToProps = ({supply, app}) => ({
    openModal: supply.openSitesModal,
    sitesToAdd: supply.selectedSites,
    loadedSites: supply.supplySources,
    publisher: app.publisher,
    validateDomain: supply
})

const mapDispatchToProps = ({
    openAddSitesModal,
    closeAddSitesModal,
    addSingleSite,
    deleteSingleSite,
    submitSites,
    validateDomainInAdsTxt,
    clearAllGridFilters
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSitesModal);
