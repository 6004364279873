import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {openAdsTxtModal} from '../actionImports';
import Icon from '../../../Icon';

const MISSING_ADS_TXT_STATUS_ID = 3;

const statusLinkageMapper = {
    0: {label: 'Pending Approval', className: 'pending'},
    1: {label: 'Approved', className: 'approved'},
    2: {label: 'Not Approved', className: 'not-approved'},
    3: {label: 'Missing ads.txt', className: 'missing-ads-txt'}
};

const CLASS_NAMES = {
    STATUS_PREFIX: 'supply-status-',
    WARNING_EXCLAMATION: 'warning-exclamation'
}

class SupplyStatusFormatter extends Component {
    constructor(props) {
        super(props);

        this.setOpenAdsTxtModal = this.setOpenAdsTxtModal.bind(this);
    }

    setOpenAdsTxtModal() {
        this.props.openAdsTxtModal(this.props.row);
    }

    render () {
        const {status} = this.props.row;
        const label = statusLinkageMapper[status].label;
        const className = statusLinkageMapper[status].className;

        return (
            <div className={className}>
                {status === MISSING_ADS_TXT_STATUS_ID &&
                    <span className={CLASS_NAMES.WARNING_EXCLAMATION} onClick={this.setOpenAdsTxtModal}>
                        <Icon icon="warning_exclamation.svg" />
                    </span>
                }
                <span className={`${CLASS_NAMES.STATUS_PREFIX}${className}`}>
                    {label}
                </span>
            </div>
        )
    }
}

SupplyStatusFormatter.propTypes = {
    row: PropTypes.object,
    openAdsTxtModal: PropTypes.func
};

const mapDispatchToProps = ({
    openAdsTxtModal
});

export default connect(null, mapDispatchToProps)(SupplyStatusFormatter);