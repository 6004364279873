import {appStates} from '../constants/appStates';
import {categoriesConstants} from '../constants/categoriesConstants';
import {reducerHelper} from './reducerHelper';

const initialState = {};

export const categoriesReducer = (state = initialState, action) => {
    const {type, payload} = action;
    const categoriesList = 'categoriesList';

    switch (type) {
        case categoriesConstants.GET_CATEGORIES_REQUEST:
            return reducerHelper(state, categoriesList, undefined, appStates.LOADING);
        case categoriesConstants.GET_CATEGORIES_SUCCESS:
            return reducerHelper(state, categoriesList, payload.categoriesList, appStates.LOADED);
        case categoriesConstants.GET_CATEGORIES_FAILURE:
            return reducerHelper(state, categoriesList, undefined, appStates.ERROR);
        case categoriesConstants.SET_OPEN_CATEGORIES_SECTIONS:
            return {
                ...state,
                openCategoriesSections: {
                    ...state.openCategoriesSections,
                    [payload.type]: {
                        [payload.id]: !payload.isOpen
                    }
                }
            }
        default:
            return state;
    }
}