import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from '../imports';
import {Tooltip} from '@perion-undertone/ut-react-common';
import {textProvider} from '../imports';

const CONSTANT_TEXTS = {
    PUBLISHER_LEVEL_BLOCKED_ADVERTISER: textProvider.getText('supplySourceSettingsFormatter', 'publisherLevelBlockedAdvertiser')
}

const BlockedAdvertiserNameFormatter = (props) => {
    const isPublisherLevel = props.dependentValues.columnInfo.isPublisherLevel;
    const blockedAdvertiserName = props.row.Advertiser.name;
    const supplySourceNameView = blockedAdvertiserName;
    const trigger = (<div>
        <Icon icon='pub_level.svg' />
        <span className='publisher-level-blocked-sales-account'>
            {blockedAdvertiserName}
        </span>
    </div>);
    const publisherNameView = (
        <Tooltip trigger={trigger}
                 placement='bottom'
                 appendToBody={true}
                 hookId="blocklist-name-formatter-tooltip"
        >
            {CONSTANT_TEXTS.PUBLISHER_LEVEL_BLOCKED_ADVERTISER}
        </Tooltip>
    );
    return (<div>
        {props.row.publishersSupplySourcesId === 0 && !isPublisherLevel ?
            publisherNameView :
            supplySourceNameView}
    </div>);
};

BlockedAdvertiserNameFormatter.propTypes = {
    row: PropTypes.object,
    dependentValues: PropTypes.object
};

export default BlockedAdvertiserNameFormatter;