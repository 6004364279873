import React, {Component} from 'react';
import ReactDataGrid from 'react-data-grid';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {AutoSizer} from 'react-virtualized';
import format from 'string-template';
import {Tooltip, Icon} from '@perion-undertone/ut-react-common';

import {UdriveLoader, textProvider} from './imports';
import GridRow from './GridRow';
import {placementGroupGridSchema} from './grid/placementGroupGridSchema';
import GridNoRowsTemplate from '../../GridNoRowsTemplate';
import BulkActionsOverlay from '../../grid/BulkActionsOverlay';
import PlacementGroupCheckbox from './grid/PlacementGroupCheckbox';
import PlacementGroupHeaderCheckbox from './grid/PlacementGroupHeaderCheckbox';
import {clearAllGridFilters} from '../../../actions/gridCustomizationActions';
import {setSelectedPlacementGroupsIds} from '../../../actions/placementsActions';
import {isAuthorized, permissionConstants} from '../../imports';
import {PUBLISHER_TYPES_MAP} from '../../../utils/globals';

const {VIEW_PLACEMENT_GROUP_DESCRIPTION, EDIT_PLACEMENT_GROUP, DELETE_PLACEMENT_GROUPS} = permissionConstants;

const CONSTANT_TEXTS = {
    GROUPS_COUNTER: textProvider.getText('placementGroupGrid', 'groupsCounter')
};

const CLASS_NAMES = {
    PLACEMENTS_CONTAINER: 'placements-container',
    PLACEMENTS_INNER_CONTAINER: 'placements-inner-container',
    PLACEMENT_GROUPS_COUNTER: 'placement-groups-counter',
    REACT_GRID_CHECKBOX_CONTAINER: 'react-grid-checkbox-container',
    REACT_GRID_CHECKBOX_CONTAINER_VISIBLE: 'react-grid-checkbox-container-visible',
    FILTER_INFO_TOOLTIP: 'filter-info-tooltip',
    FILTERS_TOOLTIP_TITLE: 'filter-tooltip-title',
    FILTERS_TOOLTIP_LIST: 'filter-tooltip-list',
    FILTERED_BY: 'filtered-by',
    CLEAR_FILTER_ICON_CONTAINER: 'clear-all-filters-container',
    CLEAR_FILTER_ICON: 'clear-all-filters-icon',
    CLEAR_FILTER_BUTTON: 'clear-all-filters-button'
};

class PlacementGroupGrid extends Component {

    cancelBulkDelete = () => {
        this.props.setSelectedPlacementGroupsIds(null);
    }

    handleClearAllFilters = () => {
        this.props.clearAllGridFilters();
    }

    renderFilteredByTrigger = (numOfFilters) => {
        return (<span className={CLASS_NAMES.FILTERED_BY}>Filter by {numOfFilters}</span>);
    }

    getActiveValues(filter) {
        if(filter.field !== 'status' && filter.field !== 'adUnits') {
            return filter.value;
        }
        const {allFilterOptions} = this.props.customization;
        const filterOptions = allFilterOptions[filter.field];
        const values = filter.value;
        const foundFilters = filterOptions && filterOptions.filter(option => filter.field === 'status' ? !values.includes(option.alias) : !values.includes(option.name));
        const filterToShow = foundFilters && foundFilters.map(filter => filter.name);

        return filterToShow[0] ? filterToShow.join(', ') : 'none';
    }

    renderFilterInfo = (customization, numOfFilters) => {
        return (
            <div>
                <Tooltip placement="bottom-start"
                         trigger={this.renderFilteredByTrigger(numOfFilters)}
                         className={'placements-filter-tooltip'}
                         hookId="placement-group-grid-tooltip"
                >
                    <span className={CLASS_NAMES.FILTERS_TOOLTIP_TITLE}>
                        Used Filters ({numOfFilters})
                    </span>
                    <ul className={CLASS_NAMES.FILTERS_TOOLTIP_LIST}>
                        {customization.filters.map(filter => {
                            return <li key={filter.field}>{filter.field} - {this.getActiveValues(filter)}</li>
                        })}
                    </ul>
                </Tooltip>
                <span className={CLASS_NAMES.CLEAR_FILTER_ICON_CONTAINER}>
                    <div className={CLASS_NAMES.CLEAR_FILTER_ICON}>
                        <Icon icon="icon-filter_clear"
                              hookId="placement-group-filter-icon"
                        />
                    </div>
                    <div className={CLASS_NAMES.CLEAR_FILTER_BUTTON}>
                        <Icon icon="icon-close_filter_clear"
                              onClick={this.handleClearAllFilters}
                              hookId="placement-group-filter-button"
                        />
                    </div>
                </span>
            </div>
        );
    }

    render() {
        const {placementGroups, customization, selectedGroupsIds, placementsPagination, publisherTypeId} = this.props;
        const publisherTypeCd = PUBLISHER_TYPES_MAP[publisherTypeId];
        const rowSource = (placementGroups && placementGroups.data) || []
        const numOfFilters = customization && customization.filters ? customization.filters.length : 0;
        const viewDescription = isAuthorized({permission: VIEW_PLACEMENT_GROUP_DESCRIPTION});
        const editOrDeletePlacement = isAuthorized({permission: EDIT_PLACEMENT_GROUP}) || isAuthorized({permission: DELETE_PLACEMENT_GROUPS});

        return (
            <div className={CLASS_NAMES.PLACEMENTS_CONTAINER}>
                {placementGroups && placementGroups.data
                    ?   <div className={CLASS_NAMES.PLACEMENTS_INNER_CONTAINER}>
                            <div className={CLASS_NAMES.PLACEMENT_GROUPS_COUNTER} >
                                {placementsPagination.count ? format(CONSTANT_TEXTS.GROUPS_COUNTER, placementsPagination.count) : ''}
                            </div>
                            <div className={CLASS_NAMES.FILTER_INFO_TOOLTIP}>
                                {numOfFilters > 0 ? this.renderFilterInfo(customization, numOfFilters) : null}
                            </div>
                            <AutoSizer>
                                {({height, width}) => {
                                    return (
                                        <ReactDataGrid columns={placementGroupGridSchema(viewDescription, editOrDeletePlacement, publisherTypeCd)}
                                            ref={node => this.grid = node}
                                            enableCellAutoFocus={false}
                                            rowGetter={i => rowSource[i]}
                                            rowsCount={rowSource.length}
                                            rowHeight={56}
                                            rowRenderer={GridRow}
                                            headerRowHeight={41}
                                            minWidth={width}
                                            minHeight={height}
                                            emptyRowsView={GridNoRowsTemplate}
                                            rowActionsCell={editOrDeletePlacement ? PlacementGroupCheckbox : undefined}
                                            selectAllRenderer={editOrDeletePlacement ? PlacementGroupHeaderCheckbox : undefined}
                                        />
                                    );
                                }}
                            </AutoSizer>
                        </div>
                    :   <UdriveLoader />
                }
                {selectedGroupsIds.length > 0
                    && (<BulkActionsOverlay cancelBulkDelete={this.cancelBulkDelete}
                                            selectedGroupIds={selectedGroupsIds}
                                            displayBlockOption={false}
                                            displayDeleteOption={true}
                                            idsType='placementGroupIds'
                />)}
            </div>
        );
    }
}

PlacementGroupGrid.propTypes = {
    placementGroups: PropTypes.object,
    searchTerm: PropTypes.string,
    grid: PropTypes.object,
    customization: PropTypes.object,
    clearAllGridFilters: PropTypes.func,
    setSelectedPlacementGroupsIds: PropTypes.func,
    selectedGroupsIds: PropTypes.array,
    placementsPagination: PropTypes.object,
    publisherTypeId: PropTypes.number
};

const mapStateToProps = ({placements, grid, app}) => ({
    placementGroups: placements.placementGroups,
    searchTerm: placements.searchTerm,
    grid,
    customization: grid.customization,
    selectedGroupsIds: placements.selectedGroupsIds,
    placementsPagination: placements.placementsPagination,
    publisherTypeId: app.publisher.data.publisherTypeId
});
const mapDispatchToProps = ({
    clearAllGridFilters,
    setSelectedPlacementGroupsIds
})
export default connect(mapStateToProps, mapDispatchToProps)(PlacementGroupGrid);