import _ from 'lodash';
import React, {Component} from 'react';
import ReactDataGrid from 'react-data-grid';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {connect} from 'react-redux';
import {Icon, Tooltip} from '@perion-undertone/ut-react-common';
import {AutoSizer} from 'react-virtualized';
import GridNoRowsTemplate from '../../GridNoRowsTemplate';
import {supplySourcesGridSchema, settings} from './grid/supplySourcesGridSchema';
import {clearAllGridFilters, UdriveLoader} from './actionImports';
import BulkActionsOverlay from '../../grid/BulkActionsOverlay';
import {appStates} from "../../../constants";

const CLASS_NAMES = {
    SUPPLY_BODY_CONTAINER: 'supply-body-container',
    SUPPLY_BODY_INNER_CONTAINER: 'supply-body-inner-container',
    SUPPLY_BODY_INNER_LOADING_CONTAINER: 'supply-body-inner-container--is-loading',
    FILTER_INFO_TOOLTIP: 'filter-info-tooltip',
    FILTERS_TOOLTIP_TITLE: 'filter-tooltip-title',
    FILTERS_TOOLTIP_LIST: 'filter-tooltip-list',
    FILTERED_BY: 'filtered-by',
    CLEAR_FILTER_ICON_CONTAINER: 'clear-all-filters-container',
    CLEAR_FILTER_ICON: 'clear-all-filters-icon',
    CLEAR_FILTER_BUTTON: 'clear-all-filters-button',
    REACT_GRID_CHECKBOX_CONTAINER: 'react-grid-checkbox-container',
    REACT_GRID_CHECKBOX_CONTAINER_VISIBLE: 'react-grid-checkbox-container-visible',
};

class SupplySourcesGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: []
        };
    }

    componentDidMount() {
        this.getSupplySourcesGridSchema();
    }

    componentDidUpdate(prevProps) {
        const {selectedRows} = this.state;
        const selectedGroupIds = this.getSelectedRowsIds(selectedRows);
        const prevPlacementGroups = prevProps.supplySources && prevProps.supplySources.data;
        const currPlacementGroups = this.props.supplySources && this.props.supplySources.data;

        if (prevPlacementGroups && currPlacementGroups && !_.isEqual(prevPlacementGroups, currPlacementGroups)) {
            const currSelectedGroupIds = selectedGroupIds.filter(pgId => currPlacementGroups.find(pg => pg.publishersSupplySourcesId === pgId));
            if (!_.isEqual(selectedGroupIds, currSelectedGroupIds)) {
                this.setState({selectedGroupIds: currSelectedGroupIds});
                this.toggleSelectAllCheckbox(currSelectedGroupIds);
            }
        }
    }

    handleClearAllFilters = () => {
        this.props.dispatch(clearAllGridFilters());
    };

    renderFilteredByTrigger = (numOfFilters) => {
        return (<span className={CLASS_NAMES.FILTERED_BY}>Filter by {numOfFilters}</span>);
    };

    renderFilterInfo = (customization, numOfFilters) => {
        return (
            <div>
                <Tooltip placement="bottom-start"
                         trigger={this.renderFilteredByTrigger(numOfFilters)}
                         className={'placements-filter-tooltip'}
                         hookId="supply-sources-filter-tooltip"
                >
                    <span className={CLASS_NAMES.FILTERS_TOOLTIP_TITLE}>
                        Used Filters ({numOfFilters})
                    </span>
                    <ul className={CLASS_NAMES.FILTERS_TOOLTIP_LIST}>
                        {customization.filters.map(filter => {
                            return <li key={filter.field}>{filter.field} - {this.getActiveValues(filter)}</li>
                        })}
                    </ul>
                </Tooltip>
                <span className={CLASS_NAMES.CLEAR_FILTER_ICON_CONTAINER}>
                    <div className={CLASS_NAMES.CLEAR_FILTER_ICON}>
                        <Icon icon="icon-filter_clear" hookId="supply-sources-filter-clear" />
                    </div>
                    <div className={CLASS_NAMES.CLEAR_FILTER_BUTTON}>
                        <Icon icon="icon-close_filter_clear"
                              onClick={this.handleClearAllFilters}
                              hookId="supply-sources-filter-button"
                        />
                    </div>
                </span>
            </div>
        );
    }

    getActiveValues(filter) {
        if(filter.field !== 'status' && filter.field !== 'store') {
            return filter.value;
        }
        const {allFilterOptions} = this.props.customization;
        const filterField = filter.field === 'store' ? 'supplySourceType' : filter.field === 'status' ? 'supplySourceStatus' : filter.field;
        const filterOptions = allFilterOptions[filterField];
        const values = filter.value;
        const foundFilters = filterOptions && filterOptions.filter(option => !values.includes(option.alias));
        const filterToShow = foundFilters && foundFilters.map(filter => filter.name);

        return filterToShow[0] ? filterToShow.join(', ') : 'none';
    }

    getSupplySourcesGridSchema = () => {
        const {features} = this.props;

        const settingsFeature = features.find(feature => feature.featureName === 'SupplySourceSettings');
        const isSettingsVisible = supplySourcesGridSchema.find(field => field.key === "supply-actions");
        settingsFeature.isEnabled && !isSettingsVisible && supplySourcesGridSchema.push(settings);
    }

    cancelBulkDelete = () => {
        this.setState({selectedRows: []});
        this.grid.selectAllCheckbox.checked = false;
        this.grid.selectAllCheckbox.parentNode.className = CLASS_NAMES.REACT_GRID_CHECKBOX_CONTAINER;
    }

    setSelectAllCheckbox = (rows, shouldSelect) => {
        const {selectedRows} = this.state;

        let newRows = [];

        if (rows.length < 2) {
            newRows = shouldSelect
                ? selectedRows.concat(rows[0])
                : selectedRows.filter(i => rows[0].row.publishersSupplySourcesId !== i.row.publishersSupplySourcesId);
        }

        this.setState(
            {selectedRows: newRows},
            () => {this.toggleSelectAllCheckbox(this.state.selectedRows)}
        );
    }

    getSelectedRowsIds = (selectedRows) => {
        return selectedRows.map(r => r.row.publishersSupplySourcesId);
    }

    getSelectedRowsDisplayNames = (selectedRows) => {
        return selectedRows.map(r => r.row.displayName);
    }

    toggleSelectAllCheckbox = (selectedRows) => {
        const selectedGroupIds = this.getSelectedRowsIds(selectedRows);
        const {selectAllCheckbox} = this.grid;
        if (_.isArray(selectedGroupIds) && selectedGroupIds.length < 1) {
            selectAllCheckbox.checked = false;
            selectAllCheckbox.parentNode.className = CLASS_NAMES.REACT_GRID_CHECKBOX_CONTAINER;
        } else {
            selectAllCheckbox.checked = true;
            selectAllCheckbox.parentNode.className = CLASS_NAMES.REACT_GRID_CHECKBOX_CONTAINER_VISIBLE;
        }
    }

    onRowsSelected = rows => {
        const shouldSelect = rows.length === 1;
        this.setSelectAllCheckbox(rows, shouldSelect);
    }

    onRowsDeselected = rows => {
        this.setSelectAllCheckbox(rows, false);
    }

    render() {
        const {supplySources, customization} = this.props;
        const rowSource = (supplySources && supplySources.data) || [];
        const numOfFilters = customization && customization.filters ? customization.filters.length : 0;
        const selectedGroupIds = this.getSelectedRowsIds(this.state.selectedRows);
        const selectedGroupDisplayNames = this.getSelectedRowsDisplayNames(this.state.selectedRows);
        const innerContainerClassNames = cx(CLASS_NAMES.SUPPLY_BODY_INNER_CONTAINER, {
            [CLASS_NAMES.SUPPLY_BODY_INNER_LOADING_CONTAINER]: supplySources && supplySources.state === appStates.LOADING
        });
        return (<div className={CLASS_NAMES.SUPPLY_BODY_CONTAINER}>
                {_.isNil(supplySources && supplySources.data) ? <UdriveLoader /> : <div className={innerContainerClassNames}>
                                <div className={CLASS_NAMES.FILTER_INFO_TOOLTIP}>
                                    {numOfFilters > 0 ? this.renderFilterInfo(customization, numOfFilters) : null}
                                </div>
                                <AutoSizer>
                                    {({height, width}) => {
                                        return (<ReactDataGrid columns={supplySourcesGridSchema}
                                                ref={node => this.grid = node}
                                                enableCellAutoFocus={false}
                                                rowGetter={i => rowSource[i]}
                                                rowsCount={rowSource.length}
                                                rowHeight={56}
                                                headerRowHeight={41}
                                                minWidth={width}
                                                minHeight={height}
                                                emptyRowsView={GridNoRowsTemplate}
                                                rowSelection={{
                                                    showCheckbox: true,
                                                    onRowsSelected: this.onRowsSelected,
                                                    onRowsDeselected: this.onRowsDeselected,
                                                    selectBy: {
                                                        keys: {rowKey: 'publishersSupplySourcesId', values: selectedGroupIds}
                                                    }
                                                }}
                                        />);
                                    }}
                                </AutoSizer>
                            </div>
                    }
                    {selectedGroupIds.length > 0
                        && (<BulkActionsOverlay cancelBulkDelete={this.cancelBulkDelete}
                                                selectedGroupIds={selectedGroupIds}
                                                selectedGroupDisplayNames={selectedGroupDisplayNames}
                                                displayBlockOption={true}
                                                displayDeleteOption={false}
                    />)}
            </div>
        );
    }
}

SupplySourcesGrid.propTypes = {
    supplySources: PropTypes.object,
    grid: PropTypes.object,
    customization: PropTypes.object,
    features: PropTypes.array,
    filter: PropTypes.object
};

const mapStateToProps = ({supply, grid, features}) => ({
    supplySources: supply.supplySources,
    grid,
    customization: grid.customization,
    features
});

export default connect(mapStateToProps)(SupplySourcesGrid);
