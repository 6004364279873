import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {getHookId, DataGrid} from '@perion-undertone/ut-react-common';
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import _ from 'lodash';

import PlacementHeader from './PlacementHeader';
import PlacementGroupModal from './modal/PlacementGroupModal';
import PlacementGroupGrid from './PlacementGroupGrid';
import {getPlacementGroups, getPlacementFile} from './imports';
import {getAdUnits} from './imports';
import PlacementConfirmationModal from './PlacementConfirmationModal';
import FileUpload from './FileUpload';
import {clearAllGridFilters} from '../../../actions/gridCustomizationActions';

const CLASS_NAMES = {
    PLACEMENTS_WRAPPER: 'placements-wrapper',
    PLACEMENTS_GRID_CONTAINER: 'placements-grid-container',
}

class Placements extends Component {
    
    constructor(props) {
        super(props);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeLimit = this.handleChangeLimit.bind(this);
        this.state = {
            rows: this.props.limit ? this.props.limit : 20,
            pageNo: this.props.page ? this.props.page : 1
        }
    }

    componentDidMount() {
        const {getPlacementGroups, clearAllGridFilters, getAdUnits, getPlacementFile, publisherId, searchTerm} = this.props;
        getPlacementGroups(publisherId, this.state.rows, this.state.pageNo, searchTerm);
        getAdUnits(publisherId);
        getPlacementFile(publisherId);
        clearAllGridFilters();
    }

    componentDidUpdate(prevProps) {
        const {publisherId, searchTerm, customization, getPlacementGroups} = this.props;
        const filters = get(customization, 'filters') || [];
        const prevFilters = get(prevProps.customization, 'filters') || [];
        const sort = get(customization, 'sort') || [];
        const prevSort = get(prevProps.customization, 'sort') || [];
        if(!isEqual(filters, prevFilters) || !isEqual(sort, prevSort) ) {
            getPlacementGroups(publisherId, this.state.rows, 1, searchTerm, false);
        }
    }

    handleChangePage(page) {
        const {getPlacementGroups, publisherId, searchTerm} = this.props;
        this.setState({pageNo: page});
        getPlacementGroups(publisherId, this.state.rows, page, searchTerm);
    }
    handleChangeLimit(val) {
        const {getPlacementGroups, publisherId, searchTerm} = this.props;
        this.setState({rows: val});
        getPlacementGroups(publisherId, val, this.state.pageNo, searchTerm);
    }
    render() {
        const {publisherId, count} = this.props;
        return (
            <div className={CLASS_NAMES.PLACEMENTS_WRAPPER}
                 {...getHookId('placements-page')}
            >
                <div className={CLASS_NAMES.PLACEMENTS_GRID_CONTAINER}>
                    <PlacementHeader />
                    <PlacementGroupGrid />
                </div>
                <footer>
                    <DataGrid.PagingPanel 
                        handleChangeItemsPerPage={this.handleChangeLimit}
                        handleChangePage={this.handleChangePage}
                        itemsPerPage={this.state.rows}
                        itemsPerPageOptions={[12, 20, 50, 100]}
                        itemsPerPageText="Rows per page"
                        page={this.state.pageNo}
                        rowCount={_.isUndefined(count) ? 0 : Number(count)}
                    />
                    <FileUpload publisherId={publisherId} />
                </footer>
                <PlacementGroupModal publisherId={publisherId} />
                <PlacementConfirmationModal publisherId={publisherId}/>
            </div>
        );
    }
}
Placements.propTypes = {
    publisherId: PropTypes.number,
    getPlacementGroups: PropTypes.func,
    clearAllGridFilters: PropTypes.func,
    getAdUnits: PropTypes.func,
    getPlacementFile: PropTypes.func,
    searchTerm: PropTypes.string,
    customization: PropTypes.object,
    page: PropTypes.number,
    limit: PropTypes.number,
    count: PropTypes.number
};

const mapStateToProps = ({placements, app, grid}) => ({
    currentGroup: placements.currentGroup,
    publisherId: app.publisher.data.id,
    searchTerm: placements.searchTerm,
    customization: grid.customization,
    page: placements.placementsPagination.page,
    limit: placements.placementsPagination.limit,
    count: placements.placementsPagination.count
});

const mapDispatchToProps = ({
    getPlacementGroups,
    getAdUnits,
    getPlacementFile,
    clearAllGridFilters
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Placements));
