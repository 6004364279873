import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ConfirmationModal from '../ConfirmationModal';
import Icon from '../Icon';
import {deletePlacementGroups, bulkDeletePlacementGroupAdSlot} from '../../actions/placementsActions';
import textProvider from '../../texts/textProvider';
import {formatString} from '../../texts/formatString';
import SupplySettingsModal from '../features/supply/SupplySettingsModal';
import cx from 'classnames';
import {confirm, ToastProvider} from '@perion-undertone/ut-react-common';
import get from "lodash/get";

const CLASS_NAMES = {
    BULK_DELETE_OVERLAY_WRAPPER: 'bulk-delete-overlay-wrapper',
    BULK_DELETE_OVERLAY_TEXT: 'bulk-delete-overlay-text',
    BULK_DELETE_OVERLAY_SELECTED_NUMBER: 'bulk-delete-overlay-selected-number',
    BULK_DELETE_OVERLAY_SELECTED_TEXT: 'bulk-delete-overlay-selected-text',
    BULK_DELETE_ICON_WRAPPER: 'bulk-delete-icon-wrapper',
    BULK_SETTINGS_ICON_WRAPPER: 'bulk-settings-icon-wrapper',
    BULK_DELETE_CANCEL: 'bulk-delete-cancel',
    BULK_DELETE_OVERLAY_ADSLOTS_WRAPPER: 'bulk-delete-adslots-wrapper'
};

const CONSTANT_TEXTS = {
    BULK_DELETE_MESSAGE: textProvider.getText('confirmationModal', 'bulkDeleteConfirmationMessage'),
    CLEAR_ALL_TEXT: textProvider.getText('confirmationModal', 'clearAll'),
    APPROVE_TEXT: textProvider.getText('confirmationModal', 'approve'),
    SELECTED_PLACEMENT_GROUPS: textProvider.getText('confirmationModal', 'selectedPlacementGroups'),
    HEADER_TEXT: textProvider.getText('confirmationModal', 'bulkDeleteHeader')
}

class BulkActionsOverlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDeleteConfirmationModal: false,
            openSupplySettingsModal: false,
            placementGroupIds: [],
            supplySourcesIds: []
        }
    }

    onOpenModal = (modalType, idsType, selectedIds) => {
        this.setState({[modalType]: true, [idsType]: selectedIds})
    }

    onCloseModal = (modalType) => {
        this.setState({[modalType]: false});
    }

    onDelete = async () => {
        const {
            publisherId, deletePlacementGroups, bulkDeletePlacementGroupAdSlot, cancelBulkDelete,
            idsType, selectedGroupIds, placementsPagination: {limit, page}, searchTerm, customization
        } = this.props;
        const sort = get(customization, 'sort') || [];
        const isConfirmed = await confirm(this.getConfirmMessage(idsType, selectedGroupIds));
        if(isConfirmed) {
            if(idsType === 'adSlotsIds') {
                bulkDeletePlacementGroupAdSlot(selectedGroupIds);
            }
            if(idsType === 'placementGroupIds') {
                deletePlacementGroups(publisherId, selectedGroupIds, limit, page, searchTerm, sort);
            }
        }
        cancelBulkDelete();
    }

    getConfirmMessage = (idsType, selectedGroupIds) => {
        if(idsType === 'adSlotsIds') {
            return {
                confirmationMessage: 'Are you sure you want to delete these ad slots?',
                headerText: 'Delete ad slots',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }
        }
        return {
            confirmationMessage: formatString(CONSTANT_TEXTS.BULK_DELETE_MESSAGE, selectedGroupIds.length),
            headerText: CONSTANT_TEXTS.HEADER_TEXT,
            confirmButtonText: CONSTANT_TEXTS.APPROVE_TEXT,
            cancelButtonText: CONSTANT_TEXTS.CANCEL_TEXT
        }
    }

    openSupplySettingsModalFunc = () =>{
        const {selectedGroupIds} = this.props;
        if(selectedGroupIds.length === 1){
            return ToastProvider.error("Please select at least 2 in order to bulk edit.");
        }
        this.onOpenModal('openSupplySettingsModal', 'supplySourcesIds', selectedGroupIds)
    }

    render() {
        const {
            cancelBulkDelete, selectedGroupIds, displayBlockOption, selectedGroupDisplayNames, 
            displayDeleteOption, isFromAdSlots
        } = this.props;
        const confirmBulkDeleteMessage = formatString(CONSTANT_TEXTS.BULK_DELETE_MESSAGE, selectedGroupIds.length);
        
        const selectedPlacementGroups = CONSTANT_TEXTS.SELECTED_PLACEMENT_GROUPS;
        const selectedPlacementGroupsNumber = selectedGroupIds.length;

        return (
        <div className={cx(CLASS_NAMES.BULK_DELETE_OVERLAY_WRAPPER, 
            isFromAdSlots && CLASS_NAMES.BULK_DELETE_OVERLAY_ADSLOTS_WRAPPER)}>
            <div className={CLASS_NAMES.BULK_DELETE_OVERLAY_TEXT}>
            <div className={CLASS_NAMES.BULK_DELETE_OVERLAY_SELECTED_NUMBER}>
                        {selectedPlacementGroupsNumber}
                    </div>
                    <div className={CLASS_NAMES.BULK_DELETE_OVERLAY_SELECTED_TEXT}>
                        {selectedPlacementGroups}
                    </div>
            </div>
            {displayDeleteOption && <div className={CLASS_NAMES.BULK_DELETE_ICON_WRAPPER}
                 onClick={this.onDelete}
            >
                <Icon icon="trash_03" />
            </div>}
            {displayBlockOption && <div className={CLASS_NAMES.BULK_SETTINGS_ICON_WRAPPER}
                 onClick={this.openSupplySettingsModalFunc}
            >
                <Icon icon="general" />
            </div>}
            <div className={CLASS_NAMES.BULK_DELETE_CANCEL}
                 onClick={cancelBulkDelete}
            >
                {CONSTANT_TEXTS.CLEAR_ALL_TEXT}
            </div>
            {this.state.openDeleteConfirmationModal ?
                <ConfirmationModal openModal={this.state.openDeleteConfirmationModal}
                                   closeModal={this.onCloseModal.bind(this, 'openDeleteConfirmationModal')}
                                   closeOnOutsideClick={false}
                                   onConfirm={this.deletePlacementGroupRow}
                                   confirmationMessage={confirmBulkDeleteMessage}
                                   confirmButtonText={CONSTANT_TEXTS.APPROVE_TEXT}
                                   headerText={CONSTANT_TEXTS.HEADER_TEXT}
                />
            : null}
            {this.state.openSupplySettingsModal ?
                <SupplySettingsModal openModal={this.state.openSupplySettingsModal}
                                     closeModal={this.onCloseModal.bind(this, 'openSupplySettingsModal')}
                                     closeOnOutsideClick={false}
                                     supplySourceNames={selectedGroupDisplayNames}
                                     publishersSupplySourcesIds={this.state.supplySourcesIds}
                                     isBulkBlock={true}
                />
            : null}
        </div>);
    }
}

const mapStateToProps = ({grid, placements, app}) => ({
    publisherId: app.publisher.data.id,
    placementsPagination: placements.placementsPagination,
    searchTerm: placements.searchTerm,
    customization: grid.customization,
});

const mapDispatchToProps = ({
    deletePlacementGroups,
    bulkDeletePlacementGroupAdSlot
});

BulkActionsOverlay.propTypes = {
    selectedGroupIds: PropTypes.array,
    deletePlacementGroups: PropTypes.func,
    bulkDeletePlacementGroupAdSlot: PropTypes.func,
    publisherId: PropTypes.number,
    cancelBulkDelete: PropTypes.func,
    displayBlockOption: PropTypes.bool,
    displayDeleteOption: PropTypes.bool,
    selectedGroupDisplayNames: PropTypes.array,
    idsType: PropTypes.string,
    isFromAdSlots: PropTypes.bool,
    placementsPagination: PropTypes.object,
    searchTerm: PropTypes.string,
    customization: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionsOverlay);