import React from 'react';
import PropTypes from 'prop-types';
import GridHeaderCell from './GridHeaderCell';

const headerCellRenderer = (props) => (<GridHeaderCell column={props.column}/>)

headerCellRenderer.propTypes = {
    column: PropTypes.object
};

export default headerCellRenderer;
