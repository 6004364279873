import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Checkbox, Input, Select} from '@perion-undertone/ut-react-common';
import _ from 'lodash';
import {
    textProvider,
    setIsAuctionPriceMacro,
    setAuctionPriceMacro,
    setWinEventType,
    seAuctionPriceMacroType
} from '../imports';
import metadataProvider from '../../../../utils/MetadataProvider';
import {PUBLISHER_TYPES_MAP} from '../../../../utils/globals';

const CONSTANT_TEXTS = {
    SSP_TITLE: textProvider.getText('advancedSettings', 'sspconfigtitle'),
    WINEVENT: textProvider.getText('advancedSettings', 'winevent'),
    AUCTIONPRICE: textProvider.getText('advancedSettings', 'auctionprice'),
    MACRO_PRICE_TYPE: textProvider.getText('sspConfigurations', 'macropricetype')
}
const defaultWinOption = {label: 'nurl', value: 'nurl'};
const getEventWinOptionByStr = (value, options) => {
    if(_.isEmpty(value)){
        return defaultWinOption;
    }
    const selectedOption = _.find(options, {value})

    return selectedOption ? selectedOption : defaultWinOption;
}


const getMacroPriceTypeOptionByStr = (initValue, options) =>{
    const value = _.isNil(initValue) ? '' : _.toString(initValue);
    if(_.isEmpty(value)){
        const burlOption = _.find(options, {value: '0'});
        return burlOption;
    }
    return _.find(options, {value});
}



class SspConfigurations extends Component {
  
    setIsAuctionPriceMacro = () =>{
        this.props.setIsAuctionPriceMacro(!this.props.currentGroup.isAuctionPriceMacro);
    }

    setAuctionPriceMacro = event =>{
            this.props.setAuctionPriceMacro(event.target.value);

    }

    onChangeWinEvent = option =>{
        this.props.setWinEventType(option.value);
    }
  
   
    onChangeAuctionPriceType = option =>{
        this.props.seAuctionPriceMacroType(option.value);
    }

  render() {
    
    const {publisher, currentGroup, isSSPAllowed} = this.props;
    const publisherTypeStr = PUBLISHER_TYPES_MAP[_.get(publisher, 'data.publisherTypeId', '')];
    const isSSpPublisher = _.toLower(publisherTypeStr) === 'ssp';
    if(!isSSpPublisher || !isSSPAllowed){
        return null;
    }
    const {isAuctionPriceMacro, auctionPriceMacro, winEventType, actionPriceMacroType} = currentGroup;
    const winTypeEventOptions = metadataProvider.getAsList('PlacementWinEventTypes');
    
    const priceMacroTypes = metadataProvider.getAsList('PlacementPriceMacroType'); 
    const selectedWinEventOption = getEventWinOptionByStr(winEventType, winTypeEventOptions);
    const selectedMacroPriceType = getMacroPriceTypeOptionByStr(actionPriceMacroType, priceMacroTypes);

    return (
        <div className='ssp-warpper'>
        <div className='is-auction-price-macro'>
               <Checkbox squared={true}
                      transparent={true}
                      checked={isAuctionPriceMacro}
                      onChange={this.setIsAuctionPriceMacro}
                      hookId='set-auction-price-macro'>
                     {CONSTANT_TEXTS.AUCTIONPRICE}
            </Checkbox> 
            <Input onChange={this.setAuctionPriceMacro}  disabled={!isAuctionPriceMacro} hookId="auction_price_macro" value={auctionPriceMacro} className="auction-price-macro" />
        </div>
        <div className='action-price-macro-type'>
        {CONSTANT_TEXTS.MACRO_PRICE_TYPE}
            <Select 
                options={priceMacroTypes}
                type='select'
                value={selectedMacroPriceType}
                isFocused={false}
                disabled={false}
                onChange={this.onChangeAuctionPriceType}
                hookId='action-price-macro-type'
        />
        </div>
       <div className='win-type-event'>
       {CONSTANT_TEXTS.WINEVENT}
            <Select 
                options={winTypeEventOptions}
                type='select'
                value={selectedWinEventOption}
                isFocused={false}
                disabled={false}
                onChange={this.onChangeWinEvent}
                hookId='win-event-type'
        />
       </div>
       </div>
    )
  }
}


const mapStateToProps = (state) => ({
    publisher: state.app.publisher,
    currentGroup: state.placements.currentGroup,
    placementGroups: state.placements.placementGroups
});

const mapDispatchToProps = ({
    setIsAuctionPriceMacro,
    setAuctionPriceMacro,
    setWinEventType,
    seAuctionPriceMacroType
});


SspConfigurations.propTypes = {
    publisher: PropTypes.object,
    currentGroup: PropTypes.object,
    placementGroups: PropTypes.object,
    setIsAuctionPriceMacro: PropTypes.func,
    setAuctionPriceMacro: PropTypes.func,
    setWinEventType: PropTypes.func,
    placementTagTypeId: PropTypes.number,
    seAuctionPriceMacroType: PropTypes.func,
    isSSPAllowed: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(SspConfigurations);