import {of} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import {
    getCurrentPublisherSuccess, getCurrentPublisherFailed,
    fetchPublishersSuccess, fetchPublishersFailed
} from '../actions/appActions';
import {appConstants} from '../constants/appConstants';

export const currentPublisherEpic = actions$ => {
    return actions$.pipe(
        ofType(appConstants.CURRENT_PUBLISHER_REQUEST),
        mergeMap(action => {
            const {publisherId, withUserUpdate} = action.payload;
            const query = withUserUpdate ? 'withUserUpdate=true' : '';

            return ajax.getJSON(`/api/publisher/${publisherId}?${query}`,
                {'Content-Type': 'application/json'}).pipe(
                map(res => getCurrentPublisherSuccess(res)),
                catchError(error => of(getCurrentPublisherFailed(error)))
            )
        }),
    )
};

export const fetchPublishersEpic = actions$ => {
    return actions$.pipe(
        ofType(appConstants.FETCH_PUBLISHERS_REQUEST),
        mergeMap(action => {
            const {searchTerm, publisherId} = action.payload;
            let query = '';
            // searchTerm and publisherId are mutually exclusive
            query = searchTerm && `searchTerm=${searchTerm}`;
            query = publisherId && `publisherId=${publisherId}`;

            return ajax.getJSON(`/api/publishers?${query}`,
                {'Content-Type': 'application/json'}).pipe(
                map(res => fetchPublishersSuccess(res)),
                catchError(error => of(fetchPublishersFailed(error)))
            )
        }),
    )
};