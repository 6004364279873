import {toPercent, thousandsToKFormatter} from '../imports';
import SupplyStatusFormatter from './SupplyStatusFormatter';
import SupplyNameTooltipFormatter from './SupplyNameTooltipFormatter';
import {enums} from '@perion-undertone/ramp-helpers';
import textProvider from '../../../../texts/textProvider';
import ColumnSearchTextFilter from '../../../grid/filters/ColumnSearchTextFilter';
import headerCellRenderer from '../../../headerCellRenderer';
import ColumnCheckboxFilter from '../../../grid/filters/ColumnCheckboxFilter';
import SupplyActionsFormatter from './SupplyActionsFormatter';
import {supplySourceStoreNames} from '../../../../utils/globals';
import get from 'lodash/get';
const {SUPPLY_SOURCE_TYPE} = enums;
const CLASS_NAMES = {
    SUPPLY_NAME_CELL: 'supply-name-cell',
    SUPPLY_TYPE_CELL: 'supply-type-cell',
    SUPPLY_REQUESTS_CELL: 'supply-requests-cell',
    SUPPLY_IMPRESSIONS_CELL: 'supply-impressions-cell',
    SUPPLY_FILL_RATE_CELL: 'supply-fill-rate-cell',
    SUPPLY_ACTIONS_CELL: 'supply-actions-cell'
}

const CONSTANT_TEXTS = {
    NAME: textProvider.getText('supplySourceGrid', 'name'),
    STATUS: textProvider.getText('supplySourceGrid', 'status'),
    TYPE: textProvider.getText('supplySourceGrid', 'type'),
    REQUESTS: textProvider.getText('supplySourceGrid', 'requests'),
    IMPRESSIONS: textProvider.getText('supplySourceGrid', 'impressions'),
    FILL_RATE: textProvider.getText('supplySourceGrid', 'fillRate'),
    WEBSITE: textProvider.getText('supplySourceType', 'website'),
    ANDROID: textProvider.getText('supplySourceType', 'android'),
    APPLE: textProvider.getText('supplySourceType', 'apple'),
}

export const supplySourcesGridSchema = [
    {
        name: CONSTANT_TEXTS.NAME,
        id: 'displayName',
        key: 'displayName',
        width: 400,
        cellClass: CLASS_NAMES.SUPPLY_NAME_CELL,
        headerRenderer: headerCellRenderer,
        filterComponent: ColumnSearchTextFilter,
        isSortable: true,
        formatter: SupplyNameTooltipFormatter
    },
    {
        name: CONSTANT_TEXTS.STATUS,
        id: 'status',
        key: 'status',
        width: 130,
        formatter: SupplyStatusFormatter,
        aliasKey: 'supplySourceStatus',
        headerRenderer: headerCellRenderer,
        filterComponent: ColumnCheckboxFilter,
        isSortable: false
    },
    {
        name: CONSTANT_TEXTS.TYPE,
        id: 'store',
        key: 'SupplySource.type',
        aliasKey: 'supplySourceType',
        headerRenderer: headerCellRenderer,
        cellClass: CLASS_NAMES.SUPPLY_TYPE_CELL,
        formatter: ({row}) => {
            if (row.SupplySource.type === SUPPLY_SOURCE_TYPE.APP) {
                return get(supplySourceStoreNames, row.SupplySource.store, 'N/A');
            }
            return CONSTANT_TEXTS.WEBSITE;
        },
        filterComponent: ColumnCheckboxFilter,
        isSortable: false
    },
    {
        name: CONSTANT_TEXTS.REQUESTS,
        key: "last30DaysRequests",
        cellClass: CLASS_NAMES.SUPPLY_REQUESTS_CELL,
        headerRenderer: headerCellRenderer,
        formatter: ({value}) => thousandsToKFormatter(value),
        isSortable: true
    },
    {
        name: CONSTANT_TEXTS.IMPRESSIONS,
        key: "last30DaysImpressions",
        cellClass: CLASS_NAMES.SUPPLY_IMPRESSIONS_CELL,
        headerRenderer: headerCellRenderer,
        formatter: ({value}) => thousandsToKFormatter(value),
        isSortable: true
    },
    {
        name: CONSTANT_TEXTS.FILL_RATE,
        key: "fillRate",
        cellClass: CLASS_NAMES.SUPPLY_FILL_RATE_CELL,
        headerRenderer: headerCellRenderer,
        formatter: ({row}) => toPercent(row.fillRate),
        isSortable: false
    }
];

export const settings = {
    name: '',
    key: "supply-actions",
    cellClass: CLASS_NAMES.SUPPLY_ACTIONS_CELL,
    formatter: SupplyActionsFormatter,
    featureName: 'SupplySourceSettings'
}
