import {of, Subject} from 'rxjs';
import ajax from '../utils/ajaxProxy';
import {mergeMap, map, catchError, concatMap, merge, switchMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import Cookies from 'js-cookie';
import _ from "lodash";

import {
    getPlacementGroupsSuccess, getPlacementGroupsFailed,
    createPlacementGroupSuccess, createPlacementGroupFailed,
    deletePlacementGroupsSuccess, deletePlacementGroupsFailed,
    setPlacementGroupStatusSuccess, setPlacementGroupStatusFailed,
    updatePlacementGroupSuccess, updatePlacementGroupFailed,
    importFileSuccess, importFileFailed,
    uploadFileSuccess, uploadFileFailed, uploadFileProgress,
    downloadFileSuccess, downloadFileFailed,
    deleteFileSuccess, deleteFileFailed,
    getPlacementFileSuccess, getPlacementFileFailed, markAsDownloaded
} from '../actions/placementsActions';
import {getAdUnitsSuccess, getAdUnitsFailed} from '../actions/adUnitsActions';
import {placementsConstants} from '../constants/placementsConstants';
import {adUnitsConstants} from '../constants/adUnitsConstants';

const getFilterValue = (value, type, filterOptions) => {
    if(type === 'options') {
        const allAliasValues = _.map(filterOptions, 'alias');
        //get the checked items
        return _.reject(allAliasValues, opt => _.includes(value, opt));
    }
    return value;
}

const transformOrder = (sort) => {
    const {field, direction} = _.first(sort) || {};
    if(_.isEmpty(sort) || direction === 'none') {
        return '';
    }
    return `&order=${field},${direction}`
}

const transformFilters = (filters) => {
    let query = '';
    _.forEach(filters, (filter) => {
        const {field, type, filterOptions} = filter;
        let fieldToBeUsed = field;
        const filterValue = getFilterValue(filter.value, type, filterOptions);
        const queryValue = _.isArray(filterValue) ? _.join(filterValue, ',') : filterValue;
        if(field === 'Tag Type') fieldToBeUsed = 'tag_type';
        if(field === 'Name') fieldToBeUsed = 'name_filter';
        if(field === 'status') fieldToBeUsed = 'status_filter';
        if(field === 'ID') fieldToBeUsed = 'display_id_filter';
        query = `${query}&${_.toLower(fieldToBeUsed)}=${queryValue}`;
    });
    return query;
}

export const requestPlacementGroupsEpic = (actions, state) => (
    actions.pipe(
        ofType(placementsConstants.PLACEMENT_GROUPS_REQUEST, placementsConstants.SEARCH_PLACEMENT_GROUPS),
        switchMap(action =>{
                const {publisherId, limit, page, searchTerm} = action.payload;
                const filters = _.get(state.value, 'grid.customization.filters');
                const sort = _.get(state.value, 'grid.customization.sort')
                const queryFilters = transformFilters(filters);
                const orderBy = transformOrder(sort)
                const query = `&page=${(page)}&limit=${limit}&searchTerm=${searchTerm ? searchTerm : ''}${queryFilters}${orderBy}`;
                return ajax.getJSON(`api/placement-groups/${publisherId}${limit && page ? `?${query}` : ''}`).pipe(
                    map(data => getPlacementGroupsSuccess(data)),
                    catchError(error => of(getPlacementGroupsFailed(error)))
                )
            }
        )
    )
);

export const requestPlacementFileEpic = actions => (
    actions.pipe(
        ofType(placementsConstants.PLACEMENT_FILE_REQUEST),
        mergeMap(action =>
            ajax.getJSON(`api/placement-groups/${action.payload.publisherId}/file`).pipe(
                map(data => getPlacementFileSuccess(data)),
                catchError(error => getPlacementFileFailed(error))
            )
        )
    )
);

export const uploadFileEpic = actions => (
    actions.pipe(
        ofType(placementsConstants.UPLOAD_FILE_REQUEST),
        mergeMap(({payload}) => {
            const {file, publisherId} = payload;
            const url = `api/placement-groups/${publisherId}/file`;
            const fd = new FormData();
            fd.append('File', file);

            const progressSubscriber = new Subject();
            const request = ajax({
                method: 'POST',
                url,
                body: fd,
                headers: {'Accept': 'multipart/form-data'},
                progressSubscriber
            });

            const requestObservable = request
                .pipe(
                    concatMap(() => {
                        return [
                            uploadFileProgress(),
                            uploadFileSuccess(file.name)
                        ]
                    }),
                    catchError(error => of(uploadFileFailed(error)))
                )

            return progressSubscriber
                .pipe(
                    map(e => ({percentage: Math.round((e.loaded / e.total) * 100)})),
                    map(data => (uploadFileProgress(data.percentage))),
                    merge(requestObservable),
                    catchError(error => uploadFileFailed(error))
                )

        }),
        catchError(error => of(uploadFileFailed(error)))
    )
);

export const importFileEpic = actions => (
    actions.pipe(
        ofType(placementsConstants.IMPORT_FILE_REQUEST),
        mergeMap(({payload}) => {
            const {file} = payload;
            const url = `api/placement-groups/parse-adslots`;
            const fd = new FormData();
            fd.append('File', file);

            return ajax({
                method: 'POST',
                url,
                body: fd,
                headers: {
                    'Accept': 'multipart/form-data',
                    'x-xsrf-token': Cookies.get('x-xsrf-token')
                }
            }).pipe(
                map((data) => importFileSuccess(data.response)),
                catchError(error => of(importFileFailed(error.response.data)))
            )
        }),
        catchError(error => of(importFileFailed(error)))
    )
);

export const downloadEpic = actions => (
    actions.pipe(
        ofType(placementsConstants.DOWNLOAD_FILE_REQUEST),
        mergeMap(action =>
            ajax.getJSON(`api/placement-groups/${action.payload.publisherId}/file?content=true`).pipe(
                concatMap(data => {
                    return [
                        downloadFileSuccess(data),
                        markAsDownloaded(data.name)
                    ]
                }),
                catchError(error => of(downloadFileFailed(error)))
            )
        ),
        catchError(error => of(downloadFileFailed(error)))
    )
);

export const deleteFileEpic = actions => (
    actions.pipe(
        ofType(placementsConstants.DELETE_FILE_REQUEST),
        mergeMap(action => {
            const {publisherId} = action.payload;
            return ajax.delete(`api/placement-groups/${publisherId}/file`).pipe(
                map(data => deleteFileSuccess(data.response)),
                catchError(error => of(deleteFileFailed(error)))
            )
        })
    )
);

export const createPlacementGroupEpic = (actions, state) => (
    actions.pipe(
        ofType(placementsConstants.CREATE_PLACEMENT_GROUP_REQUEST),
        mergeMap(action => {
            const {publisherId, placementGroup, limit, page, searchTerm} = action.payload;
                const filters = _.get(state.value, 'grid.customization.filters');
                const sort = _.get(state.value, 'grid.customization.sort')
                const queryFilters = transformFilters(filters);
                const orderBy = transformOrder(sort)
                const query = `&page=${(page)}&limit=${limit}&searchTerm=${searchTerm ? searchTerm : ''}${queryFilters}${orderBy}`;
            return ajax.post(`api/placement-groups/${publisherId}${limit && page ? `?${query}` : ''}`, placementGroup, {'Content-Type': 'application/json'}).pipe(
                map(data => createPlacementGroupSuccess(data.response)),
                catchError(error => of(createPlacementGroupFailed(error)))
            )
        })
    )
);
export const updatePlacementGroupEpic = (actions, state) => (
    actions.pipe(
        ofType(placementsConstants.UPDATE_PLACEMENT_GROUP_REQUEST),
        mergeMap(action => {

            const {publisherId, limit, page, searchTerm, placementGroupId, placementGroup} = action.payload;
                const filters = _.get(state.value, 'grid.customization.filters');
                const sort = _.get(state.value, 'grid.customization.sort')
                const queryFilters = transformFilters(filters);
                const orderBy = transformOrder(sort)
                const query = `&page=${(page)}&limit=${limit}&searchTerm=${searchTerm ? searchTerm : ''}${queryFilters}${orderBy}`;
            return ajax.put(`api/placement-groups/${publisherId}/${placementGroupId}${limit && page ? `?${query}` : ''}`, placementGroup, {'Content-Type': 'application/json'}).pipe(
                map(data => {
                    return updatePlacementGroupSuccess(data.response)
                }),
                catchError(error => of(updatePlacementGroupFailed(error)))
            )
        })
    )
);
export const deletePlacementGroupsEpic = (actions, state) => (
    actions.pipe(
        ofType(placementsConstants.DELETE_PLACEMENT_GROUPS_REQUEST),
        mergeMap(action => {
            const {publisherId, placementGroupsIds, limit, page, searchTerm} = action.payload;
            const pgIds = placementGroupsIds.join(",");
            const filters = _.get(state.value, 'grid.customization.filters');
            const sort = _.get(state.value, 'grid.customization.sort')
            const queryFilters = transformFilters(filters);
            const orderBy = transformOrder(sort)
            const query = `&page=${(page)}&limit=${limit}&searchTerm=${searchTerm ? searchTerm : ''}${queryFilters}${orderBy}`;
            return ajax.delete(`api/placement-groups/${publisherId}/${pgIds}${limit && page ? `?${query}` : ''}`, {'Content-Type': 'application/json'}).pipe(
                map(data => deletePlacementGroupsSuccess(data.response)),
                catchError(error => of(deletePlacementGroupsFailed(error)))
            )
        })
    )
);

export const requestAdUnitsEpic = actions => (
    actions.pipe(
        ofType(adUnitsConstants.GET_AD_UNITS_REQUEST),
        mergeMap(action =>
            ajax.getJSON(`api/adunits/${action.payload.publisherId}`).pipe(
                map(data => getAdUnitsSuccess(data)),
                catchError(error => of(getAdUnitsFailed(error)))
            )
        )
    )
);

export const setPlacementGroupStatusEpic = (actions, state) => (
    actions.pipe(
        ofType(placementsConstants.SET_PLACEMENT_GROUP_STATUS_REQUEST),
        mergeMap(action => {

            const {publisherId, limit, page, searchTerm, placementGroupId, status} = action.payload;
            const filters = _.get(state.value, 'grid.customization.filters');
            const sort = _.get(state.value, 'grid.customization.sort')
            const queryFilters = transformFilters(filters);
            const orderBy = transformOrder(sort)
            const query = `&page=${(page)}&limit=${limit}&searchTerm=${searchTerm ? searchTerm : ''}${queryFilters}${orderBy}`;
            return ajax.patch(`api/placement-groups/${publisherId}/${placementGroupId}/status/${status}${limit && page ? `?${query}` : ''}`, {'Content-Type': 'application/json'}).pipe(
                map(data => setPlacementGroupStatusSuccess(data.response)),
                catchError(error => of(setPlacementGroupStatusFailed(error)))
            )
        })
    )
);