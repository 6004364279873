import React, {useState} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Icon from './Icon';
import cx from 'classnames';
import textProvider from '../texts/textProvider';
import {Popover, Tooltip}  from '@perion-undertone/ut-react-common';

const CLASS_NAMES = {
    CTC_WRAPPER: 'copy-to-clipboard-wrapper',
    CTC_POPOVER: 'copy-to-clipboard-popover',
    CTC_POPOVER_MENU: 'copy-to-clipboard-popover-menu',
    CTC_POPOVER_MENU_ITEM: 'copy-to-clipboard-popover-menu-item',
    SQUARED_ICON_WRAPPER: 'squared-icon-wrapper',
    ID_ICON_WRAPPER: 'id-icon-wrapper',
    COPY_ICON: 'copy-icon',
    COPY_TXT: 'copy-txt'
};

const CONSTANT_TEXTS = {
    COPIED_MESSAGE: textProvider.getText('tooltip', 'copied')
};

const CopyToClipboard = (props) => {
    const {copy, noWrap, copyMessage, placement} = props;
    const [copyTarget, setCopyTarget] = useState('');

    const copyMessageTrigger = (<>
        <Icon icon='copy.svg' hookId={props.hookId} />
        <span className={CLASS_NAMES.COPY_TXT}>{copy}</span>
    </>);

    const trigger = (<div>
        {copyMessage ?
            <Tooltip appendToBody
                     placement="top"
                     trigger={<div>{copyMessageTrigger}</div>}
            >
                {copyMessage}
            </Tooltip> :
            copyMessageTrigger
        }
        </div>);

    const copyToClipboard = (e, copyValue, targetName) => {
        navigator.clipboard.writeText(copyValue);
        setCopyTarget(targetName);
    };

    const getCopiedMessage = () => {
        return props.copiedMessage || CONSTANT_TEXTS.COPIED_MESSAGE;
    }

    return (
        <div className={CLASS_NAMES.CTC_WRAPPER}>
            {document.queryCommandSupported('copy') &&
                <div>
                    <div className={cx({[CLASS_NAMES.SQUARED_ICON_WRAPPER]: !noWrap}, CLASS_NAMES.ID_ICON_WRAPPER, CLASS_NAMES.COPY_ICON)}>
                        <Popover trigger={trigger}
                                 className={cx({[CLASS_NAMES.CTC_POPOVER]: !!props.copyValue})}
                                 onClick={props.copyValue ? (e) => copyToClipboard(e, props.copyValue, props.copyTarget) : _.noop()}
                                 placement={placement}
                                 appendToBody
                                 basic={true}
                                 disabled={false}
                                 hookId="copy-to-clipboard-popover"
                        >
                            {props.copyValue && <div>
                                {copyTarget === props.copyTarget ? getCopiedMessage() : ''}
                            </div>}
                        </Popover>
                    </div>
                </div>}
        </div>
    );
};

CopyToClipboard.propTypes = {
    copy: PropTypes.string,
    copyValue: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string
    ]),
    copyTarget: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    noWrap: PropTypes.bool,
    hookId: PropTypes.string,
    copiedMessage: PropTypes.string,
    copyMessage: PropTypes.string,
    placement: PropTypes.string
};

CopyToClipboard.defaultProps = {
    noWrap: false,
    placement: 'bottom'
}

export default CopyToClipboard;