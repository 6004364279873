import React, {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Route, withRouter} from 'react-router-dom';
import {ReconfigureFlopFlip} from '@flopflip/react-redux';
import {get} from 'lodash';
import {useAuth0} from '@auth0/auth0-react';
import _ from 'lodash';
import {appStates, Login} from './imports';
import {loginUserSuccess} from '../actions/userActions';
import {UNDERTONE_NAMESPACE, USER_TYPES} from '../auth';

const RFF = ({user}) => {
    return <ReconfigureFlopFlip user={user} />;
};

RFF.propTypes = {
    user: PropTypes.string
}

const MemoRFF = React.memo(RFF);

const PrivateRoute = (props) => {
    const {component: Component, user, dispatch, ...rest} = props;
    const {isAuthenticated, isLoading, user: auth0User, loginWithRedirect, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        (async () => {
            let accessToken;
            try{
                accessToken = await getAccessTokenSilently({cacheMode: 'on'});
            }catch(err) {
                // await loginWithRedirect({
                //     appState: {
                //         returnTo: `${window.location.origin}/#${props.location.pathname}`
                //     }
                // });
                Promise.resolve();
            }
             if (isAuthenticated && !isLoading && auth0User) {
                accessToken = await getAccessTokenSilently();
                const tokens = {'x-access-token': accessToken}
                const logedUser = _.assign({}, auth0User,{
                    id: _.get(auth0User, 'user_metadata.wf_id'),
                    roles: _.get(auth0User, `${UNDERTONE_NAMESPACE}/roles`),
                    userType: USER_TYPES.WORKFLOW
                });
                dispatch(loginUserSuccess({user: logedUser, tokens}));
            }
        })()
    }, [isAuthenticated, isLoading, auth0User, loginWithRedirect, getAccessTokenSilently, dispatch]);

    return (
        <Route {...rest}
            render={props => {
                return !isLoading ? (
                    <Fragment>
                        <MemoRFF user={get(user, 'data.email', 'longtail-publisher')} />
                        {user.state === appStates.LOADED ?
                            <Component {...props} /> :
                            <Login />
                        }
                    </Fragment>
                ) : <div></div>
            }}
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.func,
    user: PropTypes.object
};

const mapStateToProps = ({app}) => ({
    user: app.user
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));
