import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from '@perion-undertone/ut-react-common';

const CLASS_NAMES = {
    PREDEFINED_REPORT_TOASTER: 'predefined-report-toaster',
    PREDEFINED_REPORT_TOASTER_ICON: 'predefined-report-toaster-icon'
}

const Toaster = (props) => {
    const {notificationIcon, notificationHookId, notificationMessage} = props;

    return (<div className={CLASS_NAMES.PREDEFINED_REPORT_TOASTER}>
        <div className={CLASS_NAMES.PREDEFINED_REPORT_TOASTER_ICON}>
            <Icon icon={notificationIcon} hookId={notificationHookId}/>
        </div>
        {notificationMessage}
    </div>);
}

Toaster.propTypes = {
    notificationIcon: PropTypes.string,
    notificationHookId: PropTypes.string,
    notificationMessage: PropTypes.string
};

export default Toaster;