import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';
import format from 'string-template';

import {Image, textProvider, changePage} from './imports';

const CLASS_NAMES = {
    PAGINATION_CONTAINER: 'pagination-container',
    PAGE_INDICATOR: 'page-indicator',
    PAGGING_ARROW_WRAPPER: 'pagging-arrow-wrapper',
    PAGGING_ARROW_WRAPPER_DISABLED: 'pagging-arrow-wrapper-disabled'
};

const CONSTANT_TEXTS = {
    PAGE_INDICATOR: textProvider.getText('webPreview', 'pageIndicator')
};

const Pagination = (props) => {
    const {currentPage, lastPage} = props;

    const goToPrevPage = () => {
        if (currentPage === 1) return;
        return props.dispatch(changePage(currentPage - 1));
    };

    const goToNextPage = () => {
        if (currentPage === lastPage) return;
        return props.dispatch(changePage(currentPage + 1));
    };

    return (
        <div className={CLASS_NAMES.PAGINATION_CONTAINER}>
            <div className={cx(CLASS_NAMES.PAGGING_ARROW_WRAPPER, {
                    [CLASS_NAMES.PAGGING_ARROW_WRAPPER_DISABLED]: currentPage === 1
                 })}
                 onClick={goToPrevPage}
            >
                <Image name='page-prev.svg' hookId='pagination-arrow-back-icon' />
            </div>
            <div className={CLASS_NAMES.PAGE_INDICATOR} >
                {format(CONSTANT_TEXTS.PAGE_INDICATOR, currentPage, lastPage)}
            </div>
            <div className={cx(CLASS_NAMES.PAGGING_ARROW_WRAPPER, {
                    [CLASS_NAMES.PAGGING_ARROW_WRAPPER_DISABLED]: currentPage === lastPage
                 })}
                 onClick={goToNextPage}
            >
                <Image name='page-next.svg' hookId='pagination-arrow-next-icon' />
            </div>
        </div>
    );
}

Pagination.propTypes = {
    currentPage: PropTypes.number,
    lastPage: PropTypes.number,
    changePage: PropTypes.func
}

const mapStateToProps = ({reportPreview}) => ({
    currentPage: reportPreview.currentPage,
    lastPage: reportPreview.lastPage
});

export default connect(mapStateToProps)(Pagination);
