import React from 'react';
import app_logo from '../../assets/log-app-new.png';

const CLASS_NAMES = {
    CONTAINER: 'app-logo',
    IMG: 'app-logo-img'
};

const AppLogo = () => {
    return (
        <div className={CLASS_NAMES.CONTAINER}>
            <img src={app_logo} className={CLASS_NAMES.IMG} data-automation='navigation-udrive-logo' />
        </div>
    );
};

export default AppLogo;
