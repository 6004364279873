export const placementsConstants = {
    PLACEMENT_GROUPS_REQUEST: 'PLACEMENT_GROUPS_REQUEST',
    PLACEMENT_GROUPS_SUCCESS: 'PLACEMENT_GROUPS_SUCCESS',
    PLACEMENT_GROUPS_FAILURE: 'PLACEMENT_GROUPS_FAILURE',

    CREATE_PLACEMENT_GROUP_REQUEST: 'CREATE_PLACEMENT_GROUP_REQUEST',
    CREATE_PLACEMENT_GROUP_SUCCESS: 'CREATE_PLACEMENT_GROUP_SUCCESS',
    CREATE_PLACEMENT_GROUP_FAILURE: 'CREATE_PLACEMENT_GROUP_FAILURE',

    UPDATE_PLACEMENT_GROUP_REQUEST: 'UPDATE_PLACEMENT_GROUP_REQUEST',
    UPDATE_PLACEMENT_GROUP_SUCCESS: 'UPDATE_PLACEMENT_GROUP_SUCCESS',
    UPDATE_PLACEMENT_GROUP_FAILURE: 'UPDATE_PLACEMENT_GROUP_FAILURE',

    DELETE_PLACEMENT_GROUPS_REQUEST: 'DELETE_PLACEMENT_GROUPS_REQUEST',
    DELETE_PLACEMENT_GROUPS_SUCCESS: 'DELETE_PLACEMENT_GROUPS_SUCCESS',
    DELETE_PLACEMENT_GROUPS_FAILURE: 'DELETE_PLACEMENT_GROUPS_FAILURE',

    SET_PLACEMENT_GROUP_STATUS_REQUEST: 'SET_PLACEMENT_GROUP_STATUS_REQUEST',
    SET_PLACEMENT_GROUP_STATUS_SUCCESS: 'SET_PLACEMENT_GROUP_STATUS_SUCCESS',
    SET_PLACEMENT_GROUP_STATUS_FAILURE: 'SET_PLACEMENT_GROUP_STATUS_FAILURE',

    OPEN_PLACEMENT_GROUP_MODAL: 'OPEN_PLACEMENT_GROUP_MODAL',
    CLOSE_PLACEMENT_GROUP_MODAL: 'CLOSE_PLACEMENT_GROUP_MODAL',
    TOGGLE_SELECT_AD_UNIT: 'TOGGLE_SELECT_AD_UNIT',
    TOGGLE_SELECT_ALL_AD_UNITS: 'TOGGLE_SELECT_ALL_AD_UNITS',
    CLEAR_SELECTED_AD_UNITS: 'CLEAR_SELECTED_AD_UNITS',
    SEARCH_PLACEMENT_GROUPS: 'SEARCH_PLACEMENT_GROUPS',
    PREVIEW_AD_UNIT_INFO: 'PREVIEW_AD_UNIT_INFO',

    OPEN_CONFIRMATION_MODAL: 'OPEN_CONFIRMATION_MODAL',
    CLOSE_CONFIRMATION_MODAL: 'CLOSE_CONFIRMATION_MODAL',

    SET_PLACEMENT_GROUP_TITLE: 'SET_PLACEMENT_GROUP_TITLE',

    UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
    UPLOAD_FILE_PROGRESS: 'UPLOAD_FILE_PROGRESS',
    UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
    UPLOAD_FILE_FAILURE: 'UPLOAD_FILE_FAILURE',

    IMPORT_FILE_REQUEST: 'IMPORT_FILE_REQUEST',
    IMPORT_FILE_PROGRESS: 'IMPORT_FILE_PROGRESS',
    IMPORT_FILE_SUCCESS: 'IMPORT_FILE_SUCCESS',
    IMPORT_FILE_FAILURE: 'IMPORT_FILE_FAILURE',

    DOWNLOAD_FILE_REQUEST: 'DOWNLOAD_FILE_REQUEST',
    DOWNLOAD_FILE_SUCCESS: 'DOWNLOAD_FILE_SUCCESS',
    DOWNLOAD_FILE_FAILURE: 'DOWNLOAD_FILE_FAILURE',
    MARK_AS_DOWNLOADED: 'MARK_AS_DOWNLOADED',

    DELETE_FILE_REQUEST: 'DELETE_FILE_REQUEST',
    DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
    DELETE_FILE_FAILURE: 'DELETE_FILE_FAILURE',

    PLACEMENT_FILE_REQUEST: 'PLACEMENT_FILE_REQUEST',
    PLACEMENT_FILE_SUCCESS: 'PLACEMENT_FILE_SUCCESS',
    PLACEMENT_FILE_FAILURE: 'PLACEMENT_FILE_FAILURE',

    REMOVE_UPLOAD_ERROR_MESSAGE: 'REMOVE_UPLOAD_ERROR_MESSAGE',

    CREATE_NEW_PLACEMENT_GROUP_AD_SLOTS: 'CREATE_NEW_PLACEMENT_GROUP_AD_SLOTS',
    SAVE_PLACEMENT_GROUP_AD_SLOTS: 'SAVE_PLACEMENT_GROUP_AD_SLOTS',
    DELETE_PLACEMENT_GROUP_AD_SLOTS: 'DELETE_PLACEMENT_GROUP_AD_SLOTS',
    BULK_DELETE_PLACEMENT_GROUP_AD_SLOTS: 'BULK_DELETE_PLACEMENT_GROUP_AD_SLOTS',
    TOGGLE_AD_SLOTS_EDIT_MODE: 'TOGGLE_AD_SLOTS_EDIT_MODE',
    AD_SLOTS_CHANGES: 'AD_SLOTS_CHANGES',
    SEARCH_AD_SLOTS: 'SEARCH_AD_SLOTS',

    SET_ONE_OFF_PUBLISHER: 'SET_ONE_OFF_PUBLISHER',
    SET_PASSBACK_TAG: 'SET_PASSBACK_TAG',
    SET_IS_THROTTELING: 'SET_IS_THROTTELING',
    SET_USE_SYSTEM_APPEND_PIXEL: 'SET_USE_SYSTEM_APPEND_PIXEL',
    SET_IS_AUCTION_PRICE_MACRO: 'SET_IS_AUCTION_PRICE_MACRO',
    SET_AUCTION_PRICE_MACRO: 'SET_AUCTION_PRICE_MACRO',
    SET_IS_PREFER_VIDAZOO_PLAYER: 'SET_IS_PREFER_VIDAZOO_PLAYER',
    SET_WIN_TYPE_EVENT: 'SET_WIN_TYPE_EVENT',
    SET_AUCTION_PRICE_MACRO_TYPE: 'SET_AUCTION_PRICE_MACRO_TYPE',


    SET_THROTTELING: 'SET_THROTTELING',

    SET_SELECTED_PLACEMENT_GROUPS_IDS: 'SET_SELECTED_PLACEMENT_GROUPS_IDS'
}
