import React from 'react';
import Icon from './Icon';

const UndertoneLogo = () => {
    return (
        <div className='undertone-logo'>
            <Icon icon='udrive_new_logo.svg' />
        </div>
    );
};

export default UndertoneLogo;