import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Input} from '@perion-undertone/ut-react-common';
import Icon from './Icon';

const CLASS_NAMES = {
    SEARCH_GROUPS: 'search-groups',
    SEARCH_ICON: 'search-icon'
}

class SearchBar extends Component {
    render() {
        const {icon, placeholder, result, value} = this.props;
        return (<div className={CLASS_NAMES.SEARCH_ICON}>
                    <Icon icon={icon} hookId="search-bar-icon"/>
                    <Input type='search'
                           placeholder={placeholder}
                           className={CLASS_NAMES.SEARCH_GROUPS}
                           onChange={result}
                           hookId="search-bar-input"
                           value={value}
                    />
        </div>);
    }

}

SearchBar.propTypes = {
    result: PropTypes.func,
    icon: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string
};

export default SearchBar;
