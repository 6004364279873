import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Tooltip, Icon} from '@perion-undertone/ut-react-common';

import {runningAtInfo} from './scheduleReport/scheduleReportHelper';

const CLASS_NAMES = {
    ANALYTICS_SIDEBAR_MENU: 'analytics-sidebar-menu',
    ANALYTICS_SIDEBAR_MENU_TITLE: 'analytics-sidebar-menu-title',
    ANALYTICS_SIDEBAR_MENU_TITLE_NEW: 'analytics-sidebar-menu-title--new',
    ANALYTICS_SIDEBAR_MENU_ITEM: 'analytics-sidebar-menu-item',
    ANALYTICS_SIDEBAR_MENU_ITEM_ACTIVE: 'analytics-sidebar-menu-item--active'
};

const ReportsMenu = (props) => {
    const {title, reportsList, activeItemName, handleItemChange, isReportsSchedulingEnabled, isNewReportsList} = props;

    return (
        <Fragment>
            <div className={cx(CLASS_NAMES.ANALYTICS_SIDEBAR_MENU_TITLE, {
                [CLASS_NAMES.ANALYTICS_SIDEBAR_MENU_TITLE_NEW]: isNewReportsList})}
            >
                {title}
            </div>
            <ul className={CLASS_NAMES.ANALYTICS_SIDEBAR_MENU}>
                {reportsList.map((item, index) => {
                    const {name, hasRecurrence, recurrenceInterval} = item;
                    const tooltipTrigger = (
                        <div>
                            <Icon icon='icon-recurrence' hookId='report-recurrence-icon'/>
                        </div>
                    );

                    return (
                        <li key={index}
                            onClick={() => handleItemChange(item)}
                            className={cx(CLASS_NAMES.ANALYTICS_SIDEBAR_MENU_ITEM,
                                {[CLASS_NAMES.ANALYTICS_SIDEBAR_MENU_ITEM_ACTIVE]: name === activeItemName}
                            )}
                        >
                            {name}
                            {isReportsSchedulingEnabled && hasRecurrence &&
                                <Tooltip trigger={tooltipTrigger}
                                         placement='bottom'
                                         appendToBody={true}
                                         hookId="schedule-info-tooltip"
                                >
                                    {runningAtInfo(recurrenceInterval)}
                                </Tooltip>
                            }
                        </li>
                    );
                })}
            </ul>
        </Fragment>
    );
};

ReportsMenu.propTypes = {
    reportsList: PropTypes.array,
    title: PropTypes.string,
    activeItemName: PropTypes.string,
    handleItemChange: PropTypes.func,
    isNewReportsList: PropTypes.bool,
    isReportsSchedulingEnabled: PropTypes.bool
};

export default ReportsMenu;