import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cx from 'classnames';
import {Input, Select} from '@perion-undertone/ut-react-common';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import AdunitsCounter from './AdunitsCounter';
import {selectFeatureFlag} from '@flopflip/react-redux';
import {
    Image,
    textProvider,
    setPlacementGroupTitle
} from '../imports';
import {PUBLISHER_TYPES_MAP} from '../../../../utils/globals';

const CLASS_NAMES = {
    MODAL_HEADER: 'placement-group-modal-header-wrapper',
    MODAL_HEADER_TITLE_WRAPPER: 'placement-group-modal-header-title-wrapper',
    MODAL_HEADER_TITLE: 'placement-group-modal-header-title',
    PLACEMENT_TABS_WRAPPER: 'placement-tabs-wrapper',
    PLACEMENT_TAB: 'placement-tab',
    PLACEMENT_TAB_ACTIVE: 'placement-tab-active',
    GROUP_NAME_LABEL: 'placement-group-name-label',
    GROUP_NAME_INPUT: 'placement-group-name-input',
    GROUP_NAME_WRAPPER: 'placement-group-name-wrapper',
    PLACEMENT_TYPE_SELECT_WRAPPER: 'placement-type-select-wrapper',
    PLACEMENT_TYPE_SELECT: 'placement-type-select',
    INVALID_GROUP_INPUT: 'placement-group-invalid-input',
    DIVIDER: 'placement-group-modal-header-divider'
};

const CONSTANT_TEXTS = {
    UPDATE_PLACEMENT_GROUP: textProvider.getText('adUnits', 'updatePlacementGroup'),
    CREATE_NEW_PLACEMENT_GROUP: textProvider.getText('adUnits', 'createNewPlacementGroup'),
    GROUP_NAME: textProvider.getText('adUnits', 'groupName'),
    TYPE_GROUP_NAME: textProvider.getText('adUnits', 'typeGroupName'),
    ADD: textProvider.getText('adSlots', 'add'),
    TAG_TYPE: textProvider.getText('placementGroupGrid', 'tagType'),
    ADD_ADUNITS: textProvider.getText('adUnits', 'addAdunits'),
    ADVANCED_SETTINGS: textProvider.getText('advancedSettings', 'advancedSettings'),
    SSP_CONFIG: textProvider.getText('sspConfigurations', 'ssptabtitle')
}

const PlacementGroupHeader = (props) => {
    const placementTagOpts = props.placementTagOptions;
    const {currentGroup, isInvalidGroupName, isInvalidGroupAdUnits, publisher, isPlacementGroupThrottelingOn} = props;
    const publisherTypeStr = PUBLISHER_TYPES_MAP[get(publisher, 'data.publisherTypeId', '')];
    const isSSpPublisher = toLower(publisherTypeStr) === 'ssp';
    const {adUnits, name} = currentGroup;
    const selectedAdUnits = adUnits.map(adUnit => adUnit.id);

    const setTitle = ({target: {value}}) => {
        props.setPlacementGroupTitle(value);
    };

    return (
        <div className={CLASS_NAMES.MODAL_HEADER}>
            <div className={CLASS_NAMES.MODAL_HEADER_TITLE_WRAPPER}>
                <div>
                    <Image name='create_icon.svg'
                           hookId='placement-group-title-img'
                           className='placement-group-title-img'
                    />
                    <span className={CLASS_NAMES.MODAL_HEADER_TITLE}>
                        {currentGroup && currentGroup.placementGroupId
                        ? CONSTANT_TEXTS.UPDATE_PLACEMENT_GROUP
                        : CONSTANT_TEXTS.CREATE_NEW_PLACEMENT_GROUP}
                    </span>
                </div>
                <div className={CLASS_NAMES.PLACEMENT_TABS_WRAPPER}>
                    <div className={cx(CLASS_NAMES.PLACEMENT_TAB,
                         {[CLASS_NAMES.PLACEMENT_TAB_ACTIVE]: props.activePlacementTab == 'adUnitsTab'})}
                         onClick={() => props.onPlacementTabChange('adUnitsTab')}>{CONSTANT_TEXTS.ADD_ADUNITS}</div>
                    <div className={cx(CLASS_NAMES.PLACEMENT_TAB,
                         {[CLASS_NAMES.PLACEMENT_TAB_ACTIVE]: props.activePlacementTab == 'adSlotsTab'})}
                         onClick={() => props.onPlacementTabChange('adSlotsTab')}>{CONSTANT_TEXTS.ADVANCED_SETTINGS}</div>
                    {isSSpPublisher && isPlacementGroupThrottelingOn && <div className={cx(CLASS_NAMES.PLACEMENT_TAB,
                         {[CLASS_NAMES.PLACEMENT_TAB_ACTIVE]: props.activePlacementTab == 'sspconfigTab'})}
                         onClick={() => props.onPlacementTabChange('sspconfigTab')}>{CONSTANT_TEXTS.SSP_CONFIG}</div>}
                </div>
                {props.activePlacementTab === 'adUnitsTab' ?
                    <Fragment>
                        <div className="adunits-header-tab-wrapper">
                            <div className={CLASS_NAMES.PLACEMENT_TYPE_SELECT_WRAPPER}>
                                <label className={CLASS_NAMES.PLACEMENT_TYPE_SELECT_WRAPPER}>{CONSTANT_TEXTS.TAG_TYPE}</label>
                                <div className={CLASS_NAMES.PLACEMENT_TYPE_SELECT}>
                                    <Select options={placementTagOpts}
                                            onChange={props.onPlacementTagChange}
                                            value={props.selectedPlacementTag}
                                            type='select'
                                            hookId='placement-types-select'
                                    />
                                </div>
                            </div>
                            <div className={CLASS_NAMES.GROUP_NAME_WRAPPER}>
                                <p className={cx(CLASS_NAMES.GROUP_NAME_LABEL,
                                    {[CLASS_NAMES.INVALID_GROUP_INPUT]: isInvalidGroupName})}
                                >
                                    {CONSTANT_TEXTS.GROUP_NAME}
                                </p>
                                <Input type='search'
                                       iconPosition='right'
                                       className={cx(
                                           CLASS_NAMES.GROUP_NAME_INPUT,
                                           {[CLASS_NAMES.INVALID_GROUP_INPUT]: isInvalidGroupName}
                                       )}
                                       placeholder={CONSTANT_TEXTS.TYPE_GROUP_NAME}
                                       // name is currentGroup.name
                                       value={currentGroup && name ? name : ''}
                                       onChange={setTitle}
                                       hookId='placement-group-name-input'
                                />
                            </div>
                        </div>
                        <AdunitsCounter counter={selectedAdUnits.length}
                                        className={cx({[CLASS_NAMES.INVALID_GROUP_INPUT]: isInvalidGroupAdUnits})}
                        />
                    </Fragment> :
                    null
                }
            </div>
        </div>
    );
};

PlacementGroupHeader.propTypes = {
    currentGroup: PropTypes.object,
    isInvalidGroupName: PropTypes.bool,
    isInvalidGroupAdUnits: PropTypes.bool,
    setPlacementGroupTitle: PropTypes.func,
    onPlacementTagChange: PropTypes.func,
    onPlacementTabChange: PropTypes.func,
    activePlacementTab: PropTypes.string,
    placementTagOptions: PropTypes.array,
    selectedPlacementTag: PropTypes.object,
    publisher: PropTypes.object,
    isPlacementGroupThrottelingOn: PropTypes.bool
};

const mapStateToProps = (state) => ({
    publisher: state.app.publisher,
    isPlacementGroupThrottelingOn: selectFeatureFlag('isPlacementGroupThrottelingOn')(state)
});
const mapDispatchToProps = ({
    setPlacementGroupTitle
});

export default connect(mapStateToProps, mapDispatchToProps)(PlacementGroupHeader);
