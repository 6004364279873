import React from 'react';
import ContentLoader from 'react-content-loader';

const CLASS_NAMES = {
  PLACEHOLDER_STRIPE_WRAPPER: 'placeholder-stripe-wrapper',
  PLACEHOLDER_STRIPE_CONTAINER: 'placeholder-stripe-container',
  PLACEHOLDER_LINEAR_CONTAINER: 'placeholder-linear-container',
  PLACEHOLDER_CIRCLE_CONTAINER: 'placeholder-circle-container'
};

export const StripePlaceholderLoader = () => (
  <div className={CLASS_NAMES.PLACEHOLDER_STRIPE_WRAPPER}>
    <div className={CLASS_NAMES.PLACEHOLDER_STRIPE_CONTAINER}>
      <ContentLoader speed={2}
                     height={88}
                     width={1560}
                     primaryColor="#ececec"
                     secondaryColor="rgba(207, 207, 207, 0.7)"
      >
        <circle cx="60" cy="40" r="30" />
        <rect x="160" y="20" rx="10" ry="10" width="70" height="18" />
        <rect x="160" y="55" rx="10" ry="10" width="140" height="18" />

        <circle cx="460" cy="40" r="30" />
        <rect x="560" y="20" rx="10" ry="10" width="70" height="18" />
        <rect x="560" y="55" rx="10" ry="10" width="140" height="18" />

        <circle cx="860" cy="40" r="30" />
        <rect x="960" y="20" rx="10" ry="10" width="70" height="18" />
        <rect x="960" y="55" rx="10" ry="10" width="140" height="18" />

        <circle cx="1260" cy="40" r="30" />
        <rect x="1360" y="20" rx="10" ry="10" width="70" height="18" />
        <rect x="1360" y="55" rx="10" ry="10" width="140" height="18" />
      </ContentLoader>
    </div>
  </div>
);

export const LinearPlaceholderLoader = () => (
  <div className={CLASS_NAMES.PLACEHOLDER_LINEAR_CONTAINER}>
    <ContentLoader speed={3}
                   height={365}
                   width={850}
                   primaryColor="#ececec"
                   secondaryColor="rgba(207, 207, 207, 0.7)"
    >
      <rect x="48" y="40" rx="10" ry="10" width="140" height="18" />
      <rect x="48" y="170" rx="10" ry="10" width="500" height="18" />
      <rect x="48" y="220" rx="10" ry="10" width="400" height="18" />
      <rect x="48" y="290" rx="10" ry="10" width="700" height="18" />
    </ContentLoader>
  </div>
);

export const CirclePlaceholderLoader = () => (
  <div className={CLASS_NAMES.PLACEHOLDER_CIRCLE_CONTAINER}>
    <ContentLoader speed={3}
                   height={365}
                   width={850}
                   primaryColor="#ececec"
                   secondaryColor="rgba(207, 207, 207, 0.7)"
    >
      <rect x="48" y="20" rx="10" ry="10" width="140" height="18" />
      <circle cx="120" cy="220" r="75" />
      <circle cx="550" cy="220" r="75" />
      <rect x="210" y="170" rx="10" ry="10" width="50" height="18" />
      <rect x="210" y="220" rx="10" ry="10" width="140" height="18" />
      <rect x="210" y="260" rx="10" ry="10" width="140" height="18" />
      <rect x="640" y="170" rx="10" ry="10" width="50" height="18" />
      <rect x="642" y="220" rx="10" ry="10" width="140" height="18" />
      <rect x="642" y="270" rx="10" ry="10" width="140" height="18" />
    </ContentLoader>
  </div>
);