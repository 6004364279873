import React, {Component} from 'react';
import textProvider from "../../../../texts";
import {Button, Input} from "@perion-undertone/ut-react-common";
import PropTypes from "prop-types";
import Icon from "../../../Icon";
import {parseUrl} from '@perion-undertone/ramp-helpers';
import {connect} from "react-redux";
import {addMainSite} from '../../../../actions/onboardingActions';
import {setViewSiteUnderReviewPage} from '../../../../actions/appActions';
import OnboardingSideBar from './OnboardingSideBar';

const CLASS_NAMES = {
    STEP_COMPONENT: 'step-comp',
    STEP_LAYOUT_LEFT: 'layout-left',
    INPUT_WRAPPER: 'input-wrapper',
    ERROR: 'input-error',
    CODE_ERROR_MESSAGE: 'error-message',
};

const CONSTANT_TEXTS = {
    TITLE: textProvider.getText('onboardingModal', 'step_1_1.title'),
    DOMAIN_INFO_LABEL: textProvider.getText('onboardingModal', 'step_1_1.domainInfoLabel'),
    BUTTON: textProvider.getText('onboardingModal', 'step_1_1.button'),
    URL_SITE: textProvider.getText('onboardingModal', 'step_1_1.urlSite'),
    ERROR_SUGGESTIONS: textProvider.getText('onboardingModal', 'step_1_1.validationUrlMessage')
};

class OnboardingStep_1_1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            touched: {
                url: false
            }
        };
        this.submitMainSite = this.submitMainSite.bind(this);
        this.handleUrlChange = this.handleUrlChange.bind(this);
        this.shouldMarkError = this.shouldMarkError.bind(this);
        this.shouldMarkValid = this.shouldMarkValid.bind(this);
    }

    submitMainSite() {
        const {shouldDisplayEnterSite, onboarding} = this.props;
        const site = parseUrl(this.state.url);
        site.isMain = true;

        const updateSite = shouldDisplayEnterSite && onboarding.mainSite && {
            publishersSupplySourcesId: onboarding.mainSite.publishersSupplySourcesId,
            isMain: false,
            isDeleted: true
        };

        this.props.setViewSiteUnderReviewPage(true);
        this.props.addMainSite(site, updateSite);
    }

    handleUrlChange(event) {
        this.setState({url: event.target.value});
    }

    handleBlur = field => () => {
        this.setState({
            touched: {...this.state.touched, [field]: true}
        });
    };

    shouldMarkError(hasErrors) {
        const shouldShow = this.state.touched['url'];
        return hasErrors && shouldShow;
    }

    shouldMarkValid(hasErrors) {
        const shouldShow = this.state.touched['url'];
        return !hasErrors && shouldShow;
    }

    render() {
        const {url, touched} = this.state;
        const {user, shouldDisplayEnterSite} = this.props;
        const hasErrors = !parseUrl(url);
        const disableAddingSite = hasErrors;
        const markError = this.shouldMarkError(hasErrors);
        const markValid = this.shouldMarkValid(hasErrors);

        return (
            <div id="step_1_1" className={CLASS_NAMES.STEP_COMPONENT}>
                <div className={CLASS_NAMES.STEP_LAYOUT_LEFT}>
                    <h1>{shouldDisplayEnterSite ? 'Please Enter Site' : `${CONSTANT_TEXTS.TITLE}, ${user.data.firstName}!`}</h1>
                    <p>{shouldDisplayEnterSite ? 'Please note that the previous site will be deleted' : CONSTANT_TEXTS.DOMAIN_INFO_LABEL}</p>
                    <br />
                    <br />
                    <br />
                    <form>
                        <label htmlFor="url">{CONSTANT_TEXTS.URL_SITE}</label>
                        <div className={CLASS_NAMES.INPUT_WRAPPER}>
                            <Input type='url'
                                   name='url'
                                   className={markError ? 'error' : (markValid ? 'valid': 'url-input')}
                                   value={url}
                                   placeholder={CONSTANT_TEXTS.PLACEHOLDER_URL}
                                   onChange={this.handleUrlChange}
                                   onBlur={this.handleBlur('url')}
                                   iconPosition={'right'}
                                   hookId='onboarding-first-step-url-input'
                            />
                            {touched['url'] && markValid ? <Icon icon={'valid_url.svg'} className={'input-error-img'} /> : (markError ? <Icon icon={'invalid_url.svg'} className={'input-error-img'} /> : null)}
                            <div className={CLASS_NAMES.ERROR_MESSAGE_CONTAINER}>
                                {markError ? <p className={CLASS_NAMES.ERROR}>{CONSTANT_TEXTS.ERROR_SUGGESTIONS}</p> : null}
                            </div>

                            {markValid ?
                                <p className={CLASS_NAMES.VALID}>{CONSTANT_TEXTS.VALIDATION_MESSAGE}</p>
                                : null}
                        </div>
                        <Button disabled={disableAddingSite}
                                hookId='add-site-button'
                                onClick={this.submitMainSite}
                        >
                            {CONSTANT_TEXTS.BUTTON}
                        </Button>
                    </form>
                </div>
                <OnboardingSideBar img='step_1_1.png' />
            </div>
        )
    }
}

OnboardingStep_1_1.propTypes = {
    user: PropTypes.object,
    onboarding: PropTypes.object,
    addMainSite: PropTypes.func,
    setViewSiteUnderReviewPage: PropTypes.func,
    shouldDisplayEnterSite: PropTypes.bool
};

const mapStateToProps = ({app}) => ({
    user: app.user,
    onboarding: app.onboarding,
    shouldDisplayEnterSite: app.shouldDisplayEnterSite,
});

const mapDispatchToProps = ({
    addMainSite,
    setViewSiteUnderReviewPage
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingStep_1_1);