import {supplyConstants} from '../constants/supplyConstants';

export const getPublisherSupplySources = (options) => ({
    type: supplyConstants.SUPPLY_SOURCES_REQUEST,
    payload: {...options}
})

export const getSupplySourcesSuccess = ({supplySources, count}) => ({
    type: supplyConstants.SUPPLY_SOURCES_SUCCESS,
    payload: {supplySources, count}
});

export const getSupplySourcesFailure = error => ({
    type: supplyConstants.SUPPLY_SOURCES_FAILURE,
    payload: {error}
})

export const openAddSitesModal = () => ({
    type: supplyConstants.OPEN_ADD_SITES_MODAL
});
export const closeAddSitesModal = () => ({
    type: supplyConstants.CLOSE_ADD_SITES_MODAL
});

export const changePage = (page) => ({
    type: supplyConstants.CHANGE_PAGE, payload: {page}
});

export const changeItemsPerPage = (itemsPerPage) => (
    {type: supplyConstants.CHANGE_ITEMS_PER_PAGE, payload: {itemsPerPage}}
);

export const validateDomainInAdsTxt = (url, publisherId) => ({
    type: supplyConstants.VALIDATE_DOMAIN_REQUEST,
    payload: {url, publisherId}
});
export const validateDomainInAdsTxtSuccess = (domainData) => ({
    type: supplyConstants.VALIDATE_DOMAIN_SUCCESS,
    payload: {domainData}
});
export const validateDomainInAdsTxtFailed = (url, error) => ({
    type: supplyConstants.VALIDATE_DOMAIN_FAILURE,
    payload: {url, error}
});

export const setAdsTxtDomain = (publisherId, publishersSupplySourcesId, url, hasValidAdsTxt) => ({
    type: supplyConstants.SET_ADSTXT_DOMAIN_REQUEST,
    payload: {publisherId, publishersSupplySourcesId, url, hasValidAdsTxt}
});
export const setAdsTxtDomainSuccess = (supplySources) => ({
    type: supplyConstants.SET_ADSTXT_DOMAIN_SUCCESS,
    payload: {supplySources}
});
export const setAdsTxtDomainFailed = (error) => ({
    type: supplyConstants.SET_ADSTXT_DOMAIN_FAILURE,
    payload: {error}
});

export const openAdsTxtModal = (validatedDomain) => ({
    type: supplyConstants.OPEN_ADS_TXT_MODAL,
    payload: {validatedDomain}
});
export const closeAdsTxtModal = () => ({
    type: supplyConstants.CLOSE_ADS_TXT_MODAL
});

export const addSingleSite = site => ({
    type: supplyConstants.ADD_SELECTED_SITE,
    payload: {site}
});
export const deleteSingleSite = site => ({
    type: supplyConstants.DELETE_SELECTED_SITE,
    payload: {site}
});

export const submitSites = (sites, publisherId) => ({
    type: supplyConstants.SUBMIT_SITES_REQUEST,
    payload: {sites, publisherId}
});
export const submitSitesFailed = allSites => ({
    type: supplyConstants.SUBMIT_SITES_FAILURE,
    payload: {allSites}
});
export const submitSitesSuccess = ({supplySources, count}) => ({
    type: supplyConstants.SUBMIT_SITES_SUCCESS,
    payload: {supplySources, count}
});
export const searchSupplySites = (searchTerm) => ({
    type: supplyConstants.SEARCH_SUPPLY_SITES,
    payload: {searchTerm}
});

// Get Blocked Advertisers
export const getBlockedAdvertisers = (publisherId, publishersSupplySourcesIds, isPublisherLevel) => ({
    type: supplyConstants.GET_BLOCKED_ADVERTISERS_REQUEST,
    payload: {publisherId, publishersSupplySourcesIds, isPublisherLevel}
});
export const getBlockedAdvertisersSuccess = blockedAdvertisers => ({
    type: supplyConstants.GET_BLOCKED_ADVERTISERS_SUCCESS,
    payload: {blockedAdvertisers}
});
export const getBlockedAdvertisersFailed = error => ({
    type: supplyConstants.GET_BLOCKED_ADVERTISERS_FAILURE,
    payload: {error}
});
// Block Advertisers
export const blockAdvertisers = (publisherId, advertisers) => ({
    type: supplyConstants.BLOCK_ADVERTISERS_REQUEST,
    payload: {publisherId, advertisers}
});
export const blockAdvertisersSuccess = blockedAdvertisers => ({
    type: supplyConstants.BLOCK_ADVERTISERS_SUCCESS,
    payload: {blockedAdvertisers}
});
export const blockAdvertisersFailed = error => ({
    type: supplyConstants.BLOCK_ADVERTISERS_FAILURE,
    payload: {error}
});

export const searchAdvertisersBlockList = (searchTerm) => ({
    type: supplyConstants.SEARCH_BLOCKED_ADVERTISERS,
    payload: {searchTerm}
});
// Get Blocked Categories
export const getBlockedCategories = (publisherId, publishersSupplySourcesId, isPublisherLevel) => ({
    type: supplyConstants.GET_BLOCKED_CATEGORIES_REQUEST,
    payload: {publisherId, publishersSupplySourcesId, isPublisherLevel}
});
export const getBlockedCategoriesSuccess = blockedCategories => ({
    type: supplyConstants.GET_BLOCKED_CATEGORIES_SUCCESS,
    payload: {blockedCategories}
});
export const getBlockedCategoriesFailed = error => ({
    type: supplyConstants.GET_BLOCKED_CATEGORIES_FAILURE,
    payload: {error}
});

// Block/Unblock Categories
export const blockCategories = (publisherId, publishersSupplySourcesId, categoryData, isPublisherLevel) => ({
    type: supplyConstants.BLOCK_CATEGORIES_REQUEST,
    payload: {publisherId, publishersSupplySourcesId, categoryData, isPublisherLevel}
});
export const blockCategoriesSuccess = blockedCategories => ({
    type: supplyConstants.BLOCK_CATEGORIES_SUCCESS,
    payload: {blockedCategories}
});
export const blockCategoriesFailed = error => ({
    type: supplyConstants.BLOCK_CATEGORIES_FAILURE,
    payload: {error}
});

// Get Bulk Blocked Categories by Supply Sources Ids
export const getBulkBlockedCategories = (publisherId, supplySourcesIds) => ({
    type: supplyConstants.GET_BULK_BLOCKED_CATEGORIES_REQUEST,
    payload: {publisherId, supplySourcesIds}
});



export const getBulkBlockedCategoriesSuccess = bulkBlockedCategories => ({
    type: supplyConstants.GET_BULK_BLOCKED_CATEGORIES_SUCCESS,
    payload: {bulkBlockedCategories}
});
export const getBulkBlockedCategoriesFailed = error => ({
    type: supplyConstants.GET_BULK_BLOCKED_CATEGORIES_FAILURE,
    payload: {error}
});

// Bulk Block/Unblock Categories
export const bulkBlockCategories = (publisherId, categories) => ({
    type: supplyConstants.BULK_BLOCK_CATEGORIES_REQUEST,
    payload: {publisherId, categories}
});
export const bulkBlockCategoriesSuccess = bulkBlockedCategories => ({
    type: supplyConstants.BULK_BLOCK_CATEGORIES_SUCCESS,
    payload: {bulkBlockedCategories}
});
export const bulkBlockCategoriesFailed = error => ({
    type: supplyConstants.BULK_BLOCK_CATEGORIES_FAILURE,
    payload: {error}
});

// Get Video Limitations by Supply Sources Ids
export const getVideoLimitations = (publisherId, supplySourcesIds) => ({
    type: supplyConstants.GET_VIDEO_LIMITATIONS_REQUEST,
    payload: {publisherId, supplySourcesIds}
});
export const getVideoLimitationsSuccess = videoLimitations => ({
    type: supplyConstants.GET_VIDEO_LIMITATIONS_SUCCESS,
    payload: {videoLimitations}
});
export const getVideoLimitationsFailed = error => ({
    type: supplyConstants.GET_VIDEO_LIMITATIONS_FAILURE,
    payload: {error}
});

// Manage Video Limitations by Supply Sources Ids
export const manageVideoLimitations = (publisherId, videoLimitations) => ({
    type: supplyConstants.MANAGE_VIDEO_LIMITATIONS_REQUEST,
    payload: {publisherId, videoLimitations}
});
export const manageVideoLimitationsSuccess = videoLimitations => ({
    type: supplyConstants.MANAGE_VIDEO_LIMITATIONS_SUCCESS,
    payload: {videoLimitations}
});
export const manageVideoLimitationsFailed = error => ({
    type: supplyConstants.MANAGE_VIDEO_LIMITATIONS_FAILURE,
    payload: {error}
});
