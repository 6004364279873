import {publisherConstants} from "../constants/publisherConstants"

export const updatePublisherRequest = (publisherId, updateOptions) => {
    return {
        type: publisherConstants.UPDATE_PUBLISHER_REQUEST,
        payload: {publisherId, updateOptions}
    }
}

export const updatePublisherSuccess = (updatedOptions) => {
    return {
        type: publisherConstants.UPDATE_PUBLISHER_SUCCESS,
        payload: updatedOptions.publisher
    }
}

export const updatePublisherFailure = (error) => {
    return {
        type: publisherConstants.UPDATE_PUBLISHER_FAILURE,
        payload: error
    }
}