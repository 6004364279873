import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {Image} from './imports';

const CLASS_NAMES = {
    ONBOARDING_SIDEBAR_WRAPPER: 'onboarding-sidebar-wrapper'
};

const OnboardingSideBar = (props) => {
    const {img, className} = props;

    return (
        <div className={cx(CLASS_NAMES.ONBOARDING_SIDEBAR_WRAPPER, className)}>
            {img &&
                <Image name={img}
                       hookId='onboarding-sidebar-img'
                />
            }
            {props.children}
        </div>
    );
};

OnboardingSideBar.propTypes = {
    img: PropTypes.string,
    className: PropTypes.string
};

export default OnboardingSideBar;
