import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const CLASS_NAMES = {
    SWITCH: 'switch',
	SWITCH_DISABLED: 'switch-disabled',
	SLIDER: 'slider',
	INNER_SLIDER: 'inner-slider'
};

class PlacementsStatusToggle extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	onChange(event) {
		const {onStatusChange, isOn, placementGroupId} = this.props;
		const toggledValue = !isOn;
		onStatusChange(event, placementGroupId, toggledValue);
	}

	render() {
		const {isOn, disabled} = this.props;
		return (
			<label className={cx(CLASS_NAMES.SWITCH, {[CLASS_NAMES.SWITCH_DISABLED]: disabled})}>
				<input type='checkbox' checked={isOn} onChange={this.onChange} disabled={disabled} />
				<div className={CLASS_NAMES.SLIDER}>
					<div className={CLASS_NAMES.INNER_SLIDER}></div>
				</div>
			</label>
		);
	}
}

PlacementsStatusToggle.propTypes = {
	status: PropTypes.bool,
	onStatusChange: PropTypes.func,
	isOn: PropTypes.bool,
	disabled: PropTypes.bool,
	placementGroupId: PropTypes.number
};

export default PlacementsStatusToggle;