import React from 'react';
import PropTypes from 'prop-types';
import {Popover} from '@perion-undertone/ut-react-common';

const CLASS_NAMES = {
    AD_UNITS_TOOLTIP_LINK: 'adunits-tooltip-link',
    AD_UNITS_CONTAINER: 'ad-units-container',
    AD_UNITS_CONTENT: 'ad-units-content',
};

const AdUnitsFormatter = ({value}) => {
    const adunits = typeof value === 'string' ? value.split(',') : value;
    let [firstUnit, ...otherUnits] = adunits;
    firstUnit = firstUnit || {name: ''};
    const trigger = otherUnits.length ? <span className={CLASS_NAMES.AD_UNITS_TOOLTIP_LINK}>{`+${otherUnits.length}`}</span> : null;
    return (
        <div>
            {`${firstUnit.name || firstUnit}  `}

            <Popover basic={true}
                     appendToBody={true}
                     trigger={trigger}
                     className={CLASS_NAMES.AD_UNITS_CONTAINER}
                     hookId="ad-units-formatter-popover"
            >
                <div className={CLASS_NAMES.AD_UNITS_CONTENT}>
                    {otherUnits.map((adUnit) => <div key={adUnit.id || adUnit}>{adUnit.name || adUnit}</div>)}
                </div>
            </Popover>
        </div>
    );
};

AdUnitsFormatter.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default AdUnitsFormatter;