import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {selectFeatureFlag} from '@flopflip/react-redux';

import AdSlotsView from '../AdSlotsView';
import {Tooltip} from '@perion-undertone/ut-react-common';
import CopyToClipboard from '../../../CopyToClipboard';

const CLASS_NAMES = {
    PLACEMENT_TAG_TYPE_CELL_WRAPPER: 'placement-tag-type-cell-wrapper',
};

const PlacementTagTypeColumnFormatter = (props) => {
    const {row, isPlacementGroupTagTypesEnabled} = props;
    return (
        <div className={CLASS_NAMES.PLACEMENT_TAG_TYPE_CELL_WRAPPER}>
            <CopyToClipboard copyValue={isPlacementGroupTagTypesEnabled && row.placementTagCode}
                             copyTarget={!isPlacementGroupTagTypesEnabled && props.rowIndex}
                             copyMessage='Copy Tag'
                             copiedMessage='Tag copied!'
                             placement='right'
                             hookId='copy-to-clipboard-tag-type'
            />
            {isPlacementGroupTagTypesEnabled ?
                <Tooltip trigger={<div>{row.placementTagTypeName}</div>}
                         appendToBody={true}
                         className='placement-tag-code-tooltip'
                         hookId='placement-tag-code-tooltip'
                >
                    {row.placementTagCode}
                </Tooltip> :
            row.placementTagTypeName}
            <AdSlotsView adSlots={row.adSlots} />
        </div>
    );
};


PlacementTagTypeColumnFormatter.propTypes = {
    row: PropTypes.object,
    isPlacementGroupTagTypesEnabled: PropTypes.bool,
    rowIndex: PropTypes.integer
};

const mapStateToProps = (state) => ({
    isPlacementGroupTagTypesEnabled: selectFeatureFlag('placementGroupTagTypes')(state),
});

export default connect(mapStateToProps)(PlacementTagTypeColumnFormatter);