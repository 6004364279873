import React from 'react';
import PropTypes from 'prop-types';

const CLASS_NAMES = {
    TOOLTIP_WITH_SCROLLBAR: 'tooltip-with-scrollbar',
    TOOLTIP_WITH_SCROLLBAR_WRAPPER: 'tooltip-with-scrollbar-wrapper',
    TOOLTIP_WITH_SCROLLBAR_CONTENTS: 'tooltip-with-scrollbar-contents',
}

const TooltipWithScrollbar = (props) => {
    const {trigger, contents} = props;

    return (
        <div className={CLASS_NAMES.TOOLTIP_WITH_SCROLLBAR}>
            {trigger}
            <div className={CLASS_NAMES.TOOLTIP_WITH_SCROLLBAR_WRAPPER}>
                <span className={CLASS_NAMES.TOOLTIP_WITH_SCROLLBAR_CONTENTS}>
                    {contents}
                </span>
            </div>
        </div>
    );
}

TooltipWithScrollbar.propTypes = {
    trigger: PropTypes.object,
    contents: PropTypes.array
};

export default TooltipWithScrollbar;