import {featuresConstants} from '../constants/featuresConstants';

export const getFeaturesRequest = () => ({
    type: featuresConstants.FEATURES_REQUEST
});
export const getFeaturesSuccess = (features) => ({
    type: featuresConstants.FEATURES_SUCCESS,
    payload: features
});
export const getFeaturesFailed = () => ({
    type: featuresConstants.FEATURES_FAILURE
});