import React, {Component} from 'react';
import {getImagePath} from './../../../../utils/helpers';
import textProvider from "../../../../texts";
import PropTypes from 'prop-types';
import SendEmail from '../../../SendEmail';
import {getAdsTxtCode} from '@perion-undertone/ramp-helpers';
import CopyToClipboard from "../../../CopyToClipboard";
import OnboardingSideBar from "./OnboardingSideBar";
import {connect} from "react-redux";
import {validateMainSite} from './../../../../actions/onboardingActions';

const CLASS_NAMES = {
    STEP_COMPONENT: 'step-comp',
    STEP_LAYOUT_LEFT: 'layout-left',
    STEP_INFO: 'info',
    STEP_INFO_COPY: 'copy-info',
    STEP_INFO_COPY_HOLDER: 'copy-holder',
    STEP_BUTTONS_HOLDER: 'buttons-holder',
    STEP_BUTTONS_HOLDER_ACTION: 'action',
    STEP_BUTTONS_HOLDER_COPY: 'copy',
    STEP_CHK_BOX_HOLDER: 'check-box-holder',
    STEP_CHK_BOX_HOLDER_CHECKBOX: 'checkbox',
    STEP_NEXT_BUTTON: 'next-button',
    STEP_SEARCH: 'search'
};

const CONSTANT_TEXTS = {
    TITLE: textProvider.getText('onboardingModal', 'step_3_2.title'),
    MAX_REVENUE: textProvider.getText('onboardingModal', 'step_3_2.maxRevenue'),
    PASTE_CODE1: textProvider.getText('onboardingModal', 'step_3_2.pasteCode1'),
    PASTE_CODE2: textProvider.getText('onboardingModal', 'step_3_2.pasteCode2'),
    PASTE_CODE3: textProvider.getText('onboardingModal', 'step_3_2.pasteCode3'),
    CHECKBOX: textProvider.getText('onboardingModal', 'step_3_2.checkbox'),
    BUTTON: textProvider.getText('onboardingModal', 'step_3_2.button'),
    COPY_CODE: textProvider.getText('onboardingModal', 'step_3_2.copyCode')
};

class OnboardingStep_3_2 extends Component {
    constructor(props) {
        super(props);

        this.validateAdsText = this.validateAdsText.bind(this);
    }

    validateAdsText() {
        const {onboarding} = this.props;
        const url = onboarding.mainSite.SupplySource.address;
        const pssId = onboarding.mainSite.publishersSupplySourcesId;
        const hasValidAdsTxt = onboarding.mainSite.hasValidAdsTxt;
        this.props.validateMainSite(url, pssId, hasValidAdsTxt, true);
    }

    render() {
        const {onboarding, publisher} = this.props;
        const adsTxtCode = getAdsTxtCode(publisher.data.id);
        const {mainSite} = onboarding;

        return (
            <div id="step_3_2" className={CLASS_NAMES.STEP_COMPONENT}>
                <div className={CLASS_NAMES.STEP_LAYOUT_LEFT}>
                    <h2>{CONSTANT_TEXTS.TITLE}</h2>
                    <p className={CLASS_NAMES.STEP_INFO}>{CONSTANT_TEXTS.MAX_REVENUE}</p>
                    <p className={CLASS_NAMES.STEP_INFO_COPY}>
                        {CONSTANT_TEXTS.PASTE_CODE1}
                        {` ${mainSite.SupplySource.address}/ads.txt`} <br />
                        {CONSTANT_TEXTS.PASTE_CODE2}
                        <span className="action">{CONSTANT_TEXTS.PASTE_CODE3}</span>
                    </p>
                    <div className={CLASS_NAMES.STEP_INFO_COPY_HOLDER}>
                        <textarea readOnly={true} value={adsTxtCode}>
                            {adsTxtCode}
                        </textarea>
                        <div className={CLASS_NAMES.STEP_BUTTONS_HOLDER}>
                            <CopyToClipboard copyValue={adsTxtCode}
                                             copyTarget={'Copy ads txt'}
                                             copy={CONSTANT_TEXTS.COPY_CODE} noWrap
                            />
                        </div>
                    </div>
                    <SendEmail placeholder={adsTxtCode}
                               shouldClose={onboarding.isValidating}
                    />
                    <div className={CLASS_NAMES.STEP_CHK_BOX_HOLDER}>
                        <input className={CLASS_NAMES.STEP_CHK_BOX_HOLDER_CHECKBOX}
                               id="checkbox-1" type="checkbox" defaultChecked={true}
                        />
                        <label htmlFor="checkbox-1"><span>{CONSTANT_TEXTS.CHECKBOX}</span></label>
                    </div>
                    <button onClick={this.validateAdsText} className={CLASS_NAMES.STEP_NEXT_BUTTON}>
                        {CONSTANT_TEXTS.BUTTON}
                    </button>
                    {onboarding.isValidating &&
                        <div className={CLASS_NAMES.STEP_SEARCH}>
                            <img src={getImagePath("search.png")} alt={'Search'}/>
                        </div>
                    }
                </div>
                <OnboardingSideBar img='step_3_2.png' />
            </div>
        )
    }
}

OnboardingStep_3_2.propTypes = {
    onboarding: PropTypes.object,
    publisher: PropTypes.object,
    validateMainSite: PropTypes.func
};

const mapStateToProps = ({app}) => ({
    onboarding: app.onboarding,
    publisher: app.publisher
});

const mapDispatchToProps = ({
    validateMainSite
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingStep_3_2);