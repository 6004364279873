import React from 'react';

import {redirectTo, textProvider} from './imports';

const CONSTANT_TEXTS = {
    REDIRECT_TO_CREATE_ACCOUNT: textProvider.getText('loginComponent', 'redirectToCreateAccount'),
    HERE: textProvider.getText('common', 'here')
}

const NavigateToCreateAccount = () => {
    return (
        <div className='redirect-bottom-container'
             style={{textAlign: 'center', color: 'white'}}
        >
            {`${CONSTANT_TEXTS.REDIRECT_TO_CREATE_ACCOUNT} `}
            <a className='link-underline' onClick={() => redirectTo('/create-account')}>
                {CONSTANT_TEXTS.HERE}
            </a>
        </div>
    );
};

export default NavigateToCreateAccount;