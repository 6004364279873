import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button} from '@perion-undertone/ut-react-common';
import get from "lodash/get";
import isEmpty from 'lodash/isEmpty';

import {
    statusConstants,
    textProvider,
    createPlacementGroup,
    updatePlacementGroup
} from '../imports';

const CLASS_NAMES = {
    MODAL_FOOTER: 'placement-group-modal-footer'
};

const CONSTANT_TEXTS = {
    CANCEL: textProvider.getText('confirmationModal', 'cancel'),
    SAVE: textProvider.getText('confirmationModal', 'save')
};

const PlacementGroupFooter = (props) => {
    const {currentGroup, updatePlacementGroup, createPlacementGroup, publisherId, placementTagTypeId,
        isSaveClicked, setSaveClicked, cancelModal, isInvalidGroup, isPlacementGroupTagTypesEnabled,
        setActivePlacementTab, placementsPagination: {limit, page}, searchTerm, customization} = props;
    const isValidGroup = !isInvalidGroup();
    const sort = get(customization, 'sort') || [];

    const saveGroup = () => {
        setSaveClicked(true);

        if (isSaveDisabled()) return;

        if(!isValidGroup) {
            return setActivePlacementTab('adUnitsTab');
        }

        if (isValidGroup) {
            const currentGroupCopy = {...currentGroup};
            currentGroupCopy.adSlots = currentGroupCopy.adSlots.map(adSlot => adSlot.name || adSlot);
            currentGroupCopy.placementTagTypeId = placementTagTypeId;
            setSaveClicked(false);
            if (currentGroupCopy.placementGroupId) {
                updatePlacementGroup(publisherId, currentGroupCopy.placementGroupId, currentGroupCopy, limit, page, searchTerm, sort);
            } else {                
                currentGroupCopy.actionPriceMacroType = isEmpty(currentGroupCopy.actionPriceMacroType) ? 0 : currentGroupCopy.actionPriceMacroType;
                createPlacementGroup({
                    ...currentGroupCopy,
                    status: statusConstants.ACTIVE,
                    type: isPlacementGroupTagTypesEnabled ? 'direct' : 'bidding'
                }, publisherId, limit, page, searchTerm, sort);
            }
            setSaveClicked(false);
        }
    };

    const isSaveDisabled = () => {
        if (!isSaveClicked) return false;
        return isInvalidGroup();
    }

    return (
        <div className={CLASS_NAMES.MODAL_FOOTER}>
            <Button transparent
                    onClick={cancelModal}
                    hookId='adunit-cancel-button'
            >
                {CONSTANT_TEXTS.CANCEL}
            </Button>
            <Button onClick={saveGroup}
                    disabled={isSaveDisabled()}
                    hookId='adunit-save-button'
            >
                {CONSTANT_TEXTS.SAVE}
            </Button>
        </div>
    );
};

PlacementGroupFooter.propTypes = {
    isSaveClicked: PropTypes.bool,
    isInvalidGroup: PropTypes.func,
    setSaveClicked: PropTypes.func,
    cancelModal: PropTypes.func,
    currentGroup: PropTypes.object,
    createPlacementGroup: PropTypes.func,
    updatePlacementGroup: PropTypes.func,
    publisherId: PropTypes.number,
    placementTagTypeId: PropTypes.number,
    isPlacementGroupTagTypesEnabled: PropTypes.bool,
    setActivePlacementTab: PropTypes.func,
    placementsPagination: PropTypes.object,
    searchTerm: PropTypes.string,
    customization: PropTypes.object
};

const mapDispatchToProps = ({
    createPlacementGroup,
    updatePlacementGroup,
});


export default connect(null, mapDispatchToProps)(PlacementGroupFooter);
