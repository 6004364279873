import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, Animation} from '@perion-undertone/ut-react-common';
import {get, isEmpty} from 'lodash';

import {VIDEO_DIRECTION_DETAILS} from './utils';
import {textProvider} from '../imports';
import {getAdUnitConfigurations, saveAdUnitConfigurations} from '../actionImports';
import AdUnitConfiguration from './AdUnitConfiguration';
import {selectFeatureFlag} from "@flopflip/react-redux";

const CLASS_NAMES = {
    INNER_CONTAINER: 'supply-settings-modal-inner-container',
    TITLE: 'block-list-title',
    ACTION_DESCRIPTION: 'action-description',
    SEPARATOR: 'section-wrapper-separator',
    FOOTER: 'supply-settings-modal-footer',
    DONE_BUTTON: 'supply-settings-modal-done-button',
    CANCEL_BUTTON: 'supply-settings-modal-cancel-button',
};

const CONSTANT_TEXTS = {
    ADUNIT_CONFIGURATIONS: textProvider.getText('supplySourceSettingsModal', 'adunitConfigurationsTitle'),
    VIDEO_SNAPPING: textProvider.getText('supplySourceSettingsModal', 'videoSnapping'),
    VIDEO_SNAPPING_DESCRIPTION: textProvider.getText('supplySourceSettingsModal', 'videoSnappingDescription'),
    ENTRY_DIRECTION: textProvider.getText('supplySourceSettingsModal', 'entryDirection'),
    ENTRY_DIRECTION_DESCRIPTION: textProvider.getText('supplySourceSettingsModal', 'entryDirectionDescription'),
    CANCEL: textProvider.getText('confirmationModal', 'cancel'),
    SAVE: textProvider.getText('confirmationModal', 'save'),
    VIDEO_DISPLAY: textProvider.getText('supplySourceSettingsModal', 'videoDisplay'),
    VIDEO_DISPLAY_DESCRIPTION: textProvider.getText('supplySourceSettingsModal', 'videoDisplayDescription')
};

class AdunitConfigurations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inlineVideoDesktop: {},
            inlineVideoMobile: {},
            videoAdhesionDesktop: {},
            inlineVideoOrientation: {},
        };
    }

    setSelected = (name, value) => this.setState({[name]: value});

    removeSelected = (name) => this.setState({[name]: {}});

    isSelected = (name, position) => !isEmpty(this.state[name]) && this.state[name].selectedPosition === position;
    isDisabled = (name, position) => !isEmpty(this.state[name]) && this.state[name].selectedPosition === position && this.state[name].isDisabled;

    componentDidMount () {
        const {publisherId, publishersSupplySourcesId, isPublisherLevel} = this.props;
        this.props.getAdUnitConfigurations(publisherId, isPublisherLevel ? 0 : publishersSupplySourcesId);
    }

    componentDidUpdate(prevProps, prevState) {
        const {adUnitConfig, isBulkMode, isPublisherLevel} = this.props;
        const configData = adUnitConfig && adUnitConfig.data;
        const videoAdhesionConfig = configData && configData.find(config => get(config.AdUnit, 'name') === 'Video Adhesion');
        const inlineVideoDesktopConfig = configData && configData.find(config => get(config, 'name') === 'videoDirection' && get(config.AdUnitDeviceType, 'name') === 'Desktop');
        const inlineVideoMobileConfig = configData && configData.find(config => get(config, 'name') === 'videoDirection' && get(config.AdUnitDeviceType, 'name') === 'Smartphone');
        const inlineVideoDisplayVerticalConfig = configData && configData.find(config => get(config, 'name') === 'videoOrientation' && get(config, 'defaultValue') === 'both');

        if (isEmpty(this.state.videoAdhesionDesktop) && videoAdhesionConfig) {
            const videoAdhesionDesktop = VIDEO_DIRECTION_DETAILS.videoAdhesion.desktop;
            let configValue = videoAdhesionConfig.defaultValue;
            if (videoAdhesionConfig.PublisherLevelAdUnitConfiguration) {
                configValue = videoAdhesionConfig.PublisherLevelAdUnitConfiguration.adUnitConfigurationValue;
            }
            if (videoAdhesionConfig.PublisherSupplySourceAdUnitConfiguration && !isBulkMode) {
                configValue = videoAdhesionConfig.PublisherSupplySourceAdUnitConfiguration.adUnitConfigurationValue;
            }
            videoAdhesionDesktop.selectedPosition = configValue;
            this.setState({videoAdhesionDesktop});
        }
        if (isEmpty(this.state.inlineVideoDesktop) && inlineVideoDesktopConfig) {
            const inlineVideoDesktop = VIDEO_DIRECTION_DETAILS.inlineVideo.desktop;
            let configValue = inlineVideoDesktopConfig.defaultValue;
            if (inlineVideoDesktopConfig.PublisherLevelAdUnitConfiguration) {
                configValue = inlineVideoDesktopConfig.PublisherLevelAdUnitConfiguration.adUnitConfigurationValue;
            }
            if (inlineVideoDesktopConfig.PublisherSupplySourceAdUnitConfiguration && !isBulkMode) {
                configValue = inlineVideoDesktopConfig.PublisherSupplySourceAdUnitConfiguration.adUnitConfigurationValue;
            }
            inlineVideoDesktop.selectedPosition = configValue;
            this.setState({inlineVideoDesktop});
        }
        if (isEmpty(this.state.inlineVideoMobile) && inlineVideoMobileConfig) {
            const inlineVideoMobile = VIDEO_DIRECTION_DETAILS.inlineVideo.mobile;
            let configValue = inlineVideoMobileConfig.defaultValue;
            if (inlineVideoMobileConfig.PublisherLevelAdUnitConfiguration) {
                configValue = inlineVideoMobileConfig.PublisherLevelAdUnitConfiguration.adUnitConfigurationValue;
            }
            if (inlineVideoMobileConfig.PublisherSupplySourceAdUnitConfiguration && !isBulkMode) {
                configValue = inlineVideoMobileConfig.PublisherSupplySourceAdUnitConfiguration.adUnitConfigurationValue;
            }
            inlineVideoMobile.selectedPosition = configValue;
            this.setState({inlineVideoMobile});
        }
        if (isEmpty(this.state.inlineVideoOrientation) && inlineVideoDisplayVerticalConfig) {
            const inlineVideoOrientation = VIDEO_DIRECTION_DETAILS.videoOrientation.orientation;
            inlineVideoOrientation.isDisabled = false;
            this.setState({inlineVideoOrientation});
            const isPublisherLevelSetting = inlineVideoDesktopConfig.PublisherLevelAdUnitConfiguration &&
                inlineVideoDesktopConfig.PublisherLevelAdUnitConfiguration.publishersSupplySourcesId === 0 && !isPublisherLevel;

            let configValue = inlineVideoDisplayVerticalConfig.defaultValue;
            if (inlineVideoDisplayVerticalConfig.PublisherLevelAdUnitConfiguration) {
                configValue = inlineVideoDisplayVerticalConfig.PublisherLevelAdUnitConfiguration.adUnitConfigurationValue;
            }
            if (inlineVideoDisplayVerticalConfig.PublisherSupplySourceAdUnitConfiguration && !isBulkMode) {
                configValue = inlineVideoDisplayVerticalConfig.PublisherSupplySourceAdUnitConfiguration.adUnitConfigurationValue;
            }

            if (isEmpty(prevState.inlineVideoOrientation) && prevState.inlineVideoOrientation === this.state.inlineVideoOrientation) {
                inlineVideoOrientation.selectedPosition = configValue;
                this.setState({inlineVideoOrientation});
            }
            if (configValue === 'horizontal-only' && isPublisherLevelSetting) {
                inlineVideoOrientation.isDisabled = true;
                this.setState({inlineVideoOrientation});
            }
        }
    }

    saveAdUnitConfigurations = () => {
        const {adUnitConfig, publisherId, isBulkMode, isPublisherLevel, publishersSupplySourcesIds, publishersSupplySourcesId} = this.props;
        const configData = adUnitConfig && adUnitConfig.data;
        const videoAdhesionConfig = configData && configData.find(config => get(config.AdUnit, 'name') === 'Video Adhesion');
        const inlineVideoDesktopConfig = configData && configData.find(config => get(config.AdUnit, 'name') === 'Inline Video' && get(config.AdUnitDeviceType, 'name') === 'Desktop');
        const inlineVideoMobileConfig = configData && configData.find(config => get(config.AdUnit, 'name') === 'Inline Video' && get(config.AdUnitDeviceType, 'name') === 'Smartphone');
        const inlineVideoDisplayVerticalConfig = configData && configData.find(config => get(config, 'name') === 'videoOrientation' && get(config, 'defaultValue') === 'both');
        const {inlineVideoDesktop, inlineVideoMobile, inlineVideoOrientation, videoAdhesionDesktop} = this.state;
        const adUnitConfigurations = [];

        if (isPublisherLevel) {
            if (inlineVideoDesktopConfig) {
                const adUnitConfigurationValue = inlineVideoDesktop.selectedPosition;
                adUnitConfigurations.push({
                    adUnitConfigurationId: inlineVideoDesktopConfig.id,
                    publisherId,
                    publishersSupplySourcesId: 0,
                    uid: `${publisherId}-0-${inlineVideoDesktopConfig.id}`,
                    adUnitConfigurationValue
                });
            }
            if (inlineVideoMobileConfig) {
                const adUnitConfigurationValue = inlineVideoMobile.selectedPosition;
                adUnitConfigurations.push({
                    adUnitConfigurationId: inlineVideoMobileConfig.id,
                    publisherId,
                    publishersSupplySourcesId: 0,
                    uid: `${publisherId}-0-${inlineVideoMobileConfig.id}`,
                    adUnitConfigurationValue
                });
            }
            if (inlineVideoDisplayVerticalConfig) {
                const adUnitConfigurationValue = inlineVideoOrientation.selectedPosition;
                adUnitConfigurations.push({
                    adUnitConfigurationId: inlineVideoDisplayVerticalConfig.id,
                    publisherId,
                    publishersSupplySourcesId: 0,
                    uid: `${publisherId}-0-${inlineVideoDisplayVerticalConfig.id}`,
                    adUnitConfigurationValue
                });
            }
            if (videoAdhesionConfig) {
                const adUnitConfigurationValue = videoAdhesionDesktop.selectedPosition;
                adUnitConfigurations.push({
                    adUnitConfigurationId: videoAdhesionConfig.id,
                    publisherId,
                    publishersSupplySourcesId: 0,
                    uid: `${publisherId}-0-${videoAdhesionConfig.id}`,
                    adUnitConfigurationValue
                });
            }
            if (adUnitConfigurations.length > 0) {
                this.props.saveAdUnitConfigurations(publisherId, adUnitConfigurations);
                this.props.cancelModal();
            }
            return;
        }
        if (isBulkMode) {
            publishersSupplySourcesIds.map(publishersSupplySourcesId => {
                if (inlineVideoDesktopConfig) {
                    const adUnitConfigurationValue = inlineVideoDesktop.selectedPosition;
                    adUnitConfigurations.push({
                        adUnitConfigurationId: inlineVideoDesktopConfig.id,
                        publisherId,
                        publishersSupplySourcesId,
                        uid: `${publisherId}-${publishersSupplySourcesId}-${inlineVideoDesktopConfig.id}`,
                        adUnitConfigurationValue
                    });
                }
                if (inlineVideoMobileConfig) {
                    const adUnitConfigurationValue = inlineVideoMobile.selectedPosition;
                    adUnitConfigurations.push({
                        adUnitConfigurationId: inlineVideoMobileConfig.id,
                        publisherId,
                        publishersSupplySourcesId,
                        uid: `${publisherId}-${publishersSupplySourcesId}-${inlineVideoMobileConfig.id}`,
                        adUnitConfigurationValue
                    });
                }
                if (inlineVideoDisplayVerticalConfig) {
                    const adUnitConfigurationValue = inlineVideoOrientation.selectedPosition;
                    adUnitConfigurations.push({
                        adUnitConfigurationId: inlineVideoDisplayVerticalConfig.id,
                        publisherId,
                        publishersSupplySourcesId,
                        uid: `${publisherId}-${publishersSupplySourcesId}-${inlineVideoDisplayVerticalConfig.id}`,
                        adUnitConfigurationValue
                    });
                }
                if (videoAdhesionConfig) {
                    const adUnitConfigurationValue = videoAdhesionDesktop.selectedPosition;
                    adUnitConfigurations.push({
                        adUnitConfigurationId: videoAdhesionConfig.id,
                        publisherId,
                        publishersSupplySourcesId,
                        uid: `${publisherId}-${publishersSupplySourcesId}-${videoAdhesionConfig.id}`,
                        adUnitConfigurationValue
                    });
                }
            });
        } else {
            if (inlineVideoDesktopConfig) {
                const adUnitConfigurationValue = inlineVideoDesktop.selectedPosition;
                const ivdcpssac = inlineVideoDesktopConfig.PublisherSupplySourceAdUnitConfiguration;
                if ((ivdcpssac && ivdcpssac.adUnitConfigurationValue !== adUnitConfigurationValue) || !ivdcpssac) {
                        adUnitConfigurations.push({
                            adUnitConfigurationId: inlineVideoDesktopConfig.id,
                            publisherId,
                            publishersSupplySourcesId,
                            uid: `${publisherId}-${publishersSupplySourcesId}-${inlineVideoDesktopConfig.id}`,
                            adUnitConfigurationValue
                        });
                }
            }
            if (inlineVideoMobileConfig) {
                const adUnitConfigurationValue = inlineVideoMobile.selectedPosition;
                const ivmcpssac = inlineVideoMobileConfig.PublisherSupplySourceAdUnitConfiguration;
                if ((ivmcpssac && ivmcpssac.adUnitConfigurationValue !== adUnitConfigurationValue) || !ivmcpssac) {
                        adUnitConfigurations.push({
                            adUnitConfigurationId: inlineVideoMobileConfig.id,
                            publisherId,
                            publishersSupplySourcesId,
                            uid: `${publisherId}-${publishersSupplySourcesId}-${inlineVideoMobileConfig.id}`,
                            adUnitConfigurationValue
                        });
                }
            }
            if (inlineVideoDisplayVerticalConfig) {
                const adUnitConfigurationValue = inlineVideoOrientation.selectedPosition;
                const ivmcpssac = inlineVideoDisplayVerticalConfig.PublisherSupplySourceAdUnitConfiguration;
                if ((ivmcpssac && ivmcpssac.adUnitConfigurationValue !== adUnitConfigurationValue) || !ivmcpssac) {
                        adUnitConfigurations.push({
                            adUnitConfigurationId: inlineVideoDisplayVerticalConfig.id,
                            publisherId,
                            publishersSupplySourcesId,
                            uid: `${publisherId}-${publishersSupplySourcesId}-${inlineVideoDisplayVerticalConfig.id}`,
                            adUnitConfigurationValue
                        });
                }
            }
            if (videoAdhesionConfig) {
                const adUnitConfigurationValue = videoAdhesionDesktop.selectedPosition;
                const vadcpssac = videoAdhesionConfig.PublisherSupplySourceAdUnitConfiguration;
                if ((vadcpssac && vadcpssac.adUnitConfigurationValue !== adUnitConfigurationValue) || !vadcpssac) {
                        adUnitConfigurations.push({
                            adUnitConfigurationId: videoAdhesionConfig.id,
                            publisherId,
                            publishersSupplySourcesId,
                            uid: `${publisherId}-${publishersSupplySourcesId}-${videoAdhesionConfig.id}`,
                            adUnitConfigurationValue
                        });
                }
            }
        }
        if (adUnitConfigurations.length > 0) {
            this.props.saveAdUnitConfigurations(publisherId, adUnitConfigurations);
            this.props.cancelModal();
        }
    }

    render () {
        const {adUnitConfig, isVideoOrientationEnabled} = this.props;
        const configData = adUnitConfig && adUnitConfig.data;
        const videoAdhesionConfig = configData && configData.find(config => get(config.AdUnit, 'name') === 'Video Adhesion');
        const inlineVideoConfig = configData && configData.find(config => get(config, 'name') === 'videoDirection');
        const inlineVideoOrientationConfig = isVideoOrientationEnabled && configData && configData.find(config => get(config, 'name') === 'videoOrientation');

        return (
            <Fragment>
                <div className={CLASS_NAMES.INNER_CONTAINER}>
                    <div className={CLASS_NAMES.TITLE}>
                        {CONSTANT_TEXTS.ADUNIT_CONFIGURATIONS}
                    </div>
                        {!configData && <Animation type="chasing"
                             height={100}
                             width={100}
                             rendererSettings={{preserveAspectRatio: 'xMaxYMax'}}
                             hookId='adUnit-configurations-loading'/> }
                    <div>
                        {inlineVideoConfig &&
                            <AdUnitConfiguration adUnitKey='inlineVideo'
                                                 adUnitName={inlineVideoConfig.AdUnit.name}
                                                 type={CONSTANT_TEXTS.VIDEO_SNAPPING}
                                                 description={CONSTANT_TEXTS.VIDEO_SNAPPING_DESCRIPTION}
                                                 setSelected={this.setSelected}
                                                 removeSelected={this.removeSelected}
                                                 isSelected={this.isSelected}
                                                 inlineVideoDesktop={this.state.inlineVideoDesktop}
                                                 inlineVideoMobile={this.state.inlineVideoMobile}
                                                 isDisabled={this.isDisabled}
                            />
                        }

                        {inlineVideoConfig && inlineVideoOrientationConfig && <div className={CLASS_NAMES.SEPARATOR}></div>}

                        {inlineVideoOrientationConfig &&
                            <AdUnitConfiguration adUnitKey='videoOrientation'
                                                 adUnitName={inlineVideoOrientationConfig.AdUnit.name}
                                                 type={CONSTANT_TEXTS.VIDEO_DISPLAY}
                                                 description={CONSTANT_TEXTS.VIDEO_DISPLAY_DESCRIPTION}
                                                 setSelected={this.setSelected}
                                                 removeSelected={this.removeSelected}
                                                 isSelected={this.isSelected}
                                                 inlineVideoOrientation={this.state.inlineVideoOrientation}
                                                 isDisabled={this.isDisabled}
                            />
                        }

                        {inlineVideoOrientationConfig && videoAdhesionConfig && <div className={CLASS_NAMES.SEPARATOR}></div>}

                        {videoAdhesionConfig &&
                            <AdUnitConfiguration adUnitKey='videoAdhesion'
                                                 adUnitName={videoAdhesionConfig.AdUnit.name}
                                                 type={CONSTANT_TEXTS.ENTRY_DIRECTION}
                                                 description={CONSTANT_TEXTS.ENTRY_DIRECTION_DESCRIPTION}
                                                 setSelected={this.setSelected}
                                                 removeSelected={this.removeSelected}
                                                 isSelected={this.isSelected}
                                                 videoAdhesionDesktop={this.state.videoAdhesionDesktop}
                                                 isDisabled={this.isDisabled}
                            />
                        }
                    </div>
                </div>
                <div className={CLASS_NAMES.FOOTER}>
                    <Button onClick={this.props.cancelModal}
                            className={CLASS_NAMES.CANCEL_BUTTON}
                            transparent={true}
                            hookId='adunit-configurations-cancel-button'
                    >
                        {CONSTANT_TEXTS.CANCEL}
                    </Button>
                    <Button className={CLASS_NAMES.DONE_BUTTON}
                            hookId='adunit-configurations-save-button'
                            onClick={this.saveAdUnitConfigurations}
                    >
                        {CONSTANT_TEXTS.SAVE}
                    </Button>
                </div>
            </Fragment>
        )
    }
}

AdunitConfigurations.propTypes = {
    cancelModal: PropTypes.func,
    adUnitConfig: PropTypes.object,
    getAdUnitConfigurations: PropTypes.func,
    saveAdUnitConfigurations: PropTypes.func,
    publishersSupplySourcesId: PropTypes.number,
    publishersSupplySourcesIds: PropTypes.array,
    publisherId: PropTypes.number,
    isBulkMode: PropTypes.bool,
    isPublisherLevel: PropTypes.bool,
    isVideoOrientationEnabled: PropTypes.bool
};

const mapStateToProps = (state) => ({
    adUnitConfig: state.adUnitConfigurations.adUnitConfigurations,
    isVideoOrientationEnabled:  selectFeatureFlag('isVideoOrientationEnabled')(state)
});

const mapDispatchToProps = ({
    getAdUnitConfigurations,
    saveAdUnitConfigurations
});

export default connect(mapStateToProps, mapDispatchToProps)(AdunitConfigurations);
