import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Animation, Icon} from '@perion-undertone/ut-react-common';

import AccountLayout from './AccountLayout';
import {
    appStates, createAccount, Image,
    redirectTo, resetAppState, textProvider
} from './imports';

const CLASS_NAMES = {
    EMAIL_SENT_IMAGE: 'email-sent-image',
    EMAIL_SENDING_LOADER: 'email-sending-loader',
    RESEND_EMAIL_SECTION: 'resend-email-section',
};

const CONST_TEXTS = {
    TITLE: textProvider.getText('emailSent', 'title'),
    SENT_EMAIL_TO: textProvider.getText('emailSent', 'sentAnEmailTo'),
    CHECK_YOUR_EMAIL: textProvider.getText('emailSent', 'checkYourEmail'),
    NOT_YOUR_EMAIL: textProvider.getText('emailSent', 'notYourEmail'),
    SMALL_TEXT_FIRST: textProvider.getText('emailSent', 'smallTextBottomFirst'),
    SMALL_TEXT_SECOND: textProvider.getText('emailSent', 'smallTextBottomSecond'),
    SMALL_TEXT_LINK: textProvider.getText('emailSent', 'linkText')
};

const NavigateToCreateAccount = (props) => {
    const onClick = () => {
        redirectTo('/create-account')
        props.resetAppState();
    }
    return (
        <div className='redirect-bottom-container'
             onClick={onClick}
             style={{color: 'white', textAlign: 'left', textDecoration: 'underline'}}
        >
            <Icon icon='icon-pagging_arrow_back'/>
            &nbsp; <span>{'Back to register form'}</span>
        </div>
    );
};

NavigateToCreateAccount.propTypes = {
    resetAppState: PropTypes.func
};

const ActivationEmailSent = (props) => {
    const {account, prefillAccount, resetAppState} = props;

    const backToCreateAccount = () => {
        redirectTo('/create-account');
        props.resetAppState({prefillAccount});
    }

    const resendActivationEmail = () => {
        if (account) {
            const {username, password} = account;
            props.createAccount({
                username, password,
                resendActivationEmail: true
            });
        }
    }

    const Subheader = () => {
        return (
            <div>
                {CONST_TEXTS.SENT_EMAIL_TO}
                <p>{account && account.username}</p>
                {CONST_TEXTS.CHECK_YOUR_EMAIL}
            </div>
        )
    };

    return (
        <AccountLayout size='small'
                       className='activation-email-sent-layout'
                       headerProps={{
                           Subheader,
                           header: CONST_TEXTS.TITLE
                       }}
                       customRedirect={<NavigateToCreateAccount resetAppState={resetAppState} />}
        >
            <span className='link-underline' onClick={backToCreateAccount}>
                {CONST_TEXTS.NOT_YOUR_EMAIL}
            </span>
            {account && account.state === appStates.LOADING ?
                <div className={CLASS_NAMES.EMAIL_SENDING_LOADER}>
                    <Animation type='loadingCircle'
                               height={100}
                               width={100}
                               hookId='resend-email-loading'
                               rendererSettings={{preserveAspectRatio: 'xMaxYMax'}}
                    />
                </div> :
                <div className={CLASS_NAMES.EMAIL_SENT_IMAGE}>
                    <Image name='email-sent.png' hookId='enter-code-img' />
                </div>
            }
            <div className={CLASS_NAMES.RESEND_EMAIL_SECTION}>
                <div>{CONST_TEXTS.SMALL_TEXT_FIRST}</div>
                <div>
                    {CONST_TEXTS.SMALL_TEXT_SECOND}
                    <span className='link-underline' onClick={resendActivationEmail}>
                        {CONST_TEXTS.SMALL_TEXT_LINK}
                    </span>
                </div>
            </div>
        </AccountLayout>
    );
}

ActivationEmailSent.propTypes = {
    account: PropTypes.object,
    prefillAccount: PropTypes.object,
    resetAppState: PropTypes.func,
    createAccount: PropTypes.func
};

const mapStateToProps = ({app}) => ({
    account: app.account,
    prefillAccount: app.prefillAccount
});

const mapDispatchToProps = ({
    resetAppState,
    createAccount
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivationEmailSent);