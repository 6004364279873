import React, {Component, createRef} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {GAProvider, Button, getHookId} from '@perion-undertone/ut-react-common';
import {get, isEqual} from 'lodash';
import moment from 'moment';
import {selectFeatureFlag} from '@flopflip/react-redux';
import {calendarPresets} from '../../calendarPresets';
import {registerWidgets} from './widgets/infra/widgetLoader';
import WidgetContainer from './widgets/infra/WidgetContainer';
import PeriodDropdownSelect from './PeriodDropdownSelect';
import WidgetsLibraryModal from './widgets/library/WidgetsLibraryModal';
import {loadFromLocalStorage, saveToLocalStorage, RetryFetchData, getWidgetList, getWidgetLibrary, setPeriod, setSelectedView,
    activeItems, textProvider, getReplacedValue, formatString, appStates} from './imports';

const CLASS_NAMES = {
    DASHBOARD_CONTAINER: 'dashboard-container',
    DASHBOARD_HEADER: 'dashboard-header',
    DASHBOARD_TITLE: 'dashboard-title',
    DASHBOARD_ACTIONS: 'dashboard-actions',
    WIDGET_LIBRARY_BTN: 'widget-library-button'
}
const CONSTANT_TEXTS = {
    PUBLISHER_DASHBOARD_CATEGORY: textProvider.getText('ga', 'category.publisherDashboard'),
    PUBLISHER_DASHBOARD_DATE_PICKER_CATEGORY: textProvider.getText('ga', 'category.publisherDashboardDatePicker'),
    WINDOW_SCROLL_TO_TOP_ACTION: textProvider.getText('ga', 'actions.windowScrollToTop'),
    WINDOW_SCROLL_TO_BOTTOM_ACTION: textProvider.getText('ga', 'actions.windowScrollToBottom'),
    PREDEFINE_REPORT_FORMAT: textProvider.getText('ga', 'actions.predefineReportFormat'),
    DROPDOWN_CUSTOM: textProvider.getText('dropdownOptions', 'custom'),
    ADUNITS: textProvider.getText('topAdunitsGrid', 'adUnits'),
    ALL_SITES: textProvider.getText('chartConfig', 'revenueAllSites'),
    WIDGET_GALLERY: textProvider.getText('widgetsLibraryModal', 'widgetGallery')
};
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: activeItems['custom'],
            openWidgetsLibraryModal: false,
            shouldRerender: true
        };
        this.dashboardContainer = createRef();
    }

    componentDidMount() {
        const {getWidgetList, getWidgetLibrary, setSelectedView, location} = this.props;
        let urlSearchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        window.addEventListener('beforeunload', this.componentCleanup);
        getWidgetList();
        registerWidgets();
        getWidgetLibrary();
        const contentWrapper = get(this.dashboardContainer, 'current.parentNode');
        if (contentWrapper) {
            contentWrapper.addEventListener('scroll', this.onScroll, false);
        }
        setSelectedView('revenueDropdown', CONSTANT_TEXTS.ALL_SITES);
        setSelectedView('topAdUnitsDropdown', CONSTANT_TEXTS.ADUNITS);
        if (params && params.defaultPeriod === 'last-month') {
            const period = {
                type: 'last-month',
                from: moment().utc().subtract(1, 'months').startOf('month'),
                to: moment().utc().subtract(1, 'months').endOf('month')
            }
            this.props.setPeriod(period);
            saveToLocalStorage("defaultWidgetPeriod", JSON.stringify(period))
            this.setState({
                activeItem: activeItems['last-month']
            });
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        const {widgetPreferences, selectedView, period} = this.props;
        const widgetPreferencesData = get(widgetPreferences, 'data');
        const nextWidgetPreferences = get(nextProps, 'widgetPreferences.data');
        const publishersState = get(this.props, 'publishers.state');
        const currentPublisherState = get(this.props, 'publishers.state');
        const nextPublisherState = get(nextProps, 'publishers.state');
        const newSelectedView = get(nextProps, 'selectedView');
        const nextPeriod = get(nextProps, 'period');

        // When typing in the publisher search bar, while on the dashboard, prevents the widget container component from rerendering
        if((currentPublisherState !== nextPublisherState && isEqual(widgetPreferencesData, nextWidgetPreferences) && isEqual(this.state, nextState)) ||
           (publishersState && currentPublisherState === nextPublisherState && isEqual(widgetPreferencesData, nextWidgetPreferences) && isEqual(this.state, nextState) && isEqual(period, nextPeriod))) {
            return false;
        }

        // When changing the View on a single widget, prevents the rerender of the entire content of the widget container component
        if(!isEqual(selectedView, newSelectedView) && widgetPreferences && widgetPreferences.state === appStates.LOADED) {
            return false;
        }

        // If the widgets swapped positions, the widget container component should not rerender
        if(widgetPreferences && widgetPreferences.state === appStates.LOADED && this.state === nextState && !this.state.shouldRerender) {
            return false;
        }

       return true;
    }

    componentWillUnmount() {
        const contentWrapper = get(this.dashboardContainer, 'current.parentNode');
        if(contentWrapper) {
            contentWrapper.removeEventListener('scroll', this.onScroll);
        }
        this.componentCleanup();
        window.removeEventListener('beforeunload', this.componentCleanup);
    }

    componentCleanup = () => {
        localStorage.removeItem('newlyAddedLibraryWidget');
    }

    onScroll = (e) => {
        const {user} = this.props;
        const container = e.target;
        if (container.scrollTop === 0) {
            GAProvider.notifyEvent(CONSTANT_TEXTS.PUBLISHER_DASHBOARD_CATEGORY, CONSTANT_TEXTS.WINDOW_SCROLL_TO_TOP_ACTION, getReplacedValue(user.data.name));
        }
        if ((container.scrollHeight - container.scrollTop) === container.clientHeight) {
            GAProvider.notifyEvent(CONSTANT_TEXTS.PUBLISHER_DASHBOARD_CATEGORY, CONSTANT_TEXTS.WINDOW_SCROLL_TO_BOTTOM_ACTION, getReplacedValue(user.data.name));
        }
    };

    onSelectPredefinedItem = (event, eventKey, dropdownProps) => {
        const {user} = this.props;
        const currentOptionValue = dropdownProps.children[eventKey].props.value;
        const activePreset = calendarPresets.find(preset =>  preset.period === currentOptionValue);
        const presetPeriod = {
            type: currentOptionValue,
            from: activePreset.startDate,
            to: activePreset.endDate
        }
        this.props.setPeriod(presetPeriod);
        saveToLocalStorage("defaultWidgetPeriod", JSON.stringify(presetPeriod))
        this.setState({
            activeItem: activeItems[currentOptionValue]
        });
        if (activeItems[currentOptionValue] !== this.state.activeItem) {
            GAProvider.notifyEvent(CONSTANT_TEXTS.PUBLISHER_DASHBOARD_DATE_PICKER_CATEGORY,
                formatString(CONSTANT_TEXTS.PREDEFINE_REPORT_FORMAT, activeItems[currentOptionValue]), getReplacedValue(user.data.name))
        }
    };

    onSelectCustomItem = () => {
        this.setState({
            activeItem: activeItems['custom']
        });
    }

    onPeriodChange = (periodFrom, periodTo) => {
        const period = {
            type: 'custom',
            from: periodFrom,
            to: periodTo
        }
        this.props.setPeriod(period);
        this.onSelectCustomItem();
    }

    onDatePickerOpen = (isOpen) => {
        const {user} = this.props;
        if(!isOpen){
            GAProvider.notifyEvent(CONSTANT_TEXTS.PUBLISHER_DASHBOARD_DATE_PICKER_CATEGORY,
                formatString(CONSTANT_TEXTS.PREDEFINE_REPORT_FORMAT, CONSTANT_TEXTS.DROPDOWN_CUSTOM), getReplacedValue(user.data.name));
        }
    };

    onLibraryModalOpen = () => {
        this.setState({openWidgetsLibraryModal: true});
        localStorage.removeItem('newlyAddedLibraryWidget');
    }

    onLibraryModalClose = () => {
        this.setState({openWidgetsLibraryModal: false});
    }

    getDefaultType() {
        const periodType = get(JSON.parse(loadFromLocalStorage('defaultWidgetPeriod')), 'type');
        return {type: periodType || 'last-month'};
    }

    getWidgets = () => {
        const {widgetPreferences} = this.props;
        if (widgetPreferences.data && widgetPreferences.data.userSchema) {
            registerWidgets(widgetPreferences.data.userSchema);
            return widgetPreferences.data.userSchema;
        }
        return widgetPreferences.data;
    }
    rerenderWidgets = (shouldRerender) => {
        // If we update from library modal, we set to true and rerender
        // If we update from widget container(swap positions only), we set it to false
        this.setState({shouldRerender});
    }
    render() {
        const {widgetPreferences, publisherId, period, widgetLibrary, isWidgetLibraryEnabled, setPeriod} = this.props;
        if (!widgetPreferences || (widgetPreferences.state === appStates.LOADING)) {
            return <div></div>
        }

        if (widgetPreferences && widgetPreferences.state === appStates.ERROR) {
            return <RetryFetchData classNames='centered' />
        }
        return (
            <div className={CLASS_NAMES.DASHBOARD_CONTAINER}
                 ref={this.dashboardContainer}
                 {...getHookId('dashboard-page')}
            >
                <div className={CLASS_NAMES.DASHBOARD_HEADER}>
                    <div className={CLASS_NAMES.DASHBOARD_TITLE}>Dashboard</div>
                    <div className={CLASS_NAMES.DASHBOARD_ACTIONS}>
                        <PeriodDropdownSelect onSelectPredefinedItem={this.onSelectPredefinedItem}
                                              activeItem={this.state.activeItem}
                                              period={period}
                                              onDatePickerOpen={this.onDatePickerOpen}
                                              onPeriodChange={this.onPeriodChange}
                                              setPeriod={setPeriod}
                        />
                        {isWidgetLibraryEnabled &&
                            <Button className={CLASS_NAMES.WIDGET_LIBRARY_BTN}
                                    icon="icon-library"
                                    iconPosition="left"
                                    onClick={this.onLibraryModalOpen}
                                    hookId="widget-library-button"
                            >
                                {CONSTANT_TEXTS.WIDGET_GALLERY}
                        </Button>}
                    </div>
                </div>
                <WidgetContainer widgetDefinitions={this.getWidgets()}
                                 publisherId={publisherId}
                                 period={this.getDefaultType()}
                                 rerenderWidgets={this.rerenderWidgets}
                />
                <WidgetsLibraryModal openModal={this.state.openWidgetsLibraryModal}
                                     closeModal={this.onLibraryModalClose}
                                     closeOnOutsideClick={false}
                                     widgetLibraryData={widgetLibrary}
                                     widgets={this.getWidgets()}
                                     rerenderWidgets={this.rerenderWidgets}
                />
            </div>
        );
    }
}

Dashboard.propTypes = {
    widgetPreferences: PropTypes.object,
    getWidgetList: PropTypes.func,
    getWidgetLibrary: PropTypes.func,
    widgetLibrary: PropTypes.object,
    publisherId: PropTypes.number,
    setPeriod: PropTypes.func,
    period: PropTypes.object,
    user: PropTypes.object,
    setSelectedView: PropTypes.func,
    selectedView: PropTypes.object,
    isWidgetLibraryEnabled: PropTypes.bool
};

const mapStateToProps = (state) => ({
    publisherId: state.app.publisher.data.id,
    widgetPreferences: state.dashboard.widgetPreferences,
    widgetLibrary: state.dashboard.widgetLibrary,
    period: state.dashboard.period,
    user: state.app.user,
    selectedView: state.dashboard.selectedView,
    isWidgetLibraryEnabled: selectFeatureFlag('widgetLibrary')(state),
    publishers: state.app.publishers
});

const mapDispatchToProps = ({
    getWidgetList,
    getWidgetLibrary,
    setPeriod,
    setSelectedView
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
