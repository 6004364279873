import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Modal, Button} from '@perion-undertone/ut-react-common';
import WidgetPreviewCard from './WidgetPreviewCard';
import {loadFromLocalStorage, saveToLocalStorage, textProvider, Icon, updateWidgetPreferences} from '../../imports';

const CLASS_NAMES = {
    WIDGETS_LIBRARY_MODAL: 'widgets-library-modal',
    MODAL_HEADER: 'modal-header',
    MODAL_HEADER_TITLE: 'modal-header-title',
    MODAL_HEADER_ICON_CONTAINER: 'modal-header-icon-container',
    MODAL_HEADER_TITLE_CONTAINER: 'modal-header-title-container',
    WIDGET_GALLERY_TITLE: 'widget-gallery-title',
    WIDGET_GALLERY_DESCRIPTION: 'widget-gallery-description',
    MODAL_BODY: 'modal-body',
    MODAL_FOOTER: 'modal-footer',
    WIDGET_GALLERY_DONE_BTN: 'widget-gallery-done-btn'
}

const CONSTANT_TEXTS = {
    WIDGET_GALLERY: textProvider.getText('widgetsLibraryModal', 'widgetGallery'),
    WIDGET_GALLERY_DESCRIPTION: textProvider.getText('widgetsLibraryModal', 'widgetGalleryDescription'),
    FIND_WIDGETS: textProvider.getText('common', 'find'),
    DONE: textProvider.getText('common', 'done'),
}

class WidgetsLibraryModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedWidgets: props.widgets
        }
    }

    cancelModal = () => {
        const {widgets, closeModal} = this.props;
        closeModal();
        this.setState({selectedWidgets: widgets});
        localStorage.removeItem('newlyAddedLibraryWidget');
    }

    handleWidgetsSave = () => {
        const {updateWidgetPreferences, closeModal, rerenderWidgets} = this.props;
        updateWidgetPreferences(this.state.selectedWidgets);
        rerenderWidgets(true);
        closeModal();
    }

    handleWidgetChange = (libraryWidget) => {
        const {selectedWidgets} = this.state;
        const {id, type, kind, component, w, h} = libraryWidget;
        let lsWidgets = JSON.parse(loadFromLocalStorage('newlyAddedLibraryWidget')) || [];
        const selectedWidget = selectedWidgets.find(widget => widget.type === type);
        if (selectedWidget) {
            const newSelectedWidgets = selectedWidgets.filter(widget => widget.type !== selectedWidget.type);
            this.setState({selectedWidgets: newSelectedWidgets});
            lsWidgets.splice(lsWidgets.indexOf(selectedWidget.i), 1);
        } else {
            const widgetPositionX = (id % 2 === 0) || id === 1 ? 0 : 6;
            const widgetToBeAdded = {
                i: id.toString(),
                type: type,
                kind: kind,
                component: component,
                regularWidth: w,
                smallWidth: w - 1,
                height: h,
                x: widgetPositionX,
                y: 0,
                static: libraryWidget.static
            }
            // widgetToBeAdded is one widget object
            // lsWidgets is an array of ids(i) for localStorage
            let selectedWidgetsArr = [...selectedWidgets];
            if(widgetToBeAdded.type === 'main-kpis') {
                lsWidgets.unshift(widgetToBeAdded.i);
                selectedWidgetsArr.unshift(widgetToBeAdded);
            }
            else {
                lsWidgets.push(widgetToBeAdded.i);
                selectedWidgetsArr.push(widgetToBeAdded);
            }
            this.setState({selectedWidgets: selectedWidgetsArr});
        }
        saveToLocalStorage('newlyAddedLibraryWidget', JSON.stringify(lsWidgets));
    }

    render() {
        const {openModal, widgetLibraryData} = this.props;
        if (!openModal) {
            return null;
        }

        return (
            <div>
                <Modal className={CLASS_NAMES.WIDGETS_LIBRARY_MODAL}
                       open={!!openModal}
                       basic={true}
                       closeOnOutsideClick={false}
                       onModalClose={this.cancelModal}
                       hookId="widgets-library-modal"
                >
                    <div className={CLASS_NAMES.MODAL_HEADER}>
                        <div className={CLASS_NAMES.MODAL_HEADER_TITLE}>
                            <div className={CLASS_NAMES.MODAL_HEADER_ICON_CONTAINER}>
                                <Icon icon='icon-widgets.svg' />
                            </div>
                            <div className={CLASS_NAMES.MODAL_HEADER_TITLE_CONTAINER}>
                                <div className={CLASS_NAMES.WIDGET_GALLERY_TITLE}>{CONSTANT_TEXTS.WIDGET_GALLERY}</div>
                                <div className={CLASS_NAMES.WIDGET_GALLERY_DESCRIPTION}>{CONSTANT_TEXTS.WIDGET_GALLERY_DESCRIPTION}</div>
                            </div>
                        </div>
                        {/* <div className="modal-header-input-container">
                            <div className="modal-header-input-text">{CONSTANT_TEXTS.FIND_WIDGETS}</div>
                            <Input type='search'
                                iconPosition='right'
                                className="modal-header-input"
                                placeholder='Type group name'
                                // value={currentGroup ? currentGroup.name : ''}
                                onChange={this.setTitle} />
                        </div> */}
                    </div>
                    <div className={CLASS_NAMES.MODAL_BODY}>
                        {widgetLibraryData.data.map(widget => {
                            return widget.type && <WidgetPreviewCard key={widget.id}
                                                                     libraryWidget={widget}
                                                                     widgets={this.state.selectedWidgets}
                                                                     onWidgetChange={this.handleWidgetChange.bind(this, widget)}
                            />
                        })}
                    </div>
                    <div className={CLASS_NAMES.MODAL_FOOTER}>
                        <Button onClick={this.handleWidgetsSave}
                                className={CLASS_NAMES.WIDGET_GALLERY_DONE_BTN}
                                hookId='widgets-library-modal-done-btn'
                        >
                            {CONSTANT_TEXTS.DONE}
                        </Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

WidgetsLibraryModal.propTypes = {
    openModal: PropTypes.bool,
    closeModal: PropTypes.func,
    widgetLibraryData: PropTypes.object,
    widgets: PropTypes.array,
    libraryWidget: PropTypes.string,
    updateWidgetPreferences: PropTypes.func,
    rerenderWidgets: PropTypes.func
};

const mapDispatchToProps = ({
    updateWidgetPreferences
});

export default connect(null, mapDispatchToProps)(WidgetsLibraryModal);
