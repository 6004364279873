export const supplyConstants = {
    OPEN_ADD_SITES_MODAL: 'OPEN_ADD_SITES_MODAL',
    CLOSE_ADD_SITES_MODAL: 'CLOSE_ADD_SITES_MODAL',

    VALIDATE_DOMAIN_REQUEST: 'VALIDATE_DOMAIN_REQUEST',
    VALIDATE_DOMAIN_SUCCESS: 'VALIDATE_DOMAIN_SUCCESS',
    VALIDATE_DOMAIN_FAILURE: 'VALIDATE_DOMAIN_FAILURE',

    SET_ADSTXT_DOMAIN_REQUEST: 'SET_ADSTXT_DOMAIN_REQUEST',
    SET_ADSTXT_DOMAIN_SUCCESS: 'SET_ADSTXT_DOMAIN_SUCCESS',
    SET_ADSTXT_DOMAIN_FAILURE: 'SET_ADSTXT_DOMAIN_FAILURE',

    OPEN_ADS_TXT_MODAL: 'OPEN_ADS_TXT_MODAL',
    CLOSE_ADS_TXT_MODAL: 'CLOSE_ADS_TXT_MODAL',

    ADD_SELECTED_SITE: 'ADD_SELECTED_SITE',
    DELETE_SELECTED_SITE: 'DELETE_SELECTED_SITE',

    SUBMIT_SITES_REQUEST: 'SUBMIT_SITES_REQUEST',
    SUBMIT_SITES_FAILURE: 'SUBMIT_SITES_FAILURE',
    SUBMIT_SITES_SUCCESS: 'SUBMIT_SITES_SUCCESS',

    SUPPLY_SOURCES_REQUEST: 'SUPPLY_SOURCES_REQUEST',
    SUPPLY_SOURCES_SUCCESS: 'SUPPLY_SOURCES_SUCCESS',
    SUPPLY_SOURCES_FAILURE: 'SUPPLY_SOURCES_FAILURE',


    SEARCH_SUPPLY_SITES: 'SEARCH_SUPPLY_SITES',
    SEARCH_BLOCKED_SALES_ACCOUNTS: 'SEARCH_BLOCKED_SALES_ACCOUNTS',
    SEARCH_BLOCKED_ADVERTISERS: 'SEARCH_BLOCKED_ADVERTISERS',

    // Advertisers blocklist
    GET_BLOCKED_ADVERTISERS_REQUEST: 'GET_BLOCKED_ADVERTISERS_REQUEST',
    GET_BLOCKED_ADVERTISERS_SUCCESS: 'GET_BLOCKED_ADVERTISERS_SUCCESS',
    GET_BLOCKED_ADVERTISERS_FAILURE: 'GET_BLOCKED_ADVERTISERS_FAILURE',

    BLOCK_ADVERTISERS_REQUEST: 'BLOCK_ADVERTISERS_REQUEST',
    BLOCK_ADVERTISERS_SUCCESS: 'BLOCK_ADVERTISERS_SUCCESS',
    BLOCK_ADVERTISERS_FAILURE: 'BLOCK_ADVERTISERS_FAILURE',

    GET_BLOCKED_CATEGORIES_REQUEST: 'GET_BLOCKED_CATEGORIES_REQUEST',
    GET_BLOCKED_CATEGORIES_SUCCESS: 'GET_BLOCKED_CATEGORIES_SUCCESS',
    GET_BLOCKED_CATEGORIES_FAILURE: 'GET_BLOCKED_CATEGORIES_FAILURE',

    BLOCK_CATEGORIES_REQUEST: 'BLOCK_CATEGORIES_REQUEST',
    BLOCK_CATEGORIES_SUCCESS: 'BLOCK_CATEGORIES_SUCCESS',
    BLOCK_CATEGORIES_FAILURE: 'BLOCK_CATEGORIES_FAILURE',

    GET_BULK_BLOCKED_CATEGORIES_REQUEST: 'GET_BULK_BLOCKED_CATEGORIES_REQUEST',
    GET_BULK_BLOCKED_CATEGORIES_SUCCESS: 'GET_BULK_BLOCKED_CATEGORIES_SUCCESS',
    GET_BULK_BLOCKED_CATEGORIES_FAILURE: 'GET_BULK_BLOCKED_CATEGORIES_FAILURE',

    BULK_BLOCK_CATEGORIES_REQUEST: 'BULK_BLOCK_CATEGORIES_REQUEST',
    BULK_BLOCK_CATEGORIES_SUCCESS: 'BULK_BLOCK_CATEGORIES_SUCCESS',
    BULK_BLOCK_CATEGORIES_FAILURE: 'BULK_BLOCK_CATEGORIES_FAILURE',

    GET_VIDEO_LIMITATIONS_REQUEST: 'GET_VIDEO_LIMITATIONS_REQUEST',
    GET_VIDEO_LIMITATIONS_SUCCESS: 'GET_VIDEO_LIMITATIONS_SUCCESS',
    GET_VIDEO_LIMITATIONS_FAILURE: 'GET_VIDEO_LIMITATIONS_FAILURE',

    MANAGE_VIDEO_LIMITATIONS_REQUEST: 'MANAGE_VIDEO_LIMITATIONS_REQUEST',
    MANAGE_VIDEO_LIMITATIONS_SUCCESS: 'MANAGE_VIDEO_LIMITATIONS_SUCCESS',
    MANAGE_VIDEO_LIMITATIONS_FAILURE: 'MANAGE_VIDEO_LIMITATIONS_FAILURE',

    GET_ADUNIT_CONFIGURATIONS_REQUEST: 'GET_ADUNIT_CONFIGURATIONS_REQUEST',
    GET_ADUNIT_CONFIGURATIONS_SUCCESS: 'GET_ADUNIT_CONFIGURATIONS_SUCCESS',
    GET_ADUNIT_CONFIGURATIONS_FAILURE: 'GET_ADUNIT_CONFIGURATIONS_FAILURE',

    SAVE_ADUNIT_CONFIGURATIONS_REQUEST: 'SAVE_ADUNIT_CONFIGURATIONS_REQUEST',
    SAVE_ADUNIT_CONFIGURATIONS_SUCCESS: 'SAVE_ADUNIT_CONFIGURATIONS_SUCCESS',
    SAVE_ADUNIT_CONFIGURATIONS_FAILURE: 'SAVE_ADUNIT_CONFIGURATIONS_FAILURE',
 
    CHANGE_PAGE: '@@Supply/CHANGE_PAGE',
    CHANGE_ITEMS_PER_PAGE: '@@Supply/CHANGE_ITEMS_PER_PAGE'
};
