import _ from 'lodash';
import {authorization} from '@perion-undertone/ut-permissions-module';

export const routesHelper = (localRoutes, featuresConfig, publisherId, user, flags, publisherTypeCd, publisherCountry) => {
    if (!user) {
        return [];
    }

    const newRoutes = localRoutes.map((route) => {
        const feature = featuresConfig.find((feature) => feature.featureName === route.featureName);
        const hasPermission = authorization.resolve(feature.permission, user.data.name, {type: "publisher", id: _.toString(publisherId)});
        const isFlagEnabled = _.isBoolean(flags[feature.featureFlag]) ? flags[feature.featureFlag] : true;
        if (route.featureName === 'Payment') {
            route['isEnabled'] = feature.isEnabled && hasPermission && isFlagEnabled && !!publisherCountry;
        } else {
            route['isEnabled'] = feature.isEnabled && hasPermission && isFlagEnabled;
        }

        const disableSupplyForDSPPublishers = publisherTypeCd === 'DSP' && route.url === '/supply';
        if (disableSupplyForDSPPublishers) {
            route['isEnabled'] = false;
        }
        
        return route;
    });

    return newRoutes;
};