import React from 'react';
import PropTypes from 'prop-types';
import {TextEllipsis} from '@perion-undertone/ut-react-common';

const DescriptionFormatter = ({value}) => {
    return (
        <TextEllipsis placement="top">
            {value || 'N/A'}
        </TextEllipsis>
    );
};

DescriptionFormatter.propTypes = {
    value: PropTypes.string,
};

export default DescriptionFormatter;