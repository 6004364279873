export const VIDEO_DIRECTION_DETAILS = {
    inlineVideo: {
        desktop: {
            deviceType: 'Desktop',
            name: 'inlineVideoDesktop',
            images: {
                'bottom-right': {
                    img: 'adunit-desktop-right.svg',
                    imgSelected: 'adunit-desktop-right-selected.svg',
                    label: 'Right',
                    example: 'https://creative-p.undertone.com/demo/inline-video.html?float=bottom-right'
                },
                'bottom-left': {
                    img: 'adunit-desktop-left.svg',
                    imgSelected: 'adunit-desktop-left-selected.svg',
                    label: 'Left',
                    example: 'https://creative-p.undertone.com/demo/inline-video.html?float=bottom-left'
                },
                none: {
                    img: 'adunit-desktop-none.svg',
                    imgSelected: 'adunit-desktop-none-selected.svg',
                    label: 'None',
                    example: 'https://creative-p.undertone.com/demo/inline-video.html?float='
                }
            }
        },
        mobile: {
            deviceType: 'Mobile',
            name: 'inlineVideoMobile',
            images: {
                'bottom-right': {
                    img: 'adunit-mobile-right.svg',
                    imgSelected: 'adunit-mobile-right-selected.svg',
                    label: 'Right',
                    example: 'https://admin.sparkflow.net/d/?d=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NzY3OTEsImZvcm1hdCI6MjQxfQ.AjuSlCnCecQP6aqwDJdL_K57vw_S02BgZtt1E_AOeNw&nodevices=1&device=Apple+iPhone+5+%2F+5S+%2F+5C&float=bottom-right'
                },
                'bottom-left': {
                    img: 'adunit-mobile-left.svg',
                    imgSelected: 'adunit-mobile-left-selected.svg',
                    label: 'Left',
                    example: 'https://admin.sparkflow.net/d/?d=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NzY3OTEsImZvcm1hdCI6MjQxfQ.AjuSlCnCecQP6aqwDJdL_K57vw_S02BgZtt1E_AOeNw&nodevices=1&device=Apple+iPhone+5+%2F+5S+%2F+5C&float=bottom-left'
                },
                none: {
                    img: 'adunit-mobile-none.svg',
                    imgSelected: 'adunit-mobile-none-selected.svg',
                    label: 'None',
                    example: 'https://admin.sparkflow.net/d/?d=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NzY3OTEsImZvcm1hdCI6MjQxfQ.AjuSlCnCecQP6aqwDJdL_K57vw_S02BgZtt1E_AOeNw&nodevices=1&device=Apple+iPhone+5+%2F+5S+%2F+5C&float=none'
                }
            }
        },
    },
    videoAdhesion: {
        desktop: {
            deviceType: 'Desktop',
            name: 'videoAdhesionDesktop',
            images: {
                'bottom-right': {
                    img: 'adunit-desktop-from-right.svg',
                    imgSelected: 'adunit-desktop-from-right-selected.svg',
                    label: 'From Right',
                    example: 'https://creative-p.undertone.com/demo/video-adhesion.html?float=bottom-right'
                },
                'bottom-left': {
                    img: 'adunit-desktop-from-left.svg',
                    imgSelected: 'adunit-desktop-from-left-selected.svg',
                    label: 'From Left',
                    example: 'https://creative-p.undertone.com/demo/video-adhesion.html?float=bottom-left'
                },
            }
        }
    },
    videoOrientation: {
        orientation: {
            deviceType: 'Mobile',
            name: 'inlineVideoOrientation',
            images: {
                'horizontal-only': {
                    img: 'adunit-mobile-horizontal.svg',
                    imgSelected: 'adunit-mobile-horizontal-selected.svg',
                    label: 'Horizontal',
                    isDisabled: true,
                    isSelected: true,
                    exampleText: 'Horizontal example',
                    example: 'https://admin.sparkflow.net/d/?d=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjgwNjk2IiwiZm9ybWF0IjoiMjE3In0.7zx0OU9nArbKMZhIA6mxQJibK4CKgER9ZGpFmx5UWjw&nodevices=1&zoom=1&device=Apple+iPhone+5+%2F+5S+%2F+5C&rotated=0'
                },
                'both': {
                    img: 'adunit-mobile-vertical.svg',
                    imgSelected: 'adunit-mobile-vertical-selected.svg',
                    label: 'Vertical',
                    exampleText: 'Vertical example',
                    example: 'https://admin-staging.sparkflow.net/d/?d=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjEwNDk4OCIsImZvcm1hdCI6IjI0NSJ9.ohfwMzHaQtdEOlIM75gYO81o_R9-DlrlceCgeg09Wcw&nodevices=1&device=Apple+iPhone+5+%2F+5S+%2F+5C'
                },
            }
        }
    }
};
