import React from 'react';
import PropTypes from 'prop-types';

const SupplyNameTooltipFormatter = ({row}) => {
    return (<div>
                <div title={row.SupplySource.url}>{row.displayName}</div>
            </div>);
};

SupplyNameTooltipFormatter.propTypes = {
    row: PropTypes.object,
};

export default SupplyNameTooltipFormatter;